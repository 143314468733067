import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  mainImg,
  logoImg
} from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  hideSearchBar?: boolean;
  navigation: any;
  handleGlobalSearch?: (evt: any) => void | undefined;
  searchKeyword?: string | undefined;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  drawerStatus: boolean;
  drawerWidth: number;
  mainImage: any;
  logoImage: any;
  sidebarOpenLogoWidth: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LayoutController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      logoImage: logoImg,
      mainImage: mainImg,
      drawerStatus: localStorage.getItem('drawerStatus') ? true : false,
      drawerWidth: localStorage.getItem('drawerStatus') ? 210 : 133,
      sidebarOpenLogoWidth: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const token = localStorage.getItem("token");
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleDrawerClick = () => {
    const newStatus = !this.state.drawerStatus
    this.setState({
      drawerStatus: newStatus,
      drawerWidth: this.state.drawerStatus ? 133 : 210
    });

    localStorage.setItem('drawerStatus', newStatus ? 'true' : '');
  };
  // Customizable Area End
}
