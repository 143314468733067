import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButtonMU from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import CustomDeleteModal from "../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import { FormControl, FormLabel, Slider, TextField } from "@material-ui/core";
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import ExpenseListController, {
  IExpense,
  Props
} from "./ExpenseListController";
import { debounce } from "lodash";
import ExpenseModalForm from "./ExpenseModalForm.web";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  }
});
// Customizable Area End

class ExpenseList extends ExpenseListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta: { total_pages },
      isLoading,
      expenses,
      anchorEl,
      actionMenuItems,
      deleteModalOpen,
      formMode,
      selectAllCheckboxChecked,
      currentPage
    } = this.state;

    return (
      <Layout
        navigation={navigation}
        handleGlobalSearch={this.handleSearchChange}
      >
        <Grid container className={classes.container}>
          <PageHeader
            title="Expense"
            createButtonTitle="Create"
            importModalTitle="Import expense by CSV file"
            printReference={this.printReferance}
            FilterTitle={"Select Expense"}
            filterForm={this.renderFilterForm()}
            onClickCreateButton={this.onClickCreate.bind(this)}
            onUploadCSVForImport={this.onUploadCSVForImport.bind(this)}
            onClickExportCSVButton={this.onClickExportCSVButton.bind(this)}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            onClickDownloadCSVTemplate={this.onClickExportCSVButton.bind(this)}
            // importErrorMessage={this.state.importErrorMessage}
          />

          <TableContainer
            style={{ overflow: isLoading ? "hidden" : "" }}
            component={Paper}
            className={classNames(classes.mt20, classes.tableContainer)}
            ref={(el: any) => (this.printReferance = el)}
          >
            {isLoading && <Loader loading positionType={"center"} />}

            <Table
              ref={this.tableRef}
              stickyHeader
              className={classes.table}
              aria-label="Expense"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.actionColumn,
                      classes.tableHead
                    )}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      inputProps={{ "aria-label": "select all tax reates" }}
                      onChange={this.onSelectAllExpenses.bind(this)}
                      indeterminate={
                        selectAllCheckboxChecked === "indeterminate"
                      }
                      checked={!!selectAllCheckboxChecked}
                      key={`tax-rate-super-checkbox-${selectAllCheckboxChecked}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHead}>Date</TableCell>
                  <TableCell className={classes.tableHead}>
                    Expense Acc
                  </TableCell>
                  <TableCell className={classes.tableHead}>Invoice#</TableCell>
                  <TableCell className={classes.tableHead}>Vendor</TableCell>
                  <TableCell className={classes.tableHead}>
                    Paid Through
                  </TableCell>
                  <TableCell className={classes.tableHead}>Status</TableCell>
                  <TableCell className={classes.tableHead}>Amount</TableCell>
                  <TableCell className={classes.tableHead} align="center" />
                  <TableCell className={classes.tableHead} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {expenses.length ? (
                  expenses.map((row, i) => {
                    const {
                      id,
                      selected,

                      attributes: {
                        date,
                        expence_account,
                        vendor,
                        paid_trough,
                        ammount
                      }
                    } = row;
                    return (
                      <TableRow key={`expense-${id}`}>
                        <TableCell
                          align="center"
                          className={classes.actionColumn}
                        >
                          <Checkbox
                            className={classes.checkbox}
                            value={selected}
                            checked={selected}
                            inputProps={{ "aria-label": "select expense" }}
                            key={`${id}-checkbox-${selected}`}
                            onChange={event => this.onSelectExpense(event, row)}
                          />
                        </TableCell>
                        <TableCell>
                          {moment(date).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{expence_account}</TableCell>
                        <TableCell>{id}</TableCell>
                        <TableCell>{vendor}</TableCell>
                        <TableCell>{paid_trough}</TableCell>
                        <TableCell>{"---"}</TableCell>
                        <TableCell>{ammount}</TableCell>
                        <TableCell
                          align="center"
                          className={classes.actionColumn}
                        >
                          <IconButton
                            icon={Icon.Action}
                            borderless
                            className={classes.actionColumnButton}
                            onClick={event =>
                              this.onClickActionButton(event, row)
                            }
                          />
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    );
                  })
                ) : !this.state.isLoading ? (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      style={{
                        color: "red",
                        fontWeight: 600,
                        fontSize: "18px",
                        textAlign: "center"
                      }}
                    >
                      {"No Record Found"}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justify="flex-end" alignItems="center">
            <Pagination
              page={currentPage}
              totalPageCount={total_pages}
              handlePageClick={this.onClickPagination.bind(this)}
            />
          </Grid>
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          arrowSide="right"
          items={actionMenuItems}
          anchorOrigin={{ vertical: -12, horizontal: -48 }}
        />

        <CustomDeleteModal
          DialogueDeletetitle={"Delete Expense"}
          open={deleteModalOpen}
          handleClose={this.onCloseDeleteModal.bind(this)}
          handleSubmit={this.onSubmitDeleteModal.bind(this)}
          msgHandle="multiple"
        />
        <ExpenseModalForm
          {...this.formProps[this.state.formMode]}
          isOpen={this.state.isFormModalOpened}
          initialValues={this.state.form}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start

  private renderFilterForm() {
    const {
      filterForm: { paid_through: paid_trough, expense_account, invoice }
    } = this.state;

    return (
      <Grid container direction="column">
        <OutlinedSelect
          val={expense_account}
          options={[{ id: "Fees & Charges", name: "Fees & Charges" }]}
          label="Expense Account"
          name={"expense_account"}
          formControlStyle={{
            // width: "150px",
            margin: 0
          }}
          handleChange={this.onChangeFilterExpenseAccount}
        />
        <OutlinedSelect
          labelStyle={{ marginTop: "20px" }}
          val={paid_trough}
          options={[
            { id: "Advance Tax", name: "Advance Tax" },
            { id: "Prepaid Expenses", name: "Prepaid Expenses" }
          ]}
          label="Paid Through"
          name={"paid_trough"}
          formControlStyle={{
            // width: "150px",
            margin: 0
          }}
          handleChange={this.onChangeFilterPaidThrough}
        />
        <FormControl style={{ width: "100%", marginTop: "15px" }}>
          <FormLabel style={{ marginBottom: "8px" }}>Invoice #</FormLabel>
          <TextField
            value={invoice}
            color={"primary"}
            variant="outlined"
            type={"text"}
            name={"invoice"}
            placeholder={"Enter Invoice"}
            onChange={this.onChangeFilterInvoice}
          />
        </FormControl>
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(ExpenseList);
