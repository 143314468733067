import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { calenderIcon } from "./assets";
import moment from "moment";
export interface Props {
  open: boolean;
  handleClose: (type: string, action: boolean) => void;
  classes: any;
  ConcessionTypeList: [];
  handleSubmit: (formData: object) => void;
  handleChange: () => void;
  concessionCreateerrorMessage: string;
  concessionErrorMessage: concessionErrorAttribute;
  handleSubmitConcessionType: (FormData: object) => void;
  handleEditConcessionTypeSubmit:(FormData: object) => void;
  handleDeleteConcessionTypeSubmit:() => void;
  selectedConcessionTypeItem:any;
  handleConcessionTypeEditmodal:any;
  handleConcessionTypeEditmodalClose:any;
  handleConcessionTypemodalOpen:any;
  handleConcessionTypemodal:any;
  handleConcessionTypemodalClose:any;
  handleConcessionTypeDeletemodalClose:any
  handleConcessionTypeDeletemodal:any
  handleConcessionTypeEditmodalOpen:any
  handleConcessionTypeDeletemodalOpen:any
  concessionTypeCreateerrorMessage:any
}

interface InitialField {
  fee_concession_type_id: string;
  name: string;
  valid_until: any;
  mode: string;
  amount: any;
}
interface concessionErrorAttribute {
  name: string;
  fee_concession_type: any;
  valid_until: any;
  mode: string;
  amount: any;
}
interface S {
  closeModal: boolean;
  data: any;
  noteHeader: string;
  disabledbutton: boolean;
  loadingSpinner: boolean;
  concessionFormstate: InitialField;
  concessionNameError: string;
  concessionTypeError: string;
  concessionModeError: string;
  concessionAmountError: string;
  concessionValidUntilError: string;
  handalConcessionTypemodal: boolean;
  calenderIcon: any;
}

interface SS {
  id: any;
}

const concessionInitialField: InitialField = {
  fee_concession_type_id: "0",
  name: "",
  mode: "",
  amount: "",
  valid_until: ""
};

export default class CreateConcessionController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [];

    this.state = {
      closeModal: false,
      data: [],
      calenderIcon: calenderIcon,
      noteHeader: "",
      disabledbutton: true,
      loadingSpinner: false,
      concessionFormstate: concessionInitialField,
      concessionNameError: "",
      concessionTypeError: "",
      concessionValidUntilError: "",
      concessionModeError: "",
      concessionAmountError: "",
      handalConcessionTypemodal: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open) {
      this.resetFormState();
    }
  }

  resetFormState() {
    this.setState({
      concessionFormstate: concessionInitialField,
      concessionNameError: "",
      concessionTypeError: "",
      concessionModeError: "",
      concessionAmountError: "",
      concessionValidUntilError: ""
    });
  }

  modalhandleClose = () => {
    this.setState({
      closeModal: true,
      concessionValidUntilError: "",
      concessionAmountError: "",
      concessionNameError: "",
      concessionTypeError: "",
      concessionModeError: ""
    });
    this.props.handleClose("create", false);
  };

  handleChange = (e: any) => {
    let value = e.target.value;
    // if(value === "percentage"){
    //   this.convertToPercentage()
    // }
    this.setState({
      concessionFormstate: {
        ...this.state.concessionFormstate,
        [e.target.name]: value.trimStart() 
      }
    });
  };

  handleDateChange = (value: any) => {
    let value1= moment(value).format("y-MM-d")

    this.setState({
      concessionFormstate: {
        ...this.state.concessionFormstate,
        valid_until: value
      }
    });
  };

  handleValidation = (formState: InitialField) => {
    let isValid = true;
    const regex = /^[^-\s][a-zA-Z0-9_\s-]{2,40}[^\s]$/i;
    const regex2 = /^[0-9]+$/;
    // expression="^[A-Za-z][A-Za-z0-9]*$"
    let valueAmount = "-" + formState.amount;
    if (formState.name === "") {
      this.setState({ concessionNameError: "Concession name is required" });
      isValid = false;
    } else if (formState.name && regex.test(formState.name) === false) {
      this.setState({
        concessionNameError: "Concession name is invalid"
      });
      isValid = false;
    } else {
      this.setState({ concessionNameError: "" });
    }
    if (
      formState.fee_concession_type_id === "0" ||
      formState.fee_concession_type_id === "-1"
    ) {
      this.setState({
        concessionTypeError: "Concession type is required"
      });
      isValid = false;
    } else {
      this.setState({ concessionTypeError: "" });
    }
    if (formState.mode === "" || formState.mode === "null") {
      this.setState({
        concessionModeError: "Mode is required"
      });
      isValid = false;
    } else {
      this.setState({ concessionModeError: "" });
    }
    if (formState.valid_until === "") {
      this.setState({
        concessionValidUntilError: "Date is required"
      });
      isValid = false;
    } else {
      this.setState({ concessionValidUntilError: "" });
    }
    if (
      formState.amount === "" ||
      formState.amount === "0" ||
      formState.amount === 0 ||
      formState.amount === "-"
    ) {
      this.setState({
        concessionAmountError: "Amount is required"
      });
      isValid = false;
    } else if (regex2.test(formState.amount) === false) {
      this.setState({
        concessionAmountError: "Amount is Invalid"
      });
      isValid = false;
    } else if (formState.mode === "percentage" && formState.amount > 100) {
      this.setState({
        concessionAmountError: "You cannot Enter more than 100%"
      });
      isValid = false;
    } else {
      this.setState({ concessionAmountError: "" });
    }

    
    return isValid;
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation(this.state.concessionFormstate)) {
      this.props.handleSubmit({
        concessionData: this.state.concessionFormstate
      });
    }
  };
}
