import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import IconButtonMU from "@material-ui/core/IconButton";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import FeeCollectionForm from "./FeeCollectionForm.web";
import { capitalize } from "lodash";
import BulkUpdateFeeCollection from './BulkUpdateFeeCollection.web';
import Button from '@material-ui/core/Button';
import { bulkUpdateIcon } from "./assets";
// Customizable Area End

import FeeCollectionListController, {
  IFine,
  Props
} from "./FeeCollectionListController";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  bulkUpdateBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#fffff",
    border: "1px solid #4b4f5f",
    borderRadius: "5px",
    color: "#455366",
  },
  emptyDash: {
    height: 1,
    width: 16,
    backgroundColor: "#64748c"
  },
  filterItemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    marginTop: 20
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableCollapse: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      //backgroundColor: "white",
      //borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },

    "& tbody": {
      backgroundColor: "white",
    }
  }, 
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  },
  tableHeadRow: {
    backgroundColor: "#FFF8E8",
    fontWeight: 'normal',
    fontSize: '14px',
    borderBottom: 'none'
  },
  capitalizeFirst: {
    textTransform: "capitalize"
  },
  collapseColumn: {
    maxWidth: "10px",
    width: "10px"
  }
});
// Customizable Area End

class FeeCollectionList extends FeeCollectionListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta: { total_pages },
      isLoading,
      feeCollectionList,
      anchorEl,
      actionMenuItems,
      feeCollectionFormMode,
      selectAllCheckboxChecked,
      currentPage,
      searchKeyword,
      closeImportModal,
      checkBulkUpdateRow,
      disableBukUpload
    } = this.state;

    return (
      <Layout
        searchKeyword={searchKeyword}
        handleGlobalSearch={this.handleSearchChange}
        navigation={navigation}
      >
        <Grid container className={classes.container}>
          <PageHeader
            FilterTitle="Filters"
            title="Fee Collection"
            printReference={this.printReferance}
            onClickPrint={() => this.setState({ showAllRows: true })}
            onAfterPrint={() => this.setState({ showAllRows: false })}
            filterForm={this.renderFilterForm()}
            onClickExportCSVButton={this.onClickExportCSVButton.bind(
              this,
              false
            )}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            onClickDownloadCSVTemplate={this.onClickExportCSVButton.bind(
              this,
              true
            )}
            closeImportModal={closeImportModal}
            haveCreateButton={false}
          />

          {!disableBukUpload && (
            <Grid style={{ paddingTop: "20px" }}>
              <Button
                className={classes.bulkUpdateBtn}
                onClick={() => this.setState({ bulkUpdateRoleModalOpen: true })}
              >
              <img style={{ marginRight: "6px" }} height="18px" width="18px" src={bulkUpdateIcon} /> Bulk Update
              </Button>
            </Grid>
          )}

          <TableContainer
            style={{ overflow: isLoading ? "hidden" : "" }}
            component={Paper}
            className={classNames(classes.mt20, classes.tableContainer)}
            ref={(el: any) => (this.printReferance = el)}
          >
            {isLoading && <Loader loading positionType={"center"} />}

            <Table
              ref={this.tableRef}
              stickyHeader
              className={classes.table}
              aria-label="fee collection list"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classNames(
                      classes.tableHead,
                      classes.collapseColumn
                    )}
                  />
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.actionColumn,
                      classes.tableHead
                    )}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      inputProps={{
                        "aria-label": "select all fee collection list"
                      }}
                      onChange={this.onSelectAllFeeCollectionList.bind(this)}
                      indeterminate={
                        selectAllCheckboxChecked === "indeterminate"
                      }
                      checked={!!selectAllCheckboxChecked}
                      key={`feeCollection-list-super-checkbox-${selectAllCheckboxChecked}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Full Name
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Class
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Division
                  </TableCell>
                  <TableCell className={classes.tableHead}>Total Amount</TableCell>
                  <TableCell className={classes.tableHead}>
                    Fee Structure
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Installment
                  </TableCell>
                  <TableCell className={classes.tableHead} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {feeCollectionList && feeCollectionList.map((row, i) => {
                  const first_name = row?.attributes?.first_name
                  const last_name = row?.attributes?.last_name
                  const school_class = row?.fee_structures?.data?.attributes?.grade
                  const division = row?.fee_structures?.data?.attributes?.division
                  const id = row?.id
                  const selected = row?.selected
                  const total_amount = row?.fee_structures?.data?.attributes?.installment?.data?.attributes?.total_amount
                  const name = row?.fee_structures?.data?.attributes?.name
                  const installment = row?.fee_structures?.data?.attributes?.installment
                  return (
                    <>
                      <TableRow
                        key={`fee-collection-${id}`}
                      >
                        <TableCell
                          // align="center"
                          // className={classes.actionColumn}
                          size="small"
                        >
                          <IconButtonMU
                            size="small"
                            aria-label="expand row"
                            onClick={() =>
                              this.setState({
                                showAllRows: false,
                                openedRow:
                                  this.state.openedRow === i ? undefined : i
                              })
                            }
                          >
                            {(this.state.openedRow === i) || this.state.showAllRows ? (
                              <ArrowDropDown />
                            ) : (
                              <ArrowRight />
                            )}
                          </IconButtonMU>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.actionColumn}
                        >
                          <Checkbox
                            className={classes.checkbox}
                            value={selected}
                            checked={selected}
                            inputProps={{ "aria-label": "select tax rate" }}
                            key={`customer-${id}-checkbox-${selected}`}
                            onChange={event =>
                              this.onSelectFeeCollectionList(event, row)
                            }
                          />
                        </TableCell>
                        <TableCell className={classes.capitalizeFirst}>
                          {`${first_name} ${last_name}`}
                        </TableCell>
                        <TableCell className={classes.capitalizeFirst}>
                          {school_class}
                        </TableCell>
                        <TableCell>
                          {division}
                        </TableCell>
                        <TableCell>
                          {total_amount}
                        </TableCell>                        
                        <TableCell>
                          {name}
                        </TableCell>
                        <TableCell>
                          {installment?.data?.attributes?.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.actionColumn}
                        >
                          <IconButton
                            icon={Icon.Action}
                            borderless
                            className={classes.actionColumnButton}
                            onClick={event =>
                              this.onActionButtonClick(event, row)
                            }
                          />
                        </TableCell>
                      </TableRow>
                      {(this.state.openedRow === i ||
                          this.state.showAllRows) && (
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={10}
                            >
                              <Collapse
                                in={
                                  this.state.openedRow === i ||
                                  this.state.showAllRows
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box style={{ margin: 1 }}>
                                  <Table
                                    stickyHeader
                                    className={classes.tableCollapse}
                                    aria-label="Sub fines"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          className={classes.tableHeadRow}
                                        >
                                          Fee Name
                                        </TableCell>
                                        <TableCell
                                          className={classes.tableHeadRow}
                                        >
                                          Total Amount
                                        </TableCell>
                                        <TableCell
                                          className={classes.tableHeadRow}
                                        >
                                          Concession Type
                                        </TableCell>
                                        <TableCell
                                          className={classes.tableHeadRow}
                                        >
                                          Concession
                                        </TableCell>

                                        <TableCell
                                          className={classes.tableHeadRow}
                                          style={{ paddingRight: "155px" }}
                                        >
                                          Concession Mode
                                        </TableCell>
                                        <TableCell
                                          className={classes.tableHeadRow}
                                          style={{ paddingRight: "155px" }}
                                        >
                                          Amount After Concession
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {row?.fee_structures?.data?.attributes?.sub_fee_structures?.data &&
                                        row?.fee_structures?.data?.attributes?.sub_fee_structures?.data?.map(
                                          subFee => (
                                            <TableRow key={subFee?.id}>
                                              <TableCell>
                                                {capitalize(subFee?.attributes?.fee_name)}
                                              </TableCell>
                                              <TableCell>
                                                {subFee?.attributes?.amount}
                                              </TableCell>
                                              <TableCell>
                                                {subFee?.attributes?.concession?.data?.attributes?.fee_concession_type?.data?.type}
                                              </TableCell>
                                              <TableCell>
                                                {subFee?.attributes?.concession?.data?.attributes?.amount}
                                              </TableCell>
                                              <TableCell>
                                                {subFee?.attributes?.concession?.data?.attributes?.mode}
                                              </TableCell>
                                              <TableCell>
                                                {subFee?.attributes?.amount_after_concession}
                                              </TableCell>
                                            </TableRow>
                                          )
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                    </>
                  );
                })}
                {!feeCollectionList.length && !isLoading && (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      style={{
                        color: "red",
                        fontWeight: 600,
                        fontSize: "18px",
                        textAlign: "center"
                      }}
                    >
                      {"No Record Found"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justify="flex-end" alignItems="center">
            <Pagination
              page={currentPage - 1}
              totalPageCount={total_pages}
              handlePageClick={this.onClickPagination.bind(this)}
            />
          </Grid>
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          items={actionMenuItems}
          arrowSide="right"
          anchorOrigin={{ vertical: -12, horizontal: -35 }}
        />

        <BulkUpdateFeeCollection
          submitLoading={this.state.submitLoading}
          open={this.state.bulkUpdateRoleModalOpen}
          // handleClose={this.handleModalAction}
          handleSubmit={this.handleModalFormSubmit}
          checkBulkUpdateId={this.state.checkBulkUpdateRow}
          gradeOptions={this.state.gradeOptions}
          feeStructureOptions={this.state.feeStructureOptions}
          installmentOptions={this.state.installmentOptions}
          // rolebulkUpdateerrorMessage={this.state.rolebulkUpdateerrorMessage}
        />

        <FeeCollectionForm
          {...this.feeCollectionFormProps[feeCollectionFormMode]}
          isOpen={this.state.isFeeCollectionFormModalOpened}
          initialValues={this.state.feeCollectionForm}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  private renderFilterForm() {
    const {
      filterForm: {
        grade_id,
        division_id,
        fee_structure_id,
        installment_id
      },
      gradeOptions,
      divisionOptions,
      feeStructureOptions,
      installmentOptions
    } = this.state;

    return (
      <Grid container direction="column">
        <div style={{marginBottom: '20px'}}>
          <OutlinedSelect
            val={grade_id}
            options={gradeOptions}
            label="Class"
            name={"grade_id"}
            formControlStyle={{
              margin: 0
            }}
            handleChange={(e: any) =>
              this.onFilterElementChange(e, "grade_id")
            }
          />
        </div>

        <div style={{marginBottom: '20px'}}>
          <OutlinedSelect
            val={division_id}
            options={divisionOptions}
            label="Division"
            name={"division"}
            formControlStyle={{
              margin: 0
            }}
            handleChange={(e: any) => this.onFilterElementChange(e, "division_id")}
          />
        </div>

        <div style={{marginBottom: '20px'}}>
          <OutlinedSelect
            val={fee_structure_id}
            options={feeStructureOptions}
            label="Fee Structure"
            name={"fee_structure"}
            formControlStyle={{
              margin: 0
            }}
            handleChange={(e: any) => this.onFilterElementChange(e, "fee_structure_id")}
          />
        </div>

        <div style={{marginBottom: '20px'}}>
          <OutlinedSelect
            val={installment_id}
            options={installmentOptions}
            label="Installment"
            name={"installment"}
            formControlStyle={{
              margin: 0
            }}
            handleChange={(e: any) => this.onFilterElementChange(e, "installment_id")}
          />
        </div>
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(FeeCollectionList);
