import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { calenderIcon } from "../../PayrollIntegration/src/assets";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible,arrow, feeReceivable, feeReceived, feeOutstanding ,concessionAmount, totalAmount} from "./assets";

interface FeeReceivablesAttributes {
  id: number;
  registration_no: string;
  roll_no: string;
  division: string;
  class: string;
}

interface FeeDuePaymentAttributes {
  name: string;
  id: number;
  registration_no: string;
  roll_no: string;
  division: string;
  class: string;
  admission:any;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchKeyword: string;
  arrow: any;
  feeReceivable:any;
  feeReceived: any;
  currentReprt:any;
  feeOutstanding: any;
  concessionAmount: any;
  totalAmount: any;
  showLoader: boolean;
  feeReceivableData: FeeReceivablesAttributes[]
  feeReceivablesListMeta: any;
  feeReceivablesListerrorMessage: string;
  feeDuePaymentData: FeeDuePaymentAttributes[];
  feeDuePaymentListMeta: any;
  feeDuePaymentListerrorMessage: string
  calenderIcon:any;
  page: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdHocReportingController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
    feeRecvSelectorRef: any = null;
    feeRecvtableRef: any = null;
    apiGetFeeReceivablesList: string = "";
    apiGetFeeDuePaymentList: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
     // getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchKeyword: "",
      arrow: arrow,
      feeReceivable:feeReceivable,
      calenderIcon:calenderIcon,
      currentReprt:{},
      feeReceived: feeReceived,
      feeOutstanding: feeOutstanding,
      concessionAmount: concessionAmount,
      totalAmount: totalAmount,
      showLoader: false,
      feeReceivableData: [],
      feeReceivablesListMeta: {},
      feeReceivablesListerrorMessage: "",
      feeDuePaymentData: [],
      feeDuePaymentListMeta: {},
      feeDuePaymentListerrorMessage: "",
      page:0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.feeRecvSelectorRef = React.createRef();
    this.feeRecvtableRef = React.createRef();
    // Customizable Area End

  }  
  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.apiGetFeeReceivablesList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
                const recvData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                  return  item.attributes
              })
              this.setState({ feeReceivableData: recvData, feeReceivablesListMeta: responseJson?.meta , searchKeyword: "",
              });
               
            }else if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
            }
            this.setState({ feeReceivablesListerrorMessage: responseJson.error});
            
        }
        setTimeout(() => this.setState({ showLoader: false }),500);
      }else if (apiRequestCallId === this.apiGetFeeDuePaymentList) {
        console.log('responsefee',responseJson);
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
                const recvData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                  return  item.attributes
              })
              this.setState({ feeDuePaymentData: recvData, feeDuePaymentListMeta: responseJson?.meta , searchKeyword: "",
              });
            }else if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ feeDuePaymentListerrorMessage: responseJson.error});
        }
        setTimeout(() => this.setState({ showLoader: false }),500);
      }else { // +1 for the else path 
        // some statement
    }
    }else { // +1 for the else path 
      // some statement
  }
    // Customizable Area End
    
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  async componentDidMount() {
    const pageId = window.location.pathname;
    if(pageId === "/FeeReceivables"){
      this.getFeeReceivablesList(this.state.page);
    }else if(pageId === "/FeePaymentDue"){
      this.getFeeDuePaymentList(this.state.page);
    }
  }

  handlePageClick = (event: any) => {
    this.setState({page: event.selected});
    //this.tableRef.current.scrollIntoView()
    console.log('check current situation11',this.state.currentReprt);

    this.getFeeReceivablesList(event.selected);
    
  };

  handleReportListing =(data:any) =>{
    console.log('listing-----',data);
    this.setState({currentReprt:data})
    this.props.navigation.navigate(data.id)
  }

  getFeeReceivablesList = (page: number) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFeeReceivablesList = requestMessage.messageId
    let apiEndPoint = configJSON.getReportBaseEndPoint + `/fee_receivables`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("requestMessage.messageId",this.apiGetFeeReceivablesList)
    return true;
  };

  getFeeDuePaymentList = (page: number) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFeeDuePaymentList = requestMessage.messageId
    let apiEndPoint = configJSON.getReportBaseEndPoint + `/fee_due_payment`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  // Customizable Area End
  
}
