import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {deleteIcon,
  concessionTypeEditIcon,
  dropdownIcon } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  open: boolean;
  handleCloseTypeEditmodal: any;
  handleSubmit: (formData: object) => void;
  seletedConcessionTypeItem:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  concessionTypeForm:InitialField;
  concessionType: any;
  concessionDescription: string;
  concessionTypeError:any,
  concessionDescriptionError:any,
  showList:boolean;
  deleteIcon:any,
  concessionTypeEditIcon:any,
  dropdownIcon:any,
//   downloadIcon: any;
  // Customizable Area End
}
interface InitialField {
    fee_concession_type: string,
    description: string,
}
interface SS {
  id: any;
}
const concessionInitialField: InitialField =
{ fee_concession_type: "",description: ""
};

export default class EditConcessionTypeController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      showList:false,
      txtSavedValue: "A",
      enableField: false,
      concessionType: '',
      concessionTypeError:"",
      concessionDescriptionError:"",
      concessionDescription: '',
      concessionTypeForm:concessionInitialField,
      deleteIcon:deleteIcon,
      concessionTypeEditIcon:concessionTypeEditIcon,
      dropdownIcon:dropdownIcon,
    //   downloadIcon: downloadIcon,
      };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

 
  handleChange = (e: any) => {
    let value = e.target.value;
        this.setState({ concessionTypeForm: { ...this.state.concessionTypeForm, [e.target.name]: value } });
   };

  handleListShow=()=>{
    this.setState({showList: !this.state.showList})
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open) {
      this.resetFormState();
  }
    if (prevProps.seletedConcessionTypeItem !== this.props.seletedConcessionTypeItem) {
      const { seletedConcessionTypeItem } = this.props;
      if( seletedConcessionTypeItem ){
        this.setState({ concessionTypeForm: { 
          fee_concession_type: seletedConcessionTypeItem?.name, 
          description: seletedConcessionTypeItem?.description,} 
        });
      }
    }
  }
  resetFormState() {
    this.setState({ concessionTypeForm: concessionInitialField, concessionTypeError: "", });
}
  handleValidation = (formState: InitialField) => {
    let isValid = true;
    if (formState.fee_concession_type === "") {
        this.setState({ concessionTypeError: ('Concession type is required') });
        isValid = false;
    } else {
        this.setState({ concessionTypeError: '' });
    }

    return isValid;
};
  handleSubmit = (e: any) => {
    e.preventDefault();
   if (this.handleValidation(this.state.concessionTypeForm)) {
      this.props.handleSubmit({ concessionTypeData: this.state.concessionTypeForm ,row:this.props.seletedConcessionTypeItem});
   }
};
  // Customizable Area End
}
