import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
export const configJSON = require("./config.js");

export interface Props {
  open: boolean;
  handleClose: (type: string, action: boolean) => void;
  classes: any;
  menuList: [];
  handleSubmit: (formData: object) => void;
  handleChange: () => void;
  checkBulkUpdateId: {};
  rolebulkUpdateerrorMessage: string;
  gradeOptions: [];
  feeStructureOptions: [];
  installmentOptions: [];
}

enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

interface S {
  closeModal: boolean;
  data: any;
  noteHeader: string;
  disabledbutton: boolean;
  loadingSpinner: boolean;
  grade_id: null | number;
  division_id: null | number;
  fee_structure_id: null | number;
  installment_id: null | number;
  divisionOptions: []
}

interface SS {
  id: any;
}
// Customizable Area End
export default class BulkUpdateFeeCollectionController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      closeModal: false,
      data: [],
      noteHeader: "",
      disabledbutton: true,
      loadingSpinner: false,
      grade_id: null,
      division_id: null,
      fee_structure_id: null,
      installment_id: null,
      divisionOptions: []
    };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
  public RequestMessage = {
    GetBulkDivisionOptions: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    console.log('anananin asd asmd ams daklsmdnlkanmflkasncfl');
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      console.log('merhaba######');
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (callID) {
        case this.RequestMessage.GetBulkDivisionOptions.messageId:
          if (response !== null) {
            const divisionArray = response?.data?.map((el: any) => {
              return { id: el?.id, name: el?.attributes?.name }
            });
            console.log('divisionArray', divisionArray);
            this.setState({
              divisionOptions: divisionArray || [],
              loadingSpinner: false
            });
          }
          break;
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        grade_id: null,
        division_id: null,
        fee_structure_id: null,
        installment_id: null
      });
    }
  }

  public onElementChange(element: any, property: string) {
    this.setState({
      ...this.state,
      [property]: Number(element.target.value)
    });

    if (property === 'grade_id') {
      this.getBulkDivisionOptionsCall(element.target.value);
    }
  }

  private getBulkDivisionOptionsCall(grade_id: any) {
    this.setState({ loadingSpinner: true });

    this.RequestMessage.GetBulkDivisionOptions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_fee_structure/classes_and_divisions?grade_id=${grade_id}`
    );

    runEngine.sendMessage(this.RequestMessage.GetBulkDivisionOptions.id, this.RequestMessage.GetBulkDivisionOptions);
  }

  handleSubmit = (e: any) => {
    e.preventDefault();

    this.props.handleSubmit({
      roleData: this.props.checkBulkUpdateId,
      //status: this.state.status,
      type: "bulkUpdate"
    });
  };

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FeeCollectionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }
    // Customizable Area End
}
