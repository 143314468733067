import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, actionIcon } from "./assets";
import { toast } from "react-toastify";
import React from "react";
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import CustomImportModalController from "../../CommonLayout/CustomImportModal/src/CustomImportModalController";
import { debounce } from 'lodash';
import { string } from "yup";

const { baseURL } = require("../../../framework/src/config");
let token: any;
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  classes: any;
  // Customizable Area End
}

// Customizable Area Start

interface feeStructureAttributes {
  id: number,
  fee_structure_id: number,
  status: string,
  name: string
}

interface groupAttributes {
  id: number;
  name: string;
  email: string
  fee_structures: feeStructureAttributes[];
}
interface academicAttributes{
  id: number;
  year: string;
  created_at: string; 
} 
interface IFilterForm {
  id: any;
  academic_year_id: any;
  grade_id: any;
  division_id:any;
}

export interface taxsAttributes{
  id: number;
  name: string;
  tax_percentage: number;
  description: string;
  created_at: string;
  updated_at: string;
  custom_id: string;
  tax_type: string;
  is_gst: boolean;
}

interface concessionAttributes{
  data:{}|null
}
interface feeMultiFieldAttributes{
  id: number;
  amount_after_concession: null;
  fee_name: string;
  amount: number;
  taxs: taxsAttributes[]
  concession: concessionAttributes
}
export interface subFeeFieldAttributes{
  id: string;
  type: string;
  attributes: feeMultiFieldAttributes
}
interface subFeeStructuresAttributes{
  data: subFeeFieldAttributes[]
}

export interface installmentsDetailsAttributes{
  id: number;
  invoice_date: null;
  name: string;
  duration_no: null;
  duration: null;
  due_date: string;
  fine_id: number,
  fine_name: string
  installment_type: null,
  created_at: string;
  updated_at: string;
  installment_id: number
}
export interface installmentFormAttributes{
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  custom_id: string;
  total_amount: number;
  discounted_amount: number;
  installment_code: string;
  concession: number;
  concession_type: string;
  bill_duration_no: number;
  bill_duration: string;
  billing_cycle: number;
  payment_option: string;
  invoice_template_id: number;
  email_template_id: number;
  invoice_template_name: string;
  email_template_name: string;
  fee_structure_id: number;
  payment_collection: string;
  installment_details: installmentsDetailsAttributes[];
}
export interface installmentMultiFieldAttributes{
  
  id: string;
  type: string;
  attributes: installmentFormAttributes;
}

export interface installmentsAttributes{
  data: installmentMultiFieldAttributes[]
}

interface feeAssignmentAttribute {
  is_class: boolean;
  is_class_and_division:boolean;
  is_group: boolean;
  name: string;
  academic_year: academicAttributes;
  grade: string;
  division: string;
  fee_type: string;
  id: number;
  due_date:null;
  group_list: groupAttributes[]|null;
  sub_fee_structures: subFeeStructuresAttributes;
  installments: installmentsAttributes;
}

export interface feeAssignmentData {
  id: any;
  attributes: feeAssignmentAttribute
}

interface bulkUpdate {
  status: string,
  ids: [],
}

interface checkBulkRow {
  [key: number]: boolean;
}

interface feeAssignmentListingAttribute {
  is_class: boolean;
  is_class_and_division:boolean;
  is_group: boolean;
  name: string;
  academic_year: string;
  grade: string;
  division: string;
  fee_type: string;
  custom_id: string;
}

interface feeAssignmentListingData {
  id: number;
  attributes: feeAssignmentListingAttribute
}
const filterInitialField: IFilterForm = { id: "", division_id: "", grade_id: '',academic_year_id:'' };
// Customizable Area End

interface S {
  // Customizable Area Start
  submitLoading: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  filterAnchorEl: null,
  actionIcon: any;
  anchorEl: null;
  importExportanchorEl: null;
  deleteModalOpen: boolean;
  createModalOpen: boolean;
  editModalOpen: boolean;
  viewModalOpen: boolean;
  copyModalOpen: boolean;
  importRoleModalOpen: boolean;
  feeAssignmentData: feeAssignmentData | null;
  feeAssignmentList: any;
  feeAssignmentListMeta: any;
  page: number;
  showLoader: boolean;
  feeAssignmentListerrorMessage: string;
  roleExporterrorMessage: string;
  selectedFeeAssignmentItem: feeAssignmentListingData | undefined;
  bulkUpdateData: bulkUpdate | null;
  checkBulkUpdateRow: checkBulkRow | null;
  disableBukUpload: boolean;
  allRowsChecked: boolean | string;
  exportRoleData: any;
  searchKeyword: string;
  academicYearList: [];
  academicClassList: [];
  divisionList: [];
  studentList:[];
  feeList: [];
  fineList: [];
  taxList: [];
  invoiceTemplateList: [];
  emailTemplateList: [];
  studentListerrorMessage: string;
  viewForm: boolean;
  filterForm:IFilterForm
  feeStructureNameList:[];
  deleteMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FeeAssignmentController extends BlockComponent<
  Props,
  S,
  SS
> {

   // Customizable Area Start

  apiGetFeeAssignmentList: string = "";
  apiGetAcademicYearList: string = "";
  apiGetAcademicClassList: string = "";
  apiGetDivisionList: string = "";
  apiGetStudentList: string = "";
  apiGetFeeList: string = "";
  apiGetTaxList: string = "";
  apiGetInvoiceTemplateList: string = "";
  apiGetEmailTemplateList: string = "";
  apiAddFeeAssignment: string = "";
  apiGetFineList: string = "";
  apiFilterFeeAssignmentList: string = "";
  apiGetFeeAssignmentDetails: string = "";
  apiGetFeeAssignmentSearchList: string = "";
  apiGetFeeStructureNameList: string = "";
  apiDeleteFeeAssignment: string = "";
  tableRef: any = null;
  selectorRef: any = null;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      actionIcon: actionIcon,
      anchorEl: null,
      filterForm:filterInitialField,
      importExportanchorEl: null,
      filterAnchorEl: null,
      deleteModalOpen: false,
      createModalOpen: false,
      editModalOpen: false,
      viewModalOpen: false,
      copyModalOpen: false,
      importRoleModalOpen: false,
      submitLoading: false,
      feeAssignmentData: null,
      feeAssignmentList: [],
      feeAssignmentListMeta: {},
      page: 0,
      showLoader: false,
      feeAssignmentListerrorMessage: '',
      roleExporterrorMessage: '',
      selectedFeeAssignmentItem: undefined,
      bulkUpdateData: null,
      checkBulkUpdateRow: null,
      disableBukUpload: true,
      allRowsChecked: false,
      exportRoleData: '',
      searchKeyword: '',
      academicYearList: [],
      academicClassList: [],
      divisionList: [],
      studentList:[],
      studentListerrorMessage: '',
      feeList: [],
      taxList: [],
      fineList: [],
      invoiceTemplateList: [],
      emailTemplateList: [],
      viewForm: false,
      feeStructureNameList: [],
      deleteMessage: 'multiple',
    };
    this.selectorRef = React.createRef();
    this.tableRef = React.createRef();

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      
      if (apiRequestCallId === this.apiGetFeeAssignmentList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
             this.setState({ feeAssignmentList: responseJson?.data, feeAssignmentListMeta: responseJson?.meta , searchKeyword: ""});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ feeAssignmentListerrorMessage: responseJson.error});
            }
        }
        setTimeout(() => this.setState({ showLoader: false }),500);
      } else if (apiRequestCallId === this.apiGetAcademicYearList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              const academicData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                return  {
                          id: item.attributes.id,
                          name: item.attributes.year,
                        };
            })
              this.setState({ academicYearList: academicData});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              // else if(responseJson?.errors && responseJson?.errors[0]?.token){
              //   localStorage.removeItem("token");
              //   this.props.navigation.navigate("EmailAccountLoginBlock");
              // }
             
            }
        }
      // this.setState({ roleListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiGetAcademicClassList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              const classData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                return  {
                  id: item.id,
                  name: item.attributes.school_grade_name,
                };
            })
              this.setState({ academicClassList: classData});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              // else if(responseJson?.errors && responseJson?.errors[0]?.token =="Token has Expired, Please login again"){
              //   localStorage.removeItem("token");
              //   this.props.navigation.navigate("EmailAccountLoginBlock");
              // }
            }
        }
      // this.setState({ roleListerrorMessage: errorReponse});
      }else if (apiRequestCallId === this.apiFilterFeeAssignmentList) {
        if(responseJson.message){
          this.setState({ feeAssignmentListerrorMessage: responseJson.message});
        }
          if (responseJson !== null) {
            this.setState({
              feeAssignmentList: responseJson?.data || [],
              feeAssignmentListMeta: responseJson?.meta || {},
            });
            
          } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
            setTimeout(() => this.setState({ showLoader: false }),500);
            
            
        //this.setState({ roleMenuListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiGetDivisionList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              const divisionData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                return  {
                          id: item.id,
                          name: item.attributes.name,
                        };
            })
              this.setState({ divisionList: divisionData});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              // else if(responseJson?.errors && responseJson?.errors[0]?.token =="Token has Expired, Please login again"){
              //   localStorage.removeItem("token");
              //   this.props.navigation.navigate("EmailAccountLoginBlock");
              // }
             
            }
        }
      // this.setState({ roleListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiGetStudentList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              
              this.setState({ studentList: responseJson.data});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ studentListerrorMessage: responseJson.error});
            }
        }
        setTimeout(() => this.setState({ showLoader: false }),500);
      } else if (apiRequestCallId === this.apiGetFeeList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              const feeData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                return  item.attributes
            })
              this.setState({ feeList: feeData});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              // else if(responseJson?.errors && responseJson?.errors[0]?.token =="Token has Expired, Please login again"){
              //   localStorage.removeItem("token");
              //   this.props.navigation.navigate("EmailAccountLoginBlock");
              // }
             
            }
        }
      // this.setState({ roleListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiGetTaxList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              const taxData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                return  item.attributes
            })
              this.setState({ taxList: taxData});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
             
            }
        }
      // this.setState({ roleListerrorMessage: errorReponse});
      }else if (apiRequestCallId === this.apiGetFeeStructureNameList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              const feeNameData = responseJson?.data && responseJson?.data;
              this.setState({ feeStructureNameList: feeNameData});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
             
            }
        }
      // this.setState({ roleListerrorMessage: errorReponse});
      }else if (apiRequestCallId === this.apiGetInvoiceTemplateList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              const invoiceTempData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                return  item.attributes
            })
              this.setState({ invoiceTemplateList: invoiceTempData});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
             
            }
        }
      // this.setState({ roleListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiGetEmailTemplateList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              const emailTempData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                return  item.attributes
            })
              this.setState({ emailTemplateList: emailTempData});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
             
            }
        }
      // this.setState({ roleListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiAddFeeAssignment) {
        if (responseJson != null) {
            if (!responseJson.name_error && !responseJson.other_error && !responseJson.error && !responseJson.errors) {
              toast.success("Fee Structure created successfully");
              this.setState({ createModalOpen: false });
              this.getFeeAssignmentList(this.state.page);
            } else {
              
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }else{
                if(responseJson.errors?.message){
                  toast.error(responseJson.errors?.message[0]);
                  this.setState({ createModalOpen: false });
                  this.getFeeAssignmentList(this.state.page);
                }
              }
            }
        }
        this.setState({ showLoader: false })
      } else if (apiRequestCallId === this.apiGetFineList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              const fineData = responseJson?.data && responseJson?.data.map((item: any,index:number) => {
                return  item.attributes
            })
              this.setState({ fineList: fineData});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
             
            }
        }
      // this.setState({ roleListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiGetFeeAssignmentDetails) {
        

        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ feeAssignmentData: responseJson?.data, showLoader: false });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              //this.setState({ accountDetailserrorMessage: responseJson.error});
            }
        }
        this.setState({ showLoader: false })
        //this.setState({ roleDetailserrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiGetFeeAssignmentSearchList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ feeAssignmentList: responseJson?.data, feeAssignmentListMeta: responseJson?.meta });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ feeAssignmentListerrorMessage: responseJson.error});
            }
        }
        setTimeout(() => this.setState({ showLoader: false }),300);
        // this.setState({ roleListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiDeleteFeeAssignment) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              toast.success("Fee Assignment deleted successfully");
              this.setState({ deleteModalOpen: false });
              this.getFeeAssignmentList(this.state.page);
            
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
        }
        this.setState({ showLoader: false })
        //this.setState({ errorMessage: errorReponse});
       
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getFeeAssignmentList(this.state.page);
    this.getAcademicYearList();
    this.getFeeList();
    this.getTaxList();
    this.getEmailTemplateList();
    this.getInvoiceTemplateList();
    this.getFineList();
    this.getFeeStructureNameList();
    this.getAcademicClassList();
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  onClickCreateFeeAssignment = () => {
    this.handleModalAction("create", true);
  }

  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    this.setState({searchKeyword: value});
    const debouncedSave = debounce((value) => {
      if(value !== ""){
        this.setState({page: 0});
        this.getSearchFeeAssignmentList(0,value);
      }else{
        this.getFeeAssignmentList(0);
      }
    }, 300);
		debouncedSave(value);
  }

  handleModalAction = (type: string, action: boolean) => {
  if(type === "create" && action === false){
    this.setState({studentList:[]})
  }
    this.handleAnchorClose();
    if (type === "create") {
      this.setState({ createModalOpen: action, viewForm: false  });
    }
    if(type === "view"){
      this.setState({ createModalOpen: action, viewForm: action });
      this.getFeeAssignmentDetails(this.state.selectedFeeAssignmentItem ? this.state.selectedFeeAssignmentItem?.id : 0);
     
    }else if (type === "delete") {
       /* Handle checbox before delete modal open start*/
      const ids = Object.keys(this.state.checkBulkUpdateRow!== null && this.state.checkBulkUpdateRow).filter((item: any) => this.state.checkBulkUpdateRow && this.state.checkBulkUpdateRow[item])
      if(this.state.selectedFeeAssignmentItem?.id && !ids.includes(this.state.selectedFeeAssignmentItem.id.toString())){
        const newState = {[this.state.selectedFeeAssignmentItem?.id as number]: true};
        const disableBukUpload = this.bulkUpdateDisable(newState);
        this.setState({deleteMessage: 'single',checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: "indeterminate" });  
      }else{
        this.setState({deleteMessage: ids.length>1 ?'multiple':'single'});
      }
      /* Handle checbox before delete modal open end */
      this.setState({ deleteModalOpen: action});
    }
    return null;
  }

  handleModalFormSubmit = (formData: any) => {
 
  }

  handleAnchorClick = (event: any, row: feeAssignmentListingData) => {
    this.setState({ anchorEl: event.currentTarget, selectedFeeAssignmentItem: row });
  };

  handleAnchorClose = () => {
    this.setState({ anchorEl: null });
  };

  handleExportImportAnchorClick = (event: any) => {
    this.setState({ importExportanchorEl: event.currentTarget });
  };

  handleFilterAnchorClick = (event: any) => {
    this.setState({ filterAnchorEl: event.currentTarget });
  };

  handleFilterAnchorClose = () => {
    this.setState({ filterAnchorEl: null });
  };

  onChangeFilter=(event: any)=> {
  let  value = (event.target.value === "null") ? "" : event.target.value;
   
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        [event.target.name]: event.target.value
      }
    });
    if(event.target.name === "grade_id"){
    this.getDivisionList(event.target.value)
    }

  }
  onFilterFormSubmit= ()=> {
    //  e.preventDefault();
    // if(this.handleValidation(this.state.filterForm))
    // {
      // this.filterConcessionCall(this.state.filterForm);
      this.filterFeeAssignmentCall(this.state.filterForm)
    }
  // }
  handlePageClick = (event: any) => {
    this.setState({page: event.selected});
    this.tableRef.current.scrollIntoView()
    if(this.state.searchKeyword !== ""){
   // this.getSearchAccountList(event.selected, this.state.searchKeyword);
    }else{
      this.getFeeAssignmentList(event.selected);
    }
  };

  handleCheckAccountItem = (evt: React.FormEvent<HTMLInputElement>, id: number) => {
    const { checkBulkUpdateRow } = this.state;
    const newState = {...checkBulkUpdateRow, [id]: evt.currentTarget.checked};
    const disableBukUpload = this.bulkUpdateDisable(newState);
    let allRowsChecked = false;
    let checkAnyTrue = false;
    const trueCount = Object.keys(newState).filter((itm: string) => newState[parseInt(itm)]).length;
    if(trueCount === this.state.feeAssignmentList.length){
      allRowsChecked = true;
    }else{
      checkAnyTrue = (trueCount > 0)?true:false;
    }

    this.setState({checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: (checkAnyTrue) ? "indeterminate" : allRowsChecked });
  };

  handleCheckAllAccount = (evt: React.FormEvent<HTMLInputElement>) => {
    if(this.state.feeAssignmentList){
      const checkAccount: checkBulkRow = {};
      this.state.feeAssignmentList.map((item: feeAssignmentData) => {
        // let Event =item.id.toString()
        checkAccount[item.id] = evt.currentTarget.checked;
      });
   
      this.setState({checkBulkUpdateRow: checkAccount, allRowsChecked: evt.currentTarget.checked, disableBukUpload: !evt.currentTarget.checked });
    }
  };

  handleSubmit = (formData: any) => {
    this.setState({ createModalOpen: false  });
    if(!formData.fee_structure) return null;
      if(formData.fee_structure){
        this.addFeeAssignment(formData.fee_structure);
      }
  }

  bulkUpdateDisable = (rows: checkBulkRow) => {
    let hasTrueKeys = true;
    if(rows && Object.keys(rows).length){
      hasTrueKeys = Object.keys(rows).some((key: string) => rows[parseInt(key)]);
    }
    return !hasTrueKeys;
  };

  handleDeleteModalClose = () => {
    this.setState({ deleteModalOpen: false });
  }

  handleModalFormDelete = () => {
    this.setState({ deleteModalOpen: false });
    const ids = Object.keys(this.state.checkBulkUpdateRow!== null && this.state.checkBulkUpdateRow).filter((item: any) => this.state.checkBulkUpdateRow && this.state.checkBulkUpdateRow[item])
    const newIds = ids.join(",");
    if(this.state.selectedFeeAssignmentItem?.id && ids.includes(this.state.selectedFeeAssignmentItem.id.toString())){
      if(ids.length){
        this.deleteFeeAssignment(newIds);
      }
    }else{
      if(this.state.selectedFeeAssignmentItem?.id){
        const selectedIds = this.state.selectedFeeAssignmentItem.id;
        this.deleteFeeAssignment(selectedIds.toString());
      }
    }
    return null;
  }

  getFilterQuery=(form: IFilterForm)=> {
    return Object.entries(form)
      .filter(([key, value]) => value !== "" && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  filterFeeAssignmentCall = (form: IFilterForm) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFilterFeeAssignmentList = requestMessage.messageId
    let apiEndPoint = configJSON.getFeeAssignmentEndPoint + `/filter?${this.getFilterQuery(form)}`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteFeeAssignment = (feeAsgnId: string) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteFeeAssignment = requestMessage.messageId;
    let apiEndPoint = configJSON.getFeeAssignmentEndPoint+ `/bulk_destroy?ids=${feeAsgnId}`

    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  getFeeAssignmentList = (page: number) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFeeAssignmentList = requestMessage.messageId
    let apiEndPoint = configJSON.getFeeAssignmentEndPoint + `?page=${page + 1}`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSearchFeeAssignmentList = (page: number,searchKey: string) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const role_Data = JSON.parse('{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFeeAssignmentSearchList = requestMessage.messageId    
    let apiEndPoint = configJSON.getFeeAssignmentEndPoint + `/search?page=${page + 1}&name=${searchKey}`
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAcademicYearList = () => {
    // this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAcademicYearList = requestMessage.messageId
    let apiEndPoint = configJSON.getAcademicYearBaseEndPoint + `/year_lists`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAcademicClassList = () => {

    // this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAcademicClassList = requestMessage.messageId
    let apiEndPoint = configJSON.getFeeAssignmentBaseEndPoint + `/classes`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getDivisionList = (id: string) => {
    // this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDivisionList = requestMessage.messageId
    let apiEndPoint = configJSON.getFeeAssignmentBaseEndPoint + `/classes_and_divisions?grade_id=${id}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getStudentList = (id: string, divisionId: string, fee_structure:number, status:string,student_name:string) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiGetStudentList = requestMessage.messageId
    let apiEndPoint ='';
    let QueryParams = '';
    
    if(id){
      QueryParams += `?grade_id=${id}`;
    }
    if(divisionId){
      let prifix = QueryParams ? '&' : '?';
      QueryParams += `${prifix}division_id=${divisionId}`;
    }
    if(status){
      let prifix = QueryParams ? '&' : '?';
      QueryParams += `${prifix}status=${status}`;
    }
    if(fee_structure){
      let prifix = QueryParams ? '&' : '?';
      QueryParams += `${prifix}fee_structure=${fee_structure}`;
    }
    if(student_name){
      let prifix = QueryParams ? '&' : '?';
      QueryParams += `${prifix}student_name=${student_name}`;
    }   
    apiEndPoint = configJSON.getFeeAssignmentBaseEndPoint + `/student_list${QueryParams}`
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFeeList = () => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFeeList = requestMessage.messageId
    let apiEndPoint = configJSON.getFeeBaseEndPoint + `fee_lists`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTaxList = () => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTaxList = requestMessage.messageId
    let apiEndPoint = configJSON.getTaxBaseEndPoint + `tax_rate_names`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getInvoiceTemplateList = () => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetInvoiceTemplateList = requestMessage.messageId
    //let apiEndPoint = configJSON.getTaxBaseEndPoint + `tax_rate_names`
    let apiEndPoint = configJSON.getFeeAssignmentBaseEndPoint + `/invoice_template_lists`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getEmailTemplateList = () => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetEmailTemplateList = requestMessage.messageId
    //let apiEndPoint = configJSON.getTaxBaseEndPoint + `tax_rate_names`
    let apiEndPoint = configJSON.getFeeAssignmentBaseEndPoint + `/email_template_lists`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addFeeAssignment = (feeStructure: object) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data = {
      fee_structure: {...feeStructure}
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddFeeAssignment = requestMessage.messageId;
    let apiEndPoint = configJSON.getFeeAssignmentEndPoint
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFineList = () => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFineList = requestMessage.messageId
    //let apiEndPoint = configJSON.getTaxBaseEndPoint + `tax_rate_names`
    let apiEndPoint = configJSON.getFinesEndPoint + `/fines_list`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFeeAssignmentDetails = (id: number) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
   
     const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFeeAssignmentDetails = requestMessage.messageId
    let apiEndPoint = configJSON.getFeeAssignmentEndPoint + `/${id}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFeeStructureNameList = () => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFeeStructureNameList = requestMessage.messageId
    let apiEndPoint = configJSON.getFeeAssignmentEndPoint + `/structure_list`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.feeAssignmentManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End

}