import React from "react";
// Customizable Area Start
import {
    TextField,
    Typography
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomBulkUpdateModal from '../../CommonLayout/CustomBulkUpdateModal/src/CustomBulkUpdateModal.web';
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import BulkUpdateUsersController, {
    Props,
} from "./BulkUpdateUsersController";
// Customizable Area End
class BulkUpdateRoles extends BulkUpdateUsersController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
     // Customizable Area Start
    render() {

        const { open, handleClose, userbulkUpdateerrorMessage,roleList } = this.props;
        const { status, statusError,role, roleError, bulkFormstate,commonErrorMsg } = this.state;
        return (
            <CustomBulkUpdateModal
                title={"Bulk Update"}
                handleSubmit={this.handleSubmit}
                buttonTitle={"Update"}
                open={open}
                handleClose={(evt: object) => handleClose("bulkUpdate", false)}
                errormsg={userbulkUpdateerrorMessage ? userbulkUpdateerrorMessage : ''}

            >
                {commonErrorMsg && <p style={{color: "red", textAlign:"center"}}>{commonErrorMsg}</p>}
                <Grid spacing={3} container>
                    <Grid item xs={6}>

                        <OutlinedSelect
                            placeholderTitle="Select Role"
                            val={bulkFormstate.role}
                            options={roleList}
                            label="Role"
                            name={'role'}
                            handleChange={this.handleChange}
                            styleOverrides={roleError && !role ?
                                { border: "1px solid #d74a3b", outline: "0px", width: '100%' } : { border: "none", width: '100%' }} className={undefined}                            //loadMoreItems={this.loadMoreItems}
                            />
                            {roleError && !role &&
                            <p style={{ color: "red" }}>{roleError}</p>
                            }
                    </Grid>
                    <Grid item xs={6}>
                        <OutlinedSelect
                            placeholderTitle="Select Status"
                            val={bulkFormstate.status}
                            options={[{ id: "active", name: "Active" }, { id: "inactive", name: "Inactive" }]}
                            label="Status"
                            name={'status'}
                            handleChange={this.handleChange}
                            styleOverrides={statusError && !status ?
                                { border: "1px solid #d74a3b", outline: "0px", width: '100%' } : { border: "none", width: '100%' }} className={undefined}                            />
                            {statusError && !status &&
                            <p style={{ color: "red" }}>{statusError}</p>
                            }
                    </Grid>
                </Grid>
            </CustomBulkUpdateModal>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}));

export default withStyles(themeCreatStyle)(
    BulkUpdateRoles as React.ComponentType<any>
)
// Customizable Area End