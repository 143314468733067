import React from "react";

import {
  StyleSheet,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { capitalizeFirst } from "../../../framework/src/Utilities";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Grid from '@material-ui/core/Grid';
import CreateBill from './CreateBill.web';

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  searchbar: {
    width: "85%",
    marginLeft: "139px",
  },
  gridContainer: {
    marginTop: "95px",
    marginRight: "8px",
    marginBottom: "20px"
  }, menu: {
    marginLeft: "-25px",
    marginTop: "15px",
    width:"100%",
    "& .MuiPaper-root": {
      overflow: "visible",
    }
  },
  pageTitle: {
    fontWeight: "bold"
  },
  actionBtn: {
    marginRight: "8px",
    borderRadius: "8px",
  },
  
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    // height: "calc(100vh - 381px) !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableGrid: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap",

  },
  heading: {
    width: "81%",
    backgroundColor: "#fcf3d4",
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 5
  },
  filterBar: {
    width: "18%",
    height: 60,
    display: "flex",
    alignItems: "center",
    borderRadius: 5,

  },
  bulkUpdateBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#fffff",
    border: "1px solid #4b4f5f",
    borderRadius: "5px",
    color: "#455366",
  },

  arrowUp: {
    position: "absolute",
    width: "16px",
    height: "12px",
    top: "-12px",
    zIndex: 1,
   
    '&.left': {
      left: "10px",
    },
    '&.right': {
      right: "10px",
      boxShadow: "0px 29px 8px -3px rgb(0 0 0 / 20%)",
    },
    '&.center': {
      left: "73%",
      transform: "translateX(-50%)",
    },
    '&:before': {
      content: "''",
      position: "absolute",
      top: "0",
      left: "0",
      borderStyle: "solid",
      borderWidth: "0 8px 12px",
      borderColor: "#FFF transparent",
      display: "block",
      width: 0,
      zIndex: 0,
    }
  },
  headingLayout: {
    width: "63%",
    backgroundColor: "#fcf3d4",
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "5px",
  }
});

interface BillDataAttributes {
  id: number;
  date: string;
  bill_no: number;
  vendor_name: string;
  status: string;
  due_date: string;
  amount:string;
  balance_due: string
}
// Customizable Area End

import CentralisedBillingController, {
  Props,
} from "./CentralisedBillingController";

class CentralisedBilling extends CentralisedBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
     // Customizable Area Start
     const { classes } = this.props;
     console.log("this: ", this.state.paymentTerms);
     
     const billData = [
      {
        id:1,
        date: '19/09/2022',
        bill_no: 20220919,
        vendor_name:'Jane Doe',
        status: 'Pending Approval',
        due_date:'19/09/2022',
        amount:'2,521.00',
        balance_due:'0.00',
      },
      {
        id:2,
        date: '19/09/2022',
        bill_no: 20220919,
        vendor_name:'Jane Doe',
        status: 'Partial Paid',
        due_date:'19/09/2022',
        amount:'2,521.00',
        balance_due:'0.00',
      },
      {
        id:3,
        date: '19/09/2022',
        bill_no: 20220919,
        vendor_name:'Jane Doe',
        status: 'Paid',
        due_date:'19/09/2022',
        amount:'2,521.00',
        balance_due:'0.00',
      },
      {
        id:4,
        date: '19/09/2022',
        bill_no: 20220919,
        vendor_name:'Jane Doe',
        status: 'Draft',
        due_date:'19/09/2022',
        amount:'2,521.00',
        balance_due:'0.00',
      },
      {
        id:5,
        date: '19/09/2022',
        bill_no: 20220919,
        vendor_name:'Jane Doe',
        status: 'Over Due By 7 Day',
        due_date:'19/09/2022',
        amount:'2,521.00',
        balance_due:'0.00',
      },
      {
        id:6,
        date: '19/09/2022',
        bill_no: 20220919,
        vendor_name:'Jane Doe',
        status: 'Paid',
        due_date:'19/09/2022',
        amount:'2,521.00',
        balance_due:'0.00',
      },
      {
        id:7,
        date: '19/09/2022',
        bill_no: 20220919,
        vendor_name:'Jane Doe',
        status: 'Paid',
        due_date:'19/09/2022',
        amount:'2,521.00',
        balance_due:'0.00',
      }
     ];
     // Customizable Area End
    return (
      // Customizable Area Start
      <Layout searchKeyword={""} navigation={this.props.navigation} >
        <Grid className={classes.tableGrid} container>
          <PageHeader
            createButtonTitle={'Create'}
            onClickCreateButton={this.onClickCreateBill}
            title="Bill"
          />
          <TableContainer style={{ overflow: '' }} component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="a dense table">

                  <TableHead style={{ position: "sticky", top: "0px", zIndex: 9, }}>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell align="left">Bill No.</TableCell>
                      <TableCell align="left">Vendor Name</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Due Date</TableCell>
                      <TableCell align="left">Amount</TableCell>
                      <TableCell align="left">Balance Due</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(billData && billData.length) ? billData.map((row: BillDataAttributes) => (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            inputProps={{ 'aria-label': 'select all desserts' }}
                          />
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.date}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.bill_no}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.vendor_name)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.status)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.due_date)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.amount)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.balance_due)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" ><IconButton></IconButton></Typography>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )) : null}
                  </TableBody>

                </Table>
          </TableContainer>
          <CreateBill
            open={this.state.createModalOpen}
            handleClose={this.handleModalAction}
            paymentTerms={this.state.paymentTerms}
            createPaymentTerms={this.onCreatePaymentTerms}
            deletePaymentTerms={this.onDeletePaymentTerms}
            handleAddNewPaymentField={this.handleAddNewPaymentField}
            handleChangePaymentTerms={this.handleChangePaymentTerms}
            vendorModalOpen={this.state.vendorModalOpen}
            OnOpenVendorModal={this.OnOpenVendorModal}
            onCloseVendorModal={this.onCloseVendorModal}
            vendors={this.state.vendors}
            onSubmitVendorModal={this.onSubmitVendorModal}
            handleItemAddMore={this.handleItemAddMore}
            billData={this.state.billData}
            handleRemoveItem={this.handleRemoveItem}
            handleChange={this.handleChange}
            discountTypeState={this.state.discountTypeState}
            tds={this.state.tds}
            tdSection={this.state.tdSection}
            createTDS={this.onCreateTDS.bind(this)}
            createSection={this.onCreateSection.bind(this)}
            handleReverChargeShow={this.handleReverChargeShow}
            showReverseCharge={this.state.showReverseCharge}
          />
        </Grid>
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});

export default withStyles(useStyles)(CentralisedBilling);

// Customizable Area End
