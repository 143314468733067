import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import { formSubmitBtn } from "../../CommonLayout/Layout/src/color";
import Dialog from "@material-ui/core/Dialog";
import CustomDeleteModal from "../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";

import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
// Customizable Area End

import CustomerListFormController, {
  FormMode,
  Props
} from "./CustomerListFormController";
import { borderRadius } from "react-select/src/theme";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { deleteIcon } from "./assets";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  form: {
    "& .MuiSelect-select.Mui-disabled": {
      color: "#7E7E7E"
    }
  },
  countryCodeContainer: {
    marginRight: "5px",
    marginTop: "-8px",
  },
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)",

    "& h6, & label": {
      color: "#7E766B",
      fontSize: 14,
      fontWeight: 600
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#fe4554"
    },
    "& .MuiRadio-colorSecondary.Mui-checked.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  radioContainer: {
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#fe4554"
    },
    "& .MuiRadio-colorSecondary.Mui-checked.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  viewMode: {
    "& .question": {
      "& h6, & label": {
        color: "#1E1E1E"
      }
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#44556C"
    },
    "& .MuiInputBase-root > *": {
      opacity: 0,

      "&.MuiSelect-root.Mui-disabled, &.MuiInputBase-input.Mui-disabled": {
        opacity: 1,
        color: "#7E7E7E",
        paddingLeft: 0,
        fontWeight: 500
      }
    }
  },
  sectionHeader: {
    backgroundColor: "#fef4d1",
    padding: "14px 24px",
    fontWeight: 600,
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)"
  },
  sectionHeaderWithBtn: {
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)",
    paddingRight: "33px"
  },
  sectionHeaderWithBtnTitle: {
    backgroundColor: "#fef4d1",
    padding: "14px 24px",
    fontWeight: 600,
    width: "100%",
    borderRadius: "10px"
  },
  cardWithBtnContainer: {
    marginTop: "20px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: "10px",
    justifyContent: "space-between",
    width: "99%"
  },
  cardWithBtnMainContainer: {
    padding: "30px",
    paddingTop: "10px",
    width: "calc(100% - 45px)"
  },
  cardWithBtnIconsContainer: {
    backgroundColor: "#fef4d1",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    width: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  paymentHeaderContainer: {
    marginTop: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 24
  },
  deleteButton: {
    backgroundColor: "#FF414A",
    width: "10px",
    height: "10px",
    margin: "0px",
    borderRadius: "15px"
  },
  paymentDeleteButton: {
    backgroundColor: "#FF414A",
    width: "40px",
    height: "40px",
    margin: "0px",
    borderRadius: "50%",
    marginTop: "8px" // total height 56, iconheight 40, to center (56-40)/2
  },
  label: {
    color: "#44556c",
    padding: 0,
    fontSize: "1rem",
    fontFamily: "open sans",
    fontWeight: 600
  }
});
// Customizable Area End

class CustomerListForm extends CustomerListFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      isOpen,
      formMode,
      title,
      submitLabel,
      handleCustomerType,
      customerType
    } = this.props;

    const {
      form,
      states,
      sameWithBillingAddress,
      businessForm,
      sameWithBusinessBillingAddress,
      gstTreatments,
      placeOfSupplies,
      paymentOptions,
      nationalities,
      formErrors
    } = this.state;

    const renderView = (value: string) => (
      <div style={{ color: "#7B7B7B", marginTop: "17px" }}>{value}</div>
    );

    // INDIVIDUAL CUSTOMER FORM
    const renderIndividual = () => {
      return (
        <>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.sectionHeader}
          >
            <Typography style={{ fontWeight: 600 }}>Primary Contact</Typography>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  First Name
                </FormLabel>
                <TextField
                  className={
                    formErrors[`primary_first_name`] ? "errorInput" : "withoutError"
                  }
                  required
                  value={form?.primary_details?.first_name}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"first_name"}
                  placeholder={"Enter First Name"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onPrimaryDetailElementChange(e, "first_name")
                  }
                />
                {formErrors[`primary_first_name`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`primary_first_name`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Last Name</FormLabel>
                <TextField
                  className={
                    formErrors[`primary_last_name`] ? "errorInput" : "withoutError"
                  }
                  required
                  value={form?.primary_details?.last_name}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"last_name"}
                  placeholder={"Enter Last Name"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onPrimaryDetailElementChange(e, "last_name")
                  }
                />
                {formErrors[`primary_last_name`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`primary_last_name`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Gender</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors[`primary_gender`] ? "errorSelect" : "withoutErrorSelect"
                  }
                  val={form?.primary_details?.gender}
                  options={[
                    { id: "Male", name: "Male" },
                    { id: "Female", name: "Female" },
                    { id: "Other", name: "Other" }
                  ]}
                  label=""
                  placeholder="Select Gender"
                  name={"gender"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onPrimaryDetailElementChange(e, "gender")
                  }
                />
                {formErrors[`primary_gender`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`primary_gender`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Relationship</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors[`primary_relationships`] ? "errorSelect" : "withoutErrorSelect"
                  }
                  val={form?.primary_details?.relationships}
                  options={[
                    { id: "Father", name: "Father" },
                    { id: "Mother", name: "Mother" }
                  ]}
                  label=""
                  placeholder="Select Relationship"
                  name={"relationships"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onPrimaryDetailElementChange(e, "relationships")
                  }
                />
                {formErrors[`primary_relationships`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`primary_relationships`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Contact No.
                </FormLabel>
                {formMode === FormMode.View ? (
                  renderView(`+${form?.primary_details?.country_code} ${form?.primary_details?.phone_number}`)
                ) : (
                  <div style={{ display: "flex" }}>
                    <div className={classes.countryCodeContainer}>
                      <OutlinedSelect
                        className={
                          formErrors[`primary_country_code`] ? "errorSelect" : "withoutErrorSelect"
                        }
                        val={"" + form?.primary_details?.country_code}
                        options={this.countryCodes}
                        label=""
                        name={"country_code"}
                        formControlStyle={{ minWidth: '84px' }}
                        placeholderTitle={""}
                        handleChange={(e: any) =>
                          this.onPrimaryDetailElementChange(e, "country_code")
                        }
                      />
                      {formErrors[`primary_country_code`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`primary_country_code`]}
                        </Typography>
                      )}
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <TextField
                        className={
                          formErrors[`primary_phone_number`] ? "errorInput" : "withoutError"
                        }
                        value={form?.primary_details?.phone_number}
                        color={"primary"}
                        variant="outlined"
                        type={"number"}
                        name={"phone_number"}
                        placeholder={"Enter Phone Number"}
                        //disabled={formMode === FormMode.View}
                        onChange={e =>
                          this.onPrimaryDetailElementChange(e, "phone_number")
                        }
                      />
                      {formErrors[`primary_phone_number`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`primary_phone_number`]}
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Email</FormLabel>
                <TextField className={
                    formErrors[`primary_email`] ? "errorInput" : "withoutError"
                  }
                  value={form?.primary_details?.email}
                  color={"primary"}
                  variant="outlined"
                  type={"email"}
                  name={"email"}
                  placeholder={"Enter Email"}
                  disabled={formMode === FormMode.View}
                  onChange={e => this.onPrimaryDetailElementChange(e, "email")}
                />
                {formErrors[`primary_email`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`primary_email`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Remarks</FormLabel>
                <TextField
                  value={form?.primary_details?.remarks}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"remarks"}
                  placeholder={
                    formMode === FormMode.View && !form?.primary_details?.remarks
                      ? ""
                      : "Enter Remarks"
                  }
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onPrimaryDetailElementChange(e, "remarks")
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            style={{ marginTop: 20 }}
            wrap="nowrap"
            alignItems="center"
            className={classes.radioContainer}
          >
            <Typography
              className={classes.label}
              style={{ marginRight: "20px" }}
            >
              Bill To:{" "}
            </Typography>
            <Radio
              checked={form?.primary_details?.billto === "primary"}
              value={"primary"}
              onChange={e =>
                this.onPrimaryDetailRadioChange("primary", "billto")
              }
              name="billto"
              disabled={formMode === FormMode.View}
            />
            <InputLabel htmlFor="billto">Primary Contact</InputLabel>
            <Radio
              checked={form?.primary_details?.billto === "secondary"}
              value={"secondary"}
              onChange={e =>
                this.onPrimaryDetailRadioChange("secondary", "billto")
              }
              name="billto"
              disabled={formMode === FormMode.View}
            />
            <InputLabel htmlFor="billto">Secondary Contact</InputLabel>
          </Grid>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.sectionHeader}
          >
            <Typography style={{ fontWeight: 600 }}>Contact Details</Typography>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Address</FormLabel>
                <TextField className={
                    formErrors[`contact_street_address`] ? "errorInput" : "withoutError"
                  }
                  value={form?.contact_details?.street_address}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"street_address"}
                  placeholder={"Unit No. 203, No.148, 2nd Floor"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onContactDetailElementChange(e, "street_address")
                  }
                />
                <TextField className={
                    formErrors[`contact_address`] ? "errorInput" : "withoutError"
                  }
                  value={form?.contact_details?.address}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"address"}
                  style={{
                    marginTop: `${
                      formMode !== FormMode.View ? "10px" : "-10px"
                    }`
                  }}
                  placeholder={"Embassy Square, Infantry Road"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onContactDetailElementChange(e, "address")
                  }
                />
                {(formErrors[`contact_address`] ||
                  formErrors[`contact_street_address`]) && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`contact_address`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>City</FormLabel>
                <TextField className={
                    formErrors[`contact_city`] ? "errorInput" : "withoutError"
                  }
                  value={form?.contact_details?.city}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"city"}
                  placeholder={"Enter City"}
                  disabled={formMode === FormMode.View}
                  onChange={e => this.onContactDetailElementChange(e, "city")}
                />
                {formErrors[`contact_city`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`contact_city`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>State</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors[`contact_state`] ? "errorSelect" : "withoutErrorSelect"
                  }
                  val={form?.contact_details?.state}
                  options={states}
                  label=""
                  placeholder="Select State"
                  name={"state"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onContactDetailElementChange(e, "state")
                  }
                />
                {formErrors[`contact_state`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`contact_state`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>PIN Code</FormLabel>
                <TextField className={
                    formErrors[`contact_pin_code`] ? "errorInput" : "withoutError"
                  }
                  value={form?.contact_details?.pin_code}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"pin_code"}
                  placeholder={"Enter PIN Code"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onContactDetailElementChange(e, "pin_code")
                  }
                />
                {formErrors[`contact_pin_code`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`contact_pin_code`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Country</FormLabel>
                <TextField className={
                    formErrors[`contact_country`] ? "errorInput" : "withoutError"
                  }
                  value={form?.contact_details?.country}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"country"}
                  placeholder={"Enter Country"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onContactDetailElementChange(e, "country")
                  }
                />
                {formErrors[`contact_country`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`contact_country`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Email</FormLabel>
                <TextField className={
                    formErrors[`contact_email`] ? "errorInput" : "withoutError"
                  }
                  value={form?.contact_details?.email}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"email"}
                  placeholder={"Enter Email"}
                  disabled={formMode === FormMode.View}
                  onChange={e => this.onContactDetailElementChange(e, "email")}
                />
                {formErrors[`contact_email`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`contact_email`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Contact No.
                </FormLabel>
                {formMode === FormMode.View ? (
                  renderView(`+${form?.contact_details?.country_code} ${form?.contact_details?.phone_number}`)
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "5px", marginTop: "-8px" }}>
                      <OutlinedSelect
                        className={
                          formErrors[`contact_country_code`] ? "errorSelect" : "withoutErrorSelect"
                        }   
                        val={"" + form?.contact_details?.country_code}
                        options={this.countryCodes}
                        label=""
                        name={"country_code"}
                        formControlStyle={{ minWidth: '84px' }}
                        placeholderTitle={""}
                        handleChange={(e: any) =>
                          this.onContactDetailElementChange(e, "country_code")
                        }
                      />
                      {formErrors[`contact_country_code`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`contact_country_code`]}
                        </Typography>
                      )}
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <TextField className={
                          formErrors[`contact_phone_number`] ? "errorInput" : "withoutError"
                        }
                        value={form?.contact_details?.phone_number}
                        color={"primary"}
                        variant="outlined"
                        type={"number"}
                        name={"phone_number"}
                        placeholder={"Enter Phone Number"}
                        //disabled={formMode === FormMode.View}
                        onChange={e =>
                          this.onContactDetailElementChange(e, "phone_number")
                        }
                      />
                      {formErrors[`contact_phone_number`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`contact_phone_number`]}
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Billing Address
                </FormLabel>
                <TextField className={
                    formErrors[`contact_billing_street`] ? "errorInput" : "withoutError"
                  }
                  value={form?.contact_details?.billing_street}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"billing_street"}
                  placeholder={"Unit No. 203, No.148, 2nd Floor"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onContactDetailElementChange(e, "billing_street")
                  }
                />
                <TextField className={
                    formErrors[`contact_billing_address`] ? "errorInput" : "withoutError"
                  }
                  value={form?.contact_details?.billing_address}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"billing_address"}
                  style={{
                    marginTop: `${
                      formMode !== FormMode.View ? "10px" : "-10px"
                    }`
                  }}
                  placeholder={"Embassy Square, Infantry Road"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onContactDetailElementChange(e, "billing_address")
                  }
                />
                {(formErrors[`contact_billing_address`] ||
                  formErrors[`contact_billing_street`]) && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`contact_billing_address`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  <span style={{ marginRight: "24px" }}>Shipping Address*</span>
                  {formMode !== FormMode.View && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sameWithBillingAddress}
                          onChange={this.handleAddressCheck.bind(this)}
                          name="checkedA"
                        />
                      }
                      label="Same as Billing Address"
                    />
                  )}
                </FormLabel>
                <TextField className={
                    formErrors[`contact_shiping_street`] ? "errorInput" : "withoutError"
                  }
                  value={
                    sameWithBillingAddress
                      ? form?.contact_details?.billing_street
                      : form?.contact_details?.shiping_street
                  }
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"shiping_street"}
                  placeholder={"Unit No. 203, No.148, 2nd Floor"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onContactDetailElementChange(e, "shiping_street")
                  }
                />
                <TextField className={
                    formErrors[`contact_shiping_address`] ? "errorInput" : "withoutError"
                  }
                  value={
                    sameWithBillingAddress
                      ? form?.contact_details?.billing_address
                      : form?.contact_details?.shiping_address
                  }
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"shiping_address"}
                  style={{
                    marginTop: `${
                      formMode !== FormMode.View ? "10px" : "-10px"
                    }`
                  }}
                  placeholder={"Embassy Square, Infantry Road"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onContactDetailElementChange(e, "shiping_address")
                  }
                />
                {(formErrors[`contact_shiping_address`] ||
                  formErrors[`contact_shiping_street`]) && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`contact_shiping_address`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={
              formMode !== FormMode.View
                ? classes.sectionHeaderWithBtn
                : classes.sectionHeader
            }
          >
            <Typography
              style={{ fontWeight: 600 }}
              className={
                formMode !== FormMode.View && classes.sectionHeaderWithBtnTitle
              }
            >
              Secondary Details
            </Typography>
            {formMode !== FormMode.View && (
              <Button
                style={{
                  boxShadow: "none",
                  textTransform: "none",
                  fontSize: 16,
                  padding: "14px 28px",
                  border: "1px solid",
                  lineHeight: 1.5,
                  background: formSubmitBtn,
                  marginLeft: "33px",
                  borderRadius: "10px",
                  color: "white"
                }}
                onClick={this.handleAddSecondaryClick.bind(this)}
              >
                Add
              </Button>
            )}
          </Grid>

          {form?.secondary_details?.map((item, i) => (
            <Grid
              className={classes.cardWithBtnContainer}
              container
              direction="row"
            >
              <Grid
                item
                direction="column"
                className={classes.cardWithBtnMainContainer}
              >
                <Grid container direction="row" style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                    <FormControl required style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        First Name
                      </FormLabel>
                      <TextField className={
                    formErrors[`secondary_first_name_${i}`] ? "errorInput" : "withoutError"
                  }
                        value={item?.first_name}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"first_name"}
                        placeholder={"Enter First Name"}
                        disabled={formMode === FormMode.View}
                        onChange={e =>
                          this.onSecondaryDetailElementChange(
                            e,
                            "first_name",
                            i
                          )
                        }
                      />
                      {formErrors[`secondary_first_name_${i}`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`secondary_first_name_${i}`]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                    <FormControl required style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Last Name
                      </FormLabel>
                      <TextField className={
                    formErrors[`secondary_last_name_${i}`] ? "errorInput" : "withoutError"
                  }
                        value={item?.last_name}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"last_name"}
                        placeholder={"Enter Last Name"}
                        disabled={formMode === FormMode.View}
                        onChange={e =>
                          this.onSecondaryDetailElementChange(e, "last_name", i)
                        }
                      />
                      {formErrors[`secondary_last_name_${i}`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`secondary_last_name_${i}`]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                    <FormControl required style={{ width: "100%" }}>
                      <FormLabel>Gender</FormLabel>
                      <OutlinedSelect
                        className={
                          formErrors[`secondary_gender_${i}`] ? "errorSelect" : "withoutErrorSelect"
                        }
                        val={item?.gender}
                        options={[
                          { id: "Male", name: "Male" },
                          { id: "Female", name: "Female" },
                          { id: "Other", name: "Other" }
                        ]}
                        label=""
                        placeholder="Select Gender"
                        name={"gender"}
                        disabled={formMode === FormMode.View}
                        handleChange={(e: any) =>
                          this.onSecondaryDetailElementChange(e, "gender", i)
                        }
                      />
                      {formErrors[`secondary_gender_${i}`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`secondary_gender_${i}`]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                    <FormControl required style={{ width: "100%" }}>
                      <FormLabel>Nationality</FormLabel>
                      <OutlinedSelect
                        className={
                          formErrors[`secondary_nationality_${i}`] ? "errorSelect" : "withoutErrorSelect"
                        }
                        val={item?.nationality}
                        options={nationalities}
                        label=""
                        placeholder="Select Nationality"
                        name={"nationality"}
                        disabled={formMode === FormMode.View}
                        handleChange={(e: any) =>
                          this.onSecondaryDetailElementChange(
                            e,
                            "nationality",
                            i
                          )
                        }
                      />
                      {formErrors[`secondary_nationality_${i}`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`secondary_nationality_${i}`]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                    <FormControl required style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Admission Number
                      </FormLabel>
                      <TextField className={
                    formErrors[`secondary_admission_number_${i}`] ? "errorInput" : "withoutError"
                  }
                        value={item?.admission_number}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"admission_number"}
                        placeholder={"Enter Admission Number"}
                        disabled={formMode === FormMode.View}
                        onChange={e =>
                          this.onSecondaryDetailElementChange(
                            e,
                            "admission_number",
                            i
                          )
                        }
                      />
                      {formErrors[`secondary_admission_number_${i}`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`secondary_admission_number_${i}`]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                    <FormControl required style={{ width: "100%" }}>
                      <FormLabel>Class</FormLabel>
                      <OutlinedSelect
                        className={
                          formErrors[`secondary_class_name_${i}`] ? "errorSelect" : "withoutErrorSelect"
                        }
                        val={item?.class_name}
                        options={[
                          { id: "I", name: "I" },
                          { id: "II", name: "II" },
                          { id: "III", name: "III" },
                          { id: "IV", name: "IV" },
                          { id: "V", name: "V" },
                          { id: "VI", name: "VI" },
                          { id: "VII", name: "VII" },
                          { id: "VIII", name: "VIII" },
                          { id: "IX", name: "IX" },
                          { id: "X", name: "X" },
                          { id: "XI", name: "XI" },
                          { id: "XII", name: "XII" }
                        ]}
                        label=""
                        placeholder="Select Relationship"
                        name={"class_name"}
                        disabled={formMode === FormMode.View}
                        handleChange={(e: any) =>
                          this.onSecondaryDetailElementChange(
                            e,
                            "class_name",
                            i
                          )
                        }
                      />
                      {formErrors[`secondary_class_name_${i}`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`secondary_class_name_${i}`]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                    <FormControl required style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Division
                      </FormLabel>
                      <TextField className={
                    formErrors[`secondary_class_division_${i}`] ? "errorInput" : "withoutError"
                  }
                        value={item?.class_division}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"class_division"}
                        placeholder={"Enter Division"}
                        disabled={formMode === FormMode.View}
                        onChange={e =>
                          this.onSecondaryDetailElementChange(
                            e,
                            "class_division",
                            i
                          )
                        }
                      />
                      {formErrors[`secondary_class_division_${i}`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`secondary_class_division_${i}`]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {formMode !== FormMode.View && (
                <Grid
                  className={classes.cardWithBtnIconsContainer}
                  item
                  direction="column"
                >
                  <Grid item>
                    <IconButton
                      icon={deleteIcon}
                      className={classes.deleteButton}
                      onClick={this.handleSecondaryItemDelete.bind(this, i)}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
        </>
      );
    };

    // BUSINESS CUSTOMER FORM
    const renderBusiness = () => {
      return (
        <>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.sectionHeader}
          >
            <Typography style={{ fontWeight: 600 }}>
              Business Details
            </Typography>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Company Name
                </FormLabel>
                <TextField className={
                    formErrors[`business_company_name`] ? "errorInput" : "withoutError"
                  }
                  value={businessForm?.business_details?.company_name}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"company_name"}
                  placeholder={"Company Name"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "company_name",
                      "business_details"
                    )
                  }
                />
                {formErrors[`business_company_name`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`business_company_name`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Primary Contact
                </FormLabel>
                <TextField className={
                    formErrors[`business_primary_contact`] ? "errorInput" : "withoutError"
                  }
                  value={businessForm?.business_details?.primary_contact}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"primary_contact"}
                  placeholder={"Primary Contact"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "primary_contact",
                      "business_details"
                    )
                  }
                />
                {formErrors[`business_primary_contact`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`business_primary_contact`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Customer Email
                </FormLabel>
                <TextField className={
                    formErrors[`business_email`] ? "errorInput" : "withoutError"
                  }
                  value={businessForm?.business_details?.email}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"email"}
                  placeholder={"company@mail.com"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "email",
                      "business_details"
                    )
                  }
                />
                {formErrors[`business_email`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`business_email`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Contact No.
                </FormLabel>
                {formMode === FormMode.View ? (
                  renderView(
                    `+${businessForm?.business_details?.country_code} ${
                      businessForm?.business_details?.contact_number
                    }`
                  )
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "5px", marginTop: "-8px" }}>
                      <OutlinedSelect
                        className={
                          formErrors[`business_country_code`] ? "errorSelect" : "withoutErrorSelect"
                        }
                        val={"" + businessForm?.business_details?.country_code}
                        options={this.countryCodes}
                        label=""
                        name={"country_code"}
                        formControlStyle={{ minWidth: '84px' }}
                        placeholderTitle={""}
                        handleChange={(e: any) =>
                          this.onBusinessCustomerElementChange.bind(this)(
                            e,
                            "country_code",
                            "business_details"
                          )
                        }
                      />
                      {formErrors[`business_country_code`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`business_country_code`]}
                        </Typography>
                      )}
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <TextField className={
                          formErrors[`business_contact_number`] ? "errorInput" : "withoutError"
                        }
                        value={businessForm?.business_details?.contact_number}
                        color={"primary"}
                        variant="outlined"
                        type={"number"}
                        name={"contact_number"}
                        placeholder={"Contact No"}
                        //disabled={formMode === FormMode.View}
                        onChange={e =>
                          this.onBusinessCustomerElementChange.bind(this)(
                            e,
                            "contact_number",
                            "business_details"
                          )
                        }
                      />
                      {formErrors[`business_contact_number`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`business_contact_number`]}
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Website</FormLabel>
                <TextField className={
                    formErrors[`business_website_url`] ? "errorInput" : "withoutError"
                  }
                  value={businessForm?.business_details?.website_url}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"website_url"}
                  placeholder={"www.companyurl.com"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "website_url",
                      "business_details"
                    )
                  }
                />
                {formErrors[`business_website_url`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`business_website_url`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Billing Address
                </FormLabel>
                <TextField className={
                    formErrors[`business_billing_street`] ? "errorInput" : "withoutError"
                  }
                  value={businessForm?.business_details?.billing_street}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"billing_street"}
                  placeholder={"Unit No. 203, No.148, 2nd Floor"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "billing_street",
                      "business_details"
                    )
                  }
                />
                <TextField className={
                    formErrors[`business_billing_address`] ? "errorInput" : "withoutError"
                  }
                  value={businessForm?.business_details?.billing_address}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"billing_address"}
                  style={{
                    marginTop: `${
                      formMode !== FormMode.View ? "10px" : "-10px"
                    }`
                  }}
                  placeholder={"Embassy Square, Infantry Road"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "billing_address",
                      "business_details"
                    )
                  }
                />
                {(formErrors[`business_billing_address`] ||
                  formErrors[`business_billing_street`]) && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`business_billing_address`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  <span style={{ marginRight: "24px" }}>Shipping Address*</span>
                  {formMode !== FormMode.View && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sameWithBusinessBillingAddress}
                          onChange={this.handleBusinessAddressCheck.bind(this)}
                          name="checkedA"
                        />
                      }
                      label="Same as Billing Address"
                    />
                  )}
                </FormLabel>
                <TextField className={
                    formErrors[`business_shipping_street`] ? "errorInput" : "withoutError"
                  }
                  value={
                    sameWithBusinessBillingAddress
                      ? businessForm?.business_details?.billing_street
                      : businessForm?.business_details?.shipping_street
                  }
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"shipping_street"}
                  placeholder={"Unit No. 203, No.148, 2nd Floor"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "shipping_street",
                      "business_details"
                    )
                  }
                />
                <TextField className={
                    formErrors[`business_shipping_address`] ? "errorInput" : "withoutError"
                  }
                  value={
                    sameWithBusinessBillingAddress
                      ? businessForm?.business_details?.billing_address
                      : businessForm?.business_details?.shipping_address
                  }
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"shipping_address"}
                  style={{
                    marginTop: `${
                      formMode !== FormMode.View ? "10px" : "-10px"
                    }`
                  }}
                  placeholder={"Embassy Square, Infantry Road"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "shipping_address",
                      "business_details"
                    )
                  }
                />
                {(formErrors[`business_shipping_address`] ||
                  formErrors[`business_shipping_street`]) && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`business_shipping_address`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.sectionHeader}
          >
            <Typography style={{ fontWeight: 600 }}>Other Details</Typography>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>GST Treatment</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors[`other_gst_treatment_id`] ? "errorSelect" : "withoutErrorSelect"
                  }
                  optionWithDescription
                  val={`${businessForm?.other_details?.gst_treatment_id || businessForm?.other_details?.gst_treatment?.id}`}
                  options={gstTreatments}
                  label=""
                  placeholderTitle="Select Treatment"
                  placeholder="Select Treatment"
                  name={"gst_treatment_id"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "gst_treatment_id",
                      "other_details"
                    )
                  }
                />
                {formErrors[`other_gst_treatment_id`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`other_gst_treatment_id`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>GSTIN</FormLabel>
                <TextField className={
                    formErrors[`other_gstin_number`] ? "errorInput" : "withoutError"
                  }
                  value={businessForm?.other_details?.gstin_number}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"gstin_number"}
                  placeholder={"GSTIN Number"}
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "gstin_number",
                      "other_details"
                    )
                  }
                />
                {formErrors[`other_gstin_number`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`other_gstin_number`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Place of Supply</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors[`other_place_of_supply`] ? "errorSelect" : "withoutErrorSelect"
                  }
                  val={businessForm?.other_details?.place_of_supply}
                  options={placeOfSupplies}
                  label=""
                  placeholder="Select State"
                  name={"place_of_supply"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "place_of_supply",
                      "other_details"
                    )
                  }
                />
                {formErrors[`other_place_of_supply`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`other_place_of_supply`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            style={{ marginTop: 20 }}
            wrap="nowrap"
            alignItems="center"
            className={classes.radioContainer}
          >
            <Typography
              className={classes.label}
              style={{ marginRight: "20px" }}
            >
              Tax Preference*{" "}
            </Typography>
            <Radio
              checked={businessForm?.other_details?.tax_preference === "taxable"}
              value={"taxable"}
              onChange={e =>
                this.onBusinessCustomerRadioChange("taxable", "tax_preference")
              }
              name="tax_preference"
              disabled={formMode === FormMode.View}
            />
            <InputLabel htmlFor="tax_preference">Taxable</InputLabel>
            <Radio
              checked={
                businessForm?.other_details?.tax_preference === "tax_exempt"
              }
              value={"tax_exempt"}
              onChange={e =>
                this.onBusinessCustomerRadioChange(
                  "tax_exempt",
                  "tax_preference"
                )
              }
              name="tax_preference"
              disabled={formMode === FormMode.View}
            />
            <InputLabel htmlFor="tax_preference">Tax Exempt</InputLabel>
          </Grid>
          {formErrors[`other_tax_preference`] && (
            <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
              {formErrors[`other_tax_preference`]}
            </Typography>
          )}

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Payment Terms</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors[`other_payment_term_id`] ? "errorSelect" : "withoutErrorSelect"
                  }
                  actionButtonLabel={"Configure Terms"}
                  hasActionButton
                  onActionButtonClick={this.handleOpenPaymentModal.bind(this)}
                  val={`${businessForm?.other_details?.payment_term_id || businessForm?.other_details?.payment_term?.id}`}
                  options={paymentOptions?.map(el => ({
                    id: `${el.id}`,
                    name: el.term_name
                  }))}
                  label=""
                  placeholder="Select Term"
                  name={"payment_term_id"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) => {
                    if (e.target.value) {
                      e.target.value = parseFloat(e.target.value);
                      this.onBusinessCustomerElementChange.bind(this)(
                        e,
                        "payment_term_id",
                        "other_details"
                      );
                    }
                  }}
                />
                {formErrors[`other_payment_term_id`] && (
                  <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                    {formErrors[`other_payment_term_id`]}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Remarks</FormLabel>
                <TextField
                  value={businessForm?.other_details?.remarks}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"remarks"}
                  placeholder={
                    formMode === FormMode.View && !businessForm?.other_details?.remarks
                      ? ""
                      : "Enter Remarks"
                  }
                  disabled={formMode === FormMode.View}
                  onChange={e =>
                    this.onBusinessCustomerElementChange.bind(this)(
                      e,
                      "remarks",
                      "other_details"
                    )
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      );
    };

    return (
      <CustomModal
        title={title}
        handleSubmit={this.onSubmit.bind(this)}
        buttonTitle={submitLabel}
        open={isOpen}
        handleClose={this.handleFormClose.bind(this)}
        submitLoading={this.state.isSubmitting}
        hideCancelButton={formMode === FormMode.View}
      >
        <Grid
          container
          direction="column"
          className={classNames(
            classes.form,
            FormMode.View === formMode ? classes.viewMode : ""
          )}
        >
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justify="center"
            alignItems="center"
            className={classes.question}
          >
            <Typography
              className={classes.label}
              style={{ marginRight: "48px" }}
            >
              Customer Type
            </Typography>
            <Radio
              checked={customerType === "individual"}
              value="individual"
              onChange={() => {
                this.setState({ formErrors: {} });
                handleCustomerType.bind(this)("individual");
              }}
              name="customer_type"
              disabled={
                formMode === FormMode.View || formMode === FormMode.Edit
              }
            />
            <InputLabel htmlFor="customer_type">Individual</InputLabel>
            <Radio
              checked={customerType === "business"}
              value="business"
              onChange={() => {
                this.setState({ formErrors: {} });
                handleCustomerType.bind(this)("business");
              }}
              name="customer_type"
              disabled={
                formMode === FormMode.View || formMode === FormMode.Edit
              }
            />
            <InputLabel htmlFor="customer_type">Business</InputLabel>
          </Grid>
          {customerType === "individual"
            ? renderIndividual()
            : renderBusiness()}
        </Grid>

        <>
          <Dialog
            PaperProps={{
              style: {
                borderRadius: "25px",
                maxHeight: "479px"
              }
            }}
            open={this.state.openPaymentModal}
            // onClose={handleClose}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className={classes.paymentHeaderContainer}>
              <DialogTitle id="alert-dialog-title">
                <Typography style={{ fontWeight: 600 }}>
                  Configure Payment Terms
                </Typography>
              </DialogTitle>
              <div>
                <Button
                  style={{
                    borderRadius: "5px",
                    boxShadow: "none",
                    textTransform: "none",
                    fontSize: 16,
                    padding: "8px",
                    border: "1px solid",
                    lineHeight: 1.5,
                    background: formSubmitBtn,
                    color: "white"
                  }}
                  type="submit"
                  onClick={this.onPaymentAddClick.bind(this)}
                >
                  + Add New
                </Button>
              </div>
            </div>

            <DialogContent>
              <Grid container direction="row" style={{ marginTop: 15 }}>
                <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
                  <FormControl required style={{ width: "100%" }}>
                    <FormLabel>Term Name</FormLabel>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
                  <FormControl required style={{ width: "100%" }}>
                    <FormLabel>Number of Days</FormLabel>
                  </FormControl>
                </Grid>
              </Grid>

              {paymentOptions?.map((el, i) => {
                return (
                  <Grid container direction="row" style={{ marginTop: 8 }}>
                    <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
                      <FormControl required style={{ width: "100%" }}>
                        <TextField
                          value={el?.term_name}
                          color={"primary"}
                          variant="outlined"
                          type={"text"}
                          name={"term-name"}
                          placeholder={"Term Name"}
                          disabled={formMode === FormMode.View}
                          onChange={e => {
                            paymentOptions[i].term_name = e.target.value;
                            this.setState({
                              paymentOptions: [...paymentOptions]
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
                      <FormControl required style={{ width: "100%" }}>
                        <TextField
                          value={el?.no_of_days}
                          color={"primary"}
                          variant="outlined"
                          type={"text"}
                          name={"no_of_days"}
                          placeholder={"Number of Days"}
                          disabled={formMode === FormMode.View}
                          onChange={e => {
                            paymentOptions[i].no_of_days =
                              parseFloat(e.target.value) || 0;
                            this.setState({
                              paymentOptions: [...paymentOptions]
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton
                        height={'21px'}
                        icon={deleteIcon}
                        className={classes.paymentDeleteButton}
                        onClick={this.handlePaymentTermDelete.bind(this, el)}
                      />
                    </Grid>
                  </Grid>
                );
              })}
              <CustomDeleteModal
                DialogueDeletetitle={"Delete Payment Term"}
                open={this.state.deleteModalOpen}
                handleClose={this.onCloseDeleteModal.bind(this)}
                handleSubmit={this.onSubmitDeleteModal.bind(this)}
              />
            </DialogContent>

            <DialogActions
              style={{
                justifyContent: "flex-start",
                marginLeft: "18px",
                marginBottom: "26px"
              }}
            >
              <Button
                className={classes.paymentActionBtn}
                style={{
                  borderRadius: "5px",
                  boxShadow: "none",
                  textTransform: "none",
                  fontSize: 16,
                  padding: "8px 45px",
                  border: "1px solid",
                  lineHeight: 1.5,
                  backgroundColor: "white",
                  color: "rgb(63 63 63)",
                  borderColor: "#ea586b",
                  marginRight: "20px"
                }}
                onClick={this.handlePaymentModalClose.bind(this)}
              >
                Cancel
              </Button>

              <Button
                style={{
                  borderRadius: "5px",
                  boxShadow: "none",
                  textTransform: "none",
                  fontSize: 16,
                  padding: "8px 45px",
                  border: "1px solid",
                  lineHeight: 1.5,
                  background: formSubmitBtn,
                  color: "white"
                }}
                type="submit"
                onClick={this.handlePaymentAddSave.bind(this)}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </CustomModal>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(CustomerListForm);
