import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View,
  Copy
}

export interface ITaxRateForm {
  is_gst: boolean;
  tax_type: string;
  tax_percentage: number;
  name: string;
  description: string;
  id?: number;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: ITaxRateForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  onClose: () => void;
  onSubmit: (form: ITaxRateForm) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: ITaxRateForm;
  formErrors: { [key: string]: string };
  isSubmitting: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaxRatesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  private initialValues: ITaxRateForm = {
    is_gst: true,
    tax_type: "",
    tax_percentage: undefined as any,
    name: "",
    description: ""
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      formErrors: {},
      isSubmitting: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (callID === this.props.requestMessage?.messageId) {
        if (response && response.errors !== undefined) {
          Object.entries(response.errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              response.errors[key] = value.map(v => `${this.humanizeText(key)} ${v}`).join(", ");
            }
          });
          this.setState({
            formErrors: {
              ...this.state.formErrors,
              ...response.errors
            },
            isSubmitting: false
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public componentDidUpdate(prevProps: Props) {
    const { isOpen, initialValues, formMode } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: ITaxRateForm = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View, FormMode.Copy].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };
        }

        this.setState({
          form,
          formErrors: {},
          isSubmitting: false
        });
      }
    }
  }

  public onChangeGST(is_gst: boolean) {
    const { tax_type, ...others } = this.state.form;

    if (is_gst) {
      this.setState({ form: { tax_type, ...others, is_gst } });
    }
    else {
      this.setState({ form: { ...others, is_gst } as ITaxRateForm });
    }
  }

  public onChangeTaxType(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        tax_type: element.target.value
      }
    });
  }

  public onChangeTaxPercentage(element: React.ChangeEvent<{ value: string }>) {
    const { value } = element.target;

    if (value.match(/^0/g)) {
      element.target.value = value.replace(/^0/g, "");
    }

    this.setState({
      form: {
        ...this.state.form,
        tax_percentage: Math.abs(parseFloat(element.target.value.replace(/^0/g, '')) || 0)
      }
    });
  }

  public onChangeTaxName(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        name: element.target.value.substring(0, 50).replace(/[^a-zA-Z0-9 ]/g, "")
      }
    });
  }

  public onChangeDescription(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        description: element.target.value.substring(0, 150)
      }
    });
  }

  public onSubmit() {
    this.setState({ isSubmitting: this.props.formMode !== FormMode.View });

    const formErrors: { [key: string]: string } = {};
    Object.entries(this.state.form)
      .filter(([key, value]) => !["description"].includes(key))
      .forEach(([key, value]) => {
        if (value === undefined || value === "" || value === "null" || (Array.isArray(value) && value.length === 0)) {
          formErrors[key] = `${this.humanizeText(key)} is required`;
        }
      });

    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors, isSubmitting: false });

      return;
    }

    this.props.onSubmit(this.state.form);
  }

  public handleFormClose() {
    this.setState({
      form: { ...(this.props.initialValues || this.initialValues) },
      formErrors: {},
      isSubmitting: false
    });

    console.log("handleFormClose");
    

    this.props.onClose();
  }

  private humanizeText(text: string) {
    return text
      .replace(/(_|-)/g, " ")
      .replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
  }
  // Customizable Area End
}
