import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { IPaymentTerms } from "./CreateBillController";
import { IVendor } from "../../CommonLayout/VendorSelectionModal/src/VendorSelectionModalController";
const { baseURL } = require("../../../framework/src/config");

interface ItemInitialField {
  name: string;
  account: string;
  quantity: number;
  rate: number;
  taxIds: string[];
  amount: number;
}

export interface BillAttributes {
  itemData: ItemInitialField[];
}
const itemAddMoreField: ItemInitialField = {
  name: '',
  account: '',
  quantity: 0,
  rate: 0,
  taxIds: [],
  amount: 0,
};
const billInitialField: BillAttributes = {
  itemData: [{ ...itemAddMoreField }],
}


// Customizable Area End

export const configJSON = require("./config");
enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  // Customizable Area End
}
export interface ITDS {
  id: string;
  type: string;
  attributes: {
    id: number;
    section: string;
    tax_rate: number;
    tax_name: string;
    status: boolean;
  }
}

export interface ITDSSection {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  }
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  createModalOpen: boolean;
  anchorEl: null;
  paymentTerms: Array<IPaymentTerms>;
  showLoader: boolean;
  paymentTermsRemoveIndex: number;
  vendorModalOpen: boolean;
  vendors: IVendor[];
  billData: BillAttributes;
  discountTypeState: string;
  tds:Array<ITDS>
  tdSection: Array<ITDSSection>;
  showReverseCharge: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CentralisedBillingController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
    apiGetPaymentTerms: string = "";
    apiCreatePaymentTerm: string = "";
    apiUpdatePaymentTerm: string = "";
    apiDeletePaymentTerm: string = "";
    apiGetVendors: string = "";
    
    public RequestMessage = {
    GetTDS: this.buildRequestMessage(Method.GET),
    GetTDSSection: this.buildRequestMessage(Method.GET),
    CreateTDS: this.buildRequestMessage(Method.POST),
    CreateSection: this.buildRequestMessage(Method.POST),
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: null,
      createModalOpen: false,
      paymentTerms: [],
      showLoader: false,
      paymentTermsRemoveIndex: 0,
      vendorModalOpen: false,
      vendors: [],
      billData: billInitialField,
      discountTypeState: 'transaction_level',
      tds:[],
      tdSection:[],
      showReverseCharge: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(!responseJson?.errors){
      switch (apiRequestCallId) {
        case this.RequestMessage.GetTDS.messageId:
            this.setState({
              tds: responseJson?.data
            });
          break;
          case this.RequestMessage.GetTDSSection.messageId:
            this.setState({
              tdSection: responseJson?.data
            });
          break;
          case this.RequestMessage.CreateSection.messageId:
            this.getTDSSectionCall();
          
          break;

        case this.RequestMessage.CreateTDS.messageId:
            this.getTDSCall();
          break;
          case this.apiGetPaymentTerms:
              
                    this.setState({
                      paymentTerms: responseJson?.data 
                    });
                
            break;
            case this.apiCreatePaymentTerm:
                
                  this.getPaymentTermsCall();
                
            break;
            case this.apiDeletePaymentTerm:
                  this.removePaymentTermByIndex(this.state.paymentTermsRemoveIndex)
            break;
            case this.apiGetVendors:
                  this.setState({
                    vendors: responseJson?.data
                    // isLoading: false
                  });
                
              
             break;
      }
    }else if( responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
      localStorage.removeItem("token");
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
    }

    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  
  async componentDidMount() {
    super.componentDidMount();
    this.getPaymentTermsCall();
    this.getVendorsCall();
    this.getTDSSectionCall()
    this.getTDSCall()
    console.log('helloooo');
  }

  onClickCreateBill = () => {
    this.handleModalAction("create", true);
  }

  handleItemAddMore = () => {

    console.log('ok data');
    const itemFieldData = this.state.billData.itemData;
    itemFieldData.push({ ...itemAddMoreField });
    this.setState({ billData: { ...this.state.billData, itemData: itemFieldData } })
  }

  handleRemoveItem =(indx: number) => {
      const itemArr = this.state.billData.itemData;
      itemArr.splice(indx, 1);
      this.setState({ billData: { ...this.state.billData, itemData: itemArr } })
  }


  removePaymentTermByIndex = (index: number) =>{
    console.log('remove index',index);
    let paymenttermNew = [...this.state.paymentTerms]; 
    paymenttermNew.splice(index,1);
    console.log('res',paymenttermNew);
    this.setState({paymentTerms: paymenttermNew});
  }
  handleChange = (evt:React.ChangeEvent<HTMLInputElement>) => {
    console.log(evt.target.value);
    if(evt.target.value == "at_line_item_level"){
    this.setState({discountTypeState: 'item_level'})
    }else{
      this.setState({discountTypeState: 'transaction_level'})
    }
  }

  handleAddNewPaymentField = () => {
    this.setState({
      paymentTerms: [...this.state.paymentTerms, { isNew: true, term_name: "", no_of_days: 0, status: true, id: '' }]
    })
  }

  handleChangePaymentTerms =(e: React.ChangeEvent<HTMLInputElement>, i:number) =>{
    let newState: any = [...this.state.paymentTerms];
    newState[i][e.target.name] = e.target.value;
    this.setState({ paymentTerms: newState});
  }

  handleModalAction = (type: string, action: boolean) => {
    
    this.handleAnchorClose();
    if (type === "create") {
      this.setState({ createModalOpen: action});
    }
    return null;
  }

  handleAnchorClose = () => {
    this.setState({ anchorEl: null });
  };
  public onCreateTDS(tds: any) {
    this.createTDSCall(tds);
  }

  onCloseVendorModal = () => {
    this.setState({
      vendorModalOpen: false
    });
  };

  OnOpenVendorModal = () => {
    this.setState({
      vendorModalOpen: true
    });
  };

  public onCreateSection(section: any) {
    this.createSectionCall(section);
  }
  onCreatePaymentTerms=(paymentTerms: Array<IPaymentTerms>)=> {
    const createPaymentTerms = paymentTerms.filter((paymentTerm) => !paymentTerm?.id);
    

    if (createPaymentTerms.length > 0) {
      this.createPaymentTermCall(createPaymentTerms);
    }
  }

  onSubmitVendorModal = (id: string) => {
    this.setState({
      vendorModalOpen: false,
      //form: { ...this.state.form, vendor_id: id }
    });
  };

  handleReverChargeShow = () => {
    this.setState({
      showReverseCharge: !this.state.showReverseCharge
    })
  }

  onDeletePaymentTerms=(data: any)=> {
    console.log('delete id', data);
    this.setState({paymentTermsRemoveIndex: data.rmIndex})
    if(data?.id){
      this.deletePaymentTermCall(data);
    }else{
      this.removePaymentTermByIndex(data.rmIndex);
    }
    
  }
  private getTDSSectionCall() {
    this.RequestMessage.GetTDSSection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/get_all_section`
    );

    runEngine.sendMessage(this.RequestMessage.GetTDSSection.id, this.RequestMessage.GetTDSSection);
  }
  private getTDSCall() {
    this.RequestMessage.GetTDS.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/tds_list`
    );

    runEngine.sendMessage(this.RequestMessage.GetTDS.id, this.RequestMessage.GetTDS);
  }
  private createTDSCall(tds: any) {
    this.RequestMessage.CreateTDS.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/create_tds`
    );

    this.RequestMessage.CreateTDS.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: { ...tds, status: true } })
    );
    this.RequestMessage.CreateTDS.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );

    runEngine.sendMessage(this.RequestMessage.CreateTDS.id, this.RequestMessage.CreateTDS);
  }

  private createSectionCall(section: any) {
    this.RequestMessage.CreateSection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/section_creation?name=${section}`
    );

    runEngine.sendMessage(this.RequestMessage.CreateSection.id, this.RequestMessage.CreateSection);
  }
  deletePaymentTermCall = (paymentTermId: any) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeletePaymentTerm = requestMessage.messageId;
    let apiEndPoint = configJSON.CustomeresEndPoint+ `/business_customers/payment_terms?id=${paymentTermId.id}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.billManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    console.log("requestMessage: ", requestMessage);
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 
  createPaymentTermCall = (paymentTerms: Array<IPaymentTerms>) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreatePaymentTerm = requestMessage.messageId;
    let apiEndPoint = configJSON.VendoresEndPoint+`/create_payment_term`;
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.billManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: paymentTerms })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPaymentTermsCall = () => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPaymentTerms = requestMessage.messageId
    let apiEndPoint = configJSON.CustomeresEndPoint + `/business_customers/payment_terms`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.billManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getVendorsCall = () => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetVendors = requestMessage.messageId
    let apiEndPoint = configJSON.VendoresEndPoint;
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.billManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.VendoresEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.billManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }

  // Customizable Area End
  
}
