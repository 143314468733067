import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";

import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
// Customizable Area End

import FeeCollectionFormController, {
  FormMode,
  Props
} from "./FeeCollectionFormController";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { deleteIcon } from "./assets";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  form: {
    "& .MuiSelect-select.Mui-disabled": {
      color: "#7E7E7E"
    },

    "& .MuiOutlinedInput-root.Mui-disabled": {
      background: '#F5F5F5'
    }
  },
  countryCodeContainer: {
    marginRight: "5px",
    marginTop: "-8px"
  },
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)",

    "& h6, & label": {
      color: "#7E766B",
      fontSize: 14,
      fontWeight: 600
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#fe4554"
    },
    "& .MuiRadio-colorSecondary.Mui-checked.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  radioContainer: {
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#fe4554"
    },
    "& .MuiRadio-colorSecondary.Mui-checked.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  viewMode: {
    "& .question": {
      "& h6, & label": {
        color: "#1E1E1E"
      }
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#44556C"
    },
    "& .MuiInputBase-root > *": {
      opacity: 0,

      "&.MuiSelect-root.Mui-disabled, &.MuiInputBase-input.Mui-disabled": {
        opacity: 1,
        color: "#7E7E7E",
        paddingLeft: 0,
        fontWeight: 500
      }
    },

    "& .MuiOutlinedInput-root.Mui-disabled": {
      background: 'white'
    }
  },
  sectionHeader: {
    backgroundColor: "#fef4d1",
    padding: "14px 24px",
    fontWeight: 600,
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)"
  },
  sectionHeaderWithBtn: {
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)",
    paddingRight: "33px"
  },
  sectionHeaderWithBtnTitle: {
    backgroundColor: "#fef4d1",
    padding: "14px 24px",
    fontWeight: 600,
    width: "100%",
    borderRadius: "10px"
  },
  cardWithBtnContainer: {
    marginTop: "20px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: "10px",
    justifyContent: "space-between",
    width: "99%",
    padding: "30px",
    paddingTop: "10px",
  },
  cardWithBtnMainContainer: {
    padding: "30px",
    paddingTop: "10px",
    width: "calc(100% - 45px)"
  },
  cardWithBtnIconsContainer: {
    backgroundColor: "#fef4d1",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    width: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  paymentHeaderContainer: {
    marginTop: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 24
  },
  deleteButton: {
    backgroundColor: "#FF414A",
    width: "10px",
    height: "10px",
    margin: "0px",
    borderRadius: "15px"
  },
  paymentDeleteButton: {
    backgroundColor: "#FF414A",
    width: "40px",
    height: "40px",
    margin: "0px",
    borderRadius: "50%",
    marginTop: "8px" // total height 56, iconheight 40, to center (56-40)/2
  },
  label: {
    color: "#44556c",
    padding: 0,
    fontSize: "1rem",
    fontFamily: "open sans",
    fontWeight: 600
  }
});
// Customizable Area End

class FeeCollectionForm extends FeeCollectionFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isOpen, formMode, title, submitLabel } = this.props;

    const { form, formErrors } = this.state;
    const renderView = (value: string) => (
      <div style={{ color: "#7B7B7B", marginTop: "17px" }}>{value}</div>
    );

    return (
      <CustomModal
        title={title}
        handleSubmit={this.onSubmit.bind(this)}
        buttonTitle={submitLabel}
        open={isOpen}
        handleClose={this.handleFormClose.bind(this)}
        submitLoading={this.state.isSubmitting}
        hideCancelButton={formMode === FormMode.View}
      >
        <Grid
          container
          direction="column"
          className={classNames(
            classes.form,
            FormMode.View === formMode ? classes.viewMode : ""
          )}
        > 
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.sectionHeader}
          >
            <Typography style={{ fontWeight: 600 }}>Student Details</Typography>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Academic Year
                </FormLabel>
                <TextField
                  value={form?.fee_structures?.data?.attributes?.academic_year?.year}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"academic_year"}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Class
                </FormLabel>
                <TextField
                  value={form?.fee_structures?.data?.attributes?.grade}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"class_name"}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Division
                </FormLabel>
                <TextField
                  value={form?.fee_structures?.data?.attributes?.division}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"division"}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Student ID
                </FormLabel>
                <TextField
                  value={form?.id}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"student_id"}
                  disabled
                />
              </FormControl>
            </Grid>    
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Full Name
                </FormLabel>
                <TextField
                  value={`${form?.attributes?.first_name} ${form?.attributes?.last_name}`}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"full_name"}
                  disabled
                />
              </FormControl>
            </Grid> 
            <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Fee Structure</FormLabel>
                <TextField
                  value={form?.fee_structures?.data?.attributes?.name}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"fee_structure_name"}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Total Amount
                </FormLabel>
                <TextField
                  value={form?.fee_structures?.data?.attributes?.installment?.data?.attributes?.total_amount}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"total_amount"}
                  disabled
                />
              </FormControl>
            </Grid> 
          </Grid>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.sectionHeader}
          >
            <Typography style={{ fontWeight: 600 }}>Fee Details</Typography>
          </Grid>
          {form?.fee_structures?.data?.attributes?.sub_fee_structures?.data?.map((subFee) => (
            <Grid
              className={classes.cardWithBtnContainer}
              container
              direction="column"
            >
              <Grid container direction="row" style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Fee Name
                    </FormLabel>
                    <TextField
                      value={subFee?.attributes?.fee_name}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"fee_name"}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Tax
                    </FormLabel>
                    <TextField
                      value={subFee?.attributes?.taxs?.map((tax) => `${tax.name} ${tax.tax_percentage}% `)}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"total_amount"}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Amount
                    </FormLabel>
                    <TextField
                      value={subFee?.attributes?.amount}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"total_amount"}
                      disabled
                    />
                  </FormControl>
                </Grid> 
                <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel>Concession Type</FormLabel>
                    <OutlinedSelect
                      className={
                        formErrors[`concession_name`] ? "errorSelect" : "withoutErrorSelect"
                      }
                      val={subFee?.attributes?.concession?.data?.attributes?.fee_concession_type?.data?.attributes?.id}
                      options={this.state.concessionTypes}
                      label=""
                      placeholder="Select Concession Type"
                      name={"concession_name"}
                      disabled={formMode === FormMode.View}
                      handleChange={(e: any) =>
                        this.onConcessionTypeChange(e, subFee?.id)
                      }
                    />
                    {formErrors[`concession_name`] && (
                      <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                        {formErrors[`concession_name`]}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              {(subFee?.attributes?.concession !== null && subFee?.attributes?.concession?.data?.attributes?.fee_concession_type) && (
                <Grid container direction="row" style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel>Concession Name</FormLabel>
                      <OutlinedSelect
                        className={
                          formErrors[`concession_type`] ? "errorSelect" : "withoutErrorSelect"
                        }
                        val={subFee?.attributes?.concession?.data?.attributes?.id}
                        options={this.state.concessionNames?.[subFee?.attributes?.concession?.data?.attributes?.fee_concession_type?.data?.attributes?.id || '']}
                        label=""
                        placeholder="Select Concession Name"
                        name={"concession_type"}
                        disabled={formMode === FormMode.View}
                        handleChange={(e: any) =>
                          this.onConcessionNameChange(e, subFee?.id)
                        }
                      />
                      {formErrors[`concession_type`] && (
                        <Typography className={"errorMsg2"} variant="caption" style={{ color: "red" }}>
                          {formErrors[`concession_type`]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Concession Amount
                      </FormLabel>
                      <TextField
                        value={subFee?.attributes?.concession?.data?.attributes?.amount}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"total_amount"}
                        disabled
                      />
                    </FormControl>
                  </Grid> 
                  <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Total Amount(After Con.)
                      </FormLabel>
                      <TextField
                        value={subFee?.attributes?.amount_after_concession}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"total_amount"}
                        disabled
                      />
                    </FormControl>
                  </Grid> 
                </Grid>
              )}
            </Grid>
          ))}
          <Grid
            className={classes.cardWithBtnContainer}
            container
            direction="column"
          >
            <Grid container direction="row" style={{ marginTop: 20 }}>
              <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Total Fee
                  </FormLabel>
                  <TextField
                    value={form?.fee_structures?.data?.attributes?.installment?.data?.attributes?.total_amount}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"total_fee"}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Installment
                  </FormLabel>
                  <TextField
                    value={form?.fee_structures?.data?.attributes?.installment?.data?.attributes?.name}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"total_fee"}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Amount Paid
                  </FormLabel>
                  <TextField
                    // value={form?.fee_structures?.data?.attributes?.installment?.data?.attributes?.name}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"total_fee"}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} style={{ paddingRight: 12 }}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Amount Due
                  </FormLabel>
                  <TextField
                    // value={form?.fee_structures?.data?.attributes?.installment?.data?.attributes?.name}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"total_fee"}
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>   
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.sectionHeader}
          >
            <Typography style={{ fontWeight: 600 }}>Installment Details</Typography>
          </Grid>
          <Grid
            className={classes.cardWithBtnContainer}
            container
            direction="column"
          >
            {form?.fee_structures?.data?.attributes?.installment?.data?.attributes?.installment_details?.map((installment) => (
              <div style={{ marginTop: 20, display: 'flex' }}>
                <div style={{marginRight: 4}}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px", fontSize: 12 }}>
                      Installment
                    </FormLabel>
                    <TextField
                      value={installment?.name}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"installment_name"}
                      disabled
                    />
                  </FormControl>
                </div>
                <div style={{marginRight: 4}}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px", fontSize: 12 }}>
                      Amount
                    </FormLabel>
                    <TextField
                      value={installment?.installment_amount}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"installment_amount"}
                      disabled
                    />
                  </FormControl>
                </div>
                <div style={{marginRight: 4}}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px", fontSize: 12 }}>
                      Due Date
                    </FormLabel>
                    <TextField
                      value={installment?.due_date}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"installment_due_date"}
                      disabled
                    />
                  </FormControl>
                </div> 
                <div style={{marginRight: 4}}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px", fontSize: 12 }}>
                      Amount Paid
                    </FormLabel>
                    <TextField
                      value={installment?.amount_paid}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"installment_amount_paid"}
                      disabled
                    />
                  </FormControl>
                </div>
                <div style={{marginRight: 4}}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px", fontSize: 12 }}>
                      Fine
                    </FormLabel>
                    <TextField
                      value={installment?.fine}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"installment_fine"}
                      disabled
                    />
                  </FormControl>
                </div>
                <div style={{marginRight: 4}}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px", fontSize: 12 }}>
                      Amount Due
                    </FormLabel>
                    <TextField
                      value={installment?.amount_due}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"installment_amount_due"}
                      disabled
                    />
                  </FormControl>
                </div>
                <div style={{marginRight: 4}}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px", fontSize: 12 }}>
                      Payment Status
                    </FormLabel>
                    <TextField
                      value={installment?.payment_status}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"installment_payment_status"}
                      disabled
                    />
                  </FormControl>
                </div>
                <div style={{marginRight: 4}}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px", fontSize: 12 }}>
                      Invoice
                    </FormLabel>
                    <TextField
                      value={'----'}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"installment_invoice"}
                      disabled
                    />
                  </FormControl>
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
      </CustomModal>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(FeeCollectionForm);
