import React from "react";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { debounce } from 'lodash';
// Customizable Area End

import { FormMode, Props as IFeeCollectionsProps } from "./FeeCollectionFormController";
export const configJSON = require("./config");

// Customizable Area Start
export interface IFine {
  name: string;
}
enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export interface IFeeCollection {
  id: string;
  type: string;
  selected: boolean;
  attributes: {
    activated: boolean;
    type: string;
    country_code: number;
    email: string;
    first_name: string;
    full_phone_number: string;
    last_name: string;
    phone_number: number;
    date_of_birth: string;
    gender: string;
    email_activated: boolean;
    phone_activated: boolean;
    school_id: number;
    zoom_user_id: null;
    account_status: string;
    avatar: null;
    avatar_id: null;
    avatar_for: null;
    school_class: {
      data: {
        id: string;
        type: string;
        attributes: {
          class_name: string;
          grade_id: number;
          grade_name: string;
          divisions: {
            data: {
              id: string;
              type: string;
              attributes: {
                name: string;
                school_id: number;
                creation_date: string;
                updated_at: string;
              }
            }
          }
        }
      }
    }
  },
  fee_structures: {
    data: {
        id: string;
        type: string;
        attributes: {
          id: number,
          name: string;
          grade: string;
          grade_id: number;
          division: string;
          division_id: number;
          is_class: boolean;
          is_class_and_division: boolean;
          is_group: boolean;
          academic_year: {
            id: number,
            year: string;
            created_at: string;
            updated_at: string;
          },
          fee_type: string;
          custom_id: string;
          total_amount: number;
          sub_fee_structures: {
            data: {
              id: string;
              type: string;
              attributes: {
                id: number;
                amount_after_concession: number,
                fee_name: string;
                amount: number;
                taxs: {
                  id: number;
                  name: string;
                  tax_percentage: number;
                  description: string;
                  created_at: string;
                  updated_at: string;
                  custom_id: string;
                  tax_type: string;
                  is_gst: boolean;
                }[],
                concession: {
                  data: {
                    id?: string;
                    type?: string;
                    attributes?: {
                      id?: number,
                      name?: string;
                      valid_until?: string,
                      fee_concession_type_id?: number,
                      amount?: number,
                      mode?: string,
                      custom_id?: string,
                      fee_concession_type?: {
                        data?: {
                          id?: string,
                          type?: string,
                          attributes: {
                            id: number,
                            name?: string,
                            description?: string,
                            deleted_at?: null
                          }
                        }
                      }
                    }
                  },
                }
              }
            }[]
          },
          installment: {
            data: {
              id: string;
              type: string;
              attributes: {
                id: number,
                name: string;
                created_at: string;
                updated_at: string;
                custom_id: string;
                total_amount: number;
                discounted_amount: number;
                installment_code: string;
                concession: null,
                concession_type: null,
                bill_duration_no: number;
                bill_duration: string;
                billing_cycle: number;
                payment_option: string;
                invoice_template_id: number;
                invoice_template_name: string;
                email_template_id: number;
                email_template_name: string;
                fee_structure_id: number;
                payment_collection: string;
                installment_details: {
                  amount_due: number;
                  amount_paid: number;
                  created_at: string;
                  due_date: string;
                  duration: null;
                  duration_no: null;
                  fine: number;
                  fine_id: number;
                  fine_name: string;
                  id: number;
                  installment_amount: number;
                  installment_id: number;
                  installment_type: null;
                  invoice_date: null;
                  name: string;
                  payment_status: string;
                  updated_at: string;
                }[]
              }
            }
          }
        }
    }
  }
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  grade_id: string;
  division_id: string;
  fee_structure_id: string;
  installment_id: string;
}

interface bulkUpdate {
  status: string,
  ids: [],
}

interface checkBulkRow {
  [key: number]: boolean;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: IFeeCollection | null;
  actionMenuItems: Array<IActionMenuItem>;
  feeCollectionList: Array<IFeeCollection>;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | 'indeterminate' | true;
  isLoading: boolean;
  filterForm: IFilterForm;
  isFeeCollectionFormModalOpened: boolean;
  feeCollectionForm: IFeeCollection | null;
  feeCollectionFormMode: FormMode;
  searchKeyword: string;
  currentPage: number;
  closeImportModal: boolean,
  showAllRows?: boolean;
  openedRow: number | undefined;
  gradeOptions: [];
  divisionOptions: [];
  feeStructureOptions: [];
  installmentOptions: [];
  bulkUpdateData: bulkUpdate | null;
  checkBulkUpdateRow: checkBulkRow | null;
  disableBukUpload: boolean;
  bulkUpdateRoleModalOpen: boolean;
  submitLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FeeCollectionListController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editFeeCollection.bind(this) },
    { label: "View", action: this.viewFeeCollection.bind(this) },
  ];

  public RequestMessage = {
    GetFeeCollections: this.buildRequestMessage(Method.GET),
    ImportFeeCollections: this.buildRequestMessage(Method.POST),
    FilterFeeCollections: this.buildRequestMessage(Method.GET),
    EditFeeCollection: this.buildRequestMessage(Method.PUT),
    ExportFeeCollections: this.buildRequestMessage(Method.GET),
    SearchFeeCollections: this.buildRequestMessage(Method.GET),
    GetGradeOptions: this.buildRequestMessage(Method.GET),
    GetDivisionOptions: this.buildRequestMessage(Method.GET),
    GetFeeStructureOptions: this.buildRequestMessage(Method.GET),
    GetInstallmentOptions: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  }

  public feeCollectionFormProps: { [key: number]: IFeeCollectionsProps } = {
    [FormMode.Edit]: {
      title: "Edit Fee Collection",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Edit,
      isOpen: false,
      onClose: this.onCloseFeeCollectionFormModal.bind(this),
      onSubmit: this.onSubmitEditFeeCollectionModal.bind(this),
      requestMessage: this.RequestMessage.EditFeeCollection,
    },
    [FormMode.View]: {
      title: "Fee Collection",
      submitLabel: "Back to Listing",
      initialValues: null,
      formMode: FormMode.View,
      isOpen: false,
      onClose: this.onCloseFeeCollectionFormModal.bind(this),
      onSubmit: this.onCloseFeeCollectionFormModal.bind(this),
      requestMessage: this.RequestMessage.Null,
    }
  }
  timeoutId: any = undefined;
  tableRef: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      actionedTableRow: null,
      feeCollectionList: [],
      actionMenuItems: [],
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      filterForm: {
        grade_id: '',
        division_id: '',
        fee_structure_id: '',
        installment_id: ''
      },
      isFeeCollectionFormModalOpened: false,
      feeCollectionForm: null,
      feeCollectionFormMode: FormMode.View,
      searchKeyword: "",
      currentPage: 1,
      closeImportModal: false,
      openedRow: undefined,
      gradeOptions: [],
      divisionOptions: [],
      feeStructureOptions: [],
      installmentOptions: [],
      bulkUpdateData: null,
      checkBulkUpdateRow: null,
      disableBukUpload: true,
      bulkUpdateRoleModalOpen: false,
      submitLoading: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.tableRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (callID) {
        case this.RequestMessage.GetFeeCollections.messageId:
        case this.RequestMessage.FilterFeeCollections.messageId:
          if (response !== null) {
            this.setState({
              feeCollectionList: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false,
              searchKeyword: ""
            });
          }
          break;

        case this.RequestMessage.EditFeeCollection.messageId:
          if (response !== null) {
            if (response?.data && !response?.errors?.length) {
              toast.success("Fee Collection updated successfully");
            } else if(response?.errors?.length) {
              toast.error("Fee Collection update is not successfull")
            }
            this.setState({ isFeeCollectionFormModalOpened: false })
            this.getFeeCollectionsCall();
          }

          break;
        
        case this.RequestMessage.ImportFeeCollections.messageId:
          if (response !== null) {
            this.setState({ closeImportModal: true }, () => {
              this.setState({ closeImportModal: false });
            });
            this.getFeeCollectionsCall();
          }

          break;
        
        case this.RequestMessage.SearchFeeCollections.messageId:
          if (response !== null) {
            this.setState({
              feeCollectionList: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;
        
        case this.RequestMessage.GetDivisionOptions.messageId:
          if (response !== null) {
            const divisionArray = response?.data?.map((el: any) => {
              return { id: el?.id, name: el?.attributes?.name }
            });

            this.setState({
              divisionOptions: divisionArray || [],
              isLoading: false
            });
          }
          break;
        
        case this.RequestMessage.GetFeeStructureOptions.messageId:
          if (response !== null) {
            const feeStructureArray = response?.data?.map((el: any) => {
              return { id: el?.id, name: el?.name }
            });

            this.setState({
              feeStructureOptions: feeStructureArray || [],
              isLoading: false
            });
          }
          break;
        
        case this.RequestMessage.GetGradeOptions.messageId:
          if (response !== null) {
            const gradeArray = response?.data?.map((el: any) => {
              return { id: el?.id, name: el?.attributes?.name }
            });

            this.setState({
              gradeOptions: gradeArray || [],
              isLoading: false
            });
          }
          break;
        
        case this.RequestMessage.GetInstallmentOptions.messageId:
          if (response !== null) {
            this.setState({
              installmentOptions: response?.data,
              isLoading: false
            });
          }
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public printReferance = React.createRef();
  public async componentDidMount() {
    super.componentDidMount();

    this.getFeeCollectionsCall();
    this.getGradeOptionsCall();
    this.getFeeStructureOptionsCall();
    this.getInstallmentOptionsCall();
  }

  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    this.setState({ searchKeyword: value });
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      if (value !== "") {
        this.setState({ currentPage: 1 });
        this.getSearchFeeCollectionsCall(1, value);
      } else {
        this.getFeeCollectionsCall();
      }
    }, 500)
  }

  public onSelectAllFeeCollectionList(event: React.ChangeEvent<HTMLInputElement>) {
    const { feeCollectionList } = this.state;
    const { checked } = event.target;

    const updatedFeeCollectionList = feeCollectionList.map((feeCollection) => {
      return {
        ...feeCollection,
        selected: checked
      }
    });

    this.setState({
      feeCollectionList: updatedFeeCollectionList,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedFeeCollectionList)
    });
  }

  public onSelectFeeCollectionList(event: React.ChangeEvent<HTMLInputElement>, row: IFeeCollection) {
    const { feeCollectionList } = this.state;
    const { checked } = event.target;

    const updatedFeeCollectionList = feeCollectionList.map((feeCollection) => {
      return {
        ...feeCollection,
        selected: row.id === feeCollection.id ? checked : feeCollection.selected
      }
    });

    this.setState({
      feeCollectionList: updatedFeeCollectionList,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedFeeCollectionList)
    });
  }

  handleModalFormSubmit = (formData: any) => {
    if(!formData.roleData) return null;
    if(formData.type && formData.type == "bulkUpdate"){
      const ids = Object.keys(formData.roleData).filter((item: string) => formData.roleData[item])
      // this.bulkUpdateRole(ids,formData.status);
    } 
  }

  public onActionButtonClick(event: any, item: IFeeCollection) {
    this.setState({
      anchorEl: event.currentTarget,
      actionedTableRow: item,
      actionMenuItems: this.tableActionMenuItems
    });
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  public onClickPagination(page: any) {
    this.setState({currentPage: page.selected});
    this.tableRef.current.scrollIntoView()

    if(this.state.searchKeyword !== ""){
      this.getSearchFeeCollectionsCall(page.selected + 1, this.state.searchKeyword);
    }else{
      this.getFeeCollectionsCall(page.selected + 1);
    }
  }

  public onCloseFeeCollectionFormModal() {
    this.setState({ isFeeCollectionFormModalOpened: false });
  }

  public onSubmitEditFeeCollectionModal(updatedFields: any, id: string) {
    const newFormat = [
      {
        id: updatedFields?.[0]?.id,
        student_fee_concessions_attributes: [{
          student_id: Number(id),
          fee_concession_id: updatedFields?.[0]?.fee_concession_id
        }]
      }
    ]
    this.editFeeCollectionCall({data: {sub_fee_structures_attributes: newFormat}}, id);
  }

  public onUploadCSVForImport(file: File) {
    this.importFeeCollectionsCall(file);
  }

  public onClickExportCSVButton(isSample: boolean) {
    this.exportFeeCollectionsCall(isSample);
  }

  //#region Filter Methods
  public onFilterElementChange(element: any, property: string) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        [property]: element.target.value
      }
    });

    if (property === 'grade_id') {
      this.getDivisionOptionsCall(element.target.value);
    }
  }

  public onFilterFormSubmit() {
    this.filterFeeCollectionsCall(this.state.filterForm);
  }
  //#endregion Filter Methods

  private getSearchFeeCollectionsCall(page: number = 1, searchKey: string) {
    this.setState({ isLoading: true });

    this.RequestMessage.SearchFeeCollections.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FeeCollectionEndPoint}/search?account_name=${searchKey}&page=${page? page: 1}`
    );
    
    runEngine.sendMessage(this.RequestMessage.SearchFeeCollections.id, this.RequestMessage.SearchFeeCollections);
  }

  private editFeeCollection() {
    this.onSelectAllFeeCollectionList({ target: { checked: false } } as any);
    
    this.openFeeCollectionModalWithInitialData(this.state.actionedTableRow, FormMode.Edit);
  }

  private viewFeeCollection() {
    this.onSelectAllFeeCollectionList({ target: { checked: false } } as any);

    this.openFeeCollectionModalWithInitialData(this.state.actionedTableRow, FormMode.View);
  }

  private openFeeCollectionModalWithInitialData(feeCollection: IFeeCollection | null, mode: FormMode) {
    if (feeCollection !== null) {
      // const { id, attributes: {
      //   name,
      //   description,
      //   tax_percentage,
      //   tax_type,
      //   is_gst
      // } } = feeCollection as IFeeCollection;

      this.setState({
        isFeeCollectionFormModalOpened: true,
        feeCollectionFormMode: mode,
        feeCollectionForm: feeCollection
      });
    }
  }

  private selectedFeeCollectionList(feeCollectionList?: Array<IFeeCollection>): Array<IFeeCollection> {
    if (feeCollectionList === undefined) {
      feeCollectionList = this.state.feeCollectionList;
    }

    return feeCollectionList.filter((feeCollection) => feeCollection?.selected);
  }

  private isSelectAllCheckboxChecked(feeCollectionList: Array<IFeeCollection>): false | "indeterminate" | true {
    const selectedFeeCollectionList = this.selectedFeeCollectionList(feeCollectionList);
    const { length } = selectedFeeCollectionList;

    return length !== 0 && (length === feeCollectionList.length ? true : 'indeterminate');
  }

  //#region Service Calls
  private getFeeCollectionsCall(page: number = 1) {
    this.setState({ isLoading: true });
    page = this.state.currentPage;

    this.RequestMessage.GetFeeCollections.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FeeCollectionEndPoint}?page=${page}`
    );

    runEngine.sendMessage(this.RequestMessage.GetFeeCollections.id, this.RequestMessage.GetFeeCollections);
  }

  private editFeeCollectionCall(data: any, id: string) {
    if (id !== undefined) {
      this.RequestMessage.EditFeeCollection.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      this.RequestMessage.EditFeeCollection.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FeeCollectionEndPoint}/${id}`
      );

      runEngine.sendMessage(this.RequestMessage.EditFeeCollection.id, this.RequestMessage.EditFeeCollection);
    }
  }

  private filterFeeCollectionsCall(form: IFilterForm) {
    this.RequestMessage.FilterFeeCollections.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FeeCollectionEndPoint}/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(this.RequestMessage.FilterFeeCollections.id, this.RequestMessage.FilterFeeCollections);
  }

  private exportFeeCollectionsCall(isSample: boolean = false) {
    let url = `${configJSON.APIBaseURL}/${configJSON.FeeCollectionEndPoint}/export`;
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage()),
    };

    if (isSample) {
      url = `${configJSON.APIBaseURL}/${configJSON.FeeCollectionEndPoint}/fee_collection_csv_sample_file`;
    }

    fetch(url, requestOptions)
      .then((response) => {
        const now = new Date().getTime();
        let fileName = `fee_collection_csv_${now}.csv`;

        if (isSample) {
          fileName = `fee_collection_csv_sample_file.csv`;
        }

        response.blob().then(blob => downloadCSV(blob, fileName))
      })
      .catch((error) => {
        toast.success(error.message);
      });
  }

  private importFeeCollectionsCall(file: File) {
    const formData = new FormData();

    formData.append("file", file);

    this.RequestMessage.ImportFeeCollections.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FeeCollectionEndPoint}/import`
    );

    this.RequestMessage.ImportFeeCollections.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(this.RequestMessage.ImportFeeCollections.id, this.RequestMessage.ImportFeeCollections);
  }

  private getGradeOptionsCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetGradeOptions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_fee_structure/classes'
    );

    runEngine.sendMessage(this.RequestMessage.GetGradeOptions.id, this.RequestMessage.GetGradeOptions);
  }

  private getDivisionOptionsCall(grade_id: any) {
    this.setState({ isLoading: true });

    this.RequestMessage.GetDivisionOptions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_fee_structure/classes_and_divisions?grade_id=${grade_id}`
    );

    runEngine.sendMessage(this.RequestMessage.GetDivisionOptions.id, this.RequestMessage.GetDivisionOptions);
  }

  private getFeeStructureOptionsCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetFeeStructureOptions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_fee_structure/fee_structures/structure_list'
    );

    runEngine.sendMessage(this.RequestMessage.GetFeeStructureOptions.id, this.RequestMessage.GetFeeStructureOptions);
  }

  private getInstallmentOptionsCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetInstallmentOptions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_installment/installments/installment_list'
    );

    runEngine.sendMessage(this.RequestMessage.GetInstallmentOptions.id, this.RequestMessage.GetInstallmentOptions);
  }
  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FeeCollectionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    return Object.entries(form)
      .filter(([key, value]) => value !== "" && value !== null && value !== "null")
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}
