import React from "react";
// Customizable Area Start
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormControlLabel, IconButton as MUIIConButton, OutlinedInput, Radio, RadioGroup,TextField,
  Typography,
  InputAdornment,
  Select,
  MenuItem } from "@material-ui/core";
import { deleteIcon ,uploadIcon} from "./assets";
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CustomItemFormController, {
  Props,
  ItemInitialField
} from "./CustomItemFormController";

import {
  StyleSheet,
  Platform
} from "react-native";
import OutlinedSelect from "../../../../components/src/OutlinedSelect";

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  dialogPaper: {
    maxWidth: "65vw",
  },
  modalTitle: {
    "backgroundColor": "#fcf3d4",
    "fontWeight": 600
  },
  uploadIconContainer: {
    // background: "#eb514f",
    height: "25px",
    display: "flex",
    borderRadius: "10px",
    width: "95%",
    justifyContent: "space-between",
    alignItems: "center",
    border: "dashed",
    padding: "8px",
    cursor: "pointer"
  },
  headerButton: {
    "borderRadius": "8px",
    "boxShadow": "none",
    "textTransform": "none",
    "fontSize": "16px",
    "padding": "6px 12px",
    "border": "1px solid #ea5c6b",
    "lineHeight": "1.5",
    "background": "rgb(234, 80, 79)",
    "color": "white",
    '&:hover, &:focus': {
      backgroundColor: "#F15B5B",
      borderColor: "#F15B5B",
      color: '#FFFFFF',
    }
  },
  reverseTypeSelect: {
    backgroundColor: "#F7F7F7",
    width: "60px",
    marginRight: "-13px",
    height: "55px",
    paddingLeft: "10px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "4px",
    borderLeft: "solid #D1D1D1"
  }
});
// Customizable Area End
class CustomItemForm extends CustomItemFormController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      // open, 
      // handleClose, 
      // submitLoading, 
      // cancelButtonTitle, 
      // errormsg, 
      // title, 
      // children, 
      // handleSubmit, 
      // stepLabel,
      // activeStep,
      // isHeaderButton,
      // headerButtonTitle, 
      // headerButtonIcon,
      // handleButtonClick,
      // isHideButton,
      // viewForm,
      // currentForm,
      classes,
      itemData,
      handleItemAddMore,
      handleRemoveItem,
      discountTypeState
    } = this.props;
    console.log('last data', itemData);
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
        <Grid
          component={Paper}
          style={{
            // position: "relative",
            // flexGrow: 1,

            marginTop: "14px",
            marginBottom: "16px",
            boxShadow: "0px 5px 10px 0px #d9d9d9",
            // height: "300px !important",
            borderRadius: 10
          }}
        >
          <Grid
            style={{
              margin: "0px",
              display: "flex",
              alignItems: "center",
              borderRadius: 5,
              backgroundColor: "#FCF3D4",
              padding: "14px"
            }}
          >
              <Grid container spacing={2} style={{display:'flex', justifyContent:'space-between'}}>
              <Grid item xs={discountTypeState == "item_level" ? 2: 3}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#44556c" }}>
                  Item Details
                </Typography>
              </Grid>
              <Grid item xs={discountTypeState == "item_level" ? 1: 2}>
                <Typography variant="subtitle1" style={{ display:"flex", justifyContent:"center",fontWeight: "bold", color: "#44556c" }}>
                  Account
                </Typography>
              </Grid>
              <Grid item xs={1} style={{marginLeft:'18px'}}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#44556c" }}>
                  Quantity
                </Typography>
              </Grid>
              <Grid item xs={1} style={{marginLeft: 14}}>
                <Typography variant="subtitle1" style={{display:"flex", justifyContent:"end",  fontWeight: "bold", color: "#44556c" }}>
                  Rate
                </Typography>
              </Grid>
              { discountTypeState && discountTypeState == "item_level" &&
                 <Grid item xs={1}>
                 <Typography variant="subtitle1" style={{display:"flex", justifyContent:"center", fontWeight: "bold", color: "#44556c" }}>
                    Discount
                  </Typography>
                </Grid>
              }
             <Grid item xs={discountTypeState == "item_level" ? 1: 2} style={{marginLeft: 14}}>
                <Typography variant="subtitle1" style={{display:"flex", justifyContent:"center", fontWeight: "bold", color: "#44556c" }}>
                  Tax
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1" style={{ marginRight:'-12px',fontWeight: "bold", color: "#44556c" }}>
                  Amount
                </Typography>

                <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#44556c" }}></Typography>
              </Grid>
            </Grid>
          </Grid>
          {itemData && itemData.map((fieldData: ItemInitialField, indx: number) => {
            return (
            <Grid
              component={Paper}
              style={{
                margin: "0px",
                display: "flex",
                padding: "14px"
              }}
            >
                   <Grid container style={{ display:'flex',justifyContent:"space-between",}} spacing={2}>
                <Grid item xs={discountTypeState == "item_level" ? 2 : 3} >
                  <FormControl style={{ minWidth: "100%" }}>
                    <TextField
                      value={''}
                      type={'text'}
                      placeholder={`Type or cick to select item`}
                      variant="outlined"
                      name={'name'}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <OutlinedSelect
                    placeholderTitle="Account Type"
                    val={''}
                    label=""
                    labelStyle={{ marginBottom: "0px" }}
                    options={[]}
                    name={'account'}
                    className={undefined}
                    handleChange={() => function () { }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormControl style={{ minWidth: "100%" }}>
                    <TextField
                      value={''}
                      type={'text'}
                      placeholder={`0`}
                      variant="outlined"
                      name={'quantity'}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControl style={{ minWidth: "100%" }}>
                    <TextField
                      value={''}
                      type={'text'}
                      placeholder={`0.00`}
                      variant="outlined"
                      name={'rate'}
                    />
                  </FormControl>
                </Grid>
                { discountTypeState && discountTypeState == "item_level" &&
                  <Grid
                    item 
                    xs={1}
                    sm={1}
                    style={{marginRight:'20px'}}
                  >
                    <OutlinedInput
                      type={"text"}
                      value={''}
                      onChange={() => function () { }}
                      endAdornment={
                        <InputAdornment position="end">
                          <div>
                            <Select
                              value={''}
                              onChange={() => function(){}}
                              className={classes.reverseTypeSelect}
                            >
                              <MenuItem value={"₹"}>₹</MenuItem>
                              <MenuItem value={"%"}>%</MenuItem>
                            </Select>
                          </div>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                }
                <Grid item xs={ 2}>
                  <OutlinedSelect
                    placeholderTitle="Select Tax"
                    val={''}
                    options={[]}
                    label=""
                    labelStyle={{ marginBottom: "0px" }}
                    name={'tax_ids'}
                    handleChange={() => function () { }}
                    className={undefined}
                  />

                </Grid>
                <Grid item xs={1}>
                  <FormControl style={{ minWidth: "100%" }}>
                    <TextField
                      value={''}
                      type={'text'}
                      placeholder={`0.00`}
                      variant="outlined"
                      name={'amount'}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1} style={{ backgroundColor: "#fcf3d4" ,maxWidth: "fit-content",padding: "5px 0px"}}>
                  <MUIIConButton
                    disabled={itemData.length > 1 ? false : true}
                    style={{ marginTop: "14px" }}
                    onClick={(evt: object) => handleRemoveItem(indx)}
                  >
                    <img height="20px" width="20px" src={deleteIcon} />
                  </MUIIConButton>
                </Grid>
              </Grid>
            </Grid>
            )
            })
          }
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={5}>
          <Button
          onClick={handleItemAddMore}
          className={this.props.classes.headerButton}
        >
          Add Line Item<AddIcon />
        </Button>
            <FormControl style={{ marginTop: "148px", minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Notes
              </FormLabel>
              <TextField
                value={''}
                type={'text'}
                placeholder={`Add Notes`}
                multiline={true}
                rows={8}
                style={{
                  border: "none",
                  width: '100%'
                }}
                variant="outlined"
                name={'notes'}
              />
            </FormControl>
            <FormControl style={{ marginTop: "82px", width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Attach Files to Bills
                    </FormLabel>
                    <div
                        style={{
                          borderColor:"#f1f1f1"
                        }}
                        className={classes.uploadIconContainer}
                      >
                         
                        <input
                          type={"file"}
                          style={{ display: "none" }}
                          name={"file"}
                        /> 
                         <div
                          style={{
                            color: "#556479"
                          }}
                        >
                          {"Upload File"}
                        </div>
                        <img
                        style={{ width: "33px", color: "#D07A85" }}
                        src={uploadIcon}
                      />
                        </div>
              </FormControl>
            {/* <Typography style={{ marginTop: "70px",fontWeight: 600, color: "#3f5371" }} variant="subtitle1" > Attach Files to Bills </Typography>
            <input
  accept="image/*"
  style={{ display: 'none' }}
  id="raised-button-file"
  multiple
  type="file"
/>
<label htmlFor="raised-button-file">
  <Button component="span" >
    Upload
  </Button>
</label>  */}
          </Grid>
          <Grid item xs={7}>
            <Paper style={{ padding: "20px" }} variant="outlined">
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ padding: "16px", fontWeight: 600, fontSize: "18px" }}>{'Sub Total'}</TableCell>
                      <TableCell align="center" ></TableCell>
                      <TableCell style={{ padding: "16px", fontWeight: 600, fontSize: "18px" }} align="right">
                        {"0.00"}
                      </TableCell>
                    </TableRow>
                    {discountTypeState && discountTypeState == "transaction_level" &&
                        <TableRow>
                          <TableCell style={{ padding: "16px", fontWeight: 500, fontSize: "16px" }}>{'Discount'}</TableCell>
                          <TableCell align="center" style={{ paddingBottom: "14px", width: "30%" }}>
                            <OutlinedSelect
                              placeholderTitle="Select Type"
                              val={''}
                              options={[]}
                              label=""
                              name={'account_type_id'}
                              handleChange={() => function () { }}
                            />
                          </TableCell>
                          <TableCell style={{ padding: "16px", fontWeight: 500, fontSize: "16px" }} align="right">
                            {"-0.00"}
                          </TableCell>
                        </TableRow>
                    }
                    <TableRow>
                      <TableCell style={{ padding: "16px", fontWeight: 600, fontSize: "18px" }}>
                        <RadioGroup row aria-label="gender" value={'tds'} name="fee_type">
                          <FormControlLabel
                            style={{
                              color: "#3f5071"
                            }}
                            value="tds"
                            control={<Radio />}
                            label="TDS"
                          />
                          <FormControlLabel
                            style={{
                              color: "#3f5071"
                            }}
                            value="tcs"
                            control={<Radio />}
                            label="TCS"
                          />
                        </RadioGroup>
                      </TableCell>
                      <TableCell align="center" style={{ paddingBottom: "14px", width: "30%" }}>
                      <OutlinedSelect
                        val={''}
                        options={this.props.tds.map(({ id, attributes: { tax_name, section } }) => ({ id, name: `${tax_name} (${section})` }))}
                        label=""
                        labelStyle={{ display: 'none' }}
                        formControlStyle={{ minWidth: 200 }}
                        placeholder="Select Tax"
                        name={'tax'}
                        // disabled={formMode === FormMode.View}
                        handleChange={{}}
                        actionButtonLabel={"Configure TDS"}
                        hasActionButton
                        onActionButtonClick={this.props.openTDSModal}
                      />
                      {/* <OutlinedSelect
                        placeholderTitle="Select Tax"
                        val={''}
                        options={[]}
                        label=""
                        name={'tax'}
                        handleChange={() => function () { }}
                      /> */}
                      </TableCell>
                      <TableCell style={{ padding: "16px", fontWeight: 600, fontSize: "18px" }} align="right">
                        {"0.00"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: "16px", fontWeight: 500, fontSize: "16px" }}>{'Adjustments'}</TableCell>
                      <TableCell align="center" >
                        <TextField
                          value={''}
                          type={'text'}
                          placeholder={`Enter Amount`}
                          style={{
                            border: "none",
                            width: '100%'
                          }}
                          variant="outlined"
                          name={'amount'}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "16px" }} align="right">
                        {"0.00"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: "16px", fontWeight: 600, fontSize: "18px" }}>{'Total'}</TableCell>
                      <TableCell align="center" ></TableCell>
                      <TableCell style={{ padding: "16px", fontWeight: 500, fontSize: "16px" }} align="right">
                        {"0.00"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Typography style={{ marginTop: "25px",fontSize:"18px",fontWeight: 600, color: "#3f5371" }} variant="subtitle1" > Templates </Typography>
            <FormControl style={{ minWidth: "78%"}}>
            <OutlinedSelect
              placeholderTitle="Select Fee Name"
              val={''}
              labelStyle={{marginTop:"20px"}}
              options={[]}
              label="Bills"
              name={'bill_template_id'}
              handleChange={()=>function(){}} className={undefined}            />
            </FormControl>
          </Grid>
        </Grid>
      </>

      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  }
});

// Customizable Area End

export default withStyles(useStyles)(CustomItemForm);