Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");
exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.PATCH = "PATCH";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CentralisedBilling";
exports.labelBodyText = "CentralisedBilling Body";
exports.VendoresEndPoint = "vendors";
exports.CustomeresEndPoint = "bx_block_profile";
exports.btnExampleTitle = "CLICK ME";
exports.billManagementURL = urlConfig.baseURL;

// Customizable Area End