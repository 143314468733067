import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

// Customizable Area End

export enum FormMode {
  Create,
  Edit,
  View
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  isLoading: boolean;
  filterVisible: boolean;
  error?: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardChartsController extends BlockComponent<
  Props,
  S,
  SS
> {
  public RequestMessage = {
    GetDashboardChart: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  };

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      isLoading: true,
      filterVisible: false

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  public countryCodes: Array<any> = Array.from(
    { length: 200 },
    (v, k) => k + 91
  ).map(v => ({
    id: v,
    name: `+${v}`
  }));

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("Message Recived", callID);

      switch (callID) {
        case this.RequestMessage.GetDashboardChart.messageId:
          if (response !== null && response.data && response.data.attributes) {
          }
          break;

        // case this.RequestMessage.EditTaxRate.messageId:
        //   if (response !== null) {
        //     this.setState({ isTaxRateFormModalOpened: false })
        //     this.getTaxRatesCall();
        //   }

        //   break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getDashboardChartCall();
  }

  //#region Service Calls

  private getDashboardChartCall() {
    this.RequestMessage.GetDashboardChart.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.DashboardChartEndPoint}/profile_detail`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetDashboardChart.id,
      this.RequestMessage.GetDashboardChart
    );
  }

  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TaxesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: window.localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }

  // Customizable Area End
}
