import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-date-picker";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import { calenderIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import VendorSelectionModal from "../../CommonLayout/VendorSelectionModal/src/VendorSelectionModal.web";
import CustomerSelectionModal from "../../CommonLayout/CustomerSelectionModal/src/CustomerSelectionModal.web";
import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
// Customizable Area End

import RecurringExpenseFormController, {
  FormMode,
  Props
} from "./RecurringExpenseFormController";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  form: {
    "& .MuiSelect-select.Mui-disabled": {
      color: "#7E7E7E",
      backgroundColor: "rgb(234 234 234)"
    }
  },
  radioContainer: {
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#fe4554"
    },
    "& .MuiRadio-colorSecondary.Mui-checked.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  customRepeatBtn: {
    backgroundColor: "#fe4552",
    color: "white",
    border: "none",
    cursor: "pointer",
    borderRadius: 4,
    padding: "10px 0",
    width: "calc(100% - 32px)",
    margin: "0 16px"
  },
  searchIconContainer: {
    background: "#eb514f",
    height: "56px",
    display: "flex",
    borderRadius: "4px",
    width: "56px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)",

    "& h6, & label": {
      color: "#7E766B",
      fontSize: 14,
      fontWeight: 600
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#fe4554"
    }
  },
  reverseTypeSelect: {
    backgroundColor: "#F7F7F7",
    width: "60px",
    marginRight: "-13px",
    height: "55px",
    paddingLeft: "10px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "4px",
    borderLeft: "solid #D1D1D1"
  },
  viewMode: {
    "& .question": {
      "& h6, & label": {
        color: "#1E1E1E"
      }
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#44556C"
    },
    "& .MuiInputBase-root > *": {
      opacity: 0,

      "&.MuiSelect-root.Mui-disabled, &.MuiInputBase-input.Mui-disabled": {
        opacity: 1,
        color: "#7E7E7E",
        paddingLeft: 0,
        fontWeight: 500,
        background: "white"
      }
    },
    "& .react-date-picker--disabled": {
      backgroundColor: "white",
      border: "none",
      marginLeft: "-10px",
      color: "#7e7e7e"
    }
  }
});
// Customizable Area End

class RecurringExpenseForm extends RecurringExpenseFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderView = (value: string, color: string = "#838383") => (
    <Typography variant="h6" style={{ color: color }}>
      {value}
    </Typography>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isOpen, formMode, title, submitLabel } = this.props;
    const { form, formErrors, gstTreatments } = this.state;
    const vendor = this.state.vendors?.find(el => el.id === form.vendor_id);
    const vendorName = vendor ? vendor.attributes.company_name : form.vendor;
    const customer = this.state.customers?.find(
      el => el.id === form.customer_id
    );
    const customerName = customer && customer.attributes.customer_name;

    return (
      <CustomModal
        title={title}
        handleSubmit={this.onSubmit.bind(this)}
        buttonTitle={submitLabel}
        open={isOpen}
        handleClose={this.handleFormClose.bind(this)}
        submitLoading={this.state.isSubmitting}
        hideCancelButton={formMode === FormMode.View}
      >
        <Grid
          container
          direction="column"
          className={classNames(
            classes.form,
            FormMode.View === formMode ? classes.viewMode : ""
          )}
        >
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justify="center"
            alignItems="center"
            className={classNames(classes.question, "question")}
          >
            <Typography style={{ marginRight: "48px" }}>
              Expense Type
            </Typography>
            <Radio
              checked={form.expense_type === "goods"}
              value={"goods"}
              onChange={() =>
                this.handleFormValueChange("goods", "expense_type")
              }
              name="expense_type"
              disabled={formMode === FormMode.View}
            />
            <InputLabel style={{ marginRight: "48px" }} htmlFor="expense_type">
              Goods
            </InputLabel>
            <Radio
              checked={form.expense_type === "services"}
              value={"services"}
              onChange={() =>
                this.handleFormValueChange("services", "expense_type")
              }
              name="expense_type"
              disabled={formMode === FormMode.View}
            />
            <InputLabel htmlFor="is_gst">Services</InputLabel>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              {form.expense_type === "services" ? (
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>SAC</FormLabel>
                  <TextField
                    className={formErrors.sac ? "errorInput" : "withoutError"}
                    value={form.sac}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"sac"}
                    placeholder={"-"}
                    disabled={formMode === FormMode.View}
                    onChange={e => this.onFormElementChange(e, "sac")}
                    required
                  />
                  {formErrors.sac && (
                    <Typography className={"errorMsg2"} variant="caption">
                      {formErrors.sac}
                    </Typography>
                  )}
                </FormControl>
              ) : (
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>HSN</FormLabel>
                  <TextField
                    className={formErrors.hsn ? "errorInput" : "withoutError"}
                    value={form.hsn}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"hsn"}
                    placeholder={"-"}
                    disabled={formMode === FormMode.View}
                    onChange={e => this.onFormElementChange(e, "hsn")}
                    required
                  />
                  {formErrors.hsn && (
                    <Typography
                      className={"errorMsg2"}
                      variant="caption"
                      style={{ color: "red" }}
                    >
                      {formErrors.hsn}
                    </Typography>
                  )}
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Expense Name
                </FormLabel>
                <TextField
                  className={
                    formErrors.expense_name ? "errorInput" : "withoutError"
                  }
                  value={form.expense_name}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"expense_name"}
                  placeholder={"Expense Name"}
                  disabled={formMode === FormMode.View}
                  onChange={e => this.onFormElementChange(e, "expense_name")}
                  required
                />
                {formErrors.expense_name && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.expense_name}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Paid Through</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors.paid_through
                      ? "errorSelect"
                      : "withoutErrorSelect"
                  }
                  val={form.paid_through}
                  options={[
                    { id: "AdvanceTax", name: "Advance Tax" },
                    { id: "EmployeeAdvance", name: "Employee Advance" },
                    { id: "Prepaid Expenses", name: "Prepaid Expenses" },
                    { id: "TDS Receivable", name: "TDS Receivable" }
                  ]}
                  label=""
                  placeholder="Select Account"
                  name={"paid_through"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onFormElementChange(e, "paid_through")
                  }
                />
                {formErrors.paid_through && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.paid_through}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Currency</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors.currency ? "errorSelect" : "withoutErrorSelect"
                  }
                  val={form.currency}
                  options={this.state.currencies}
                  label=""
                  placeholder="Currency"
                  name={"currency"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onFormElementChange(e, "currency")
                  }
                />
                {formErrors.currency && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.currency}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            {form.currency !== "INR" && (
              <>
                <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                  <FormControl required style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      {`Exchange Rate in INR for 1 ${form.currency}`}
                    </FormLabel>
                    <TextField
                      className={
                        formErrors.exchange_rate ? "errorInput" : "withoutError"
                      }
                      value={form.exchange_rate}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"exchange_rate"}
                      placeholder={"Exchange Rate"}
                      disabled={formMode === FormMode.View}
                      onChange={this.handleExchangeRate}
                      required
                    />
                    {formErrors.exchange_rate && (
                      <Typography
                        className={"errorMsg2"}
                        variant="caption"
                        style={{ color: "red" }}
                      >
                        {formErrors.exchange_rate}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                  <FormControl required style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Amount
                    </FormLabel>
                    <TextField
                      className={
                        formErrors.exchange_amount
                          ? "errorInput"
                          : "withoutError"
                      }
                      value={form.exchange_amount}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"exchange_amount"}
                      placeholder={"Exchange Amount"}
                      disabled={formMode === FormMode.View}
                      onChange={this.handleExchangeAmount}
                      required
                    />
                    {formErrors.exchange_amount && (
                      <Typography
                        className={"errorMsg2"}
                        variant="caption"
                        style={{ color: "red" }}
                      >
                        {formErrors.exchange_amount}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Expense Account</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors.expense_id ? "errorSelect" : "withoutErrorSelect"
                  }
                  val={`${form.expense_id}`}
                  options={[
                    { id: "0", name: "Job Costing" },
                    { id: "1", name: "Labour" },
                    { id: "2", name: "Materials" }
                  ]}
                  label=""
                  placeholder="Expense Account"
                  name={"expense_id"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onFormElementChange(e, "expense_id")
                  }
                />
                {formErrors.expense_id && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.expense_id}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Amount (in INR)
                </FormLabel>
                <TextField
                  className={formErrors.ammount ? "errorInput" : "withoutError"}
                  value={form.ammount}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  name={"ammount"}
                  placeholder={"Amount"}
                  disabled={formMode === FormMode.View}
                  onChange={e => this.onFormElementChange(e, "ammount")}
                  required
                />
                {formErrors.ammount && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.ammount}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Repeat Every</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors.repeat_every
                      ? "errorSelect"
                      : "withoutErrorSelect"
                  }
                  actionButtonLabel={"Custom"}
                  actionButtonClass={classes.customRepeatBtn}
                  val={form.repeat_every}
                  options={[
                    { id: "Week", name: "Week" },
                    { id: "2Weeks", name: "2 Weeks" },
                    { id: "Month", name: "Month" },
                    { id: "Month", name: "Month" },
                    { id: "2Months", name: "2 Months" },
                    { id: "3Months", name: "3 Months" },
                    { id: "6Months", name: "6 Months" },
                    { id: "Year", name: "Year" },
                    { id: "2Years", name: "2 Years" },
                    { id: "3Years", name: "3 Years" },
                    { id: "Custom", name: "Custom", type: "action" }
                  ]}
                  label=""
                  placeholder="Repeat Every"
                  name={"repeat_every"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) => {
                    if (e.target.value !== "Custom") {
                      this.setState({
                        form: {
                          ...this.state.form,
                          bill_every_count: "",
                          bill_every_option: ""
                        }
                      });
                    }
                    this.onFormElementChange(e, "repeat_every");
                  }}
                />
                {formErrors.repeat_every && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.repeat_every}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            {form.repeat_every === "Custom" && (
              <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                <div style={{ display: "flex" }}>
                  <FormControl required style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Bill Every
                    </FormLabel>
                    <TextField
                      className={
                        formErrors.bill_every_count
                          ? "errorInput"
                          : "withoutError"
                      }
                      value={form.bill_every_count}
                      color={"primary"}
                      variant="outlined"
                      type={"number"}
                      name={"bill_every_count"}
                      placeholder={"-"}
                      disabled={formMode === FormMode.View}
                      onChange={e =>
                        this.onFormElementChange(e, "bill_every_count")
                      }
                      required
                    />
                  </FormControl>
                  <FormControl
                    required
                    style={{
                      width: "100%",
                      marginTop: "16px",
                      marginLeft: "16px"
                    }}
                  >
                    <OutlinedSelect
                      className={
                        formErrors.bill_every_option
                          ? "errorSelect"
                          : "withoutErrorSelect"
                      }
                      val={form.bill_every_option}
                      options={[
                        { id: "Day(s)", name: "Day(s)" },
                        { id: "Week(s)", name: "Week(s)" },
                        { id: "Month(s)", name: "Month(s)" },
                        { id: "Year(s)", name: "Year(s)" }
                      ]}
                      label=""
                      placeholder="Repeat Every"
                      name={""}
                      disabled={formMode === FormMode.View}
                      handleChange={(e: any) =>
                        this.onFormElementChange(e, "bill_every_option")
                      }
                    />
                    {formErrors.bill_every_option && (
                      <Typography
                        className={"errorMsg2"}
                        variant="caption"
                        style={{ color: "red" }}
                      >
                        {formErrors.bill_every_option}
                      </Typography>
                    )}
                  </FormControl>
                </div>
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Start Date
                </FormLabel>
                <DatePicker
                  className={
                    formErrors.start_date ? "errorDate" : "withoutErrorDate"
                  }
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  onChange={(value: any) =>
                    this.handleFormValueChange(value, "start_date")
                  }
                  name={"start_date"}
                  disabled={formMode === FormMode.View}
                  value={form.start_date}
                  minDate={new Date()}
                  calendarIcon={
                    <img
                      style={{ height: "27px" }}
                      src={formMode !== FormMode.View && calenderIcon}
                    />
                  }
                />
                {formErrors.start_date && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.start_date}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Ends On</FormLabel>
                <DatePicker
                  className={
                    formErrors.end_date ? "errorDate" : "withoutErrorDate"
                  }
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  onChange={(value: any) =>
                    this.handleFormValueChange(value, "end_date")
                  }
                  name={"end_date"}
                  disabled={formMode === FormMode.View}
                  value={form.end_date}
                  minDate={new Date()}
                  calendarIcon={
                    <img
                      style={{ height: "27px" }}
                      src={formMode !== FormMode.View && calenderIcon}
                    />
                  }
                />
                {formErrors.end_date && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.end_date}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Vendor Name
                </FormLabel>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    className={
                      formErrors.vendor_id ? "errorInput" : "withoutError"
                    }
                    value={vendorName}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"vendor_id"}
                    style={{ flex: 1, marginRight: "15px" }}
                    placeholder={"Select Vendor Name"}
                    disabled={formMode === FormMode.View}
                    // onChange={e => this.onFormElementChange(e, "vendor_id")}
                    required
                  />
                  {formMode !== FormMode.View && (
                    <div className={classes.searchIconContainer}>
                      <SearchIcon
                        onClick={() => this.setState({ vendorModalOpen: true })}
                        style={{ color: "white", fontSize: "33px" }}
                      />
                    </div>
                  )}
                </div>
                {formErrors.vendor_id && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.vendor_id}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Customer Name
                </FormLabel>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    className={
                      formErrors.customer_id ? "errorInput" : "withoutError"
                    }
                    value={customerName}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"customer_id"}
                    style={{ flex: 1, marginRight: "15px" }}
                    placeholder={"Select Customer Name"}
                    disabled={formMode === FormMode.View}
                    // onChange={e => this.onFormElementChange(e, "customer_id")}
                    required
                  />
                  {formMode !== FormMode.View && (
                    <div className={classes.searchIconContainer}>
                      <SearchIcon
                        onClick={() =>
                          this.setState({ customerModalOpen: true })
                        }
                        style={{ color: "white", fontSize: "33px" }}
                      />
                    </div>
                  )}
                </div>
                {formErrors.customer_id && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.customer_id}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Tax</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors.tax ? "errorSelect" : "withoutErrorSelect"
                  }
                  val={form.tax}
                  options={[
                    { id: "Non-Taxable", name: "Non-Taxable" },
                    { id: "GST18%", name: "GST 18%" },
                    { id: "IGST0%", name: "IGST 0%" },
                    { id: "IGST18%", name: "IGST 18%" }
                  ]}
                  label=""
                  placeholder="Select"
                  name={"tax"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) => this.handleTaxElementChange(e)}
                />
                {formErrors.tax && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.tax}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Source of Supply</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors.source_of_supply
                      ? "errorSelect"
                      : "withoutErrorSelect"
                  }
                  val={form.source_of_supply}
                  options={this.state.states}
                  label=""
                  placeholder="Select"
                  name={"source_of_supply"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onFormElementChange(e, "source_of_supply")
                  }
                />
                {formErrors.source_of_supply && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.source_of_supply}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>Destination of Supply</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors.destination_of_supply
                      ? "errorSelect"
                      : "withoutErrorSelect"
                  }
                  val={form.destination_of_supply}
                  options={this.state.states}
                  label=""
                  placeholder="Select"
                  name={"destination_of_supply"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) =>
                    this.onFormElementChange(e, "destination_of_supply")
                  }
                />
                {formErrors.destination_of_supply && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.destination_of_supply}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {form.tax && form.tax !== "Non-Taxable" && (
            <Grid
              container
              direction="row"
              style={{ marginTop: 20 }}
              wrap="nowrap"
              alignItems="center"
              className={classes.radioContainer}
            >
              <Typography style={{ marginRight: "20px" }}>
                Amount is{" "}
              </Typography>
              <Radio
                checked={
                  form.ammount_is === "taxInclusive" &&
                  form.tax !== "Non-Taxable"
                }
                value={"taxInclusive"}
                onChange={() =>
                  this.handleFormValueChange("taxInclusive", "ammount_is")
                }
                name="ammount_is"
                disabled={formMode === FormMode.View}
              />
              <InputLabel htmlFor="ammount_is">Tax Inclusive</InputLabel>
              <Radio
                checked={
                  form.ammount_is === "taxExclusive" &&
                  form.tax !== "Non-Taxable"
                }
                value={"taxExclusive"}
                onChange={e =>
                  this.handleFormValueChange("taxExclusive", "ammount_is")
                }
                name="ammount_is"
                disabled={formMode === FormMode.View}
              />
              <InputLabel htmlFor="ammount_is">Tax Exclusive</InputLabel>
            </Grid>
          )}
          {form.tax === "Non-Taxable" && (
            <Grid container direction="row" style={{ marginTop: 20 }}>
              <Grid item xs={12} sm={8} style={{ paddingRight: 12 }}>
                <FormControl required style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Reason for Exemption
                  </FormLabel>
                  <TextField
                    className={formErrors.sac ? "errorInput" : "withoutError"}
                    // value={form.comment}
                    type={"text"}
                    color={"primary"}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="outlined"
                    name={"reason_for_exemption"}
                    placeholder={"Max 500 characters..."}
                    disabled={formMode === FormMode.View}
                    // onChange={e => this.onFormElementChange(e, "comment")}
                    required
                  />
                  {/* {formErrors.comment && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {formErrors.comment}
                  </Typography>
                )} */}
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel>GST Treatment</FormLabel>
                <OutlinedSelect
                  className={
                    formErrors.gst_treatment
                      ? "errorSelect"
                      : "withoutErrorSelect"
                  }
                  optionWithDescription
                  val={form.gst_treatment}
                  options={gstTreatments}
                  label=""
                  placeholder="Select"
                  name={"gst_treatment"}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) => this.onGstTreatmentChange(e)}
                />
                {formErrors.gst_treatment && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.gst_treatment}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            {this.state.form.gst_treatment === "registeredBusiness" && (
              <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                <FormControl required style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>GSTIN</FormLabel>
                  <TextField
                    className={
                      formErrors.gst_in ? "errorInput" : "withoutError"
                    }
                    value={form.gst_in}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"gst_in"}
                    placeholder={"-"}
                    disabled={formMode === FormMode.View}
                    onChange={e => this.onFormElementChange(e, "gst_in")}
                    required
                  />
                  {formErrors.gst_in && (
                    <Typography
                      className={"errorMsg2"}
                      variant="caption"
                      style={{ color: "red" }}
                    >
                      {formErrors.gst_in}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={8} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Reverse Charge
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.showReverseCharge}
                      onChange={this.handleReverChargeShow}
                      name="checkedA"
                    />
                  }
                  label="This transaction is applicable for reverse charge"
                />
              </FormControl>
            </Grid>
          </Grid>
          {this.state.showReverseCharge && (
            <Grid container direction="row" style={{ marginTop: 20 }}>
              <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                <FormControl required style={{ width: "100%" }}>
                  <FormLabel>Reverse Charge Account</FormLabel>
                  <OutlinedSelect
                    className={
                      formErrors.reverse_charge_account
                        ? "errorSelect"
                        : "withoutErrorSelect"
                    }
                    val={form.reverse_charge_account}
                    options={[
                      { id: "0", name: "Account 1" },
                      { id: "1", name: "Account 2" },
                      { id: "2", name: "Account 3" }
                    ]}
                    label=""
                    placeholder="Reverse Charge Account"
                    name={"reverse_charge_account"}
                    disabled={formMode === FormMode.View}
                    handleChange={() =>
                     function(){}
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Reverse Charge
                  </FormLabel>
                  <OutlinedInput
                      type={"text"}
                      value={form.reverse_charge_ammount}
                      onChange={e =>
                        this.onFormElementChange(e, "reverse_charge_ammount")
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <div>
                            <Select
                              value={form.reverse_charge_type}
                              onChange={(e: any) =>
                                this.onFormElementChange(
                                  e,
                                  "reverse_charge_type"
                                )
                              }
                              className={classes.reverseTypeSelect}
                            >
                              <MenuItem value={"₹"}>₹</MenuItem>
                              <MenuItem value={"%"}>%</MenuItem>
                            </Select>
                          </div>
                        </InputAdornment>
                      }
                    />
                </FormControl>
              </Grid>

             
            </Grid>
          )}
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={8} style={{ paddingRight: 12 }}>
              <FormControl required style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Comments</FormLabel>
                <TextField
                  className={formErrors.comment ? "errorInput" : "withoutError"}
                  value={form.comment}
                  type={"text"}
                  color={"primary"}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  name={"comment"}
                  placeholder={"Max 500 characters..."}
                  disabled={formMode === FormMode.View}
                  onChange={e => this.onFormElementChange(e, "comment")}
                  required
                />
                {formErrors.comment && (
                  <Typography
                    className={"errorMsg2"}
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    {formErrors.comment}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <VendorSelectionModal
          title={"Advanced vendor search"}
          open={this.state.vendorModalOpen}
          handleClose={this.onCloseVendorModal}
          vendors={this.state.vendors}
          handleSubmit={(id: string) => this.onSubmitVendorModal(id)}
          // handleSubmit={this.onSubmitDeleteModal.bind(this)}
        />
        <CustomerSelectionModal
          title={"Advanced customer search"}
          open={this.state.customerModalOpen}
          handleClose={this.onCloseCustomerModal}
          customers={this.state.customers}
          handleSubmit={(id: string) => this.onSubmitCustomerModal(id)}
          // handleSubmit={this.onSubmitDeleteModal.bind(this)}
        />
      </CustomModal>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(RecurringExpenseForm);