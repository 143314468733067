import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import PageHeaderController from "../../CommonLayout/HRMSCommons/src/layout/PageHeaderController";
// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
} from "./assets";
import { configJSON } from "./UserListController";
// Customizable Area End
// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View,
  Copy
}

export interface IUserForm {
  id: any;
  first_name: any;
  email: any;
  country_code: any;
  phone_number: any;
  activated: any;
  role_id: any;
  role: any;
  full_phone_number: any;
  current_password:string;
  new_password:string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: IUserForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  onClose: () => void;
  handleSubmit: (form: IUserForm) => void;
  errormsg?: any;
  editUserstatusError:any,
  editUserNameError :any,
  editUseremailError :any,
  editUserroleError :any,
  editUsermobileError :any,
  editUserPasswordError :any,
  editUserCurrentPasswordError :any,
  roleList: [];
  navigation:any
  // Customizable Area End
}
interface roleAttribute {
  id: number;
  name: string;
  status: string;
  created_by: string;
  custom_id: string;
  created: Date;
}
interface roleData {
  id: string;
  attributes: roleAttribute
}
interface roleMetaData {
  total_pages: number;
  message: string,
}


interface S {
  // Customizable Area Start
  form: IUserForm;
  isSubmitting: boolean;
  userRoleList: [],
  userRoleListMeta: roleMetaData,
  rolePage: number,
  createUserstatusError:any,
  createUserNameError :any,
  createUseremailError :any,
  createUserroleError :any,
  createUsermobileError :any,
  createUsercountry_codeError :any,
  newPasswordError:any
  passwordType:any
  imgPasswordVisible:any
  imgPasswordInVisible:any
  countryCodeList:any
  newPasswordType:any
  confirmNewPasswordError:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsersController extends BlockComponent<
  Props,
  S,
  SS
> {


  // Customizable Area Start



  private initialValues: IUserForm = {
    id: "000",
    first_name: "",
    email: "",
    country_code: "91",
    phone_number: "",
    activated: "",
    role_id: "",
    full_phone_number: "",
    role: "",
    current_password:"",
    new_password:"",
  };
  apiGetCountryCodeList: string = "";
  // Customizable Area End
  apiGetRoleList: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.log('props', props)

    // this.subScribedMessages = [
    //   // Customizable Area Start
    //   // Customizable Area End
    // ];
     // Customizable Area Start
     this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
      ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      isSubmitting: false,
      createUserstatusError:"",
      createUserNameError :"",
      newPasswordError:"",
      confirmNewPasswordError:"",
      createUseremailError :"",
      countryCodeList:[],
      createUserroleError :"",
      createUsermobileError :"",
      createUsercountry_codeError :"",
      passwordType:'password',
      userRoleList: [],
      userRoleListMeta: {total_pages: 0, message: ''},
      rolePage: 0,
      imgPasswordVisible:imgPasswordVisible,
      imgPasswordInVisible:imgPasswordInVisible,
      newPasswordType:"password"
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.apiGetCountryCodeList) {
        if (responseJson != null) {
            if(!responseJson.errors && !responseJson.error){
              let countryCodes=responseJson?.country_codes.sort()
              const codeList = responseJson?.country_codes && countryCodes.map((item: any,index:number) => {
                return { 
                  "id": item,
                  "name": "+ "+item,}
                
              })
              this.setState({ countryCodeList: codeList });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
        }
      }
    } 
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getCountryCodesList();

  }

  public componentDidUpdate(prevProps: Props) {

    const { isOpen, initialValues, formMode } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: IUserForm = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View,FormMode.Copy].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };
        }
        
        this.setState({
          form,
          isSubmitting: false
        });
      }
    }
  }

  togglePassword = () => {
    if (this.state.passwordType === "text") {
      this.setState({
        passwordType: "password",
      });

      return;
    }
    this.setState({
      passwordType: "text",
    });
  };
  toggleNewPassword = () => {
    if (this.state.newPasswordType === "password") {
      this.setState({
        newPasswordType: "text",
      });

      return;
    }
    this.setState({
      newPasswordType: "password",
    });
  };
  // public country_codes: Array<any> = this.state.countryCodeList.map(
  //   (v: any) => ({
  //     id: v,
  //     name: `+${v}`
  //   })
  // );
  public onChangeFirstName({ target }: React.ChangeEvent<{ value: string }>) {
    this.setState({ form: { ...this.state.form, first_name: target.value.trimStart() } });
  }

  public onChangeEmail({ target }: React.ChangeEvent<{ value: string }>) {
    this.setState({ form: { ...this.state.form, email: target.value } });
  }

  public onChangeCountryCode({ target }: React.ChangeEvent<{ value: string }>) {
    this.setState({ form: { ...this.state.form, country_code: target.value } });
  }

  public onChangePhoneNumber({ target }: React.ChangeEvent<{ value: string }>) {
    this.setState({ form: { ...this.state.form, phone_number: target.value } });
  }

  public onChangeActivated({ target }: React.ChangeEvent<{ value: string }>) {
    let value = target.value;
    value = (target.value=== "null") ? "" : target.value;
    this.setState({ form: { ...this.state.form, activated: value } });
  }
  public onChangeCurrentPassword({ target }: React.ChangeEvent<{ value: string }>) {
    let value = target.value;
    value = (target.value=== "null") ? "" : target.value;
    this.setState({ form: { ...this.state.form, current_password: value } });
  }
  public onChangeNewPassword({ target }: React.ChangeEvent<{ value: string }>) {
    let value = target.value;
    value = (target.value=== "null") ? "" : target.value;
    this.setState({ form: { ...this.state.form, new_password: value } });
  }

  public onChangeRoleID({ target }: React.ChangeEvent<{ value: string }>) {
    let value = target.value;
    value = (target.value=== "null") ? "" : target.value;
    this.setState({ form: { ...this.state.form, role_id: value } });
  }
  handleValidation = (form: IUserForm) => {
    let isValid = true;
    const regex = /^[a-zA-Z ]{2,40}$/i;
    if (form.first_name === "") {
        this.setState({ createUserNameError:("Name is required")
        });
        isValid = false;
    }else if (form.first_name && regex.test(form.first_name) === false) {
      this.setState({ createUserNameError:("Name is Invalid")  });
        isValid = false;
    } else {
      this.setState({ createUserNameError: ''});
      }
     let emailRegex=/^(?:[A-Za-z0-9.+-]+@[a-z.+-]+\.[a-z]{2,3})$/i

    if (form.email === "") {
         this.setState({ createUseremailError:'Email is required'
         });
         isValid = false;
       }else if (form.email&& emailRegex.test(form.email) === false) {
            this.setState({ createUseremailError:"Email is Invalid" 
         });
              isValid = false;
          }else {
            this.setState({ createUseremailError: ''});
        }
    
    if (form.country_code === "") {
      this.setState({ createUsercountry_codeError:'Select country code'
      });
        isValid = false;
    }else {
        this.setState({ createUsercountry_codeError: ''});
    }

    let phoneRegex=/^(?:.*[0-9]{10,11})$/i
    if (form.phone_number === "") {
      this.setState({ createUsermobileError:'Mobile Number is required'
      });
        isValid = false;
    }else if (form.phone_number&& phoneRegex.test(form.phone_number) === false) {
      this.setState({ createUsermobileError:"Mobile Number is Invalid" 
   });
        isValid = false;
    }else {
        this.setState({ createUsermobileError: ''});
    }

    if (form.role_id <= 0 || form.role_id === "null" ) {
      this.setState({ createUserroleError:'Role is required'
      });
        isValid = false;
    }else {
        this.setState({ createUserroleError: ''});
    }

    if (form.activated === "" || form.activated === "null") {
      this.setState({ createUserstatusError:'Status is required'
      });
        isValid = false;
    }else {
        this.setState({ createUserstatusError: ''});
    }
    const passwordRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    
    if (form.current_password ) {
      if(!form.new_password){
      this.setState({
        confirmNewPasswordError: "Confirm new password is required"
      });
      isValid = false;
    }else {
      this.setState({ confirmNewPasswordError: "" });
    }
  }
    if(form.current_password){
      if( passwordRegex.test(form.current_password) === false) {
         this.setState({
          newPasswordError:"Please enter a valid password (ex: Aa@12345)"
         });
         isValid = false;
       }else {
         this.setState({
          newPasswordError: "",
         });
       } }
   if(form.new_password){
  if (form.current_password !== form.new_password) {
      this.setState({
        confirmNewPasswordError: "Confirm password should be same"
      });
      isValid = false;
    } else {
      this.setState({ confirmNewPasswordError: "" });
    }
  }

    return isValid;
};

handleSubmit = (e: any) => {
    if (this.handleValidation(this.state.form)) {
      this.props.handleSubmit(this.state.form);
    }
};
getCountryCodesList = () => {
  let token = localStorage.getItem("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetCountryCodeList = requestMessage.messageId
  let apiEndPoint = configJSON.UsersEndPoint + `/all_country_codes`
    
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.GET
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  public handleFormClose() {
    this.setState({createUserstatusError:"",
    createUserNameError :"",
    createUseremailError :"",
    createUserroleError :"",
    createUsermobileError :"",
    createUsercountry_codeError :"",
    newPasswordError: "",
    confirmNewPasswordError:""
  })
    this.props.onClose();
  }

  // Customizable Area End
}