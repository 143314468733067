import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { calenderIcon } from "../../PayrollIntegration/src/assets";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

// Customizable Area End

export enum FormMode {
  Create,
  Edit,
  View
}

export interface Props {
  // Customizable Area Start
  classes: any;
  anchor: any;
  onClose: () => void;
  visible: boolean;
  onSubmit: () => void;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  calenderIcon: any;
  anchorEl: HTMLElement | null;
  isLoading: boolean;
  error?: string;
  class: string;
  division: string;
  date: string;
  from: string;
  to: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilterController extends BlockComponent<Props, S, SS> {
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      isLoading: true,
      calenderIcon: calenderIcon,
      class: "",
      division: "",
      date: "",
      from: "",
      to: ""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    // this.getDashboardChartCall();
  }

  //#region Service Calls

  //#endregion Service Calls

  // Customizable Area End
}
