import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { debounce } from 'lodash';
// Customizable Area End

import { FormMode, Props as ICustomerProps, ICustomerForm, IBusinessCustomerForm } from "./CustomerListFormController";
export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

interface ICustomer {
  id: number;
  type: string;
  selected: boolean;
  attributes: {
    customer_name: string;
    customer_type: string;
    contact_no: number;
    email: string;
    company_email: string;
    country_code: string;
  };
}

interface ICustomerDetail {
  id: string;
  type: string;
  attributes: {
    contact_detail: {
      id: string;
      type: string,
      attributes: {
        street_address: string;
        address: string;
        city: string;
        state: string;
        pin_code: string;
        country: string;
        email: string;
        phone_number: string;
        country_code: string;
        billing_street: string;
        billing_address: string;
        shiping_street: string;
        shiping_address: string;
      }
    },
    primary_details: {
      id: string;
      type: string,
      attributes: {
        first_name: string;
        last_name: string;
        gender: string;
        relationships: string;
        phone_number: string;
        country_code: string;
        email: string;
        remarks: string;
        billto: string;
      }
    },
    secondary_details: {
      id: string;
      type: string,
      attributes: {
        first_name: string;
        last_name: string;
        gender: string;
        nationality: string;
        admission_number: string;
        class_name: string;
        class_division: string;
      }
    }[]
  }
}

interface IBusinessCustomerDetail {
  business_details: {
    id: string;
    type: string;
    attributes: {
      company_name: string;
      primary_contact: string;
      email: string;
      country_code: string;
      contact_number: string;
      website_url: string;
      billing_street: string;
      billing_address: string;
      shipping_street: string;
      shipping_address: string;
    }
  },
  other_details: {
    id: string;
    type: string;
    attributes: {
      gst_treatment_id: number | null,
      gstin_number: string;
      place_of_supply: string;
      tax_preference: string;
      payment_term_id: number | null;
      remarks: string;
    }
  }
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  customer_type: string;
  company_name: string;
  primary_contact: string;
  secondary_contact: string;
  contact_number: string;
  email: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: ICustomer | null;
  actionMenuItems: Array<IActionMenuItem>;
  customerList: Array<ICustomer>;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | 'indeterminate' | true;
  isLoading: boolean;
  deleteModalOpen: boolean;
  deleteMessage: "single" | "multiple";
  filterForm: IFilterForm;
  isCustomerFormModalOpened: boolean;
  customerForm: ICustomerForm | null;
  businessCustomerForm: IBusinessCustomerForm | null;
  customerFormMode: FormMode;
  customerType: string | undefined;
  customerDetail: ICustomerDetail | IBusinessCustomerDetail | null;
  searchKeyword: string;
  currentPage: number;
  closeImportModal: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerListController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editCustomer.bind(this) },
    { label: "Delete", action: this.deleteCustomer.bind(this) },
    { label: "View", action: this.viewCustomer.bind(this) },
  ];

  public RequestMessage = {
    GetCustomers: this.buildRequestMessage(Method.GET),
    ImportCustomers: this.buildRequestMessage(Method.POST),
    GetCustomerDetail: this.buildRequestMessage(Method.GET),
    FilterCustomers: this.buildRequestMessage(Method.GET),
    DeleteCustomer: this.buildRequestMessage(Method.DELETE),
    CreateCustomer: this.buildRequestMessage(Method.POST),
    EditCustomer: this.buildRequestMessage(Method.PUT),
    ExportCustomers: this.buildRequestMessage(Method.GET),
    SearchCustomers: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  }

  public customerFormProps: { [key: number]: ICustomerProps } = {
    [FormMode.Create]: {
      title: "Create Customer",
      submitLabel: "Save",
      initialValues: null,
      businessInitialValues: null,
      formMode: FormMode.Create,
      isOpen: false,
      onClose: this.onCloseCustomerFormModal.bind(this),
      onSubmit: this.onSubmitCreateCustomerModal.bind(this),
      requestMessage: this.RequestMessage.CreateCustomer,
      handleCustomerType: this.handleCustomerType.bind(this),
    },
    [FormMode.Edit]: {
      title: "Edit Customer",
      submitLabel: "Update",
      initialValues: null,
      businessInitialValues: null,
      formMode: FormMode.Edit,
      isOpen: false,
      onClose: this.onCloseCustomerFormModal.bind(this),
      onSubmit: this.onSubmitEditCustomerModal.bind(this),
      requestMessage: this.RequestMessage.EditCustomer,
      handleCustomerType: this.handleCustomerType.bind(this),
    },
    [FormMode.View]: {
      title: "Customer",
      submitLabel: "Back to Listing",
      initialValues: null,
      businessInitialValues: null,
      formMode: FormMode.View,
      isOpen: false,
      onClose: this.onCloseCustomerFormModal.bind(this),
      onSubmit: this.onCloseCustomerFormModal.bind(this),
      requestMessage: this.RequestMessage.Null,
      handleCustomerType: this.handleCustomerType.bind(this),
    }
  }
  tableRef: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      actionedTableRow: null,
      customerList: [],
      actionMenuItems: [],
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      deleteMessage: "single",
      filterForm: {
        customer_type: '',
        company_name: '',
        primary_contact: '',
        secondary_contact: '',
        contact_number: '',
        email: '',
      },
      isCustomerFormModalOpened: false,
      customerForm: null,
      businessCustomerForm: null,
      customerFormMode: FormMode.Create,
      customerType: 'individual',
      customerDetail: null,
      searchKeyword: "",
      currentPage: 1,
      closeImportModal: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.tableRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (callID) {
        case this.RequestMessage.GetCustomers.messageId:
        case this.RequestMessage.FilterCustomers.messageId:
          if (response !== null) {
            this.setState({
              customerList: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false,
              searchKeyword: ""
            });
          }
          break;

        case this.RequestMessage.GetCustomerDetail.messageId:
          if (response !== null) {
            this.setState({
              isLoading: false,
              customerDetail: response?.data
            });

            this.openCustomerModalWithInitialData(response?.data);
          }
          break;

        case this.RequestMessage.DeleteCustomer.messageId:
          if (response !== null) {
            if (response?.message) {
              toast.success(response?.message);
            }
            this.setState({
              deleteModalOpen: false,
              actionedTableRow: null
            });

            this.getCustomersCall();
          }
          break;

        case this.RequestMessage.CreateCustomer.messageId:
          if (response !== null) {
            if (response?.data && !response?.errors?.length) {
              toast.success("Customer created successfully");
            }
            this.setState({ isCustomerFormModalOpened: false })
            this.getCustomersCall();
          }

          break;

        case this.RequestMessage.EditCustomer.messageId:
          if (response !== null) {
            if (response?.data && !response?.errors?.length) {
              toast.success("Customer updated successfully");
            } else if(response?.errors?.length) {
              toast.error("Customer update is not successfull")
            }
            this.setState({ isCustomerFormModalOpened: false })
            this.getCustomersCall();
          }

          break;
        
        case this.RequestMessage.ImportCustomers.messageId:
          if (response !== null) {
            this.setState({ closeImportModal: true }, () => {
              this.setState({ closeImportModal: false });
            });
            this.getCustomersCall();
          }

          break;

        // case this.RequestMessage.ExportCustomers.messageId:
        //   const rawCVS = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        //   debugger;
        //   if (response !== null) {
        //     const file = new Blob(response, { type: "text/csv" });
        //     const fileURL = URL.createObjectURL(file);
        //     window.open(fileURL);
        //   }

        //   break;
        
        case this.RequestMessage.SearchCustomers.messageId:
          if (response !== null) {
            this.setState({
              customerList: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public printReferance = React.createRef();
  public async componentDidMount() {
    super.componentDidMount();

    this.getCustomersCall();
  }

  public onClickCreateCustomer() {
    this.setState({
      isCustomerFormModalOpened: true,
      customerFormMode: FormMode.Create,
      customerType: "individual"
    });
  }

  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    this.setState({ searchKeyword: value });
    const debouncedSave = debounce((value) => {
      if (value !== "") {
        this.setState({ currentPage: 1 });
        this.getSearchCustomersCall(1, value);
      } else {
        this.getCustomersCall();
      }
    }, 100);
    debouncedSave(value);
  }

  public onSelectAllCustomerList(event: React.ChangeEvent<HTMLInputElement>) {
    const { customerList } = this.state;
    const { checked } = event.target;

    const updatedCustomerList = customerList.map((customer) => {
      return {
        ...customer,
        selected: checked
      }
    });

    this.setState({
      customerList: updatedCustomerList,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedCustomerList)
    });
  }

  public onSelectCustomerList(event: React.ChangeEvent<HTMLInputElement>, row: ICustomer) {
    const { customerList } = this.state;
    const { checked } = event.target;

    const updatedCustomerList = customerList.map((customer) => {
      return {
        ...customer,
        selected: `${row.id}-${row.attributes.customer_type}` === `${customer.id}-${customer.attributes.customer_type}` ? checked : customer.selected
      }
    });

    this.setState({
      customerList: updatedCustomerList,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedCustomerList)
    });
  }

  public onActionButtonClick(event: any, item: ICustomer) {
    this.setState({
      anchorEl: event.currentTarget,
      actionedTableRow: item,
      actionMenuItems: this.tableActionMenuItems
    });
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  public onClickPagination(page: any) {
    this.setState({currentPage: page.selected});
    this.tableRef.current.scrollIntoView()

    if(this.state.searchKeyword !== ""){
      this.getSearchCustomersCall(page.selected + 1, this.state.searchKeyword);
    }else{
      this.getCustomersCall(page.selected + 1);
    }
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      actionedTableRow: null
    });
  }

  public onSubmitDeleteModal() {
    this.setState({ selectAllCheckboxChecked: false });
    this.deleteCustomerCall();
  }

  public onCloseCustomerFormModal() {
    this.setState({ isCustomerFormModalOpened: false });
  }

  public onSubmitCreateCustomerModal(form: ICustomerForm | IBusinessCustomerForm) {
    this.createCustomerCall(form);
  }

  public onSubmitEditCustomerModal(form: ICustomerForm | IBusinessCustomerForm) {
    this.editCustomerCall(form, this.state.actionedTableRow);
  }

  public onUploadCSVForImport(file: File) {
    this.importCustomersCall(file);
  }

  public onClickExportCSVButton(isSample: boolean) {
    this.exportCustomersCall(isSample);
  }

  //#region Filter Methods
  public onFilterElementChange(element: any, property: string) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        [property]: element.target.value
      }
    });
  }

  public handleCustomerType(customerType: string) {
    this.setState({ customerType })
  }

  public onFilterFormSubmit() {
    this.filterCustomersCall(this.state.filterForm);
  }
  //#endregion Filter Methods

  private getSearchCustomersCall(page: number = 1, searchKey: string) {
    this.setState({ isLoading: true });

    this.RequestMessage.SearchCustomers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/individual_customers/customers_search?search_term=${searchKey}&page=${page? page: 1}`
    );
    
    runEngine.sendMessage(this.RequestMessage.SearchCustomers.id, this.RequestMessage.SearchCustomers);
  }

  private editCustomer() {
    this.getCustomerDetail(this.state.actionedTableRow?.id, this.state.actionedTableRow?.attributes.customer_type);
    this.setState({
      customerFormMode: FormMode.Edit,
      customerType: this.state.actionedTableRow?.attributes.customer_type
    })
  }

  private viewCustomer() {
    this.getCustomerDetail(this.state.actionedTableRow?.id, this.state.actionedTableRow?.attributes.customer_type);
    this.setState({
      customerFormMode: FormMode.View,
      customerType: this.state.actionedTableRow?.attributes.customer_type
    })
  }

  private deleteCustomer() {
    const currentCustomer = this.state.actionedTableRow;
    if (!currentCustomer) return;
    const { customerList } = this.state;
    if (!currentCustomer.selected) {
      const updatedCustomers = customerList.map(el => {
        return { ...el, selected: (el.id === currentCustomer.id && el.attributes.customer_type === currentCustomer.attributes.customer_type) ? true : false };
      });

      this.setState({
        customerList: updatedCustomers,
        selectAllCheckboxChecked: false,
        deleteMessage: "single"
      });
    } else {
      if (customerList.filter(el => el.selected).length > 1)
        this.setState({ deleteMessage: "multiple" });
      else this.setState({ deleteMessage: "single" });
    }

    this.setState({
      deleteModalOpen: true,
      customerType: this.state.actionedTableRow?.attributes.customer_type
    });
  }

  private openCustomerModalWithInitialData(customer: any) {
    if (customer !== null) {
      if (this.state.customerType === 'individual') {
        const secondary_array = customer?.attributes?.secondary_details?.map((el: any) => {
          return { ...el.attributes, id: el.id }
        })

        this.setState({
          isCustomerFormModalOpened: true,
          customerForm: {
            primary_details: customer?.attributes?.primary_details?.attributes,
            contact_details: customer?.attributes?.contact_detail?.attributes,
            secondary_details: secondary_array
          }
        });
      } else {
        this.setState({
          isCustomerFormModalOpened: true,
          businessCustomerForm: {
            business_details: customer?.business_details?.attributes,
            other_details: customer?.other_details?.attributes
          }
        })
      }
    }
  }

  private selectedCustomerList(customerList?: Array<ICustomer>): Array<ICustomer> {
    if (customerList === undefined) {
      customerList = this.state.customerList;
    }

    return customerList.filter((customer) => customer?.selected);
  }

  private isSelectAllCheckboxChecked(customerList: Array<ICustomer>): false | "indeterminate" | true {
    const selectedCustomerList = this.selectedCustomerList(customerList);
    const { length } = selectedCustomerList;

    return length !== 0 && (length === customerList.length ? true : 'indeterminate');
  }

  //#region Service Calls
  private getCustomersCall(page: number = 1) {
    this.setState({ isLoading: true });
    page = this.state.currentPage;

    this.RequestMessage.GetCustomers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/profiles/get_all_customers?page=${page}`
    );

    runEngine.sendMessage(this.RequestMessage.GetCustomers.id, this.RequestMessage.GetCustomers);
  }

  private deleteCustomerCall() {
    const customers = this.state.customerList.filter(el => el.selected)
    if (customers.length > 0) {
      if (customers.length === 1) {
        const endPoint = this.state.customerType === 'individual' ? 'individual_customers' : 'business_customers'
        this.RequestMessage.DeleteCustomer.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.CustomeresEndPoint}/${endPoint}/${customers[0].id}`
        );
      } else {
        const businessCustomers = customers.filter(el => el.attributes.customer_type === 'business').map(el => Number(el.id))
        const individualCustomers = customers.filter(el => el.attributes.customer_type === 'individual').map(el => Number(el.id))
          this.RequestMessage.DeleteCustomer.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.CustomeresEndPoint}/profiles/delete_customers?business_customers=${JSON.stringify(businessCustomers)}&individual_customers=${JSON.stringify(individualCustomers)}`
          );
        }
      
      runEngine.sendMessage(this.RequestMessage.DeleteCustomer.id, this.RequestMessage.DeleteCustomer);
        
    }
  }

  private createCustomerCall(form: ICustomerForm | IBusinessCustomerForm) {
    this.RequestMessage.CreateCustomer.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: { attributes: form } })
    );

    const endPoint = this.state.customerType === 'individual' ? 'individual_customers' : 'business_customers'
    this.RequestMessage.CreateCustomer.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/${endPoint}`
    );

    runEngine.sendMessage(this.RequestMessage.CreateCustomer.id, this.RequestMessage.CreateCustomer);
  }

  private getCustomerDetail(customerId: any, type: string | undefined) {
    const endPoint = type === 'individual' ? 'individual_customers' : 'business_customers'

    this.RequestMessage.GetCustomerDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/${endPoint}/${customerId}`
    );

    runEngine.sendMessage(this.RequestMessage.GetCustomerDetail.id, this.RequestMessage.GetCustomerDetail);
  }

  private editCustomerCall(form: ICustomerForm | IBusinessCustomerForm, customer: ICustomer | null) {
    if (customer !== null) {
      this.RequestMessage.EditCustomer.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data: { attributes: form } })
      );

      const endPoint = this.state.customerType === 'individual' ? 'individual_customers' : 'business_customers'
      this.RequestMessage.EditCustomer.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.CustomeresEndPoint}/${endPoint}/${customer?.id}`
      );

      runEngine.sendMessage(this.RequestMessage.EditCustomer.id, this.RequestMessage.EditCustomer);
    }
  }

  private filterCustomersCall(form: IFilterForm) {
    this.RequestMessage.FilterCustomers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/profiles/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(this.RequestMessage.FilterCustomers.id, this.RequestMessage.FilterCustomers);
  }

  private exportCustomersCall(isSample: boolean = false) {
    const endPoint = this.state.customerType === 'individual' ? 'individual_customers' : 'business_customers'
    let url = `${configJSON.APIBaseURL}/${configJSON.CustomeresEndPoint}/${endPoint}/export`;

    const requestOptions = {
      method: "POST",
      headers: JSON.parse(this.getHeaderMessage()),
    };

    if (isSample) {
      url = `${configJSON.APIBaseURL}/${configJSON.CustomeresEndPoint}/customer_csv_sample_file`;
    }

    fetch(url, requestOptions)
      .then((response) => {
        const now = new Date().getTime();
        let fileName = `customers_csv_${now}.csv`;

        if (isSample) {
          fileName = `customers_csv_sample_file.csv`;
        }

        response.blob().then(blob => downloadCSV(blob, fileName))
      })
      .catch((error) => {
        toast.success(error.message);
      });
  }

  private importCustomersCall(file: File) {
    const formData = new FormData();

    formData.append("file", file);

    const endPoint = this.state.customerType === 'individual' ? 'individual_customers' : 'business_customers'
    
    this.RequestMessage.ImportCustomers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/${endPoint}/import`
    );

    this.RequestMessage.ImportCustomers.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(this.RequestMessage.ImportCustomers.id, this.RequestMessage.ImportCustomers);
  }
  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CustomeresEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    return Object.entries(form)
      .filter(([key, value]) => value !== "" && value !== null && value !== "null")
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}