Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.APIBaseURL = urlConfig.baseURL;
exports.APIContentType = "application/json";

exports.itemsesEndPoint = "bx_block_item/items";
// Customizable Area End