import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";

import OrganizationProfileController, {
  FormMode,
  Props
} from "./OrganizationProfileController";
import {
  Button,
  ButtonType
} from "../../CommonLayout/HRMSCommons/src/button/default";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import {
  FormControl,
  FormLabel,
  TextField,
  Typography
} from "@material-ui/core";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import CustomUploadModal from "../../CommonLayout/CustomUploadModal/src/CustomUploadModal.web";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 170px)",
    maxHeight: "calc(100vh - 170px)",
    display: "block",
    flexWrap: "nowrap",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10,
    overflow: "auto",
    padding: "10px"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  formContainer: {
    position: "relative",
    // boxShadow: '0px 5px 10px 0px #d9d9d9',
    // borderRadius: 10,
    // maxHeight: "70vh",
    marginBottom: 10,
    padding: "10px",
    overflow: "auto"
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  },
  saveCancelButton: {
    marginRight: 30,
    height: 40,
    width: 140
  },
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)"
  },
  select: {
    height: "40px"
  },
  controlButton: {
    fontSize: 14,
    margin: 8,
    width: 80,
    height: 40
  },
  profileImage: {
    maxHeight: "180px",
    maxWidth: "230px",
    margin: "12px",
    minWidth: "100px",
    borderRadius: 5
  },
  profileContainer: {
    backgroundColor: "#FCFCFC",
    borderRadius: 10,
    marginBottom: 20,
    alignItems: "center"
  },
  profileButtonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  formLabel: { marginBottom: "16px", marginTop: "8px", color: "#405367" },
  formControl: { marginBottom: "8px", marginTop: "8px", width: "100%" }
});
// Customizable Area End

class OrganizationProfile extends OrganizationProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { isEdit, countries, states, formErrors } = this.state;
    const formMode = isEdit ? FormMode.Edit : FormMode.View;
    const renderView = (value: string) => (
      <div style={{ color: "#7B7B7B" }}>{value}</div>
    );

    const { form } = this.state;

    return (
      <Layout navigation={navigation} hideSearchBar>
        <Grid container className={classes.container}>
          <PageHeader
            title="Organization Profile"
            haveFilterButton={false}
            haveCSVButton={false}
            havePrintButton={false}
            haveCreateButton={false}
            haveEditButton={!isEdit}
            onClickEditButton={this.onClickEdit.bind(this)}
          />
          <div className={classes.formContainer}>
            {/* <OrganizationProfileForm
              {...this.organizationProfileFormProps[
                isEdit ? FormMode.Edit : FormMode.View
              ]}
              isOpen={this.state.isTaxRateFormModalOpened}
              initialValues={this.state.organizationProfileForm}
            /> */}
            <Grid container direction="column">
              <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                <FormControl
                  className={classes.profileContainer}
                  style={{
                    boxShadow:
                      formMode !== FormMode.View
                        ? "0px 5px 10px 0px #d9d9d9"
                        : "unset"
                  }}
                >
                  <img
                    className={classes.profileImage}
                    src={
                      this.state.form.profileImageUrl ||
                      "https://media.istockphoto.com/photos/headshot-portrait-of-smiling-male-employee-in-office-picture-id1309328823?s=612x612"
                    }
                  />
                  {formMode !== FormMode.View && (
                    <div className={classes.profileButtonContainer}>
                      <Button
                        title="Cancel"
                        size="small"
                        buttonType={ButtonType.Secondary}
                        className={classes.controlButton}
                        style={{ borderColor: "#F15B5A", color: "#4D5B6A" }}
                        onClick={this.onClickCancel.bind(this)}
                      />
                      <Button
                        title="Change"
                        size="small"
                        buttonType={ButtonType.Primary}
                        className={classes.controlButton}
                        onClick={() =>
                          this.setState({ uploadModalVisible: true })
                        }
                      />
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.formLabel}>
                    Organization Name
                  </FormLabel>
                  {formMode === FormMode.View ? (
                    renderView(form.name)
                  ) : (
                    <TextField
                      value={form.name}
                      //size={"small"}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"name"}
                      placeholder={"Enter Organization Name"}
                      className="inputContainerOrganization"
                      onChange={this.onChangeName.bind(this)}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={4} style={{ paddingRight: 12, marginTop: 10 }}>
                {formMode === FormMode.View ? (
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>
                      Business Location
                    </FormLabel>
                    {renderView(form.businessLocation)}
                  </FormControl>
                ) : (
                  <OutlinedSelect
                    val={form.businessLocation}
                    options={
                      !countries
                        ? []
                        : countries
                            .map(el => ({
                              id: el.name,
                              name: el.name
                            }))
                            .filter(el => el.name === "India") || []
                    }
                    label="Business Location"
                    labelStyle={{ color: "#405367" }}
                    placeholder="Select Location"
                    name={"businessLocation"}
                    disabled
                    styleOverrides={{ height: "56px" }}
                    handleChange={this.onChangeBusinessLocation.bind(this)}
                    className={undefined}
                  />
                )}
              </Grid>
              <Grid item sm={8} style={{ paddingRight: 12, marginTop: 10 }}>
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.formLabel}>Address*</FormLabel>
                  {formMode === FormMode.View ? (
                    <>
                      {renderView(form.addressLines[0])}
                      {renderView(form.addressLines[1])}
                    </>
                  ) : (
                    <>
                      <TextField
                        value={form.addressLines[0]}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        //size={"small"}
                        name={"name"}
                        placeholder={"Enter Address.."}
                        className="inputContainerOrganization"
                        onChange={this.onChangeAddressLine1.bind(this)}
                        required
                      />
                      {formErrors.addressLines && (
                        <p className="errorMsg2">{formErrors.addressLines}</p>
                      )}
                      <TextField
                        style={{ marginTop: 10 }}
                        value={form.addressLines[1]}
                        color={"primary"}
                        variant="outlined"
                        //size={"small"}
                        type={"text"}
                        name={"name"}
                        placeholder={" "}
                        onChange={this.onChangeAddressLine2.bind(this)}
                      />
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>City</FormLabel>

                    {formMode === FormMode.View ? (
                      renderView(form.city)
                    ) : (
                      <TextField
                        value={form.city}
                        color={"primary"}
                        variant="outlined"
                        //size={"small"}
                        type={"text"}
                        name={"city"}
                        placeholder={"Enter City"}
                        className="inputContainerOrganization"
                        onChange={this.onChangeCity.bind(this)}
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={4} style={{ paddingRight: 12 }}>
                  {formMode === FormMode.View ? (
                    <FormControl className={classes.formControl}>
                      <FormLabel className={classes.formLabel}>State</FormLabel>
                      {renderView(form.state)}
                    </FormControl>
                  ) : (
                    <OutlinedSelect
                      val={form.state}
                      options={
                        !states
                          ? []
                          : states.map(el => ({
                              id: el.name,
                              name: el.name
                            })) || []
                      }
                      label="State"
                      labelStyle={{ color: "#405367", marginTop: "24px" }}
                      placeholder="Select State"
                      placeholderTitle="Select State"
                      name={"state"}
                      styleOverrides={{ height: "56px" }}
                      handleChange={this.onChangeState.bind(this)}
                      className={undefined}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={2} style={{ paddingRight: 12 }}>
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>
                      Pin Code
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      renderView(form.pinCode)
                    ) : (
                      <TextField
                        value={form.pinCode}
                        color={"primary"}
                        variant="outlined"
                        //size={"small"}
                        type={"text"}
                        name={"pinCode"}
                        placeholder={"Enter Pin Code"}
                        onChange={this.onChangePinCode.bind(this)}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>Fax No</FormLabel>
                    {formMode === FormMode.View ? (
                      renderView(form.faxNo)
                    ) : (
                      <TextField
                        value={form.faxNo}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        //size={"small"}
                        name={"faxNo"}
                        placeholder={"Enter Fax No"}
                        className="inputContainerOrganization"
                        onChange={this.onChangeFaxNo.bind(this)}
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>
                      Website URL
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      renderView(form.webUrl)
                    ) : (
                      <TextField
                        value={form.webUrl}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        //size={"small"}
                        name={"webUrl"}
                        placeholder={"Enter Website URL"}
                        className="inputContainerOrganization"
                        onChange={this.onChangeWebUrl.bind(this)}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>
                      Email ID*
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      renderView(form.emailId)
                    ) : (
                      <>
                        <TextField
                          value={form.emailId}
                          color={"primary"}
                          variant="outlined"
                          type={"text"}
                          name={"emailId"}
                          //size={"small"}
                          placeholder={"Enter Email ID"}
                          className="inputContainerOrganization"
                          onChange={this.onChangeEmailId.bind(this)}
                          required
                        />
                        {formErrors.emailId && (
                          <p className="errorMsg2">{formErrors.emailId}</p>
                        )}
                      </>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>
                      Contact Number*
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      renderView(
                        "+" + form.phoneCountryCode + " " + form.contactNumber
                      )
                    ) : (
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "90px", marginRight: "5px" }}>
                          <OutlinedSelect
                            val={"" + form.phoneCountryCode}
                            options={this.countryCodes}
                            label=""
                            name={"code"}
                            placeholder={"code"}
                            styleOverrides={{
                              width: "90px",
                              marginTop: "-8px",
                              height: "56px"
                            }}
                            handleChange={this.onChangePhoneCountryCode.bind(
                              this
                            )}
                            className={undefined}
                          />
                        </div>

                        <TextField
                          style={{ width: "100%" }}
                          value={form.contactNumber}
                          color={"primary"}
                          variant="outlined"
                          type={"text"}
                          //size={"small"}
                          name={"contactNumber"}
                          placeholder={"Enter Number"}
                          className="inputContainerOrganization"
                          onChange={this.onChangeContactNumber.bind(this)}
                          required
                        />
                      </div>
                    )}
                    {formErrors.contactNumber && (
                      <p className="errorMsg2">{formErrors.contactNumber}</p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ paddingRight: 12, marginTop: 10 }}
              >
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.formLabel}>
                    License No
                  </FormLabel>
                  {formMode === FormMode.View ? (
                    renderView(form.licenseNo)
                  ) : (
                    <TextField
                      value={form.licenseNo}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"licenseNo"}
                      //size={"small"}
                      placeholder={"Enter License No"}
                      className="inputContainerOrganization"
                      onChange={this.onChangeLicenseNo.bind(this)}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={4} style={{ paddingRight: 12, marginTop: 10 }}>
                {formMode === FormMode.View ? (
                  <FormControl className={classes.formControl}>
                    <FormLabel className={classes.formLabel}>
                      Fiscal Year
                    </FormLabel>
                    {renderView(form.fiscalYear)}
                  </FormControl>
                ) : (
                  <OutlinedSelect
                    val={form.fiscalYear}
                    options={[
                      { id: "January-December", name: "January-December" },
                      { id: "February-January", name: "February-January" },
                      { id: "March-February", name: "March-February" },
                      { id: "April-March", name: "April-March" },
                      { id: "May-April", name: "May-April" },
                      { id: "June-May", name: "June-May" },
                      { id: "July-June", name: "July-June" },
                      { id: "August-July", name: "August-July" },
                      { id: "September-August", name: "September-August" },
                      { id: "October-September", name: "October-September" },
                      { id: "November-October", name: "November-October" },
                      { id: "December-November", name: "December-November" }
                    ]}
                    label="Fiscal Year"
                    labelStyle={{ color: "#405367" }}
                    placeholder="Select Fiscal Year"
                    placeholderTitle="Select Fiscal Year"
                    name={"fiscalYear"}
                    styleOverrides={{ height: "56px" }}
                    handleChange={this.onChangeFiscalYear.bind(this)}
                    className={undefined}
                  />
                )}
              </Grid>
              <CustomUploadModal
                visible={this.state.uploadModalVisible}
                handleClose={() => this.setState({ uploadModalVisible: false })}
                handleSubmit={this.onProfileImageUpload.bind(this)}
                title="Upload Image"
              />
            </Grid>
          </div>
        </Grid>
        {this.state.error && (
          <div style={{ color: "red" }}>{this.state.error}</div>
        )}
        {isEdit && (
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            style={{ marginTop: 10 }}
          >
            <Button
              title="Cancel"
              size="small"
              buttonType={ButtonType.Cancel}
              className={classes.saveCancelButton}
              onClick={this.onClickCancel.bind(this)}
            />
            <Button
              title="Save"
              size="small"
              buttonType={ButtonType.Primary}
              className={classes.saveCancelButton}
              onClick={this.onClickSave.bind(this)}
            />
          </Grid>
        )}
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area End
}

export default withStyles(useStyles)(OrganizationProfile);
