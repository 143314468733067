import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { IFineForm, ISubFineForm } from "./FineFormController";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View,
  Copy
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: IFineForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  formErrors: { [key: string]: string };
  onClose: () => void;
  onSubmit: (form: IFineForm) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: IFineForm;
  formErrors: { [key: string]: string };
  isSubmitting: boolean;
  deleteModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ModalsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  private initialValues: IFineForm = {
    created_at: new Date().toString(),
    custom_id: "",
    day: 0,
    duration: "",
    fine_amount: "0",
    fine_category: null,
    mode: "amount",
    name: "",
    updated_at: new Date().toString(),
    sub_fines_attributes: []
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.log("props", props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      formErrors: {},
      isSubmitting: false,
      deleteModalOpen: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.props.requestMessage) {
        if (callID === this.props.requestMessage.messageId) {
          //this.handleFormClose();
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public componentDidUpdate(prevProps: Props) {
    const { isOpen, initialValues, formMode, formErrors } = this.props;
    if (formErrors !== prevProps.formErrors) {
      runEngine.debugLog("formErrorsformErrorsformErrors", formErrors);
      this.setState({
        formErrors: { ...this.state.formErrors, ...formErrors }
      });
    }
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: IFineForm = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View, FormMode.Copy].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };
          if (formMode === FormMode.Copy) form.name = form.name + " - Copy";
        }

        this.setState({
          form,
          isSubmitting: false
        });
      }
    }
  }

  // public onChangeFilterFineName(event: any) {
  //   this.setState({
  //     filterForm: {
  //       ...this.state.filterForm,
  //       name: event.target.value
  //     }
  //   });
  // }

  public onChangeFineCategory(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.form.sub_fines_attributes[index];
    else {
      fineForm = this.state.form;
      if (event.target.value !== "late_fine")
        fineForm.sub_fines_attributes = [];
    }
    if (fineForm) fineForm.fine_category = event.target.value;
    this.setState({ form: { ...this.state.form } });
  }
  public onChangeFineName(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.form.sub_fines_attributes[index];
    else fineForm = this.state.form;
    if (fineForm) fineForm.name = event.target.value;
    this.setState({ form: { ...this.state.form } });
  }

  public onChangeFineDay(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.form.sub_fines_attributes[index];
    else fineForm = this.state.form;
    if (fineForm) {
      const val =
        parseInt(event.target.value) >= 0
          ? parseInt(event.target.value)
          : event.target.value;
      fineForm.day = val;
    }
    this.setState({ form: { ...this.state.form } });
  }

  public onChangeFineDuration(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.form.sub_fines_attributes[index];
    else fineForm = this.state.form;
    if (fineForm) fineForm.duration = event.target.value;
    this.setState({ form: { ...this.state.form } });
  }

  public onChangeFineAmount(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.form.sub_fines_attributes[index];
    else fineForm = this.state.form;
    if (fineForm) fineForm.fine_amount = event.target.value;
    this.setState({ form: { ...this.state.form } });
  }

  public onBlurFineAmount(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.form.sub_fines_attributes[index];
    else fineForm = this.state.form;
    if (fineForm)
      fineForm.fine_amount = parseFloat(fineForm.fine_amount).toFixed(2) || "0";

    this.setState({ form: { ...this.state.form } });
  }

  public onChangeFineMode(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.form.sub_fines_attributes[index];
    else fineForm = this.state.form;
    if (fineForm) fineForm.mode = event.target.value;
    this.setState({ form: { ...this.state.form } });
  }

  public onChangeFilterFineCategory(event: any) {
    // this.setState({
    //   form: {
    //     ...this.state.filterForm,
    //     tax_type: event.target.value
    //   }
    // });
  }

  // public onChangeTaxName(element: React.ChangeEvent<{ value: string }>) {
  //   this.setState({
  //     form: {
  //       ...this.state.form,
  //       name: element.target.value.substring(0, 50)
  //     }
  //   });
  // }

  public onClickAdd() {
    this.state.form.sub_fines_attributes = [
      ...this.state.form.sub_fines_attributes,
      {
        name: "",
        day: 0,
        duration: "week",
        fine_amount: "0",
        fine_category: "late_fine",
        mode: "amount"
      }
    ];

    this.setState({
      form: { ...this.state.form }
    });
  }

  public onClickDelete(index: number) {
    this.state.form.sub_fines_attributes = this.state.form.sub_fines_attributes.filter(
      (el, i) => i !== index
    );
    this.setState({
      form: { ...this.state.form }
    });
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false
    });
  }

  public onSubmit() {
    // this.setState({ isSubmitting: this.props.formMode !== FormMode.View });

    const formErrors: { [key: string]: string } = {};
    // Object.entries(this.state.form).forEach(([key, value]) => {
    //   if (!value) {
    //     formErrors[key] = "Please enter a value";
    //   }
    // });

    // if (Object.keys(formErrors).length > 0) {
    //   this.setState({ formErrors, isSubmitting: false });

    //   return;
    // }
    const forms: (IFineForm | ISubFineForm)[] = [
      this.state.form,
      ...this.state.form.sub_fines_attributes
    ];
    const isEmpty = (val: any) =>
      val === "" || val === undefined || val === null || val === "null";
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (isEmpty(form.name))
        formErrors["name" + (i > 0 ? i - 1 : "")] = "Name is required.";
      if (isEmpty(form.fine_category))
        formErrors["fine_category" + (i > 0 ? i - 1 : "")] =
          "Fine category is required.";
      if (isEmpty(form.fine_amount))
        formErrors["fine_amount" + (i > 0 ? i - 1 : "")] =
          "Fine amount is required.";
      if (isEmpty(form.mode))
        formErrors["mode" + (i > 0 ? i - 1 : "")] = "Fine mode is required.";
      if (
        form.fine_category === "late_fine" &&
        (isEmpty(form.day) || isEmpty(form.duration))
      )
        formErrors["day" + (i > 0 ? i - 1 : "")] =
          "Days after due date is required.";
      runEngine.debugLog("form", form);
    }
    if (Object.keys(formErrors).length < 1) {
      this.props.onSubmit(this.state.form);
      this.setState({ formErrors: {} });
    } else {
      this.setState({ formErrors });
    }
  }

  public handleFormClose() {
    this.props.onClose();
  }
  // Customizable Area End
}
