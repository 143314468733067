import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { Country, ICountry, IState, State } from "country-state-city";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

// Customizable Area End

export enum FormMode {
  Create,
  Edit,
  View
}

export interface IOrganizationProfile {
  id?: number;
  name: string;
  businessLocation: string;
  addressLines: string[];
  city: string;
  state: string;
  pinCode: string;
  faxNo: string;
  webUrl: string;
  emailId: string;
  contactNumber: string;
  licenseNo: string;
  fiscalYear: string;
}

interface IOrganizationProfileForm {
  id?: number;
  name: string;
  businessLocation: string;
  addressLines: string[];
  city: string;
  state: string;
  pinCode: string;
  faxNo: string;
  webUrl: string;
  emailId: string;
  contactNumber: string;
  licenseNo: string;
  fiscalYear: string;
  phoneCountryCode: number;
  profileImage?: any;
  profileImageUrl?: string;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formErrors: { [key: string]: string };
  anchorEl: HTMLElement | null;
  isLoading: boolean;
  deleteModalOpen: boolean;
  isTaxRateFormModalOpened: boolean;
  form: IOrganizationProfileForm;
  originalForm: IOrganizationProfileForm;
  organizationProfile: IOrganizationProfile | null;
  formMode: FormMode;
  isEdit: boolean;
  uploadModalVisible: boolean;
  error?: string;
  countries?: ICountry[];
  states?: IState[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrganizationProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  public RequestMessage = {
    GetOrganizationProfile: this.buildRequestMessage(Method.GET),
    EditOrganizationProfile: this.buildRequestMessage(Method.PUT),
    UploadProfilePhoto: this.buildRequestMessage(Method.POST),
    Null: undefined as any
  };

  // public organizationProfileFormProps: { [key: number]: IFormProps } = {
  //   [FormMode.Edit]: {
  //     title: "Edit Organization Profile",
  //     submitLabel: "Update",
  //     initialValues: this.state.form,
  //     formMode: FormMode.Edit,
  //     isOpen: false,
  //     onClose: () => {},
  //     onSubmit: () => {},
  //     requestMessage: this.RequestMessage.EditTaxRate
  //   },
  //   [FormMode.View]: {
  //     title: "View Organization Profile",
  //     submitLabel: "Back to Listing",
  //     initialValues: null,
  //     formMode: FormMode.View,
  //     isOpen: false,
  //     onClose: () => {},
  //     onSubmit: () => {},
  //     requestMessage: this.RequestMessage.Null
  //   }
  // };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formErrors: {},
      anchorEl: null,
      organizationProfile: null,
      isLoading: true,
      deleteModalOpen: false,
      uploadModalVisible: false,
      isTaxRateFormModalOpened: false,
      originalForm: {
        addressLines: ["", ""],
        businessLocation: "India",
        city: "",
        contactNumber: "",
        emailId: "",
        faxNo: "",
        fiscalYear: "",
        licenseNo: "",
        name: "",
        phoneCountryCode: 91,
        pinCode: "",
        state: "",
        webUrl: "",
        profileImage: "",
        profileImageUrl: ""
      },
      form: {
        addressLines: ["", ""],
        businessLocation: "India",
        city: "",
        contactNumber: "",
        emailId: "",
        faxNo: "",
        fiscalYear: "",
        licenseNo: "",
        name: "",
        phoneCountryCode: 91,
        pinCode: "",
        state: "",
        webUrl: "",
        profileImage: "",
        profileImageUrl: ""
      },
      isEdit: false,
      formMode: FormMode.View
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  public countryCodes: Array<any> = Array.from(
    { length: 200 },
    (v, k) => k + 91
  ).map(v => ({
    id: v,
    name: `+${v}`
  }));

  public onChangeBusinessLocation(
    element: React.ChangeEvent<{ value: string }>
  ) {
    this.setState({
      form: {
        ...this.state.form,
        businessLocation: element.target.value
      }
    });
  }

  public onChangeName(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        name: element.target.value
      }
    });
  }

  public onChangeAddressLine1(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        addressLines: [element.target.value, this.state.form.addressLines[1]]
      }
    });
  }

  public onChangeAddressLine2(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        addressLines: [this.state.form.addressLines[0], element.target.value]
      }
    });
  }

  public onChangeCity(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        city: element.target.value
      }
    });
  }

  public onChangeFaxNo(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        faxNo: element.target.value
      }
    });
  }

  public onChangeEmailId(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        emailId: element.target.value
      }
    });
  }

  public onChangeLicenseNo(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        licenseNo: element.target.value
      }
    });
  }

  public onChangeState(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        state: element.target.value
      }
    });
  }

  public onChangeFiscalYear(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        fiscalYear: element.target.value
      }
    });
  }

  public onChangePhoneCountryCode(
    element: React.ChangeEvent<{ value: string }>
  ) {
    this.setState({
      form: {
        ...this.state.form,
        phoneCountryCode: Number(element.target.value)
      }
    });
  }

  public onChangeDescription(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form
        // description: element.target.value
      }
    });
  }

  public onChangePinCode(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        pinCode: element.target.value
      }
    });
  }

  public onChangeWebUrl(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        webUrl: element.target.value
      }
    });
  }

  public onChangeContactNumber(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        contactNumber: element.target.value
      }
    });
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("Message Recived", callID);

      switch (callID) {
        case this.RequestMessage.EditOrganizationProfile.messageId:
        case this.RequestMessage.GetOrganizationProfile.messageId:
          if (response !== null && response.data && response.data.attributes) {
            const {
              data: { attributes: data }
            } = response;
            runEngine.debugLog("datad", data);

            // console.log("response?.data", response.data);
            const form = {
              ...this.state.form,
              name: data.name,
              businessLocation: "India",
              addressLines: [data.address, data.address2 || ""],
              city: data.city,
              emailId: data.email,
              state: data.state,
              licenseNo: data.license_no,
              fiscalYear: data.fiscal_year,
              faxNo: data.fax_no,
              pinCode: data.pin_code,
              webUrl: data.website_url,
              phoneCountryCode: data.account.country_code,
              contactNumber: data.contact_no,
              profileImageUrl: data.profile_image
            };
            this.setState({
              form,
              originalForm: { ...form }
            });
            runEngine.debugLog("response?.data", response.data);

            // this.setState({
            //   organizationProfile: response?.data || [],
            //   isLoading: false
            // });
          }
          break;

        // case this.RequestMessage.EditTaxRate.messageId:
        //   if (response !== null) {
        //     this.setState({ isTaxRateFormModalOpened: false })
        //     this.getTaxRatesCall();
        //   }

        //   break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getOrganizationProfileCall();

    this.setState({ countries: Country.getAllCountries() });
  }

  public async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ) {
    const {
      countries,
      form: { businessLocation }
    } = this.state;

    const {
      countries: prevCountries,
      form: { businessLocation: prevBusinessLocation }
    } = prevState;
    if (
      prevBusinessLocation !== businessLocation ||
      countries !== prevCountries
    ) {
      const country = countries
        ? countries.find(el2 => el2.name === businessLocation)
        : undefined;
      this.setState({
        states: State.getStatesOfCountry(
          countries ? (country ? country.isoCode : undefined) : undefined
        )
      });
    }
  }

  public onClickCreateTaxRate() {
    this.setState({
      isTaxRateFormModalOpened: true,
      formMode: FormMode.Create
    });
  }

  public onClickEdit() {
    this.setState({ isEdit: true });
  }

  public onClickCancel() {
    // this.getOrganizationProfileCall();
    this.setState({
      isEdit: false,
      error: "",
      form: { ...this.state.originalForm },
      formErrors: {}
    });
  }

  public onClickSave() {
    const formErrors: { [key: string]: string } = {};

    const emailRegex = /^(?:[A-Za-z0-9.+-]+@[a-z.+-]+\.[a-z]{2,3})$/i;

    if (
      this.state.form.emailId &&
      emailRegex.test(this.state.form.emailId) === false
    )
      formErrors["emailId"] = "Email ID is invalid";

    const phoneRegex = /^(?:.*[0-9]{10,11})$/i;
    if (
      this.state.form.contactNumber &&
      phoneRegex.test(this.state.form.contactNumber) === false
    ) {
      formErrors["contactNumber"] = "Contact Number is invalid";
    }

    if (!this.state.form.addressLines[0])
      formErrors["addressLines"] = "Address is required";
    if (!this.state.form.emailId)
      formErrors["emailId"] = "Email ID is required";
    if (!this.state.form.contactNumber)
      formErrors["contactNumber"] = "Contact Number is required";

    if (Object.keys(formErrors).length === 0) {
      this.editOrganizationProfileCall();
      this.setState({ isEdit: false, error: "", formErrors: {} });
    } else {
      this.setState({ formErrors });
    }
  }

  public onProfileImageUpload(file: any, url: any) {
    this.setState({
      uploadModalVisible: false,
      form: {
        ...this.state.form,
        profileImage: file,
        profileImageUrl: url
      }
    });
    this.uploadProfileImageCall(file);
  }

  //#region Service Calls

  private getOrganizationProfileCall() {
    this.RequestMessage.GetOrganizationProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.OrganizationProfileEndPoint}/profile_detail`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetOrganizationProfile.id,
      this.RequestMessage.GetOrganizationProfile
    );
  }

  private editOrganizationProfileCall() {
    const { form } = this.state;

    this.RequestMessage.EditOrganizationProfile.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        profile: {
          name: form.name,
          business_location: form.businessLocation,
          address: form.addressLines[0],
          address2: form.addressLines[1],
          email: form.emailId,
          contact_no: form.contactNumber,
          license_no: form.licenseNo,
          fiscal_year: form.fiscalYear,
          city: form.city,
          state: form.state,
          pin_code: form.pinCode,
          website_url: form.webUrl,
          fax_no: form.faxNo
        }
      })
    );

    this.RequestMessage.EditOrganizationProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.OrganizationProfileEndPoint}/update_profile`
    );

    runEngine.sendMessage(
      this.RequestMessage.EditOrganizationProfile.id,
      this.RequestMessage.EditOrganizationProfile
    );
  }

  private uploadProfileImageCall(file: File) {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token
    };

    var FormData = require("form-data");
    var bodyFormData = new FormData();
    bodyFormData.append("profile[profile_image]", file);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let apiEndPoint =
      configJSON.OrganizationProfileEndPoint + `/update_profile_pic`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TaxesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }

  // Customizable Area End
}
