import React, { Component } from 'react';
// Customizable Area Start

import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  
} from "@material-ui/core/styles";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { classNames } from 'react-select/src/utils';
import StudentList from './StudentList';
import CreateFeeAssignmentController, {
  Props,
} from "./CreateFeeAssignmentController";
import {feeAssignmentData} from './FeeAssignmentController';
import { capitalizeFirst } from 'framework/src/Utilities';
import { Styles } from '@material-ui/styles';
import { ButtonTheme } from '../../CommonLayout/HRMSCommons/src/button/default';
import { formSubmitBtn } from '../../CommonLayout/Layout/src/color';

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  dialogPaper: {
    // maxWidth: "188vh",
    // minHeight: "286px",
    borderRadius: "26px",
    minWidth: "800px",

  },
  paperWidthSm: {
    minWidth: "300px",
    maxHeight: "725px",
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,

    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    height: "300px !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    // borderSpacing: "0 10px",
    // padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableHead: {
    padding: "15px",
    backgroundColor: "#FCF3D4",
    fontSize: "16px"
  },
  tableGrid: {
    paddingTop: "20px",
  },
  activeBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '8px 40px',
    border: '1px solid',
    lineHeight: 1.5,
    background: formSubmitBtn,
    borderRadius: "8px",
    //borderColor: '#ea5c6b',
    color: "white",
    '&:hover': {
      background: formSubmitBtn,
    },
    '&:active': {
      boxShadow: 'none',
      border: "none",
      outline: 0,
    },
  },
  iconButton: {
    marginRight: 0,
    borderRadius: "5px",
    border: `1px solid #c4c4c4`,
    backgroundColor: "#ffffff",
    padding: "18px",
    '&:hover': {
      border: `1px solid ${ButtonTheme.PrimaryBackground}}`,
      borderColor: ButtonTheme.PrimaryBackground,
      color: "white",
      opacity: "1",
    },
    '&:active': {
      border: `1px solid ${ButtonTheme.PrimaryBackground}}`,
      borderColor: ButtonTheme.PrimaryBackground,
      opacity: "1",
      boxShadow: 'none',
      color: "white",
    }, '&:focus': {
      border: `1px solid ${ButtonTheme.PrimaryBackground}}`,
      borderColor: ButtonTheme.PrimaryBackground,
    },
  },
  subTitle: {
    marginBottom: "18px",
  },
  filterTitle: {
    color: "#FC585D",
    borderBottom: "1px solid #CCC",
    marginBottom: 10
  },
  actionButtons: {
    display: "flex",
    justifyContent: "left",
    marginTop: 10
  },
  // containBox: {
  //   padding: "30px",
  //   margin: "4px"
  // }
});

interface feeAssignmentFormstate {
  feeAssignmentType: string;
  academic_year_id: number;
  grade_id: number;
  division_id: number;
  name: string;
  is_class_and_division: boolean,
  is_group: boolean,
  is_class: boolean,
  grade: string,
  division: string
}

interface feeStructuresAttribute {
  name: string,
  status: string
}

interface studentData {
  id: any,
  email: string,
  name:string,
  fee_structures: feeStructuresAttribute[]
} 

type MyProps = { 
  classes: any
  handelStudentCancel:() => void,
  handleChange: (e: any) => void,
  feeAssignmentFormstate: feeAssignmentFormstate, 
  handleAddStudent: () => void, 
  handelCancel: () => void,
  AddStudentStatus: boolean,
  handleFilterButton : (event: any) => void, 
  filterModalStatus: HTMLElement | null,  
  onCloseFilterPopover: () => void,
  feeStructureNameError:string,
  academicYearError:string,
  feeAssignmentTypeError:string,
  academicYearList: [],
  academicClassList: [],
  divisionList: [],
  feeAssignmentDivisionError:string,
  studentList: [],
  studentListerrorMessage: string,
  filterStudenthandleChange: (evt: any) => void,
  feeAssignmentClassError:string,
  viewForm: boolean,
  feeAssignmentData:feeAssignmentData,
  allStudentRowsChecked:boolean,
  handleCheckAllStudents:any,
  handleCheckStudentItem:any,
  checkStudentRow:any,
  studentNext:(e:any) => void,
  feeStructureNameList: [],
  studentFilterApply: () => void,
  studentFilterFeeStrucure: number,
  studentFilterStatus: string,
  filterStudentError: string,
  feeAssignmentGroupError:string,
  searchStudentKeyword: string,
  handleSearchStudentChange: (e: any) => void,
};

// Customizable Area End

export default class FeeAssignmentForm extends Component<MyProps> {
  render() {
    // Customizable Area Start

    const { feeAssignmentFormstate, 
      handleChange,handleAddStudent, 
      handelCancel,
      AddStudentStatus,
      filterModalStatus,
      handleFilterButton,
      onCloseFilterPopover,
      feeAssignmentDivisionError,
      feeAssignmentGroupError,
      academicYearList,
      academicYearError,
      feeStructureNameError,
      academicClassList,
      feeAssignmentTypeError,
      divisionList,
      filterStudenthandleChange,
      studentList,
      handelStudentCancel,
      feeAssignmentClassError,
      studentListerrorMessage,
      viewForm,
      feeAssignmentData,
      feeStructureNameList,
      studentFilterApply,
      studentFilterFeeStrucure,
      studentFilterStatus,
      filterStudentError,
      searchStudentKeyword,
      handleSearchStudentChange
    } = this.props;

    var feeAssignmentType = "is_class";
    if(viewForm && viewForm=== true && feeAssignmentData){
      const {is_class_and_division,is_group} = feeAssignmentData?.attributes;
      feeAssignmentType = is_class_and_division === true ? "is_class_and_division": is_group=== true? "is_group": "is_class";
    }
    // Customizable Area End

     return (
    // Customizable Area Start

    viewForm ?
    
    <>
    <Grid
      container
      spacing={3}
      style={{
          marginTop: "10px",
      }}
      >
        <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={6} item>Fee Structure Name*
          <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{feeAssignmentData?.attributes.name}</Typography>
        </Grid>
        <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={6} item>Academic Year*
          <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{feeAssignmentData?.attributes.academic_year.year}</Typography>
        </Grid>
        <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={6} item>
          <RadioGroup row  aria-label="gender" value={feeAssignmentType}>
              <FormControlLabel  
               disabled
                style={{
                      color: "#465872", fontWeight: "bold"
                }} 
                value="is_class" 
                control={<Radio />} 
                label={<span style={{color: "#465872", fontWeight: "bold"}}>Class</span>} 
              />
              <FormControlLabel 
                disabled
                style={{
                  color: "#465872", fontWeight: "bold"
                }} 
                value="is_class_and_division" 
                control={<Radio />} 
                label={<span style={{color: "#465872", fontWeight: "bold"}}>Class & Division</span>} 
              />
              <FormControlLabel 
                disabled
                style={{
                  color: "#465872", fontWeight: "bold"
                }} 
                value="is_group" 
                control={<Radio />} 
                label={<span style={{color: "#465872", fontWeight: "bold"}}>Group</span>}
              />
          </RadioGroup>
        </Grid>
     </Grid>
     <Grid
          container
          spacing={3}
          style={{
              marginTop: "10px",
              marginBottom: "14px",
          }}
      >
        {(feeAssignmentData?.attributes.is_class === true || feeAssignmentData?.attributes.is_class_and_division === true) &&
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={3} item>Class
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{feeAssignmentData?.attributes.grade}</Typography>
          </Grid>
        }
        {(feeAssignmentData?.attributes.is_class_and_division === true) &&
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={3} item>Division
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{feeAssignmentData?.attributes.division}</Typography>
          </Grid>
        }
         {(feeAssignmentData?.attributes.is_group === true ) &&
         
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }}  item>Group
          <Typography style={{color: "#7e7d7d", fontWeight: 500,marginTop: "16px"}}>{"Group List"}</Typography>
          <TableContainer component={Paper} className={this.props.classes.tableContainer} style={{marginTop: "10px"}}>
                {/* {this.state.showLoader && <Loader loading={this.state.showLoader} positionType={'center'} />} */}
                <Table className={this.props.classes.table} aria-label="a dense table" style={{width: "941px"}}>

                  <TableHead style={{ position: "sticky", top: "0px", zIndex: 9, }}>
                    <TableRow>
                      <TableCell className={this.props.classes.tableHead} style={{ paddingLeft:"16px"}}>Student List</TableCell>
                      <TableCell className={this.props.classes.tableHead} style={{ minWidth: "130px",paddingLeft:"16px" }}>Fee Structure</TableCell>
                      <TableCell className={this.props.classes.tableHead}style={{ paddingLeft:"16px"}}>Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody style={{ borderSpacing: "0 10px" }}>
                    {
                      (feeAssignmentData?.attributes.group_list && feeAssignmentData?.attributes.group_list.length) ? feeAssignmentData?.attributes.group_list.map((row: studentData) => {
                        const names = row.fee_structures.map((itm:feeStructuresAttribute) => itm.name);
                        const status = row.fee_structures.map((itm:feeStructuresAttribute) => itm.status);
                        let statusVal = "Pending";
                        if(status.includes("assigned")){
                          statusVal = "Assigned";
                        }
                        return (
                          <TableRow >
                            <TableCell>
                              <Grid >
                              <Typography style={{ paddingLeft: "10px" }} variant="subtitle1" align="left">{capitalizeFirst(row.name)}</Typography>
                                <Typography style={{ paddingLeft: "10px",fontSize: "12px" }} variant="subtitle1" align="left">{capitalizeFirst(row.email)}</Typography>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid >
                                <Typography style={{ paddingLeft: "10px" }} variant="subtitle1" align="left">
                                  {names.join(", ")}
                                </Typography>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid >
                                <Typography style={{ paddingLeft: "10px" }} variant="subtitle1" align="left">
                                  {statusVal}
                                </Typography>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )
                      }) : null}
                  </TableBody>

                </Table>
              </TableContainer>
            
          </Grid>
        }

      </Grid>
     </>
    :
    <>
      <Grid
      container
      spacing={3}
      style={{
          marginTop: "10px",
      }}
      >
      <Grid item xs={6}>
          <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                  Fee Structure Name*
              </FormLabel>
              <TextField
                  value={feeAssignmentFormstate.name}
                  type={'text'}
                  placeholder={`Fee Structure Name`}
                  variant="outlined"
                  name={'name'}
                  onChange={handleChange}
              />
              {feeStructureNameError && (
              <p className="errorMsg2">{feeStructureNameError}</p>
              )}
          </FormControl>
      </Grid>
      <Grid item xs={6}>
          <OutlinedSelect
            placeholderTitle="Please Select"
            val={feeAssignmentFormstate.academic_year_id}
            options={academicYearList}
            label="Academic Year*"
            name={'academic_year_id'}
            handleChange={handleChange} 
            className={undefined}     
           />
            {academicYearError && (
              <p className="errorMsg2">{academicYearError}</p>
            )}
      </Grid>
      <Grid item xs={6}>
        <FormControl component="fieldset">
          <RadioGroup row  aria-label="gender" value={feeAssignmentFormstate.feeAssignmentType} onChange={handleChange} name="feeAssignmentType">
            <FormControlLabel  
              style={{
                    color: "#3f5071"
              }} 
              value="is_class" 
              control={<Radio/>} 
              label="Class" 
            />
            <FormControlLabel 
              style={{
                color: "#3f5071"
              }} 
              value="is_class_and_division" 
              control={<Radio />} 
              label="Class & Division" 
            />
            <FormControlLabel 
              style={{
                color: "#3f5071"
              }} 
              value="is_group" 
              control={<Radio />} 
              label="Group" 
            />
          </RadioGroup>
          {feeAssignmentTypeError && (
              <p className="errorMsg2">{feeAssignmentTypeError}</p>
            )}
        </FormControl>
      </Grid>
      </Grid>
      <Grid
          container
          spacing={3}
          style={{
              marginTop: "10px",
              marginBottom: "14px",
          }}
      >
      {(feeAssignmentFormstate.feeAssignmentType === "is_class" || feeAssignmentFormstate.feeAssignmentType === "is_class_and_division") &&
        <Grid item xs={3}>
            <OutlinedSelect
              placeholderTitle="Select Class"
              val={feeAssignmentFormstate.grade_id}
              options={academicClassList}
              label="Class*"
              name={'grade_id'}
              handleChange={handleChange} className={undefined} 
               />
                 {feeAssignmentClassError && (
              <p className="errorMsg2">{feeAssignmentClassError}</p>
            )}
        </Grid>
      }
      {feeAssignmentFormstate.feeAssignmentType === "is_class_and_division" &&
          <Grid item xs={3}>
              <OutlinedSelect
              placeholderTitle="Select Division"
              val={feeAssignmentFormstate.division_id}
              options={divisionList}
              label="Division*"
              name={'division_id'}
              handleChange={handleChange} className={undefined}  
              />
              {feeAssignmentDivisionError && (
              <p className="errorMsg2">{feeAssignmentDivisionError}</p>
            )}
          </Grid>
      }
      {feeAssignmentFormstate.feeAssignmentType === "is_group" &&
          <Grid item xs={3}>
              <Button
                style={{
                  color: "#3f5071",
                  backgroundColor: "#ffffff",
                  border: "1px solid #c4c4c4",
                  boxShadow: "none"
                }}
                onClick={handleAddStudent}
                variant="contained"
                color="primary"
                endIcon={<AddIcon style={{color: "#3f5071"}}/>}
              >
                Add Group
              </Button>
              {feeAssignmentGroupError && (
              <p className="errorMsg2">{feeAssignmentGroupError}</p>
            )}
          </Grid>
      }
      </Grid>
      <StudentList
      handleCheckStudentItem={this.props.handleCheckStudentItem}
      checkStudentRow={this.props.checkStudentRow}
      handleCheckAllStudents={this.props.handleCheckAllStudents}
      allStudentRowsChecked={this.props.allStudentRowsChecked}
      studentNext={this.props.studentNext}
      open={AddStudentStatus}
      filterModalStatus={filterModalStatus}
      handleFilterButton={handleFilterButton}
      onCloseFilterPopover={onCloseFilterPopover}
      academicClassList={this.props.academicClassList}
      divisionList={this.props.divisionList}
      handleChange={handleChange}
      filterStudenthandleChange={filterStudenthandleChange}
      studentListerrorMessage={studentListerrorMessage}
      studentList={studentList}
      handelCancel={handelStudentCancel}
      feeAssignmentFormstate={feeAssignmentFormstate}
      feeStructureNameList={feeStructureNameList}
      studentFilterApply={studentFilterApply}
      studentFilterStatus={studentFilterStatus}
      studentFilterFeeStrucure={studentFilterFeeStrucure}
      filterStudentError={filterStudentError}
      searchStudentKeyword={searchStudentKeyword}
      handleSearchStudentChange={handleSearchStudentChange}
      />
    </>
    // Customizable Area End

  );
  }
}
