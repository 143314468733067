import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { number } from 'yup';

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View
}

enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export interface ICustomerForm {
  primary_details: {
    first_name: string;
    last_name: string;
    gender: string;
    relationships: string;
    phone_number: string;
    email: string;
    remarks: string;
    billto: string;
    country_code: string;
  },
  contact_details: {
    street_address: string;
    address: string;
    city: string;
    state: string;
    pin_code: string;
    country: string;
    email: string;
    phone_number: string;
    country_code: string;
    billing_street: string;
    billing_address: string;
    shiping_street: string;
    shiping_address: string;
  },
  secondary_details: {
    first_name: string;
    last_name: string;
    gender: string;
    nationality: string;
    admission_number: string;
    class_name: string;
    class_division: string;
  }[]
}

export interface IBusinessCustomerForm {
  business_details: {
    company_name: string;
    primary_contact: string;
    email: string;
    country_code: string;
    contact_number: string;
    website_url: string;
    billing_street: string;
    billing_address: string;
    shipping_street: string;
    shipping_address: string;
  },
  other_details: {
    gst_treatment?: any,
    payment_term?: any,
    gst_treatment_id: number | null,
    gstin_number: string;
    place_of_supply: string;
    tax_preference: string;
    payment_term_id: number | null;
    remarks: string;
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: ICustomerForm | null;
  businessInitialValues: IBusinessCustomerForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  onClose: () => void;
  onSubmit: (form: ICustomerForm | IBusinessCustomerForm) => void;
  handleCustomerType: (customerType: string) => void;
  customerType?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: ICustomerForm;
  isSubmitting: boolean;
  states: [];
  nationalities: [];
  gstTreatments: {
    id: string;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
  }[];
  placeOfSupplies: [];
  isLoading: boolean;
  sameWithBillingAddress: boolean;
  sameWithBusinessBillingAddress: boolean;
  businessForm: IBusinessCustomerForm;
  openPaymentModal: boolean;
  paymentOptions: {
    isNew?: boolean;
    id?: number;
    term_name: string;
    no_of_days: number;
    account_id?: number;
  }[];
  formErrors: { [key: string]: string };
  deleteModalOpen: boolean;
  actionedPaymentTerm: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerRatesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  private initialValues: ICustomerForm = {
    primary_details: {
      first_name: '',
      last_name: '',
      gender: '',
      relationships: '',
      phone_number: '',
      country_code: '91',
      email: '',
      remarks: '',
      billto: 'primary',
    },
    contact_details: {
      street_address: '',
      address: '',
      city: '',
      state: '',
      pin_code: '',
      country: '',
      country_code: '91',
      email: '',
      phone_number: '',
      billing_street: '',
      billing_address: '',
      shiping_street: '',
      shiping_address: '',
    },
    secondary_details: [
      {
        first_name: '',
        last_name: '',
        gender: '',
        nationality: '',
        admission_number: '',
        class_name: '',
        class_division: '',
      }
    ]
  };

  private businessInitialValues: IBusinessCustomerForm = {
    business_details: {
      company_name: '',
      primary_contact: '',
      email: '',
      country_code: '91',
      contact_number: '',
      website_url: '',
      billing_street: '',
      billing_address: '',
      shipping_street: '',
      shipping_address: '',
    },
    other_details: {
      gst_treatment_id: null,
      gstin_number: '',
      place_of_supply: '',
      tax_preference: '',
      payment_term_id: null,
      remarks: '',
    }
  };

  public RequestMessage = {
    GetStates: this.buildRequestMessage(Method.GET),
    GetNationalities: this.buildRequestMessage(Method.GET),
    GetGstTreatment: this.buildRequestMessage(Method.GET),
    GetPlaceOfSupplies: this.buildRequestMessage(Method.GET),
    GetPaymentTerms: this.buildRequestMessage(Method.GET),
    CreatePaymentTerms: this.buildRequestMessage(Method.POST),
    DeletePaymentTerm: this.buildRequestMessage(Method.DELETE),
    UpdatePaymentTerms: this.buildRequestMessage(Method.PUT),
    Null: undefined as any
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      businessForm: { ...(props.businessInitialValues || this.businessInitialValues) },
      isSubmitting: false,
      states: [],
      gstTreatments: [],
      placeOfSupplies: [],
      isLoading: false,
      sameWithBillingAddress: false,
      sameWithBusinessBillingAddress: false,
      openPaymentModal: false,
      paymentOptions: [],
      nationalities: [],
      formErrors: {},
      deleteModalOpen: false,
      actionedPaymentTerm: null
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (callID) {
        case this.RequestMessage.GetStates.messageId:
          if (response !== null) {
            const stateArray = response?.data?.map((el: any) => {
              return { id: el.name, name: el.name }
            });

            this.setState({
              states: stateArray || [],
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.GetNationalities.messageId:
          if (response !== null) {
            const nationalityArray = response?.data?.map((el: any) => {
              return { id: el.name, name: el.name }
            });

            this.setState({
              nationalities: nationalityArray || [],
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.GetGstTreatment.messageId:
          const gstArray = response?.data?.map((el: any) => {
            return { ...el, id: `${el.id}` }
          });

          if (response !== null) {
            this.setState({
              gstTreatments: gstArray || [],
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.GetPlaceOfSupplies.messageId:
          const placeArray = response?.data?.map((el: any) => {
            return { id: el.name, name: el.name }
          });

          if (response !== null) {
            this.setState({
              placeOfSupplies: placeArray || [],
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.GetPaymentTerms.messageId:
          if (response !== null) {
            this.setState({
              paymentOptions: response?.data || [],
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.CreatePaymentTerms.messageId:
          if (response !== null) {
            this.getPaymentTermsCall()
          }
          break;
        
        case this.RequestMessage.DeletePaymentTerm.messageId:
          if (response !== null) {
            this.setState({
              deleteModalOpen: false,
              actionedPaymentTerm: null
            });

            this.getPaymentTermsCall();
          }
          break;
        
        case this.RequestMessage.UpdatePaymentTerms.messageId:
          if (response !== null) {
            this.getPaymentTermsCall();
          }
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getStatesCall();
    this.getNationalitiesCall();
    this.getGstTreatmentCall();
    this.getPlaceOfSuppliesCall();
    this.getPaymentTermsCall();
  }

  public componentDidUpdate(prevProps: Props, prevState: S) {
    const { isOpen, initialValues, formMode, businessInitialValues } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: ICustomerForm = { ...this.initialValues };
        let businessForm: IBusinessCustomerForm = { ...this.businessInitialValues }

        if ([FormMode.Create].includes(formMode)) {
          this.setState({
            sameWithBillingAddress: false,
            sameWithBusinessBillingAddress: false,
          })
        }

        if ([FormMode.Edit, FormMode.View].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };
          businessForm = { ...(businessInitialValues || this.businessInitialValues) };
        }

        this.setState({
          form,
          businessForm,
          isSubmitting: false
        });
      }
    }

    if (prevState.form.primary_details.billto !== this.state.form.primary_details.billto) {
      if (this.state.form.primary_details.billto === 'secondary' && !this.state.form.secondary_details.length) {
       this.handleAddSecondaryClick()
     }
    }
  }

  // public onChangeGST(is_gst: boolean) {
  //   this.setState({ form: { ...this.state.form, is_gst } });
  // }

  public onPrimaryDetailElementChange(element: React.ChangeEvent<{ value: string }>, property: string) {
    this.setState({
      form: {
        ...this.state.form,
        primary_details: {
          ...this.state.form.primary_details,
          [property]: element.target.value
        }
      }
    });
  }

  public onPrimaryDetailRadioChange(value: string, property: string) {
    this.setState({
      form: {
        ...this.state.form,
        primary_details: {
          ...this.state.form.primary_details,
          [property]: value
        }
      }
    });
  }

  public onBusinessCustomerRadioChange(value: string, property: string) {
    this.setState({
      businessForm: {
        ...this.state.businessForm,
        other_details: {
          ...this.state.businessForm.other_details,
          [property]: value
        }
      }
    });
  }

  public onContactDetailElementChange(element: React.ChangeEvent<{ value: string }>, property: string) {
    if (property === 'billing_address' && this.state.sameWithBillingAddress) {
      this.setState({
        form: {
          ...this.state.form,
          contact_details: {
            ...this.state.form.contact_details,
            [property]: element.target.value,
            shiping_address: element.target.value,
          }
        }
      })
    } else if (property === 'billing_street' && this.state.sameWithBillingAddress) {
      this.setState({
        form: {
          ...this.state.form,
          contact_details: {
            ...this.state.form.contact_details,
            [property]: element.target.value,
            shiping_street: element.target.value
          }
        }
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          contact_details: {
            ...this.state.form.contact_details,
            [property]: element.target.value
          }
        }
      });
    }

    if (property === 'shiping_address' || property === 'shiping_street') {
      this.setState({ sameWithBillingAddress: false })
    }
  }

  public onSecondaryDetailElementChange(element: React.ChangeEvent<{ value: string }>, property: string, index: number) {
    this.setState({
      form: {
        ...this.state.form,
        secondary_details: this.state.form.secondary_details.map((el, i) => {
          if (i === index) {
            return { ...el, [property]: element.target.value }
          } else {
            return el;
          }
        })
      }
    });
  }

  public handleAddSecondaryClick() {
    this.setState({
      form: {
        ...this.state.form,
        secondary_details: [
          ...this.state.form.secondary_details,
          this.initialValues.secondary_details[0]
        ]
      }
    })
  }

  public onPaymentAddClick() {
    this.setState({
      paymentOptions: [...this.state.paymentOptions, {
        isNew: true,
        term_name: '',
        no_of_days: 0
      }]
    })
  }

  public async handlePaymentAddSave() {
    let updatePaymentOptions = [];
    for (const paymentOption of this.state.paymentOptions) {
      updatePaymentOptions.push({ term_name: paymentOption.term_name, no_of_days: paymentOption.no_of_days, id: paymentOption.id })

      if (paymentOption.isNew && paymentOption.term_name) {
        this.createPaymentTermsCall({ term_name: paymentOption.term_name, no_of_days: paymentOption.no_of_days })
      }
    }

    this.updatePaymentTermsCall(updatePaymentOptions);

    this.handlePaymentModalClose()
  }

  public handlePaymentModalClose() {
    this.setState({ openPaymentModal: false })
    this.getPaymentTermsCall()
  }


  public handleOpenPaymentModal() {
    this.setState({
      openPaymentModal: true
    })
  }

  public handleSecondaryItemDelete(index: number) {
    const { primary_details, secondary_details } = this.state.form;
    if (primary_details.billto === 'secondary' && secondary_details.length === 1) return;
    
    this.setState({
      form: {
        ...this.state.form,
        secondary_details: this.state.form.secondary_details.filter((el, i) => i !== index)
      }
    });
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      actionedPaymentTerm: null
    });
  }

  public onSubmitDeleteModal() {
    this.deletePaymentTermCall(this.state.actionedPaymentTerm);
  }

  public handlePaymentTermDelete(item: any) {
    this.setState({
      deleteModalOpen: true,
      actionedPaymentTerm: item
    });
  }

  public handleAddressCheck(event: any) {
    this.setState({
      sameWithBillingAddress: event.target.checked,
      form: {
        ...this.state.form,
        contact_details: {
          ...this.state.form.contact_details,
          shiping_address: this.state.form.contact_details.billing_address,
          shiping_street: this.state.form.contact_details.billing_street
        }
      }
    })
  }

  public handleBusinessAddressCheck(event: any) {
    this.setState({
      sameWithBusinessBillingAddress: event.target.checked,
      businessForm: {
        ...this.state.businessForm,
        business_details: {
          ...this.state.businessForm.business_details,
          shipping_address: this.state.businessForm.business_details.billing_address,
          shipping_street: this.state.businessForm.business_details.billing_street
        }
      }
    })
  }

  public onBusinessCustomerElementChange(element: React.ChangeEvent<{ value: string }>, property: string, detailName: any) {
    if (property === 'billing_address' && this.state.sameWithBusinessBillingAddress) {
      this.setState({
        businessForm: {
          ...this.state.businessForm,
          [detailName]: {
            ...this.state.businessForm[detailName as unknown as keyof IBusinessCustomerForm],
            [property]: element.target.value,
            shiping_address: element.target.value,
          }
        }
      })
    } else if (property === 'billing_street' && this.state.sameWithBusinessBillingAddress) {
      this.setState({
        businessForm: {
          ...this.state.businessForm,
          [detailName]: {
            ...this.state.businessForm[detailName as unknown as keyof IBusinessCustomerForm],
            [property]: element.target.value,
            shiping_street: element.target.value
          }
        }
      })
    } else {
      this.setState({
        businessForm: {
          ...this.state.businessForm,
          [detailName]: {
            ...this.state.businessForm[detailName as unknown as keyof IBusinessCustomerForm],
            [property]: element.target.value
          }
        }
      });
    }

    if (property === 'shiping_address' || property === 'shiping_street') {
      this.setState({ sameWithBusinessBillingAddress: false })
    }
  }

  public countryCodes: Array<any> = Array.from({ length: 200 }, (v, k) => k + 91).map(
    (v) => ({
      id: v,
      name: `+${v}`
    })
  );


  validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  public onSubmit() {
    this.setState({ isSubmitting: this.props.formMode !== FormMode.View });
    const formErrors: { [key: string]: string } = {};
    if (!this.state.businessForm?.business_details || !this.state.form?.primary_details) {
      this.props.onClose()
      return;
    }

    // Request
    if (this.props.customerType === 'individual') {
      // validation
      this.state.form?.secondary_details?.forEach((el, i) => {
        Object?.entries(el).forEach(([key, value]) => {
          if (!value || Array.isArray(value) && value.length === 0) {
            formErrors[`secondary_${key}_${i}`] = "Please enter a value";
          }
        });
      });

      Object?.entries(this.state.form?.primary_details)?.forEach(([key, value]) => {
        if (key === 'remarks') return;
        if (key.includes('email') && !this.validateEmail(value)) {
          formErrors[`primary_${key}`] = "Please enter a valid email";
        } else if (!value || Array.isArray(value) && value.length === 0) {
          formErrors[`primary_${key}`] = "Please enter a value";
        }
      });

      Object?.entries(this.state.form?.contact_details)?.forEach(([key, value]) => {
        if (key.includes('email') && !this.validateEmail(value)) {
          formErrors[`contact_${key}`] = "Please enter a valid email";
        } else if (!value || Array.isArray(value) && value.length === 0) {
          formErrors[`contact_${key}`] = "Please enter a value";
        }
      });

      if (Object?.keys(formErrors).length > 0) {
        this.setState({ formErrors, isSubmitting: false });

        return;
      }

      this.props.onSubmit(this.state.form);
    } else {
      delete this.state.businessForm.other_details.gst_treatment;
      delete this.state.businessForm.other_details.payment_term;
      // validation
      Object?.entries(this.state.businessForm?.business_details).forEach(([key, value]) => {
        if (key.includes('email') && !this.validateEmail(value)) {
          formErrors[`business_${key}`] = "Please enter a valid email";
        } else if (!value || Array.isArray(value) && value.length === 0) {
          formErrors[`business_${key}`] = "Please enter a value";
        }
      });

      Object?.entries(this.state.businessForm?.other_details)?.forEach(([key, value]) => {
        if (key === 'remarks') return;
        if (!value || Array.isArray(value) && value.length === 0 || value === null || value === "null") {
          formErrors[`other_${key}`] = "Please enter a value";
        }
      });

      if (Object.keys(formErrors).length > 0) {
        this.setState({ formErrors, isSubmitting: false });

        return;
      }

      this.props.onSubmit(this.state.businessForm);
    }

    this.handleFormClose()
  }

  public handleFormClose() {
    this.setState({ formErrors: {} })
    this.props.onClose();
  }

  private deletePaymentTermCall(paymentTerm: any) {
    if (paymentTerm !== null) {

      this.RequestMessage.DeletePaymentTerm.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.CustomeresEndPoint}/business_customers/payment_terms?id=${paymentTerm.id}`
      );

      runEngine.sendMessage(this.RequestMessage.DeletePaymentTerm.id, this.RequestMessage.DeletePaymentTerm);
    }
  }

  private getStatesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetStates.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/business_customers/get_states_name`
    );

    runEngine.sendMessage(this.RequestMessage.GetStates.id, this.RequestMessage.GetStates);
  }

  private getNationalitiesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetNationalities.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/individual_customers/get_nationality`
    );

    runEngine.sendMessage(this.RequestMessage.GetNationalities.id, this.RequestMessage.GetNationalities);
  }

  private createPaymentTermsCall(paymentOption: any) {
    this.RequestMessage.CreatePaymentTerms.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: { attributes: paymentOption } })
    );

    this.RequestMessage.CreatePaymentTerms.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/business_customers/add_payment_term`
    );

    runEngine.sendMessage(this.RequestMessage.CreatePaymentTerms.id, this.RequestMessage.CreatePaymentTerms);
  }

  private updatePaymentTermsCall(updatePaymentOptions: any) {
    this.RequestMessage.UpdatePaymentTerms.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: { attributes: { payment_terms: updatePaymentOptions } }})
    );

    this.RequestMessage.UpdatePaymentTerms.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/business_customers/update_payment_term`
    );

    runEngine.sendMessage(this.RequestMessage.UpdatePaymentTerms.id, this.RequestMessage.UpdatePaymentTerms);
  }

  private getGstTreatmentCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetGstTreatment.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/business_customers/gst_treatments`
    );

    runEngine.sendMessage(this.RequestMessage.GetGstTreatment.id, this.RequestMessage.GetGstTreatment);
  }

  private getPlaceOfSuppliesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetPlaceOfSupplies.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/business_customers/get_states_name`
    );

    runEngine.sendMessage(this.RequestMessage.GetPlaceOfSupplies.id, this.RequestMessage.GetPlaceOfSupplies);
  }

  private getPaymentTermsCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetPaymentTerms.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/business_customers/payment_terms`
    );

    runEngine.sendMessage(this.RequestMessage.GetPaymentTerms.id, this.RequestMessage.GetPaymentTerms);
  }

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CustomeresEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }
  // Customizable Area End
}