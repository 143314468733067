import React from "react";
import { Button, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
// import EdiRolesController, { Props } from "./EdiRolesController";
// import CopyRolesController, {Props} from "../../RolesPermissions/src/CopyRolesController";
import CopyConcessionController, {
  Props
} from "./CopyConcessionController";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import DatePicker from "react-date-picker";
import CreateConcessionType from "./CreateConcessionType.web";
import CommonDatePicker from "../../CommonLayout/HRMSCommons/src/CommonDatePicker";
interface OptionItem {
  id: string;
  name: string;
}
class CopyConcession extends CopyConcessionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      concessionFormstate,
      concessionTypeError,
      concessionValidUntilError,
      parentAccError,
      subAccountStatus,
      concessionNameError,
      concessionAmountError,
      concessionModeError
      
    } = this.state;
    const {
      open,
      handleClose,
      concessionTypeList,
      concessionErrorMessage,
      classes,
    } = this.props;

    return (
      <CustomModal
        title={"Copy Concession"}
        handleSubmit={this.handleSubmit}
        buttonTitle={"Update"}
        open={open}
        handleClose={() => handleClose("copy", false)}
        isError={ false}
      >
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "10px"
          }}
        >
          <Grid item xs={4}>
          <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "100%" }}
            >
              <FormLabel style={{ marginBottom: "8px" }}>
                Concession Type*
              </FormLabel>
              <Select
                className={concessionTypeError ||concessionErrorMessage.fee_concession_type ?"concessionTypeDropdown errorSelect":"concessionTypeDropdown withoutErrorSelect" }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.menuPaper },
                  className: "concessionTypeDropdown"
                }}
                style={
                  concessionTypeError
                    ? {
                        border: "1px solid #d74a3b",
                        outline: "0px",
                        width: "100%"
                      }
                    : {
                        color: "black",
                        border: "none",
                        width: "100%",
                        paddingRight: "0px"
                      }
                }
                labelId={`label-${"Concession Type"}`}
                id={`select-outlined-${"Concession Type"}`}
                name={"fee_concession_type_id"}
                value={concessionFormstate.fee_concession_type_id}
                onChange={this.handleChange}
                placeholder="Select Type"
                labelWidth={0}
              >
                <MenuItem value="0" disabled={false}>
                  {"Select Type"}
                </MenuItem>
                {concessionTypeList &&
                  concessionTypeList.map((item: OptionItem, index: number) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                <MenuItem value="-1" disabled={false}>
                  <Button
                    onClick={this.props.handleConcessionTypemodalOpen}
                    style={{
                      width: "100%",
                      backgroundColor: "#feeaeb",
                      fontSize: "13px",
                      textTransform: "none",
                      borderRadius: "6px",
                      height: "40px"
                    }}
                  >
                    Create Concession Type
                  </Button>
                </MenuItem>
              </Select>
              {concessionErrorMessage.fee_concession_type && (
                <p className="errorMsg2">
                  {concessionErrorMessage.fee_concession_type}
                </p>
              )}
              {concessionTypeError && (
                <p className="errorMsg2">{concessionTypeError}</p>
              )}
            </FormControl>

            <CreateConcessionType
            concessionTypeCreateerrorMessage={this.props.concessionTypeCreateerrorMessage}
              ConcessionTypeList={concessionTypeList}
              open={this.props.handleConcessionTypemodal}
              handleClose={this.props.handleConcessionTypemodalClose}
              handleSubmit={this.props.handleSubmitConcessionType}
              handleEditConcessionTypeSubmit={this.props.handleEditConcessionTypeSubmit}
              importerrorMessage={""}
              selectedConcessionTypeItem={this.props.selectedConcessionTypeItem}
              handleConcessionTypeDeletemodalClose={
                this.props.handleConcessionTypeDeletemodalClose
              }
              handleConcessionTypeDeletemodal={
                this.props.handleConcessionTypeDeletemodal
              }
              handleConcessionTypeEditmodal={this.props.handleConcessionTypeEditmodal}
              handleConcessionTypeEditmodalClose={
                this.props.handleConcessionTypeEditmodalClose
              }
              handleConcessionTypemodal={this.props.handleConcessionTypemodal}
              handleConcessionTypemodalClose={
                this.props.handleConcessionTypemodalClose
              }
              handleConcessionTypeEditmodalOpen={
                this.props.handleConcessionTypeEditmodalOpen
              }
              handleConcessionTypeDeletemodalOpen={
                this.props.handleConcessionTypeDeletemodalOpen
              }
              handleConcessionTypemodalOpen={
                this.props.handleConcessionTypemodalOpen
              }
              handleDeleteConcessionTypeSubmit={
                this.props.handleDeleteConcessionTypeSubmit
              }
            />
            {/* <OutlinedSelect
              placeholderTitle="Select Type"
              val={concessionFormstate.fee_concession_type_id}
              options={concessionTypeList}
              label="concession Type*"
              name={"fee_concession_type_id"}
              handleChange={this.handleChange}
              styleOverrides={
                concessionTypeError &&
                !concessionFormstate.fee_concession_type_id
                  ? {
                      border: "1px solid #d74a3b",
                      outline: "0px",
                      width: "100%"
                    }
                  : { border: "none", width: "100%" }
              }
            />
            {concessionErrorMessage.fee_concession_type &&(
                  <p className="errorMsg2">{concessionErrorMessage.fee_concession_type}</p>
                )}
            {concessionTypeError &&
              !concessionFormstate.fee_concession_type_id && (
                <p className="errorMsg2">{concessionTypeError}</p>
              )} */}
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Concession Name*
              </FormLabel>
              <TextField
                value={concessionFormstate.name}
                type={"text"}
                placeholder={`Enter Name`}
                className={concessionNameError ||concessionErrorMessage.name ?"errorInput":"withoutError" }
                variant="outlined"
                name={"name"}
                onChange={this.handleChange}
              />
              {concessionErrorMessage.name &&(
                  <p className="errorMsg2">{concessionErrorMessage.name}</p>
                )}
              {concessionNameError && (
                <p className="errorMsg2">{concessionNameError}</p>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Valid Until*
              </FormLabel>
              <CommonDatePicker 
                // className={concessionValidUntilError ||concessionErrorMessage.valid_until ?"errorDate":"withoutErrorDate"}
                iconStyle={{position: "absolute",
                right: "26px",
                top: "14px",
                pointerEvents: "none"}}
                name={"valid_until"}
                handleChange={this.handleDateChange}
                placeholder={`Select date`}
                value={concessionFormstate.valid_until}
                />
              {/* <TextField
                placeholder={`Select date`}
                value={concessionFormstate.valid_until}
                className={concessionValidUntilError ||concessionErrorMessage.valid_until ?"errorInput":"withoutError" }
                color={"primary"}
                variant="outlined"
                type={"date"}
                InputLabelProps={{ shrink: true }}
                name={"valid_until"}
                onChange={this.handleChange}
              /> */}
            </FormControl>
            {concessionErrorMessage.valid_until &&(
                  <p className="errorMsg2">{concessionErrorMessage.valid_until}</p>
                )}
            {concessionValidUntilError && (
              <p className="errorMsg2">{concessionValidUntilError}</p>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Concession Amount*
              </FormLabel>
              <TextField
                value={concessionFormstate.amount}
                type={"number"}
                placeholder={`Concession Amount`}
                className={concessionAmountError ||concessionErrorMessage.amount ?"errorInput":"withoutError" }
                variant="outlined"
                name={"amount"}
                onChange={this.handleChange}
              />
            </FormControl>
            {concessionErrorMessage.amount &&(
                  <p className="errorMsg2">{concessionErrorMessage.amount}</p>
                )}
            {concessionAmountError && (
              <p className="errorMsg2">{concessionAmountError}</p>
            )}
          </Grid>
          <Grid item xs={4}>
            <OutlinedSelect
              val={concessionFormstate.mode}
              options={[
                { id: "amount", name: "Amount" },
                { id: "percentage", name: "Percentage" }
              ]}
              label="Mode*"
              name={"mode"}
              handleChange={this.handleChange}
              className={concessionModeError ||concessionErrorMessage.mode ?"errorSelect":"withoutErrorSelect" }
             />
             {concessionErrorMessage.mode &&(
                  <p className="errorMsg2">{concessionErrorMessage.mode}</p>
                )}
            {concessionModeError && (
              <p className="errorMsg2">{concessionModeError}</p>
            )}
          </Grid>
        </Grid>
      </CustomModal>
    );
  }
}
export const themeCreatStyle = (theme: any) => ({
  background_color: {
    backgroundColor: theme.palette.primary.main
  },
  title_font: {
    color: theme.props.titleColor,
    fontFamily: theme.props.titleFont
  },
  subTitle_font: {
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  heading_font: {
    color: theme.props.headingColor,
    fontFamily: theme.props.headingFont
  },
  subHeading_font: {
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  bodyText_font: {
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white"
  },
  icon_color: {
    color: theme.props.iconColor
  },
  button_color_: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    "&:hover": {
      background: "#fff"
    }
  }
});

export default withStyles(themeCreatStyle)(CopyConcession as React.ComponentType<
  any
>);
