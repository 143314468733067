import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import { defaultBtn } from "../../CommonLayout/Layout/src/color";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import AdHocReportingController, {
  Props,
} from "./AdHocReportingController";
import Loader from "../../../components/src/Loader.web";
import { capitalizeFirst } from "../../../framework/src/Utilities";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import ReactToPrint from 'react-to-print';
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
// Customizable Area End

// Customizable Area Start

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  searchbar: {
    width: "85%",
    marginLeft: "139px",
  },
  gridContainer: {
    marginTop: "95px",
    marginRight: "8px",
    marginBottom: "20px"
  }, menu: {
    marginLeft: "-25px",
    marginTop: "15px",
    width:"100%",
    "& .MuiPaper-root": {
      overflow: "visible",
    }
  },
  pageTitle: {
    fontWeight: "bold"
  },
  actionBtn: {
    marginRight: "8px",
    borderRadius: "8px",
  },
  featureBtn: {
    borderRadius: "5px",
    border: `1px solid #ffb2b2`,
    backgroundColor: "#ffffff",
    margin: "10px",
    padding: "18px",
    '&:hover': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      color: "white",
      opacity: "1",
    },
    '&:active': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      opacity: "1",
      boxShadow: 'none',
      color: "white",
    }, '&:focus': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
    },
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    // height: "calc(100vh - 381px) !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableGrid: {
    flexDirection: "column",
    flexWrap: "nowrap",

  },
  heading: {
    width: "81%",
    backgroundColor: "#fcf3d4",
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 5
  },
  filterBar: {
    width: "18%",
    height: 60,
    display: "flex",
    alignItems: "center",
    borderRadius: 5,

  },
  bulkUpdateBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#fffff",
    border: "1px solid #4b4f5f",
    borderRadius: "5px",
    color: "#455366",
  },

  createBtn: {

    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '14px 44px',
    outline: 0,
    border: 0,
    width: 185,
    lineHeight: 1.5,
    backgroundColor: defaultBtn,
    borderColor: defaultBtn,
    color: "white",
    orderRadius: "10px",
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      boxShadow: 'none',
      color: "white",
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      color: "white",
      border: "none",
      outline: 0,
    },
  },
  menuItem: {
    "&:hover": {
      background: defaultBtn,
      color: "white"
    },
  },
  arrowUp: {
    position: "absolute",
    width: "16px",
    height: "12px",
    top: "-12px",
    zIndex: 1,
   
    '&.left': {
      left: "10px",
    },
    '&.right': {
      right: "10px",
      boxShadow: "0px 29px 8px -3px rgb(0 0 0 / 20%)",
    },
    '&.center': {
      left: "73%",
      transform: "translateX(-50%)",
    },
    '&:before': {
      content: "''",
      position: "absolute",
      top: "0",
      left: "0",
      borderStyle: "solid",
      borderWidth: "0 8px 12px",
      borderColor: "#FFF transparent",
      display: "block",
      width: 0,
      zIndex: 0,
    }
  },
  headingLayout: {
    width: "63%",
    backgroundColor: "#fcf3d4",
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "5px",
  },
  desTextConcat: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "190px",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    width: "65%",
    borderRadius:'8PX'
  },
  title: {
    color: "#656c7c",
    fontSize: "1rem",
    fontWeight: "bold",
    padding: "28px",
    paddingLeft:0
  },
  listItemTitle: {
    color: "#e86a67",
    fontWeight: 600,
    borderBottom:" 1px solid rgb(223 223 223 / 54%)",
    paddingBottom: "7px",
    // "&:last-child": {
    //   border: 'none !important'  
    // }
  },
  listItemTitle2: {
    color: "#e86a67",
    fontWeight: 600,
    // borderBottom:" 1px solid rgb(223 223 223 / 54%)",
    paddingBottom: "7px"
  }

});

// Customizable Area End

class AdHocReporting extends AdHocReportingController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // localStorage.clear();
    // Customizable Area End
  }
 
  
  render() {

    // Customizable Area Start
     const reportCategory = [
      {
        id: 'FeeReceivables',
        name:'Fee Receivables'
      },
      {
        id: 'FeePaymentDue',
        name: 'Fee Payment Due'
      },
      {
        id: 'ConcessionReport',
        name: 'Concession'}];
     const { classes } = this.props;
     const { searchKeyword,arrow } = this.state;
     console.log('mycurrentreporting',reportCategory.length);
    // Customizable Area End

    return (
      // Customizable Area Start
      //handleGlobalSearch={this.handleSearchChange}
      
      <Layout searchKeyword={searchKeyword} navigation={this.props.navigation} >
        <Grid className={classes.tableGrid} container>
          <PageHeader
            title="Reports"
            haveFilterButton={false}
            haveCSVButton={false}
            haveCreateButton={false}
            havePrintButton={false}
          />
          <Grid item style={{ backgroundColor: "#ffffff"}} xs={6}>
          <Typography className={classes.title}>
          Fee Report
          </Typography>
          <div className={classes.demo}>
            <List dense={false}>
              {reportCategory && reportCategory.map((row: any,i:any) => (
                
                <ListItem style={{paddingLeft: "12px",cursor:"pointer",}}  onClick={(evt: object) => this.handleReportListing(row)}>
                  <ListItemIcon style={{minWidth: "20px",paddingBottom: "7px"}}>
                    <img width="8px" height="8px" src={arrow}/>
                  </ListItemIcon>
                  <ListItemText className={reportCategory.length-1 ===i ? classes.listItemTitle2 : classes.listItemTitle}> {row.name}</ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        </Grid >
      </Layout>
      // Customizable Area End
    );
  }
}
export default withStyles(useStyles)(AdHocReporting);
