import React from "react";
// Customizable Area Start
import {
    Checkbox,
    FormControlLabel,
    InputAdornment,
    OutlinedInput,
    TextField,
    Select,
    MenuItem
} from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import CustomModal from '../../CommonLayout/CustomModal/src/CustomModal.web';
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import { PaymentTermsModal } from "./paymentTermsModal";
import CreateBillController, {
    Props,
} from "./CreateBillController";
import CommonDatePicker from '../../CommonLayout/HRMSCommons/src/CommonDatePicker'
import CustomItemFormWeb from "../../CommonLayout/CustomItemForm/src/CustomItemForm.web";
import { TDSModal } from "../../Vendors/src/tdsModal";
import VendorSelectionModal from "../../CommonLayout/VendorSelectionModal/src/VendorSelectionModal.web";
import SearchIcon from "@material-ui/icons/Search";

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
    
    searchIconContainer: {
      background: "#eb514f",
      height: "56px",
      display: "flex",
      borderRadius: "4px",
      width: "56px",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
   
  });
// Customizable Area End
class CreateBill extends CreateBillController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { open, handleClose ,paymentTerms,deletePaymentTerms,vendorModalOpen, handleChangePaymentTerms,handleAddNewPaymentField,
            onCloseVendorModal,classes,OnOpenVendorModal,vendors,onSubmitVendorModal,handleItemAddMore,billData,  handleRemoveItem, handleChange,
            discountTypeState,showReverseCharge,handleReverChargeShow} = this.props;
        console.log("itemdata: ", billData.itemData);        
        // Customizable Area End
        return (
            // Customizable Area Start
            <CustomModal
                title={"Create Account"}
                buttonTitle={"Create"}
                open={open}
                handleClose={(evt: object) => handleClose("create", false)}
                isError={false}
                errormsg={''}

            >
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Grid item xs={12}>
                        <FormControl style={{ minWidth: "49.5%"}}>
                            <FormLabel style={{ marginBottom: "8px" }}>
                            Vendor Name
                            </FormLabel>
                            <div
                            style={{ display: "flex", justifyContent: "space-between" }}
                            >
                            <TextField
                                className={"withoutError"}
                                value={''}
                                color={"primary"}
                                variant="outlined"
                                type={"text"}
                                name={"vendor_id"}
                                style={{ flex: 1, marginRight: "15px" }}
                                placeholder={"Select Vendor Name"}
                                disabled={false}
                                required
                            />
                            {/* {formMode !== FormMode.View && ( */}
                                <div className={classes.searchIconContainer}>
                                <SearchIcon
                                    onClick={OnOpenVendorModal}
                                    style={{ color: "white", fontSize: "33px" }}
                                />
                                </div>
                            {/* )} */}
                            </div>
                            
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ minWidth: "85%" }}>
                            <FormLabel style={{ marginBottom: "8px" }}>
                                Bill*
                            </FormLabel>
                            <TextField
                                value={''}
                                type={'text'}
                                placeholder={`Bill Number`}
                                className={""}
                                variant="outlined"
                                name={'name'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ minWidth: "85%" ,paddingLeft: "40px"}}>
                            <FormLabel style={{ marginBottom: "8px" }}>
                                Order*
                            </FormLabel>
                            <TextField
                                value={''}
                                type={'text'}
                                placeholder={`Order Number`}
                                style={{
                                        border: "none",
                                        width: '100%'
                                    }}
                                variant="outlined"
                                name={'code'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl style={{ minWidth: "89%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                        Bill Receive Date*
                    </FormLabel>
                    <CommonDatePicker 
                      iconStyle={{
                        position:"absolute",
                        right:"36px",
                        top:"12px",
                        pointerEvents:'none',
                      }}
                      disabled={false}
                      name="bill_receive_date"
                      placeholder={"Bill receie Date"}
                      minDate={new Date()}
                      maxDate={new Date("01/01/8000")}
                      handleChange={()=>function(){}}
                      value={new Date()}
                      />   
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl style={{ minWidth: "89%",paddingLeft: "40px" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                        Bill Date*
                    </FormLabel>
                    <CommonDatePicker 
                     iconStyle={{
                        position:"absolute",
                        right:"36px",
                        top:"12px",
                        pointerEvents:'none',
                      }}
                      disabled={false}
                      name="bill_date"
                      placeholder={"Bill Date"}
                      minDate={new Date()}
                      maxDate={new Date("01/01/8000")}
                      handleChange={()=>function(){}}
                      value={new Date()}
                      />   
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ minWidth: "89%" }}>
                        <FormLabel style={{ marginBottom: "8px" }}>
                            Due Date
                        </FormLabel>
                        <CommonDatePicker 
                         iconStyle={{
                            position:"absolute",
                            right:"36px",
                            top:"12px",
                            pointerEvents:'none',
                          }}
                        disabled={false}
                        name="due_date"
                        placeholder={"Due Date"}
                        minDate={new Date()}
                        maxDate={new Date("01/01/8000")}
                        handleChange={()=>function(){}}
                        value={new Date()}
                        />   
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ minWidth: "85%",paddingLeft: "40px" }}>
                            <FormLabel style={{ marginBottom: "8px" }}>
                            Payment Terms*
                            </FormLabel>
                            <OutlinedSelect
                            val={( '').toString()}
                            options={paymentTerms && paymentTerms.filter((item) => !(item?.isNew)).map(({ id, term_name }) => ({ id, name: term_name }))}
                            label=""
                            labelStyle={{ display: 'none' }}
                            formControlStyle={{ minWidth: 200 }}
                            placeholder="Select Payment Terms"
                            name={'payment_term_id'}
                            disabled={false}
                            handleChange={this.onChangePaymentTerms.bind(this)}
                            actionButtonLabel={"Configure Terms"}
                            hasActionButton
                            onActionButtonClick={this.openPaymentTermsModal.bind(this)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8} style={{ paddingRight: 12 }}>
                    <FormControl required style={{ width: "100%" }}>
                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={showReverseCharge}
                            onChange={handleReverChargeShow}
                            name="checkedA"
                            />
                        }
                        label="This transaction is applicable for reverse charge"
                        />
                    </FormControl>
                    </Grid>
                    {showReverseCharge && (
                        <>
                            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
                                <FormControl required style={{ minWidth: "85%" }}>
                                <FormLabel>Reverse Charge Account</FormLabel>
                                <OutlinedSelect
                                    className={ "withoutErrorSelect"}
                                    val={''}
                                    options={[
                                    { id: "0", name: "Account 1" },
                                    { id: "1", name: "Account 2" },
                                    { id: "2", name: "Account 3" }
                                    ]}
                                    label=""
                                    placeholder="Reverse Charge Account"
                                    name={"reverse_charge_account"}
                                    disabled={false}
                                    handleChange={() => function(){}
                                    }
                                />
                                
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <FormControl style={{paddingLeft: "40px"}} >
                                <FormLabel style={{ marginBottom: "8px" }}>
                                    Reverse Charge
                                </FormLabel>
                                    <OutlinedInput
                                    type={"text"}
                                    value={''}
                                    onChange={() => function(){}}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <div>
                                            <Select
                                            value={''}
                                            onChange={() =>
                                                function(){}
                                            }
                                            className={classes.reverseTypeSelect}
                                            >
                                            <MenuItem value={"₹"}>₹</MenuItem>
                                            <MenuItem value={"%"}>%</MenuItem>
                                            </Select>
                                        </div>
                                        </InputAdornment>
                                    }
                                    />
                                </FormControl>
                            </Grid>
                       </>
                    )}
                    <Grid item xs={6}>
                        <FormControl variant="standard" style={{ minWidth: "85%"}}>
                            <FormLabel style={{ marginBottom: "8px" }}>
                            Discount Type
                            </FormLabel>
                            <OutlinedSelect
                            val={('').toString()}
                            options={[{ id: "at_line_item_level", name: "At Line Item Level" }, 
                            { id: "at_transaction_level", name: "At Transaction Level" }]}
                            label=""
                            labelStyle={{ display: 'none' }}
                            formControlStyle={{ minWidth: 200}}
                            placeholder=""
                            name={'discount_type'}
                            handleChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    
                </Grid>
                <PaymentTermsModal
                    isOpen={this.state.isPaymentTermsModalOpened}
                    onClose={this.onClosePaymentTermsModal}
                    onSubmit={this.onPaymentTermsModalSubmit}
                    paymentTerms={paymentTerms}
                    onClickDelete={deletePaymentTerms}
                    handleAddNew={handleAddNewPaymentField}
                    handleChangePaymentTerms={handleChangePaymentTerms}
                />
                <VendorSelectionModal
                    title={"Advanced vendor search"}
                    open={vendorModalOpen}
                    handleClose={onCloseVendorModal}
                    vendors={vendors}
                    handleSubmit={(id: string) => onSubmitVendorModal(id)}
                />
                <TDSModal
                isOpen={this.state.isTDSModalOpened}
                onClose={this.onCloseTDSModal.bind(this)}
                tds={this.props.tds}
                tdSection={this.props.tdSection}
                onSubmit={this.onTDSModalSubmit.bind(this)}
                onCreateSection={this.onSectionSubmit.bind(this)}
                />
                <CustomItemFormWeb discountTypeState={discountTypeState} handleItemAddMore={handleItemAddMore}   handleRemoveItem={handleRemoveItem} itemData={billData.itemData} tds={this.props.tds} onChangeTDS={undefined} openTDSModal={this.openTDSModal.bind(this)}/>
            </CustomModal>
            
            // Customizable Area End
        );
    }
}

export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}));

export default withStyles(useStyles)(CreateBill);