import React from "react";
// Customizable Area Start
import { TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomBulkUpdateModal from "../../CommonLayout/CustomBulkUpdateModal/src/CustomBulkUpdateModal.web";
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import BulkUpdateFeeCollectionController, {
  Props
} from "./BulkUpdateFeeCollectionController";

interface roleMenuSubmenu {
  id: number;
  title: string;
  permission_category_id: number;
  fullAccessStatus: boolean;
  viewStatus: boolean;
  editStatus: boolean;
  createStatus: boolean;
  deleteStatus: boolean;
  approveStatus: boolean;
}

interface roleMenuAttributes {
  id: number;
  title: string;
  menus: roleMenuSubmenu[];
}

interface roleMenuData {
  attributes: roleMenuAttributes;
}
// Customizable Area End
class BulkUpdateFeeCollection extends BulkUpdateFeeCollectionController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
    const {
      open,
      handleClose,
      gradeOptions,
      feeStructureOptions,
      installmentOptions
    } = this.props;
    const {
      grade_id,
      division_id,
      fee_structure_id,
      installment_id,
      divisionOptions
    } = this.state;
    console.log("divisionOptions", divisionOptions);
        // Customizable Area End
        return (
            // Customizable Area Start
      <CustomBulkUpdateModal
        title={"Bulk Update"}
        handleSubmit={this.handleSubmit}
        buttonTitle={"Update"}
        open={open}
        handleClose={(evt: object) => handleClose("bulkUpdate", false)}
      >
        <Grid container direction="column">
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={6} style={{ paddingRight: 16 }}>
              <OutlinedSelect
                placeholderTitle="Please Select"
                val={grade_id}
                options={gradeOptions}
                label="Class"
                name={"grade_id"}
                formControlStyle={{
                  margin: 0
                }}
                handleChange={(e: any) => this.onElementChange(e, "grade_id")}
              />
            </Grid>

            <Grid item xs={6}>
              <OutlinedSelect
                placeholderTitle="Please Select"
                val={division_id}
                options={divisionOptions}
                label="Division"
                name={"division"}
                formControlStyle={{
                  margin: 0
                }}
                handleChange={(e: any) =>
                  this.onElementChange(e, "division_id")
                }
              />
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={6} style={{ paddingRight: 16 }}>
              <OutlinedSelect
                placeholderTitle="Please Select"
                val={fee_structure_id}
                options={feeStructureOptions}
                label="Fee Structure"
                name={"fee_structure"}
                formControlStyle={{
                  margin: 0
                }}
                handleChange={(e: any) =>
                  this.onElementChange(e, "fee_structure_id")
                }
              />
            </Grid>

            <Grid item xs={6}>
              <OutlinedSelect
                placeholderTitle="Please Select"
                val={installment_id}
                options={installmentOptions}
                label="Installment"
                name={"installment"}
                formControlStyle={{
                  margin: 0
                }}
                handleChange={(e: any) =>
                  this.onElementChange(e, "installment_id")
                }
              />
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} style={{ paddingRight: 16 }} />
          </Grid>
        </Grid>
      </CustomBulkUpdateModal>
            // Customizable Area End
        );
    }
}

export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}));

export default withStyles(themeCreatStyle)(
    BulkUpdateFeeCollection as React.ComponentType<any>
)
