import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFeeCollection } from "./FeeCollectionListController";
import { number } from "yup";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Edit,
  View
}

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: IFeeCollection | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  onClose: () => void;
  onSubmit: (updatedFields: any, id: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: IFeeCollection;
  isSubmitting: boolean;
  isLoading: boolean;
  formErrors: { [key: string]: string };
  concessionTypes: [],
  concessionNames: any,
  updatedFields: {id: number, fee_concession_id: number}[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FeeCollectionFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private initialValues: IFeeCollection = {
    id: "",
    type: "",
    selected: false,
    attributes: {
      activated: false,
      type: "",
      country_code: 0,
      email: "",
      first_name: "",
      full_phone_number: "",
      last_name: "",
      phone_number: 0,
      date_of_birth: "",
      gender: "",
      email_activated: false,
      phone_activated: false,
      school_id: 0,
      zoom_user_id: null,
      account_status: "",
      avatar: null,
      avatar_id: null,
      avatar_for: null,
      school_class: {
        data: {
          id: "",
          type: "",
          attributes: {
            class_name: "",
            grade_id: 0,
            grade_name: "",
            divisions: {
              data: {
                id: "",
                type: "",
                attributes: {
                  name: "",
                  school_id: 0,
                  creation_date: "",
                  updated_at: ""
                }
              }
            }
          }
        }
      }
    },
    fee_structures: {
      data: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          name: "",
          grade: "",
          grade_id: 0,
          division: "",
          division_id: 0,
          is_class: false,
          is_class_and_division: false,
          is_group: false,
          academic_year: {
            id: 0,
            year: "",
            created_at: "",
            updated_at: ""
          },
          fee_type: "",
          custom_id: "",
          total_amount: 0,
          sub_fee_structures: {
            data: []
          },
          installment: {
            data: {
              id: '',
              type: '',
              attributes: {
                id: 0,
                name: '',
                created_at: '',
                updated_at: '',
                custom_id: '',
                total_amount: 0,
                discounted_amount: 0,
                installment_code: '',
                concession: null,
                concession_type: null,
                bill_duration_no: 0,
                bill_duration: '',
                billing_cycle: 0,
                payment_option: '',
                invoice_template_id: 0,
                invoice_template_name: '',
                email_template_id: 0,
                email_template_name: '',
                fee_structure_id: 0,
                payment_collection: '',
                installment_details: []
              }
            }
          }
        }
      }
    }
  };

  public RequestMessage = {
    GetStates: this.buildRequestMessage(Method.GET),
    GetConcessionTypes: this.buildRequestMessage(Method.GET),
    GetConcessionNames: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      isSubmitting: false,
      isLoading: false,
      formErrors: {},
      concessionTypes: [],
      concessionNames: {},
      updatedFields: []
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (callID) {
        case this.RequestMessage.GetConcessionTypes.messageId:
          if (response !== null) {
            const concessionTypeArray = response?.data?.map((el: any) => {
              return { id: el.attributes.id, name: el.attributes.name }
            });

            this.setState({
              concessionTypes: concessionTypeArray || [],
              isLoading: false
            });
          }
          break;
        case this.RequestMessage.GetConcessionNames.messageId:
          if (response !== null) {
            const concessionNameArray = response?.data?.map((el: any) => {
              return { id: el.attributes.id, name: el.attributes.name, amount: el.attributes.amount }
            });

            this.setState({
              concessionNames: {[response?.data?.[0]?.attributes?.fee_concession_type_id]: concessionNameArray || []},
              isLoading: false
            });
          }
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();
    this.getConcessionTypesCall();
  }

  public componentDidUpdate(prevProps: Props, prevState: S) {
    const { isOpen, initialValues, formMode } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        this.props.initialValues?.fee_structures?.data?.attributes?.sub_fee_structures?.data.forEach(el => {
          this.getConcessionNamesCall(el?.attributes?.concession?.data?.attributes?.fee_concession_type?.data?.attributes?.id || 0)
        })
        let form: IFeeCollection = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View].includes(formMode)) {
          form = { ...(JSON.parse(JSON.stringify(initialValues)) || this.initialValues) };
        }
        
        this.setState({
          form,
          isSubmitting: false
        });
      }
    }
  }

  public onConcessionTypeChange(element: React.ChangeEvent<{ value: number }>, subFeeId: string) {
    const newSubFeeStructures = this.state.form.fee_structures.data.attributes.sub_fee_structures.data.map(item => {
      const el = JSON.parse(JSON.stringify(item));
      if (el.id === subFeeId) {
        if (!el.attributes.concession.data) {
          el.attributes.concession.data = { attributes: { fee_concession_type: { data: { attributes: { id: element.target.value } } } } }
        } else {
          if (el?.attributes?.concession?.data?.attributes?.fee_concession_type?.data) {
            el.attributes.concession.data.attributes.fee_concession_type.data.attributes.id = element.target.value
            el.attributes.concession.data.attributes.id = 0
            el.attributes.concession.data.attributes.amount = 0
            el.attributes.amount_after_concession = 0
          }
        }
        return { ...el }
      } else {
        return el;
      }
    })
    this.state.form.fee_structures.data.attributes.sub_fee_structures.data = newSubFeeStructures
    this.setState({
      form: {
        ...this.state.form
      }
    })

    if (element.target.value !== null) {
      this.getConcessionNamesCall(element.target.value);
    }
  }

  public onConcessionNameChange(element: React.ChangeEvent<{ value: number }>, subFeeId: string) {
    const newSubFeeStructures = this.state.form.fee_structures.data.attributes.sub_fee_structures.data.map(item => {
      const el = JSON.parse(JSON.stringify(item));
      if (el.id === subFeeId) {
        const concession = this.state.concessionNames?.[el?.attributes?.concession?.data?.attributes?.fee_concession_type?.data?.attributes?.id || '']?.find((el2: any) => el2.id === element.target.value)
        if (!el.attributes.concession.data) {
          el.attributes.concession.data = { attributes: {id: concession?.id, amount: concession?.amount} }
        } else {
          if (el?.attributes?.concession?.data?.attributes) {
            el.attributes.concession.data.attributes.id = concession?.id
            el.attributes.concession.data.attributes.amount = concession?.amount
            el.attributes.amount_after_concession = 0
          }
        }
        const newUpdatedFields = [...this.state.updatedFields]
        if (newUpdatedFields.find(el => el.id === Number(subFeeId))) {
          newUpdatedFields.forEach(el => {
            if (el.id === Number(subFeeId)) {
              el.fee_concession_id = element.target.value
            }
          })
        } else {
          newUpdatedFields.push({id: Number(subFeeId), fee_concession_id: element.target.value})
        }
        this.setState({
          updatedFields: newUpdatedFields
        })
        return { ...el }
      } else {
        return el;
      }
    })
    this.state.form.fee_structures.data.attributes.sub_fee_structures.data = newSubFeeStructures
    this.setState({
      form: {
        ...this.state.form
      }
    })
  }

  public onPrimaryDetailElementChange(
    element: React.ChangeEvent<{ value: string }>,
    property: string
  ) {
    // this.setState({
    //   form: {
    //     ...this.state.form,
    //     primary_details: {
    //       ...this.state.form.primary_details,
    //       [property]: element.target.value
    //     }
    //   }
    // });
  }

  public countryCodes: Array<any> = Array.from(
    { length: 200 },
    (v, k) => k + 91
  ).map(v => ({
    id: v,
    name: `+${v}`
  }));

  validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  public onSubmit() {
    this.setState({ isSubmitting: this.props.formMode !== FormMode.View });

    this.props.onSubmit(this.state.updatedFields, this.state.form.id);
  }

  public handleFormClose() {
    this.setState({
      form: { ...(this.props.initialValues || this.initialValues) },
      formErrors: {},
      isSubmitting: false
    });

    this.props.onClose();
  }

  private getConcessionTypesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetConcessionTypes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_fee/fee_concession_types'
    );

    runEngine.sendMessage(this.RequestMessage.GetConcessionTypes.id, this.RequestMessage.GetConcessionTypes);
  }

  private getConcessionNamesCall(id: number) {
    this.setState({ isLoading: true });

    this.RequestMessage.GetConcessionNames.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_fee/fee_concession_types/${id}/concessions`
    );

    runEngine.sendMessage(this.RequestMessage.GetConcessionNames.id, this.RequestMessage.GetConcessionNames);
  }

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FeeCollectionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }
  // Customizable Area End
}
