import React from 'react';
// Customizable Area Start
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, Theme } from "@material-ui/core/styles";
import { formSubmitBtn, formCancelBtn } from "../../Layout/src/color";
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from "@material-ui/icons/Close";
import { Styles } from "@material-ui/styles";

import CustomUploadModalController, {
  Props
} from "./CustomUploadModalController";

import { Grid, IconButton, Typography } from "@material-ui/core";
import { uploadIcon } from "./assets";

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  dialogPaper: {
    // maxWidth: "188vh",
    // minHeight: "286px",
    width: "400px",
    borderRadius: "26px"
  },
  paperWidthSm: {
    minWidth: "300px",
    maxHeight: "725px"
  },
  activeBtn: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "8px 38px",
    border: "1px solid",
    lineHeight: 1.5,
    background: formSubmitBtn,
    //borderColor: '#ea5c6b',
    borderRadius: "9px",
    color: "white"
  },

  subTitle: {
    marginBottom: "18px"
  },
  icon: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "gray"
  },
  title: {
    fontWeight: "bold",
    fontSize: "18px",
    borderBottom: "2px solid #f1f1f1",
    paddingBottom: "14px",
    textAlign: "center"
  },
  nameContainer: {
    border: "1px solid #EAEAEA",
    borderRadius: 5,
    alignItems: "center",
    padding: 20,
    marginBottom: 10,
    marginTop: 10,
    color: "#A5B9D0"
  },
  dragContainer: {
    border: "1px dashed #D6DADD",
    borderRadius: 5,
    alignItems: "center",
    textAlign: "center",
    padding: 20
  },
  uploadIconContainer: {
    border: "none",
    width: "80px",
    height: "80px"
  }
});
// Customizable Area End

class CustomUploadModal extends CustomUploadModalController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { fileError, importFile, fileUrl, downloadIcon } = this.state;
    const { visible, handleClose, handleSubmit, classes, title } = this.props;

    return (
      <>
        <Dialog
          open={visible}
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          {title && (
            <DialogTitle
              onTouchCancel={() => {}}
              style={{ textAlign: "left" }}
              id="alert-dialog-title"
            >
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.icon}
              >
                <CloseIcon />
              </IconButton>
              <div style={{ color: "#3D516F" }} className={classes.title}>
                {title}
              </div>
            </DialogTitle>
          )}
          <DialogContent style={{ textAlign: "left", padding: "26px" }}>
            <Grid>
              <FormControl style={{ minWidth: "100%" }}>
                <input
                  ref={ref => (this.fileInputRef = ref as HTMLInputElement)}
                  type={"file"}
                  accept={".jpeg, .jpg, .png, .JPEG, .PNG, .JPG"}
                  style={{ display: "none" }}
                  name={"file"}
                  onChange={(e: any) => this.handleFileChange(e.target.files)}
                />
                <div style={{ fontWeight: 600, color: "#455262" }}>
                  Image Name
                </div>
                <div className={classes.nameContainer}>
                  {this.state.importFile ? this.state.importFile.name : ""}
                </div>
                <div
                  className={classes.dragContainer}
                  onClick={() => this.fileInputRef.click()}
                  onDrop={this.handleDrop}
                  onDragOver={event => event.preventDefault()}
                >
                  <img
                    className={classes.uploadIconContainer}
                    src={uploadIcon}
                  />
                  <div>Upload Image</div>
                </div>
                {fileError && <p style={{ color: "red" }}>{fileError}</p>}
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "center",
              margin: "0px 24px 24px 24px"
            }}
          >
            <Button
              disabled={!importFile}
              className={classes.activeBtn}
              onClick={() => handleSubmit(importFile, fileUrl)}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(CustomUploadModal);
