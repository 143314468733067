import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import {
  FormMode,
  Props as IFineFormProps,
  IFineForm,
  ISubFineForm
} from "./FineFormController";
import { debounce } from "lodash";
// Customizable Area End
export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

export interface IFine {
  id: string;
  type: string;
  selected: boolean;
  attributes: {
    id: number;
    name: string;
    day: number;
    duration: string;
    fine_category: string;
    fine_amount: string;
    mode: string;
    created_at: string;
    updated_at: string;
    custom_id: string;
    sub_fines?: ISubFineForm[];
  };
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  name: string;
  fine_category: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: IFine | null;
  actionMenuItems: Array<IActionMenuItem>;
  fines: Array<IFine>;
  fineNames: Array<string>;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | "indeterminate" | true;
  isLoading: boolean;
  deleteModalOpen: boolean;
  filterForm: IFilterForm;
  isFormModalOpened: boolean;
  deleteMessage: "single" | "multiple";
  form: IFineForm | null;
  formMode: FormMode;
  formErrors: { [key: string]: string };
  openedRow: number | undefined;
  showAllRows?: boolean;
  searchKeyword: string;
  currentPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FineListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editFine.bind(this) },
    { label: "Delete", action: this.deleteFine.bind(this) },
    { label: "View", action: this.viewFine.bind(this) },
    { label: "Copy", action: this.copyFine.bind(this) }
  ];

  public RequestMessage = {
    GetFines: this.buildRequestMessage(Method.GET),
    GetAllFines: this.buildRequestMessage(Method.GET),
    FilterFines: this.buildRequestMessage(Method.GET),
    DeleteFine: this.buildRequestMessage(Method.DELETE),
    CreateFine: this.buildRequestMessage(Method.POST),
    EditFine: this.buildRequestMessage(Method.PUT),
    ExportFines: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  };

  public formProps: { [key: number]: IFineFormProps } = {
    [FormMode.Create]: {
      title: "Create Fine",
      submitLabel: "Create",
      initialValues: null,
      formMode: FormMode.Create,
      isOpen: false,
      onClose: this.onCloseFineFormModal.bind(this),
      onSubmit: this.onSubmitCreateFineFormModal.bind(this),
      requestMessage: this.RequestMessage.CreateFine
    },
    [FormMode.Copy]: {
      title: "Copy Fine",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Copy,
      isOpen: false,
      onClose: this.onCloseFineFormModal.bind(this),
      onSubmit: this.onSubmitCreateFineFormModal.bind(this),
      requestMessage: this.RequestMessage.CreateFine
    },
    [FormMode.Edit]: {
      title: "Edit Fine",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Edit,
      isOpen: false,
      onClose: this.onCloseFineFormModal.bind(this),
      onSubmit: this.onSubmitEditFineFormModal.bind(this),
      requestMessage: this.RequestMessage.EditFine
    },
    [FormMode.View]: {
      title: "View Fine",
      submitLabel: "Back to Listing",
      initialValues: null,
      formMode: FormMode.View,
      isOpen: false,
      onClose: this.onCloseFineFormModal.bind(this),
      onSubmit: this.onCloseFineFormModal.bind(this),
      requestMessage: this.RequestMessage.Null
    }
    // [FormMode.Copy]: {
    //   title: "Copy Tax Rate",
    //   submitLabel: "Copy",
    //   initialValues: null,
    //   formMode: FormMode.Copy,
    //   isOpen: false,
    //   onClose: this.onCloseTaxRateFormModal.bind(this),
    //   onSubmit: this.onSubmitCreateTaxRateModal.bind(this),
    //   requestMessage: this.RequestMessage.Null
    // }
  };

  public printReferance = React.createRef();
  tableRef: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formErrors: {},
      anchorEl: null,
      actionedTableRow: null,
      fines: [],
      actionMenuItems: [],
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      filterForm: {
        name: "",
        fine_category: ""
      },
      isFormModalOpened: false,
      form: null,
      formMode: FormMode.Create,
      fineNames: [],
      openedRow: undefined,
      searchKeyword: "",
      currentPage: 0,
      deleteMessage: "single"
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.tableRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (callID) {
        case this.RequestMessage.GetFines.messageId:
        case this.RequestMessage.FilterFines.messageId:
          if (response) {
            this.setState({
              fines: response.data || [],
              pageMeta: response.meta || {},
              isLoading: false
            });
          }
          break;
        case this.RequestMessage.GetAllFines.messageId:
          if (response) {
            let fineNames = (response.data || [])
              .map((el: any) =>
                el.attributes ? el.attributes.name : undefined
              )
              .filter((el: any) => el);
            fineNames = [...new Set(fineNames)];
            this.setState({
              fineNames,
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.DeleteFine.messageId:
          if (response !== null) {
            this.setState({
              deleteModalOpen: false,
              actionedTableRow: null
            });

            this.getFinesCall();
          }
          break;
        case this.RequestMessage.EditFine.messageId:
        case this.RequestMessage.CreateFine.messageId:
          if (response !== null) {
            runEngine.debugLog("responseresponseresponse", response);
            if (response.errors && response.errors[0].name) {
              this.setState({ formErrors: response.errors[0] });
            } else {
              this.getFinesCall();
              this.setState({ isFormModalOpened: false });
            }
          }
          break;

        case this.RequestMessage.ExportFines.messageId:
          const rawCSV = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          debugger;
          if (response !== null) {
            const file = new Blob(response, { type: "text/csv" });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }

          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidUpdate(prevProps: any, prevState: any) {
    if (!this.state.isFormModalOpened && prevState.isFormModalOpened) {
      this.setState({ formErrors: {} });
    }
  }
  public async componentDidMount() {
    super.componentDidMount();

    this.getFinesCall();
    this.getAllFinesCall();
  }

  public onClickCreate() {
    this.setState({
      isFormModalOpened: true,
      formMode: FormMode.Create
    });
    // this.props.navigation.navigate("CreateFine");
    // console.log("navigate", this.props.navigation);
  }

  private openFineModalWithInitialData(fine: IFine | null, formMode: FormMode) {
    if (fine !== null) {
      const {
        id,
        attributes: {
          name,
          created_at,
          custom_id,
          day,
          duration,
          fine_amount,
          fine_category,
          updated_at,
          mode,
          sub_fines
        }
      } = fine as IFine;

      this.setState({
        isFormModalOpened: true,
        formMode: formMode,
        form: {
          id,
          name,
          created_at,
          custom_id,
          day,
          duration,
          fine_amount,
          fine_category,
          updated_at,
          mode,
          sub_fines_attributes: sub_fines || []
        }
      });
    }
  }

  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    this.setState({ searchKeyword: value });
    const debouncedSave = debounce(value => {
      if (value !== "") {
        this.setState({ currentPage: 0 });
        this.searchFinesCall(value);
      } else {
        this.getFinesCall(this.state.currentPage + 1);
      }
    }, 300);
    debouncedSave(value);
  };

  public onSelectAllFines(event: React.ChangeEvent<HTMLInputElement>) {
    const { fines: Fines } = this.state;
    const { checked } = event.target;

    const updatedFines = Fines.map(Fine => {
      return {
        ...Fine,
        selected: checked
      };
    });

    this.setState({
      fines: updatedFines,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedFines)
    });
  }

  public onSelectFine(event: React.ChangeEvent<HTMLInputElement>, row: IFine) {
    const { fines: Fines } = this.state;
    const { checked } = event.target;

    const updatedFines = Fines.map(Fine => {
      return {
        ...Fine,
        selected: row.id === Fine.id ? checked : Fine.selected
      };
    });

    this.setState({
      fines: updatedFines,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedFines)
    });
  }

  public onClickActionButton(event: any, item: IFine) {
    this.setState({
      anchorEl: event.currentTarget,
      actionedTableRow: item,
      actionMenuItems: this.tableActionMenuItems
    });
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  public onClickPagination(page: any) {
    this.setState({ currentPage: page.selected });
    this.tableRef.current.scrollIntoView();
    this.getFinesCall(page.selected + 1);
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      actionedTableRow: null
    });
  }

  public onSubmitDeleteModal() {
    this.setState({ selectAllCheckboxChecked: false });
    this.deleteFineCall();
  }

  public onCloseFineFormModal() {
    this.setState({ isFormModalOpened: false });
  }

  public onSubmitCreateFineFormModal(form: IFineForm) {
    this.createFineCall(form);
  }
  public onSubmitEditFineFormModal(form: IFineForm) {
    console.log("formformform", form);
    this.editFineCall(form);
  }

  // public onSubmitEditFineFormModal(form: ITaxRateForm) {
  //   this.edi(form);
  // }

  public onUploadCSVForImport(file: File) {
    this.importFinesCall(file);
  }

  public onClickExportCSVButton() {
    this.exportFinesCall();
  }
  onClickSampleCSVButton = () => {
    this.downloadSampleCSV();
  };

  //#region Filter Methods

  public onChangeFilterFineName(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        name: event.target.value
      }
    });
  }

  public onChangeFilterFineCategory(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        fine_category: event.target.value
      }
    });
  }

  public onFilterFormSubmit() {
    this.filterFinesCall(this.state.filterForm);
  }
  //#endregion Filter Methods

  private editFine() {
    const fine = this.state.actionedTableRow;
    if (!fine) return;
    // window.location.href = "EditFine?id=" + fine.attributes.id;
    this.openFineModalWithInitialData(fine, FormMode.Edit);
  }

  private viewFine() {
    const fine = this.state.actionedTableRow;
    if (!fine) return;
    // this.props.navigation.navigate("ViewFine?id=5");
    // window.location.href = "ViewFine?id=" + fine.attributes.id;
    this.openFineModalWithInitialData(fine, FormMode.View);
  }

  private deleteFine() {
    const currentFine = this.state.actionedTableRow;
    if (!currentFine) return;

    const { fines } = this.state;
    if (!currentFine.selected) {
      const updatedFines = fines.map(el => {
        return { ...el, selected: el.id === currentFine.id ? true : false };
      });

      this.setState({
        fines: updatedFines,
        selectAllCheckboxChecked: false,
        deleteMessage: "single"
      });
    } else {
      if (fines.filter(el => el.selected).length > 1)
        this.setState({ deleteMessage: "multiple" });
      else this.setState({ deleteMessage: "single" });
    }
    this.setState({ deleteModalOpen: true });
  }

  private copyFine() {
    const fine = this.state.actionedTableRow;
    if (!fine) return;
    this.openFineModalWithInitialData(fine, FormMode.Copy);
  }
  private copyFineCall() {
    const Fine = this.state.actionedTableRow;

    if (Fine) {
      const {
        attributes: { custom_id, id, sub_fines, ...form }
      } = Fine;

      const to: IFineForm = {
        id: undefined,
        ...form,
        name: form.name + " - Copy",
        sub_fines_attributes: sub_fines || [],
        custom_id
      };

      this.createFineCall(to);
    }
  }

  private selectedFines(Fines?: Array<IFine>): Array<IFine> {
    if (Fines === undefined) {
      Fines = this.state.fines;
    }

    return Fines.filter(Fine => Fine.selected);
  }

  private isSelectAllCheckboxChecked(
    Fines: Array<IFine>
  ): false | "indeterminate" | true {
    const selectedFines = this.selectedFines(Fines);
    const { length } = selectedFines;

    return length !== 0 && (length === Fines.length ? true : "indeterminate");
  }

  //#region Service Calls
  private getFinesCall(page: number = 1) {
    this.setState({ isLoading: true });

    this.RequestMessage.GetFines.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FinesEndPoint}?page=${page}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetFines.id,
      this.RequestMessage.GetFines
    );
  }

  private getAllFinesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetAllFines.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FinesEndPoint}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetAllFines.id,
      this.RequestMessage.GetAllFines
    );
  }

  private deleteFineCall() {
    const selectedFines = this.state.fines.filter(el => el.selected);
    if (selectedFines.length === 1) {
      this.RequestMessage.DeleteFine.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FinesEndPoint}/${selectedFines[0].id}`
      );
    } else if (selectedFines.length > 1) {
      const ids = selectedFines.reduce((p, c) => p + (p ? "," : "") + c.id, "");
      this.RequestMessage.DeleteFine.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FinesEndPoint}/bulk_destroy?ids=${ids}`
      );
    } else return;

    runEngine.sendMessage(
      this.RequestMessage.DeleteFine.id,
      this.RequestMessage.DeleteFine
    );
  }

  private filterFinesCall(form: IFilterForm) {
    this.RequestMessage.FilterFines.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FinesEndPoint}/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(
      this.RequestMessage.FilterFines.id,
      this.RequestMessage.FilterFines
    );
  }
  private searchFinesCall(fineName: string) {
    this.RequestMessage.FilterFines.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FinesEndPoint}/search?page=${this.state.currentPage ||
        1}&name=${fineName}`
    );

    runEngine.sendMessage(
      this.RequestMessage.FilterFines.id,
      this.RequestMessage.FilterFines
    );
  }

  private exportFinesCall() {
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage())
    };

    fetch(
      `${configJSON.APIBaseURL}/${configJSON.FinesEndPoint}/export`,
      requestOptions
    )
      .then(response => {
        const file_sub_name = new Date().getTime();
        response
          .blob()
          .then(blob => downloadCSV(blob, "fine_csv_" + file_sub_name));
      })
      .catch(error => {
        toast.success(error.message);
      });
  }

  downloadSampleCSV = () => {
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage())
    };

    fetch(
      `${configJSON.APIBaseURL}/${configJSON.FinesEndPoint}/csv_sample_file`,
      requestOptions
    )
      .then(response => {
        const file_sub_name = new Date().getTime();
        response
          .blob()
          .then(blob => downloadCSV(blob, "fine_csv_" + file_sub_name));
      })
      .catch(error => {
        toast.success(error.message);
      });
  };

  private importFinesCall(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: JSON.parse(this.getHeaderMessage()),
      body: formData
    };

    fetch(
      `${configJSON.APIBaseURL}/${configJSON.FinesEndPoint}/import`,
      requestOptions
    )
      .then(response => response.json())
      .then(response => toast.success(response.message))
      .catch(response => toast.error(response.error));
  }

  private createFineCall(form: IFineForm) {
    this.RequestMessage.CreateFine.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FinesEndPoint
    );
    const body = { fine: form };
    delete body.fine.id;
    body.fine.sub_fines_attributes.forEach(el => {
      delete el.id;
      delete el.fine_id;
    });
    this.RequestMessage.CreateFine.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(
      this.RequestMessage.CreateFine.id,
      this.RequestMessage.CreateFine
    );
  }

  private editFineCall(form: IFineForm) {
    const { id, ...rest } = form;

    if (id !== undefined) {
      this.RequestMessage.EditFine.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ fine: rest })
      );

      this.RequestMessage.EditFine.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FinesEndPoint}/${id}`
      );

      runEngine.sendMessage(
        this.RequestMessage.EditFine.id,
        this.RequestMessage.EditFine
      );
    }
  }
  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TaxesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    return Object.entries(form)
      .filter(([key, value]) => !!value && value !== "null" && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}
