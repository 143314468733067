Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.APIBaseURL = urlConfig.baseURL;
exports.APIContentType = "application/json";
exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.PATCH = "PATCH";
exports.OrganizationProfileEndPoint = "bx_block_profile/profiles";
exports.userManagementURL = urlConfig.baseURL;
// Customizable Area End