import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { ToastContainer, toast, cssTransition } from 'react-toastify';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import { withRouter } from 'react-router-dom';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import UserGroups from '../../blocks/UserGroups/src/UserGroups';
import Payments from '../../blocks/Payments/src/Payments';
import Concession from '../../blocks/PayrollIntegration/src/Concession.web.tsx';
import OrganisationHierarchy from '../../blocks/OrganisationHierarchy/src/OrganisationHierarchy';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import CvresumeCandidateManagement from '../../blocks/CvresumeCandidateManagement/src/CvresumeCandidateManagement';
import TaxCalculator from '../../blocks/TaxCalculator/src/TaxCalculator';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import PeopleManagement from '../../blocks/PeopleManagement/src/PeopleManagement';
import SummaryCard from '../../blocks/SummaryCard/src/SummaryCard';
import GraphicalCharts from '../../blocks/GraphicalCharts/src/GraphicalCharts';
import BreadcrumbNavigation from '../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation';
import PasswordProtectedPages from '../../blocks/PasswordProtectedPages/src/PasswordProtectedPages';
import GstIntegration from '../../blocks/GstIntegration/src/GstIntegration';
import GoalManagement from '../../blocks/GoalManagement/src/GoalManagement';
import SingleSignonsso from '../../blocks/SingleSignonsso/src/SingleSignonsso';
import AdvancedSearch2 from '../../blocks/AdvancedSearch2/src/AdvancedSearch2';
import SpendAnalysis from '../../blocks/SpendAnalysis/src/SpendAnalysis';
import BudgetingForecasting from '../../blocks/BudgetingForecasting/src/BudgetingForecasting';
import Scheduling from '../../blocks/Scheduling/src/Scheduling';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import PayrollIntegration from '../../blocks/PayrollIntegration/src/PayrollIntegration';
import CfStripeGatewayIntegration from '../../blocks/CfStripeGatewayIntegration/src/CfStripeGatewayIntegration';
import TabletSupport from '../../blocks/TabletSupport/src/TabletSupport';
import Referrals from '../../blocks/Referrals/src/Referrals';
import MultilevelApproval from '../../blocks/MultilevelApproval/src/MultilevelApproval';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import PerformanceTracker from '../../blocks/PerformanceTracker/src/PerformanceTracker';
import LeaveTracker from '../../blocks/LeaveTracker/src/LeaveTracker';
import PaymentAdmin from '../../blocks/PaymentAdmin/src/PaymentAdmin';
import FeedbackCollection from '../../blocks/FeedbackCollection/src/FeedbackCollection';
import CentralisedBilling from '../../blocks/CentralisedBilling/src/CentralisedBilling.web';
import JobListing from '../../blocks/JobListing/src/JobListing';
import AcceptPrepayments from '../../blocks/AcceptPrepayments/src/AcceptPrepayments';
import AutomaticReminders from '../../blocks/AutomaticReminders/src/AutomaticReminders';
import DataEncryption from '../../blocks/DataEncryption/src/DataEncryption';
import TeamBuilder from '../../blocks/TeamBuilder/src/TeamBuilder';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import EmployeeLogin from '../../blocks/EmployeeLogin/src/EmployeeLogin';
import TaskAllocator from '../../blocks/TaskAllocator/src/TaskAllocator';
import CarouselDisplay from '../../blocks/carouseldisplay/src/CarouselDisplay';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import CameraAccess from '../../blocks/cameraaccess/src/CameraAccess';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import Notifications from '../../blocks/notifications/src/Notifications';
import Analytics from '../../blocks/analytics/src/Analytics';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import Location from '../../blocks/location/src/Location';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import AssessmentTest from '../../blocks/AssessmentTest/src/AssessmentTest';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import ElasticSearch from '../../blocks/ElasticSearch/src/ElasticSearch';
import DragDropInterface from '../../blocks/dragdropinterface/src/DragDropInterface';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import TimesheetManagement from '../../blocks/TimesheetManagement/src/TimesheetManagement';
import AccountTransfer from '../../blocks/AccountTransfer/src/AccountTransfer';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import AdHocReporting from '../../blocks/AdHocReporting/src/AdHocReporting.web';
import FeeReceivables from '../../blocks/AdHocReporting/src/FeeReceivables.web';
import ConcessionReport from '../../blocks/AdHocReporting/src/ConcessionReport.web';
import FeePaymentDue from '../../blocks/AdHocReporting/src/FeePaymentDue.web';
import ApiIntegration from '../../blocks/ApiIntegration/src/ApiIntegration';
import ExpenseTracking from '../../blocks/ExpenseTracking/src/ExpenseTracking';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import TaxRates from '../../blocks/Tax/src/TaxRates';
import OrganizationProfile from '../../blocks/Organization/src/OrganizationProfile.web';
import DashboardCharts from '../../blocks/DashboardCharts/src/DashboardCharts.web';
import GSTSettings from '../../blocks/Tax/src/GSTSettings.web';
import Users from '../../blocks/Users/src/UserList.web';
import Account from '../../blocks/Account/src/Account.web';
import Fee from '../../blocks/Fee/src/FeeList.web';
import Fine from '../../blocks/Fine/src/FineList.web';
import CreateFine from '../../blocks/Fine/src/CreateFine';
import CustomerList from '../../blocks/CustomerList/src/CustomerList.web';
import FeeCollectionList2 from '../../blocks/FeeCollectionList/src/FeeCollectionList.web';
import FeeAssignment from '../../blocks/FeeAssignment/src/FeeAssignment.web';
import Expense from '../../blocks/Expense/src/ExpenseList.web';
import CreateUpdateExpense from '../../blocks/Expense/src/CreateUpdateExpense.web';
import Vendors from '../../blocks/Vendors/src/Vendors.web';
import PaymentPreference from '../../blocks/PaymentPreference/src/PaymentPreference.web';
import RecurringExpenseList from '../../blocks/RecurringExpenses/src/RecurringExpenseList';
import ItemList from '../../blocks/Items/src/ItemList.web';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const routeMap = {
  TaskAllocator: {
    component: TaskAllocator,
    path: '/TaskAllocator'
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: '/CarouselDisplay'
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData'
  },
  CameraAccess: {
    component: CameraAccess,
    path: '/CameraAccess'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: '/ElasticSearch'
  },
  DragDropInterface: {
    component: DragDropInterface,
    path: '/DragDropInterface'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },
  TimesheetManagement: {
    component: TimesheetManagement,
    path: '/TimesheetManagement'
  },
  UserGroups: {
    component: UserGroups,
    path: '/UserGroups'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  OrganisationHierarchy: {
    component: OrganisationHierarchy,
    path: '/OrganisationHierarchy'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  TaxRates: {
    component: TaxRates,
    path: '/TaxRates'
  },
  OrganizationProfile: {
    component: OrganizationProfile,
    path: '/OrganizationProfile'
  },
  DashboardCharts: {
    component: DashboardCharts,
    path: '/DashboardCharts'
  },
  CvresumeCandidateManagement: {
    component: CvresumeCandidateManagement,
    path: '/CvresumeCandidateManagement'
  },
  TaxCalculator: {
    component: TaxCalculator,
    path: '/TaxCalculator'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  PeopleManagement: {
    component: PeopleManagement,
    path: '/PeopleManagement'
  },
  SummaryCard: {
    component: SummaryCard,
    path: '/SummaryCard'
  },
  GraphicalCharts: {
    component: GraphicalCharts,
    path: '/GraphicalCharts'
  },
  BreadcrumbNavigation: {
    component: BreadcrumbNavigation,
    path: '/BreadcrumbNavigation'
  },
  PasswordProtectedPages: {
    component: PasswordProtectedPages,
    path: '/PasswordProtectedPages'
  },
  GstIntegration: {
    component: GstIntegration,
    path: '/GstIntegration'
  },
  GoalManagement: {
    component: GoalManagement,
    path: '/GoalManagement'
  },
  SingleSignonsso: {
    component: SingleSignonsso,
    path: '/SingleSignonsso'
  },
  AdvancedSearch2: {
    component: AdvancedSearch2,
    path: '/AdvancedSearch2'
  },
  SpendAnalysis: {
    component: SpendAnalysis,
    path: '/SpendAnalysis'
  },
  BudgetingForecasting: {
    component: BudgetingForecasting,
    path: '/BudgetingForecasting'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  PayrollIntegration: {
    component: PayrollIntegration,
    path: '/PayrollIntegration'
  },
  CfStripeGatewayIntegration: {
    component: CfStripeGatewayIntegration,
    path: '/CfStripeGatewayIntegration'
  },
  TabletSupport: {
    component: TabletSupport,
    path: '/TabletSupport'
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals'
  },
  MultilevelApproval: {
    component: MultilevelApproval,
    path: '/MultilevelApproval'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  PerformanceTracker: {
    component: PerformanceTracker,
    path: '/PerformanceTracker'
  },
  LeaveTracker: {
    component: LeaveTracker,
    path: '/LeaveTracker'
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: '/PaymentAdmin'
  },
  FeedbackCollection: {
    component: FeedbackCollection,
    path: '/FeedbackCollection'
  },
  CentralisedBilling: {
    component: CentralisedBilling,
    path: '/CentralisedBilling'
  },
  JobListing: {
    component: JobListing,
    path: '/JobListing'
  },
  AcceptPrepayments: {
    component: AcceptPrepayments,
    path: '/AcceptPrepayments'
  },
  AutomaticReminders: {
    component: AutomaticReminders,
    path: '/AutomaticReminders'
  },
  DataEncryption: {
    component: DataEncryption,
    path: '/DataEncryption'
  },
  TeamBuilder: {
    component: TeamBuilder,
    path: '/TeamBuilder'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  EmployeeLogin: {
    component: EmployeeLogin,
    path: '/EmployeeLogin'
  },
  AccountTransfer: {
    component: AccountTransfer,
    path: '/AccountTransfer'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  AdHocReporting: {
    component: AdHocReporting,
    path: '/AdHocReporting'
  }, 
  FeeReceivables: {
    component: FeeReceivables,
    path: '/FeeReceivables'
  },  
  ConcessionReport: {
    component: ConcessionReport,
    path: '/ConcessionReport'
  },
  FeePaymentDue: {
    component: FeePaymentDue,
    path: '/FeePaymentDue'
  },
  
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  ExpenseTracking: {
    component: ExpenseTracking,
    path: '/ExpenseTracking'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  TaxRates: {
    component: TaxRates,
    path: '/TaxRates'
  },
  OrganizationProfile: {
    component: OrganizationProfile,
    path: '/OrganizationProfile'
  },
  GSTSettings: {
    component: GSTSettings,
    path: '/GSTSettings'
  },
  Concession: {
    component: Concession,
    path: '/Concession'
  },
  Users: {
    component: Users,
    path: '/Users'
  },
  Customer: {
    component: CustomerList,
    path: '/Customers'
  },
  Account: {
    component: Account,
    path: '/Account'
  },
  Fee: {
    component: Fee,
    path: '/Fee'
  },
  Fine: {
    component: Fine,
    path: '/Fine'
  },
  CreateFine: {
    component: CreateFine,
    path: '/CreateFine'
  },
  EditFine: {
    component: CreateFine,
    path: '/EditFine'
  },
  FeeAssignment: {
    component: FeeAssignment,
    path: '/FeeAssignment'
  },
  ViewFine: {
    component: CreateFine,
    path: '/ViewFine'
  },
  FeeCollectionList: {
    component: FeeCollectionList2,
    path: '/FeeCollectionList'
  },
  Expense: {
    component: Expense,
    path: '/Expense'
  },
  CreateExpense: {
    component: CreateUpdateExpense,
    path: '/CreateExpense'
  },
  EditExpense: {
    component: CreateUpdateExpense,
    path: '/EditExpense'
  },
  ViewExpense: {
    component: CreateUpdateExpense,
    path: '/ViewExpense'
  },
  Vendors: {
    component: Vendors,
    path: '/Vendors'
  },
  PaymentPreference: {
    component: PaymentPreference,
    path: '/PaymentPreference'
  },
  RecurringExpenses: {
    component: RecurringExpenseList,
    path: '/RecurringExpenses'
  },
  Items: {
    component: ItemList,
    path: '/Items'
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    const defaulttheme = createMuiTheme({
      typography: {
        fontFamily: 'open sans'
      },
      overrides: {
        MuiFormLabel: {
          root: {
            color: 'black',
            fontWeight: 600
          }
        },
        MuiTab: {
          textColorSecondary: {
            color: 'black'
          }
        },
        MuiTableHead: {
          root: {
            backgroundColor: '#fcf3d4'
          }
        },
        MuiTableCell: {
          root: {
            padding: 6
          },
          head: {
            fontSize: 16,
            color: 'black',
            fontWeight: 600
          },
          body: {
            color: '#3f5371'
          }
        },
        MuiFormLabel: {
          root: {
            '&.Mui-focused': {
              color: 'none'
            }
          }
        },
        // MuiPopover:{
        //   root: {
        //     // height:"30vh",
        //     top:"450px",
        //   }
        // },

        MuiMenuItem: {
          // For ListItem, change this to MuiListItem
          root: {
            // paddingRight:"0px",
            '&$selected': {
              backgroundColor: '#ea504f',
              color: 'white',
              '&:hover': {
                backgroundColor: '#f9bebe',
                color: 'black'
              }
            },
            '&:hover': {
              backgroundColor: '#f9bebe',
              color: 'white'
            }
          }
        },
        MuiSelect: {
          select: {
            '&:focus': {
              borderRadius: '4px'
            }
          },
          selectMenu: {
            color: '#556479',
            fontWeight: 600
          }
        },
        MuiFormLabel: {
          root: {
            '&.Mui-focused': {
              color: 'rgba(0, 0, 0, 0.54)'
            },
            color: '#44556c',
            fontWeight: 600
          }
        },
        MuiCheckbox: {
          colorSecondary: {
            '&.Mui-checked': {
              color: '#ea5157'
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.38)'
            }
          }
        },
        MuiOutlinedInput: {
          notchedOutline: {
            // borderColor: "transparent"
          },
          root: {
            '&.Mui-disabled': {
              border: 'none !important'
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#c4c4c4'
              // border: '1px solid #c4c4c4!important'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#c4c4c4'
              // border: "none"
              // border: '0.px !important',
            }
          }

          // input:{
          //   borderRadius: "5px"
          // }
        },
        MuiFormControl: {
          root: {
            borderRadius: '5px'
          }
        }
      },
      MuiInput:{
        root:{
          border:"1px solid red"
        }
      }
    });
    return (
      <MuiThemeProvider theme={defaulttheme}>
        <View style={{ height: '100vh', width: '100vw' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
          <ToastContainer />
        </View>
      </MuiThemeProvider>
    );
  }
}

export default App;
