import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import { CChart } from "@coreui/react-chartjs";
import {
  bulkUpdateIcon,
  currentFeeIcon,
  feeOutstandingIcon,
  feeReceivableIcon,
  feeReceivedIcon
} from "./assets";

import DashboardChartsController, {
  FormMode,
  Props
} from "./DashboardChartsController";

import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Layout from "../../CommonLayout/Layout/src/Layout.web";

import "./index.css";
import { Typography } from "@material-ui/core";
import Filter from "./Filter.web";

// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 170px)",
    maxHeight: "calc(100vh - 170px)",
    display: "block",
    flexWrap: "nowrap",
    // boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10,
    overflow: "auto",
    padding: "10px"
  },
  chartContainer: {
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10,
    padding: 20
  },
  cardContainer: {
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10,
    padding: 15
  },
  cardTitle: {
    marginLeft: "5px",
    color: "#474F5F"
  },
  cardImage: {
    width: "26px",
    height: "26px",
    objectFit: "contain",
    background: "#FCF6D0",
    borderRadius: "5px",
    padding: "5px"
  },
  currentDot: {
    width: "10px",
    height: "10px",
    alignSelf: "center",
    background: "#F25B5E",
    display: "flex",
    marginRight: "10px",
    borderRadius: "5px"
  },
  dot: {
    width: "10px",
    height: "10px",
    alignSelf: "center",
    display: "flex",
    marginRight: "10px",
    borderRadius: "5px"
  },
  previousDot: {
    width: "10px",
    height: "10px",
    alignSelf: "center",
    background: "#CDCDD4",
    display: "flex",
    marginRight: "10px",
    borderRadius: "5px"
  },
  outstandingDot: {
    width: "10px",
    height: "10px",
    alignSelf: "center",
    background: "#E79418",
    display: "flex",
    marginRight: "10px",
    borderRadius: "5px"
  },
  lineYearsContainer: {
    borderRight: "solid rgb(204, 203, 203)",
    marginTop: "15px",
    paddingTop: "10px",
    paddingRight: "15px",
    paddingBottom: "10px"
  },
  lineTotalsContainer: {
    display: "flex",
    marginTop: "15px",
    marginLeft: "15px",
    paddingTop: "5px"
  },
  totalTitle: {
    fontWeight: 600,
    fontSize: "larger"
  },
  lineTotal: {
    fontSize: "x-large",
    color: "rgb(64, 77, 107)",
    fontWeight: 600
  },
  doughnutLegendTitle: {
    fontWeight: 500,
    marginRight: 4
  },
  doughnutLegendContainer: { display: "flex", alignItems: "center" },
  formContainer: {
    position: "relative",
    // boxShadow: '0px 5px 10px 0px #d9d9d9',
    // borderRadius: 10,
    // maxHeight: "70vh",
    marginBottom: 10,
    padding: "10px",
    overflow: "auto"
  },
  doughnutTitle: {
    zIndex: 1,
    position: "relative",
    marginTop: "-54%",
    width: "100%",
    textAlign: "center",
    color: "#8E929C",
    fontSize: "x-large"
  },
  chartTitle: {
    fontSize: "x-large",
    fontWeight: 600,
    marginBottom: 30
  }
});
// Customizable Area End

class DashboardCharts extends DashboardChartsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;

    // const renderPopOver = ({ opened, filter, anchor, onSubmit, onClose }) => (
    //   <Popover
    //     open={anchor}
    //     anchorEl={anchor}
    //     onClose={onClose}
    //     anchorOrigin={{
    //       vertical: "bottom",
    //       horizontal: "right"
    //     }}
    //     transformOrigin={{
    //       vertical: "top",
    //       horizontal: "right"
    //     }}
    //   >
    //     <Grid container direction="column" style={{ width: 410, padding: 16 }}>
    //       <Grid item className={classes.filterTitle}>
    //         <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
    //           Filters
    //         </Typography>
    //       </Grid>
    //       {/* <Grid item>{filterForm}</Grid> */}
    //       <Grid item className={classes.actionButtons}>
    //         <Button
    //           title="Cancel"
    //           buttonType={ButtonType.Cancel}
    //           onClick={onClose}
    //         />
    //         <Button
    //           title="Apply"
    //           buttonType={ButtonType.Primary}
    //           onClick={onSubmit}
    //         />
    //       </Grid>
    //     </Grid>
    //   </Popover>
    // );

    const renderCard = (props: {
      title: string;
      field1: { title: string; number: string; color: string };
      field2?: { title: string; number: string; color: string };
      progressRatio?: number;
      src: any;
    }) => (
      <div className={classes.cardContainer}>
        <div style={{ display: "flex", alignItems: "center", height: "50px" }}>
          <img src={props.src} className={classes.cardImage} />
          <div className={classes.cardTitle}>{props.title}</div>
        </div>
        <div style={{ display: "flex", alignItems: "center", height: "50px" }}>
          {props.progressRatio ? (
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  zIndex: 2,
                  width: (props.progressRatio || 0) + "%",
                  height: "12px",
                  background: "#3DBB3D",
                  borderRadius: "5px"
                }}
              />
              <div
                style={{
                  width: 100 - (props.progressRatio || 0) + 4 + "%",
                  height: "12px",
                  background: "#F47C7C",
                  borderRadius: "5px",
                  marginLeft: "-4%"
                }}
              />
            </div>
          ) : (
            <div style={{ width: "100%", border: "solid lightgray 1px" }} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "50px",
            width: "100%"
          }}
        >
          <div style={{ width: "50%" }}>
            <div style={{ color: props.field1.color }}>
              {props.field1.title}
            </div>
            <div style={{ marginTop: 5 }}>₹{props.field1.number}</div>
          </div>
          {props.field2 && (
            <div
              style={{
                width: "50%",
                borderLeft: "solid lightgray 1px",
                paddingLeft: "15px"
              }}
            >
              <div style={{ color: props.field2.color }}>
                {props.field2.title}
              </div>
              <div style={{ marginTop: 5 }}>₹{props.field2.number}</div>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <Layout navigation={navigation} hideSearchBar>
        <Grid container className={classes.container}>
          <PageHeader
            title="Dashboard"
            haveFilterButton={false}
            haveCSVButton={false}
            havePrintButton={false}
            haveCreateButton={false}
          />
          <div className={classes.formContainer}>
            <Grid container direction="column">
              <Grid
                container
                direction="row"
                xs={12}
                sm={12}
                style={{ marginTop: 20 }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  <div style={{ width: "23%" }}>
                    {renderCard({
                      title: "Current Date Fee Collection",
                      field1: {
                        title: "Received",
                        number: "0.00",
                        color: "#3DBB3D"
                      },
                      field2: {
                        title: "Receivables",
                        number: "500.00",
                        color: "#F47C7C"
                      },
                      progressRatio: 40,
                      src: currentFeeIcon
                    })}
                  </div>
                  <div style={{ width: "23%" }}>
                    {renderCard({
                      title: "Fee Receivables",
                      field1: {
                        title: "Receivables",
                        number: "500.00",
                        color: "#F47C7C"
                      },
                      src: feeReceivableIcon
                    })}
                  </div>
                  <div style={{ width: "23%" }}>
                    {renderCard({
                      title: "Fee Received",
                      field1: {
                        title: "Received",
                        number: "0.00",
                        color: "#3DBB3D"
                      },
                      src: feeReceivedIcon
                    })}
                  </div>
                  <div style={{ width: "23%" }}>
                    {renderCard({
                      title: "Fee Outstanding",
                      field1: {
                        title: "Outstanding",
                        number: "500.00",
                        color: "#F47C7C"
                      },
                      src: feeOutstandingIcon
                    })}
                  </div>
                </div>
              </Grid>
              <Grid
                container
                xs={12}
                sm={12}
                style={{ marginTop: 20 }}
                className={classes.chartContainer}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className={classes.chartTitle}>
                    Fee Receivable, Fee Received and Outstanding
                  </div>
                  <div>
                    <Button
                      variant="text"
                      endIcon={<DownIcon />}
                      style={{
                        textTransform: "none",
                        color: "#475168",
                        fontSize: "18px"
                      }}
                      onClick={e => {
                        this.setState({
                          anchorEl: e.currentTarget,
                          filterVisible: true
                        });
                      }}
                    >
                      Last 12 Months
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <CChart
                    options={{
                      plugins: {
                        legend: {
                          display: false
                        }
                      }
                    }}
                    type="bar"
                    data={{
                      labels: [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July"
                      ],
                      datasets: [
                        {
                          label: "Receivable",
                          backgroundColor: "#CDCDD4",
                          data: [
                            40000,
                            20000,
                            12000,
                            39000,
                            10000,
                            40000,
                            33333
                          ]
                        },
                        {
                          label: "Received",
                          backgroundColor: "#F25B5E",
                          data: [
                            15000,
                            33000,
                            22000,
                            55000,
                            12000,
                            10000,
                            33333
                          ]
                        },
                        {
                          label: "Outstanding",
                          backgroundColor: "#E79418",
                          data: [
                            15000,
                            13000,
                            42000,
                            35000,
                            62000,
                            70000,
                            33333
                          ]
                        }
                      ]
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div
                      style={{
                        marginLeft: 60,
                        marginRight: 60,
                        marginTop: "40%",
                        height: "50%",
                        color: "lightgray",
                        border: "solid 1px"
                      }}
                    />
                    <div style={{ marginTop: 60 }}>
                      <div style={{ display: "flex" }}>
                        <div className={classes.currentDot} />
                        <div style={{ color: "#F25B5E" }}>Receivable</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className={classes.previousDot} />
                        <div style={{ color: "#CDCDD4" }}>Received</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className={classes.outstandingDot} />
                        <div style={{ color: "#E79418" }}>Outstandings</div>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <div
                          className={classes.totalTitle}
                          style={{ color: "#F25B5E" }}
                        >
                          Total Receivables
                        </div>
                        <div className={classes.lineTotal}>₹50.000</div>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <div
                          className={classes.totalTitle}
                          style={{ color: "#CDCDD4" }}
                        >
                          Total Received
                        </div>
                        <div className={classes.lineTotal}>₹150.000</div>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <div
                          className={classes.totalTitle}
                          style={{ color: "#EE8B13" }}
                        >
                          Total Outstandings
                        </div>
                        <div className={classes.lineTotal}>₹150.000</div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container sm={12} style={{ marginTop: 20 }}>
                <Grid item sm={8}>
                  <div
                    style={{
                      marginRight: "20px"
                    }}
                    className={classes.chartContainer}
                  >
                    <div style={{ borderBottom: "solid #CCCBCB" }}>
                      <div className={classes.chartTitle}>
                        Current Year V/S Previous Year
                      </div>
                      <CChart
                        options={{
                          plugins: {
                            legend: {
                              display: false
                            }
                          }
                        }}
                        type="line"
                        data={{
                          labels: [
                            "January ASD",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July"
                          ],
                          datasets: [
                            {
                              label: "Current Year",
                              backgroundColor: "rgba(220, 220, 220, 0.2)",
                              borderColor: "#F55A59",
                              pointBackgroundColor: "#F55A59",
                              pointBorderColor: "#F55A59",
                              data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
                              fill: "origin"
                            },
                            {
                              label: "Previous Year",
                              backgroundColor: "rgba(151, 187, 205, 0.2)",
                              borderColor: "#CDCDD4",
                              pointBackgroundColor: "rgba(151, 187, 205, 1)",
                              pointBorderColor: "#CDCDD4",
                              fill: "origin",
                              data: [50, 12, 28, 29, 7, 25, 12, 70, 60, 80, 90]
                            }
                          ]
                        }}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className={classes.lineYearsContainer}>
                        <div style={{ display: "flex" }}>
                          <div className={classes.currentDot} />
                          <div style={{ color: "#F25B5E" }}>Current Year</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className={classes.previousDot} />
                          <div style={{ color: "#CDCDD4" }}>Previous Year</div>
                        </div>
                      </div>
                      <div className={classes.lineTotalsContainer}>
                        <div>
                          <div
                            className={classes.totalTitle}
                            style={{ color: "#F25B5E" }}
                          >
                            Current Year
                          </div>
                          <div className={classes.lineTotal}>₹50.000</div>
                        </div>
                        <div style={{ marginLeft: "40px" }}>
                          <div
                            className={classes.totalTitle}
                            style={{ color: "#CDCDD4" }}
                          >
                            Previous Year
                          </div>
                          <div className={classes.lineTotal}>₹150.000</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={4} className={classes.chartContainer}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div className={classes.chartTitle}>Fee</div>
                      <div>
                        <Button
                          variant="text"
                          endIcon={<DownIcon />}
                          style={{
                            textTransform: "none",
                            color: "#475168",
                            fontSize: "18px"
                          }}
                          onClick={e => {
                            this.setState({
                              anchorEl: e.currentTarget,
                              filterVisible: true
                            });
                          }}
                        >
                          Last 12 Months
                        </Button>
                      </div>
                    </div>
                    <CChart
                      style={{ padding: 20 }}
                      type="doughnut"
                      options={
                        {
                          cutout: "90%",
                          plugins: {
                            legend: {
                              display: false
                            }
                          }
                        } as any
                      }
                      data={{
                        labels: [
                          "Admission Fee",
                          "Transportation Fee",
                          "Mess Fee",
                          "Tution Fee"
                        ],
                        datasets: [
                          {
                            backgroundColor: [
                              "#E79418",
                              "#F6595E",
                              "#959394",
                              "#3DBB3D"
                            ],
                            data: [46, 25, 25, 14]
                          }
                        ]
                      }}
                    />
                    <div className={classes.doughnutTitle}>Fee</div>
                  </div>
                  <div
                    style={{
                      zIndex: 1,
                      position: "relative",
                      marginTop: "50%",
                      width: "100%",
                      textAlign: "center"
                    }}
                  />
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "fit-content" }}>
                      <div className={classes.doughnutLegendContainer}>
                        <div
                          className={classes.dot}
                          style={{ background: "#E79418" }}
                        />
                        <div className={classes.doughnutLegendTitle}>
                          Admission Fee
                        </div>

                        <div style={{ color: "gray" }}>%46</div>
                      </div>
                      <div className={classes.doughnutLegendContainer}>
                        <div
                          className={classes.dot}
                          style={{ background: "#F6595E" }}
                        />
                        <div className={classes.doughnutLegendTitle}>
                          Transportation Fee
                        </div>
                        <div style={{ color: "gray" }}>%25</div>
                      </div>
                    </div>
                    <div style={{ width: "fit-content", marginLeft: "10px" }}>
                      <div className={classes.doughnutLegendContainer}>
                        <div
                          className={classes.dot}
                          style={{ background: "#959394" }}
                        />
                        <div className={classes.doughnutLegendTitle}>
                          Mess Fee
                        </div>

                        <div style={{ color: "gray" }}>%25</div>
                      </div>
                      <div className={classes.doughnutLegendContainer}>
                        <div
                          className={classes.dot}
                          style={{ background: "#3DBB3D" }}
                        />
                        <div className={classes.doughnutLegendTitle}>
                          Tution Fee
                        </div>
                        <div style={{ color: "gray" }}>%14</div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Filter
          anchor={this.state.anchorEl}
          visible={this.state.filterVisible}
          onSubmit={() => this.setState({ filterVisible: false })}
          onClose={() => this.setState({ filterVisible: false })}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area End
}

export default withStyles(useStyles)(DashboardCharts);
