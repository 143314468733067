Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdHocReporting";
exports.labelBodyText = "AdHocReporting Body";

exports.btnExampleTitle = "CLICK ME";
exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.PATCH = "PATCH";
exports.reportsManagementURL = urlConfig.baseURL;
exports.getReportBaseEndPoint = "bx_block_student";
// Customizable Area End