import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, actionIcon,percentageIcon,
  rupeeIcon,calenderIcon } from "./assets";
import moment from "moment";
import { toast } from "react-toastify";
import React from "react";
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import CustomImportModalController from "../../CommonLayout/CustomImportModal/src/CustomImportModalController";
import { debounce } from 'lodash';
import { string } from "yup";

const { baseURL } = require("../../../framework/src/config");


// Customizable Area End

let token: any;
export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  classes: any;
  // Customizable Area End
}
interface fee_concession_type {
  id: any;
  data:{
    attributes:{
      id:any,
      name:any
    }
  }
}

interface concessionAttribute {
  custom_id: number;
  name: string;
  fee_concession_type: fee_concession_type;
  valid_until: any;
  mode: string;
  amount:any;
}

interface concessionTypeAttribute {
  id:any;
  name: string;
  description:string;
}
interface concessionTypeFormAttribute {
  id:any
  fee_concession_type: string;
  description:string;
}

interface concessionTypeForm {
  concessionData:concessionTypeFormAttribute
 
}

interface InitialField {
  fee_concession_type_id: any,
  name: string,
  valid_until: string,
  mode: string,
  amount: any,
}
interface IFilterForm {
  valid_until: any;
  amount_start: any;
  amount_end: any;
  mode:string;
  fee_concession_type_id: string;
}
interface concessionData {
  id: any;
  attributes: concessionAttribute
}

interface concessionTypeData {
  id: any;
  attributes:concessionTypeAttribute
}
interface bulkUpdate {
  status: string,
  ids: [],
}

interface checkBulkRow {
  [key: number]: boolean;
}

interface S {
  // Customizable Area Start
  handleConcessionTypeEditmodal:any
  handleConcessionTypeDeletemodal:any
  submitLoading: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  handleConcessionTypemodal: boolean;
  enableField: boolean;
  filterAnchorEl: null,
  actionIcon: any;
  calenderIcon:any;
  filterModeError:any;
  percentageIcon:any;
  rupeeIcon:any;
  anchorEl: null;
  filterConcessionTypeError:string;
  importExportanchorEl: null;
  deleteModalOpen: boolean;
  createModalOpen: boolean;
  editModalOpen: boolean;
  viewModalOpen: boolean;
  copyModalOpen: boolean;
  bulkUpdateConcessionModalOpen: boolean;
  importRoleModalOpen: boolean;
  concessionData: concessionData | null;
  concessionList: any;
  concessionListMeta: any;
  page: number;
  roleId: number;
  ConcessionTypeList: [];
  parentAccountList: [];
  showLoader: boolean;
  concessionListerrorMessage: string;
  roleExporterrorMessage: string;
  concessionImporterrorMessage: string;
  concessionDetails: object | null;
  concessionDetailserrorMessage: string;
  roleSearcherrorMessage: string;
  concessionErrorMessage: InitialField;
  concessionCreateerrorMessage: string;
  accountUpdateNameerrorMessage: string;
  selectedConcessionItem: concessionData | undefined;
  concessionUpdateerrorMessage: string;
  bulkUpdateData: bulkUpdate | null;
  checkBulkUpdateRow: checkBulkRow | null;
  disableBukUpload: boolean;
  allRowsChecked: boolean | string;
  concessionbulkUpdateerrorMessage: string;
  concessionTypeCreateerrorMessage:string
  exportRoleData: any;
  filterDateError:any;
  searchKeyword: string;
  filterForm:IFilterForm;
  concessionTypeUpdateerrorMessage:any;
  selectedConcessionTypeItem:any;
  max:any;
  closeImportModal:boolean
  deleteMessage:string;
  // Customizable Area End
}
interface SS {
  id: any;
}
const concessionErrorInitialField: InitialField =
{
  fee_concession_type_id: "0", name: "", mode: "", amount: "",
  valid_until: "",
};
const filterInitialField: IFilterForm =
{
  valid_until: null,
  amount_start: 0,
  amount_end: 1000,
  fee_concession_type_id: '',
  mode:'all',
};

export default class ConcessionController extends BlockComponent<
  Props,
  S,
  SS
> {

  apiUpdateConcession: string = "";
  apiGetConcessionList: string = "";
  apiGetConcessionTypeList: string = "";
  apiAddConcession: string = "";
  apiAddConcessionType: string = "";
  apiDeleteConcessionType: string = "";
  apiUpdateConcessionType: string = "";
  apiDeleteConcession: string = "";
  apiGetConcessionDetails: string = "";
  apiFilterConcessionList: string = "";
  selectorRef: any = null;
  apiBulkUpdateConcession: string = "";
  apiGetConcessionSearchList: string = "";
  apiImportConcession: string = "";
  tableRef: any = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      filterModeError:"",
      max:20000,
      handleConcessionTypeEditmodal:false,
      handleConcessionTypeDeletemodal:false,
      handleConcessionTypemodal:false,
      filterConcessionTypeError:"",
      enableField: false,
      actionIcon: actionIcon,
      filterDateError:"",
      anchorEl: null,
      calenderIcon:calenderIcon,
      closeImportModal:false,
      percentageIcon:percentageIcon,
      rupeeIcon:rupeeIcon,
      importExportanchorEl: null,
      filterAnchorEl: null,
      deleteModalOpen: false,
      createModalOpen: false,
      concessionTypeUpdateerrorMessage:'',
      editModalOpen: false,
      viewModalOpen: false,
      filterForm: filterInitialField,
      copyModalOpen: false,
      bulkUpdateConcessionModalOpen: false,
      importRoleModalOpen: false,
      submitLoading: false,
      concessionData: null,
      concessionList: [],
      concessionListMeta: {},
      ConcessionTypeList: [],
      parentAccountList: [],
      concessionDetails: null,
      page: 0,
      showLoader: false,
      concessionListerrorMessage: '',
      selectedConcessionTypeItem:{},
      concessionDetailserrorMessage: '',
      roleExporterrorMessage: '',
      concessionImporterrorMessage: '',
      roleSearcherrorMessage: '',
      concessionCreateerrorMessage: '',
      concessionTypeCreateerrorMessage:"",
      accountUpdateNameerrorMessage: '',
      roleId: 0,
      selectedConcessionItem: undefined,
      concessionErrorMessage: concessionErrorInitialField,
      concessionUpdateerrorMessage: '',
      bulkUpdateData: null,
      checkBulkUpdateRow: null,
      disableBukUpload: true,
      allRowsChecked: false,
      concessionbulkUpdateerrorMessage: '',
      exportRoleData: '',
      searchKeyword: '',
      deleteMessage: 'multiple',
    };
    this.tableRef = React.createRef();
    this.selectorRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      
      if (apiRequestCallId === this.apiGetConcessionList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ concessionList: responseJson?.data, concessionListMeta: responseJson?.meta, searchKeyword: "" });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ concessionListerrorMessage: responseJson.error});
            }
        }
        setTimeout(() => this.setState({ showLoader: false }),500);
      // this.setState({ roleListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiImportConcession) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              toast.success("Account import successfully");
              this.getConcessionList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ concessionImporterrorMessage: responseJson.error});
            }
        }
        this.setState({ showLoader: false })
      } else if (apiRequestCallId === this.apiGetConcessionTypeList) {
        if (responseJson != null) {
         
            if (!responseJson.error && !responseJson.errors) {
              let concessionTypedata;
              const concessionTypeRes = responseJson?.data && responseJson?.data.map((item: concessionTypeData,index:number) => {
                return  concessionTypedata ={
                  "id": item.id,
                  "name": item.attributes.name,
                  "description":item.attributes.description
                }
              })
              this.setState({
                ConcessionTypeList: concessionTypeRes,

              });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
        }
        //this.setState({ roleMenuListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiFilterConcessionList) {
        if(responseJson.message){
          this.setState({ concessionListerrorMessage: responseJson.message});
        }
          if (responseJson !== null) {
            this.setState({
              concessionList: responseJson?.data || [],
              concessionListMeta: responseJson?.meta || {},
            });
            
          } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
            setTimeout(() => this.setState({ showLoader: false }),500);
            
            
        //this.setState({ roleMenuListerrorMessage: errorReponse});
      }else if (apiRequestCallId === this.apiAddConcession) {
        if (responseJson != null) {
            if (!responseJson.message && !responseJson.error && !responseJson.errors) {
              toast.success("Concession created successfully");
              this.setState({ createModalOpen: false });
              this.setState({ copyModalOpen: false });
              this.getConcessionList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }else if(responseJson.errors.name[0]){
                this.setState({ concessionErrorMessage:{ ...this.state.concessionErrorMessage, name :responseJson.errors.name[0]}});

              }else if(responseJson.errors.valid_until[0]){
                this.setState({ concessionErrorMessage:{ ...this.state.concessionErrorMessage, valid_until :responseJson.errors.valid_until[0]}});

              }else if(responseJson.message){
                this.setState({ concessionErrorMessage:{ ...this.state.concessionErrorMessage, amount :responseJson.message}});

              }else if(responseJson.errors.fee_concession_type[0]){
                this.setState({ concessionErrorMessage:{ ...this.state.concessionErrorMessage, amount :responseJson.errors.fee_concession_type[0]}});

              }else{
                this.setState({ concessionCreateerrorMessage: responseJson.message || responseJson.errors || responseJson.error});

              }
            }
        }
        this.setState({ showLoader: false })
      }else if (apiRequestCallId === this.apiAddConcessionType) {
        if (responseJson != null) {
            if (!responseJson.name_error && !responseJson.other_error && !responseJson.error && !responseJson.errors) {
              toast.success("Concession Type created successfully");
              this.getConcessionTypeList();
              this.setState({ handleConcessionTypemodal: false });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }else if(responseJson.errors.name[0]){
                this.setState({ concessionTypeCreateerrorMessage :responseJson.errors.name[0]});

              }else{
                this.setState({ concessionTypeCreateerrorMessage: responseJson.message});

              }
            }
        }
        this.setState({ showLoader: false })
      } else if (apiRequestCallId === this.apiUpdateConcessionType) {
        if (responseJson != null) {
            if (!responseJson.name_error && !responseJson.other_error && !responseJson.error && !responseJson.errors) {
              toast.success("Concession Type Updated successfully");
              this.getConcessionTypeList();
              this.setState({ handleConcessionTypeEditmodal: false });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }else if(responseJson.errors.name[0]){
                this.setState({ concessionErrorMessage:{ ...this.state.concessionErrorMessage, name :responseJson.errors.name[0]}});

              }else{
                this.setState({ concessionTypeUpdateerrorMessage: responseJson.message});

              }
            }
        }
        this.setState({ showLoader: false })
      }else if (apiRequestCallId === this.apiDeleteConcession) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              toast.success(responseJson.message);
              this.setState({ handleConcessionTypeDeletemodal: false });
              this.getConcessionList(this.state.page);
            
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
        }
        this.setState({ showLoader: false })
        //this.setState({ errorMessage: errorReponse});
      }else if (apiRequestCallId === this.apiDeleteConcessionType) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              toast.success(responseJson.message);
              this.getConcessionTypeList();
              this.setState({ handleConcessionTypeDeletemodal: false });
              
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
        }
        this.setState({ showLoader: false })
        //this.setState({ errorMessage: errorReponse});
      }else if (apiRequestCallId === this.apiGetConcessionDetails) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ concessionDetails: responseJson?.data, showLoader: false });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ concessionDetailserrorMessage: responseJson.error});
            }
        }
        this.setState({ showLoader: false })
        //this.setState({ roleDetailserrorMessage: errorReponse});
      // Customizable Area End
      }else if (apiRequestCallId === this.apiUpdateConcession) {
        if (responseJson != null) {
          if (!responseJson.name_error && !responseJson.other_error && !responseJson.error && !responseJson.errors) {
            toast.success(responseJson.meta.message);
              this.setState({ editModalOpen: false });
              this.getConcessionList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }else if(responseJson.errors.name[0]){
                this.setState({ concessionErrorMessage:{ ...this.state.concessionErrorMessage, name :responseJson.errors.name[0]}});

              }else if(responseJson.errors.valid_until[0]){
                this.setState({ concessionErrorMessage:{ ...this.state.concessionErrorMessage, valid_until :responseJson.errors.valid_until[0]}});

              }else if(responseJson.message){
                this.setState({ concessionErrorMessage:{ ...this.state.concessionErrorMessage, amount :responseJson.message}});

              }else if(responseJson.errors.fee_concession_type[0]){
                this.setState({ concessionErrorMessage:{ ...this.state.concessionErrorMessage, amount :responseJson.errors.fee_concession_type[0]}});

              }else{
                this.setState({ concessionUpdateerrorMessage: responseJson.message || responseJson.errors});

              }
            }
        }
        this.setState({ showLoader: false })
      }else if (apiRequestCallId === this.apiBulkUpdateConcession) {
        if (responseJson != null ) {
            if (!responseJson.error && !responseJson.errors) {
              toast.success(responseJson.meta.message);
              this.setState({ bulkUpdateConcessionModalOpen: false,checkBulkUpdateRow: null, disableBukUpload: true, allRowsChecked: false });
              this.getConcessionList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ concessionbulkUpdateerrorMessage: responseJson.error.name[0]});
            }
        }
        this.setState({ showLoader: false })
      }else if (apiRequestCallId === this.apiGetConcessionSearchList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ concessionList: responseJson.data ? responseJson.data : [], concessionListMeta: responseJson?.meta });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ concessionListerrorMessage: responseJson.error});
            }
        }
        setTimeout(() => this.setState({ showLoader: false }),300);
        // this.setState({ roleListerrorMessage: errorReponse});
      }
    }
  }

  onClickCreateConcession = () => {
    this.handleModalAction("create", true);
  }

  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    this.setState({searchKeyword: value});
    const debouncedSave = debounce((value) => {
      if(value !== ""){
        this.setState({page: 0});
        this.getSearchConcessionList(0,value);
      }else{
        this.getConcessionList(0);
      }
    }, 100);
		debouncedSave(value);
  }
  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  // importExportItem = [
  //   {
  //     label: "Import CSV",
  //     action: (item: IActionMenuItem) => this.handleExportImport(item)
  //   },
  //   {
  //     label: "Export CSV",
  //     action: (item: IActionMenuItem) => this.handleExportImport(item)
  //   }
  // ]

  async componentDidMount() {
    super.componentDidMount();
    this.getConcessionList(this.state.page);
    this.getConcessionTypeList();

  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleExportConcession = () => {
    token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token,
      },
    };
    fetch(`${configJSON.concessionURL}/${configJSON.getConcessionEndPoint}/fee_concessions/export`, requestOptions)
      .then((response) => {
        const file_sub_name = new Date().getTime()

        response.blob().then(blob => downloadCSV(blob, "concession_csv_"+file_sub_name))
      })
      .catch((error) => {
        toast.success(error.message);
        });
}
  handleAnchorClick = (event: any, row: concessionData) => {
    this.setState({ anchorEl: event.currentTarget, selectedConcessionItem: row });
  };

  handleAnchorClose = () => {
    this.setState({ anchorEl: null });
  };

  handleExportImportAnchorClick = (event: any) => {
    this.setState({ importExportanchorEl: event.currentTarget });
  };

  handleFilterAnchorClick = (event: any) => {
    this.setState({ filterAnchorEl: event.currentTarget });
  };

  handleFilterAnchorClose = () => {
    this.setState({ filterAnchorEl: null });
  };

  handleModalAction = (type: string, action: boolean) => {
    this.setState({ concessionErrorMessage: concessionErrorInitialField });
    this.setState({ concessionCreateerrorMessage: '' });
    this.setState({ concessionTypeCreateerrorMessage: '' });
    this.setState({ concessionUpdateerrorMessage: '' });
    this.setState({ accountUpdateNameerrorMessage: '' });

    const typeArr = ["view","edit","copy"];
    if(typeArr.includes(type)){
      if(!action){
        this.setState({ concessionDetails: null, selectedConcessionItem: undefined});
      }else{
        if(this.state.selectedConcessionItem){
          const newState = {[this.state.selectedConcessionItem?.id as string]: true};
          const disableBukUpload = this.bulkUpdateDisable(newState);
          this.setState({checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: "indeterminate" });
        }
        this.getConcessionDetails(this.state.selectedConcessionItem ? this.state.selectedConcessionItem?.id : 0);
      }
    }
    this.handleAnchorClose();
    if (type === "create") {
      this.setState({ createModalOpen: action });
    }else if (type === "delete") {
     /* Handle checbox before delete modal open start */
      const ids = Object.keys(this.state.checkBulkUpdateRow!== null && this.state.checkBulkUpdateRow).filter((item: any) => this.state.checkBulkUpdateRow && this.state.checkBulkUpdateRow[item])
      if(!ids.includes(this.state.selectedConcessionItem?.id as string)){
        const newState = {[this.state.selectedConcessionItem?.id as string]: true};
        const disableBukUpload = this.bulkUpdateDisable(newState);
        this.setState({deleteMessage: 'single',checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: "indeterminate" });
      }else{
        this.setState({deleteMessage: ids.length>1 ? 'multiple':'single'});
      }
      /* Handle checbox before delete modal open end */
      this.setState({ deleteModalOpen: action, concessionData: this.state.selectedConcessionItem ?this.state.selectedConcessionItem : null });
    }
     else if (type === "edit") {
      this.setState({ editModalOpen: action, concessionData: this.state.selectedConcessionItem ?this.state.selectedConcessionItem : null });
    } 
    else if (type === "copy") {
      this.setState({ copyModalOpen: action, concessionData: this.state.selectedConcessionItem ? this.state.selectedConcessionItem : null });
    } 
    else if (type === "bulkUpdate") {
      this.setState({ bulkUpdateConcessionModalOpen: action });
   }
     else {
      this.setState({ viewModalOpen: action, concessionData: this.state.selectedConcessionItem ?this.state.selectedConcessionItem : null });
    }
    return null;
  }

  handleModalFormSubmit = (formData: any) => {
    if(!formData.concessionData) return null;
    if(formData.type && formData.type == "bulkUpdate"){
      const ids = Object.keys(formData.concessionData).filter((item: string) => formData.concessionData[item])
      this.bulkUpdateAccount(ids,formData.concession);
    }else{
      if(formData.concessionData){
        this.setState({ submitLoading: true });
        const concessionPostData = { 
          fee_concession_type_id: formData.concessionData.fee_concession_type_id,
          name: formData.concessionData.name.trim(),
          amount: formData.concessionData.amount,
          mode: formData.concessionData.mode,
          valid_until:moment(formData.concessionData.valid_until).format("DD-MM-YYYY") 
        }
        if(formData.type == "update"){
          if(this.state.selectedConcessionItem?.id){
            const con_id = this.state.selectedConcessionItem?.id ;
            this.updateConcession(concessionPostData,con_id);
          }
          else{
            return null;
          }
        }else{
          this.addConcession(concessionPostData);
        }
      }
    }  
  }

  handleDeleteModalClose = () => {
    this.setState({ deleteModalOpen: false });
  }

  public handleFilterSliderRange(event: any, value: Array<number> | number) {
    if (Array.isArray(value)) {
      this.setState({
        filterForm: {
          ...this.state.filterForm,
          amount_start: value[0],
          amount_end: value[1]
        }
      });
    }
  }

onChangeFilterConcessionType=(event: any)=> {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        fee_concession_type_id: event.target.value
      }
    });
  }

onChangeFilterMode=(event: any)=> {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        mode: event.target.value
      }
    });
    if(event.target.value === "percentage"){
      
      this.setState({ max: 100});
      }else{
        this.setState({ max: 20000});
      }
  }
//  onChangeFilterDate=(event: any)=>  {
//     this.setState({
//       filterForm: {
//         ...this.state.filterForm,
//         valid_until: event.target.value
//       }
//     });
//   }
  handleDateChange=(value:any)=>{
    let value1= moment(value).format("DD-MM-YYYY")
      this.setState({
        filterForm: {
          ...this.state.filterForm,
          valid_until:value
        }
      });
  
  }
  handleValidation = (form: IFilterForm) => {
    let isValid = true;
    if (form.fee_concession_type_id === "-1" ||form.fee_concession_type_id === "0"||form.fee_concession_type_id === "-1" || !form.fee_concession_type_id ) {
        this.setState({ filterConcessionTypeError:("Role is required")
        });
        isValid = false;
    }else {
      this.setState({ filterConcessionTypeError: ''});
      }
    
    if (!form.valid_until ) {
      this.setState({ filterDateError:("Status is required")  });
        isValid = false;
    } else {
      this.setState({ filterDateError: ''});
      }
      if (form.mode === "" ) {
        this.setState({ filterModeError:("Status is required")  });
          isValid = false;
      }else {
        this.setState({ filterModeError: ''});
        }

    return isValid;
};
handleConcessionTypeEditmodalOpen = (row: concessionTypeData) => {
  this.setState({ handleConcessionTypeEditmodal: true ,selectedConcessionTypeItem:row});
};

handleConcessionTypeEditmodalClose = () => {
  this.setState({ handleConcessionTypeEditmodal: false });
};
handleConcessionTypeDeletemodalClose = () => {
  this.setState({ handleConcessionTypeDeletemodal: false });
};
handleConcessionTypeDeletemodalOpen = (row: concessionTypeData) => {
  this.setState({ handleConcessionTypeDeletemodal: true ,selectedConcessionTypeItem:row});
};
handleConcessionTypemodalOpen = () => {
  this.setState({ handleConcessionTypemodal: true });
};

handleConcessionTypemodalClose = () => {
  this.setState({ handleConcessionTypemodal: false,concessionTypeCreateerrorMessage:'' });
};

 onFilterFormSubmit= ()=> {
    //  e.preventDefault();
    // if(this.handleValidation(this.state.filterForm))
    // {
      this.filterConcessionCall(this.state.filterForm);
    }
  // }

  handlePageClick = (event: any) => {
    this.setState({page: event.selected});
    this.tableRef.current.scrollIntoView()
    if(this.state.searchKeyword !== ""){
    this.getSearchConcessionList(event.selected, this.state.searchKeyword);
    }else{
      this.getConcessionList(event.selected);
    }
  };

  handleModalFormDelete = () => {
    this.setState({ deleteModalOpen: false });
    // if(this.state.concessionData && this.state.concessionData.id ){
      // const account_id = this.state.concessionData.id;
      // this.deleteConcession(account_id);
    // }
    const ids = Object.keys(this.state.checkBulkUpdateRow!== null && this.state.checkBulkUpdateRow).filter((item: any) => this.state.checkBulkUpdateRow && this.state.checkBulkUpdateRow[item])
    const newIds = ids.join(",");
    if(ids.includes(this.state.selectedConcessionItem?.id as string)){
      if(ids.length){
        this.deleteConcession(newIds);
      }
    }else{
     this.deleteConcession(this.state.selectedConcessionItem?.id as string);
    }
    return null;
  }
  bulkUpdateAccount = (Ids: string[],concessionData: any) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    // this.bulkUpdateAccount(ids,concessionData,);
    const data= {
        data: {
          ids: [Ids],
          ...(concessionData.valid_until && {valid_until: moment(concessionData.valid_until).format("DD-MM-YYYY")}),
          ...(concessionData.mode && {mode: concessionData.mode}),
        ...(concessionData.fee_concession_type_id && {fee_concession_type_id: concessionData.fee_concession_type_id})
      }}
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiBulkUpdateConcession = requestMessage.messageId;
    let apiEndPoint = configJSON.getConcessionEndPoint+ `/fee_concessions/bulk_update`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // private filterUsersCall(form: IFilterForm) {
  //   this.RequestMessage.FilterUsers.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     `${configJSON.UsersEndPoint}/filter?${this.getFilterQuery(form)}`
  //   );

  //   runEngine.sendMessage(this.RequestMessage.FilterUsers.id, this.RequestMessage.FilterUsers);
  // }
  getFilterQuery=(form: IFilterForm)=> {
   
    return Object.entries(form)
      .filter(([key, value]) => value !== "" && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  filterConcessionCall = (form: IFilterForm) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiFilterConcessionList = requestMessage.messageId
    let QueryParams = '';
    if(form.amount_start >= 0){
      QueryParams += `?amount_start=${form.amount_start}`;
    }
    if(form.amount_end){
      let prifix = QueryParams ? '&' : '?';
      QueryParams += `${prifix}amount_end=${form.amount_end}`;
    }

    if(form.fee_concession_type_id && form.fee_concession_type_id !== "null"){
      let prifix = QueryParams ? '&' : '?';
      QueryParams += `${prifix}fee_concession_type_id=${form.fee_concession_type_id}`;
    }
    if(form.valid_until){
      let prifix = QueryParams ? '&' : '?';
      QueryParams += `${prifix}valid_until=${moment(form.valid_until).format("DD-MM-YYYY")}`;
    }
    if(form.mode){
      let prifix = QueryParams ? '&' : '?';
      QueryParams += `${prifix}mode=${form.mode}`;
    }  
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concessions/filter${QueryParams}`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 
  getConcessionList = (page: number) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetConcessionList = requestMessage.messageId
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concessions?page=${page + 1}`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getConcessionDetails = (id: number) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetConcessionDetails = requestMessage.messageId
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concessions/${id}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateConcession = (concessionData: object,conId: number) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data = {
      data: {...concessionData}
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateConcession = requestMessage.messageId;
    let apiEndPoint = configJSON.getConcessionEndPoint+ `/fee_concessions/${conId }`;
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteConcession = (accId: string) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteConcession = requestMessage.messageId;
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concessions/bulk_destroy?ids=${accId}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  deleteConcessionType = () => {
   
    let token = localStorage.getItem("token");
    let id=this.state.selectedConcessionTypeItem.id
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteConcessionType = requestMessage.messageId;
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concession_types/${id}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getConcessionTypeList = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetConcessionTypeList = requestMessage.messageId;
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concession_types`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  
  addConcession = (concessionData: object) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data =  {
      data:{ ...concessionData}}
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddConcession = requestMessage.messageId;
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concessions`;
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
   
  addConcessionType = (concessionData: concessionTypeForm) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data =  {
                    name :concessionData.concessionData.fee_concession_type,
                    description:concessionData.concessionData.description,
                  }
                 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddConcessionType = requestMessage.messageId;
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concession_types`;
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  updateConcessionType = (concessionTypeData: any) => {
    let id=concessionTypeData.row.id
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data =  {
                    name :concessionTypeData.concessionTypeData.fee_concession_type,
                    description:concessionTypeData.concessionTypeData.description,
                  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateConcessionType = requestMessage.messageId;
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concession_types/${id}`;
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  handleCheckAccountItem = (evt: React.FormEvent<HTMLInputElement>, id: number) => {
    const { checkBulkUpdateRow } = this.state;
    const newState = {...checkBulkUpdateRow, [id]: evt.currentTarget.checked};
    const disableBukUpload = this.bulkUpdateDisable(newState);
    let allRowsChecked = false;
    let checkAnyTrue = false;
    const trueCount = Object.keys(newState).filter((itm: string) => newState[parseInt(itm)]).length;
    if(trueCount === this.state.concessionList.length){
      allRowsChecked = true;
    }else{
      checkAnyTrue = (trueCount > 0)?true:false;
    }

    this.setState({checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: (checkAnyTrue) ? "indeterminate" : allRowsChecked });
  };

  handleCheckAllAccount = (evt: React.FormEvent<HTMLInputElement>) => {
    if(this.state.concessionList){
      const checkAccount: checkBulkRow = {};
      this.state.concessionList.map((item: concessionData) => {
        checkAccount[item.id] = evt.currentTarget.checked;
      });
   
      this.setState({checkBulkUpdateRow: checkAccount, allRowsChecked: evt.currentTarget.checked, disableBukUpload: !evt.currentTarget.checked });
    }
  };

  bulkUpdateDisable = (rows: checkBulkRow) => {
    let hasTrueKeys = true;
    if(rows && Object.keys(rows).length){
      hasTrueKeys = Object.keys(rows).some((key: string) => rows[parseInt(key)]);
    }
    return !hasTrueKeys;
  };
  getSearchConcessionList = (page: number,searchKey: string) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetConcessionSearchList = requestMessage.messageId    
    let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concessions/search?name=${searchKey}`
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.concessionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  importConcessionData = (file: File) => {

  const formData = new FormData();
  formData.append("file", file);
  token = localStorage.getItem("token");
  
  const header = {
    "Content-Type": configJSON.APIContentType,
    token: localStorage.getItem("token"),
  };
  
  const headerData =  JSON.stringify(header);
   const requestOptions = {
      method: "POST",
      headers: JSON.parse(headerData),
      body: formData
    };
  fetch(`${configJSON.concessionURL}/${configJSON.getConcessionEndPoint}/fee_concessions/import`, requestOptions)
  .then((response) => {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json();
    } else {
      return response.blob();
    }
  })
  .then((response) => {
    if(response instanceof Blob){
      const file_sub_name = new Date().getTime();
      downloadCSV(response, "concession_invalid_csv_"+file_sub_name);
      this.setState({ closeImportModal: true }, () => {
        this.setState({ closeImportModal: false });
      });
    }else{
      if(response.message){
        toast.success(response.message);
        this.setState({ closeImportModal: true }, () => {
          this.setState({ closeImportModal: false });
        });
        this.getConcessionList(this.state.page);
      }else if(response?.errors && response?.errors[0]?.token == "Invalid token"){
          localStorage.removeItem("token");
          this.props.navigation.navigate("EmailAccountLoginBlock");
      }else{
        this.setState({ concessionImporterrorMessage: response.common_error});
      }
    }
  })
  .catch((error) => {
    toast.error(error?.message ? error.message : error);
  });
}
  // importConcessionData = (file: any) => {
  //   token = localStorage.getItem("token");
  //   const header = {
  //     "Content-Type": configJSON.dashboarContentType,
  //     token,
  //   };
  //   var FormData = require('form-data');
  //   var bodyFormData = new FormData();
  //   bodyFormData.append("file", file)
     
  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   this.apiImportConcession = requestMessage.messageId;
  //   let apiEndPoint = configJSON.getConcessionEndPoint + `/fee_concessions/import`
  //   this.setState({ showLoader: true });
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     apiEndPoint
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestbaseURLMessage),
  //     configJSON.concessionURL
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     bodyFormData
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.POST
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  //   return true;
  // }
  handleDownloadCSVFile = () => {
    token = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      };
      fetch(`${configJSON.concessionURL}/bx_block_fee/fee_concessions/csv_sample_file`, requestOptions)
        .then((response) => {
          const file_sub_name = new Date().getTime()
          response.blob().then(blob => downloadCSV(blob, "concession_csv_sample_"+file_sub_name))
        })
        .catch((error) => {
          toast.success(error.message);
          });
  }
  // Customizable Area End

}