import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import { defaultBtn } from "../../CommonLayout/Layout/src/color";
import AdHocReportingController, {
  Props,
} from "./AdHocReportingController";
import { capitalizeFirst } from "../../../framework/src/Utilities";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import CommonCardCount from "./CommonCardCount";

// Customizable Area End

// Customizable Area Start

interface concessionReport {
  admission: number;
  student: string;
  class: string;
  division: string;
  fee_name: string;
  amount: string;
  concession: string;
  concession_amount:string;
  concession_given_by: string;
  total_amount: string;
}
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  searchbar: {
    width: "85%",
    marginLeft: "139px",
  },
  gridContainer: {
    marginTop: "95px",
    marginRight: "8px",
    marginBottom: "20px"
  }, menu: {
    marginLeft: "-25px",
    marginTop: "15px",
    width:"100%",
    "& .MuiPaper-root": {
      overflow: "visible",
    }
  },
  pageTitle: {
    fontWeight: "bold"
  },
  actionBtn: {
    marginRight: "8px",
    borderRadius: "8px",
  },
  featureBtn: {
    borderRadius: "5px",
    border: `1px solid #ffb2b2`,
    backgroundColor: "#ffffff",
    margin: "10px",
    padding: "18px",
    '&:hover': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      color: "white",
      opacity: "1",
    },
    '&:active': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      opacity: "1",
      boxShadow: 'none',
      color: "white",
    }, '&:focus': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
    },
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    // height: "calc(100vh - 381px) !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableGrid: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap",

  },
  heading: {
    width: "81%",
    backgroundColor: "#fcf3d4",
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 5
  },
  filterBar: {
    width: "18%",
    height: 60,
    display: "flex",
    alignItems: "center",
    borderRadius: 5,

  },
  bulkUpdateBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#fffff",
    border: "1px solid #4b4f5f",
    borderRadius: "5px",
    color: "#455366",
  },

  createBtn: {

    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '14px 44px',
    outline: 0,
    border: 0,
    width: 185,
    lineHeight: 1.5,
    backgroundColor: defaultBtn,
    borderColor: defaultBtn,
    color: "white",
    orderRadius: "10px",
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      boxShadow: 'none',
      color: "white",
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      color: "white",
      border: "none",
      outline: 0,
    },
  },
  menuItem: {
    "&:hover": {
      background: defaultBtn,
      color: "white"
    },
  },
  arrowUp: {
    position: "absolute",
    width: "16px",
    height: "12px",
    top: "-12px",
    zIndex: 1,
   
    '&.left': {
      left: "10px",
    },
    '&.right': {
      right: "10px",
      boxShadow: "0px 29px 8px -3px rgb(0 0 0 / 20%)",
    },
    '&.center': {
      left: "73%",
      transform: "translateX(-50%)",
    },
    '&:before': {
      content: "''",
      position: "absolute",
      top: "0",
      left: "0",
      borderStyle: "solid",
      borderWidth: "0 8px 12px",
      borderColor: "#FFF transparent",
      display: "block",
      width: 0,
      zIndex: 0,
    }
  },
  headingLayout: {
    width: "63%",
    backgroundColor: "#fcf3d4",
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "5px",
  },
  desTextConcat: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "190px",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
  },
  title: {
    color: "#656c7c",
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "28px",
  },
  listItemTitle: {
    color: "#e86a67",
    fontWeight: 600
  },
  
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 16,
    marginBottom: 10,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
  },

});

// Customizable Area End

class ConcessionReport extends AdHocReportingController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // localStorage.clear();
    // Customizable Area End
  }
 
  
  render() {

    // Customizable Area Start
     const { classes } = this.props;
     const { searchKeyword } = this.state;
     const cardCountData = [
      {
        title: 'Concession Amount',
        icon: this.state.concessionAmount,
        amount: <span style={{color:"rgb(62 187 63)"}}>{'₹ 15,000.00'}</span>,
      },
      {
        title: 'Total Amount',
        icon: this.state.totalAmount,
        amount: '₹ 75,000.00',
      }
     ];
     const concessionReportData = [
      {
        admission: 191010,
        student: 'Student Name',
        class: 'Grade 3',
        division: 'A',
        fee_name: 'Fee Name',
        amount: '₹ 40,000.00',
        concession: 'Concession Type',
        concession_amount:'₹ 5,000.00',
        concession_given_by: 'User Name',
        total_amount: '₹ 35,000.00',
      },
      {
        admission: 195022,
        student: 'Student Name',
        class: 'Grade 5',
        division: 'B',
        fee_name: 'Fee Name',
        amount: '₹ 20,000.00',
        concession: 'Concession Type',
        concession_amount:'₹ 5,000.00',
        concession_given_by: 'User Name',
        total_amount: '₹ 15,000.00',
      },
      {
        admission: 197034,
        student: 'Student Name',
        class: 'Grade 7',
        division: 'B',
        fee_name: 'Fee Name',
        amount: '₹ 30,000.00',
        concession: 'Concession Type',
        concession_amount:'₹ 5,000.00',
        concession_given_by: 'User Name',
        total_amount: '₹ 25,000.00',
      }
     ];
     
    // Customizable Area End

    return (
      // Customizable Area Start
      //handleGlobalSearch={this.handleSearchChange}
      <Layout searchKeyword={searchKeyword} navigation={this.props.navigation} >
        <Grid className={classes.tableGrid} container>
          <PageHeader
            title="Concession Report"
            // haveFilterButton={false}
            haveCreateButton={false}
            FilterTitle="Select Concession"
            filterForm={this.renderFilterForm()}
          />
          <CommonCardCount classes={this.props.classes} cardCountData={cardCountData}/>
            <TableContainer style={{ overflow: '' }} ref={(el: any) => this.feeRecvSelectorRef = el} component={Paper} className={classes.tableContainer}>
                <Table ref={this.feeRecvtableRef} className={classes.table} aria-label="a dense table">
                  <TableHead style={{ position: "sticky", top: "0px", zIndex: 9, }}>
                    <TableRow>
                      <TableCell style={{paddingLeft:"40px"}}>Admission #</TableCell>
                      <TableCell align="left">Student</TableCell>
                      <TableCell align="left">Class</TableCell>
                      <TableCell align="left">Division</TableCell>
                      <TableCell align="left">Fee Name</TableCell>
                      <TableCell align="left">Amount</TableCell>
                      <TableCell align="left">Concession</TableCell>
                      <TableCell align="left">Concession Amount</TableCell>
                      <TableCell align="left">Concession Given By</TableCell>
                      <TableCell align="left">Total Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(concessionReportData && concessionReportData.length) ? concessionReportData.map((row: concessionReport) => (
                      <TableRow key={row.admission}>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" ,paddingLeft:"31px" }}>
                            <Typography variant="subtitle1" >{row.admission}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.student)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.class)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.division}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.fee_name}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.amount}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.concession}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.amount}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.concession_given_by}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.amount}</Typography>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )) : null}
                  </TableBody>

                </Table>
              </TableContainer>
         
        </Grid >
      </Layout>
      // Customizable Area End
    );
  }
  private renderFilterForm() {

    return (
      <Grid
        container
        spacing={2}
        style={{
          padding: "10px 5px"
        }}
      >
        <Grid item md={12}>
          <OutlinedSelect
            val={null}
            options={[]}
            label="Concession Type"
            name={"concession_type_id"}
            placeholderTitle={'Select Concession'}
            formControlStyle={{
              width: "100%",
              margin: 0,
              borderRadius:"10px"
            }}
            handleChange={{}} 
            className={"filtersReport"}  
            />
        </Grid>
        <Grid item md={12}>
          <OutlinedSelect
            val={null}
            options={[]}
            label="Class"
            name={"concession_type_id"}
            placeholderTitle={'Select Class'}
            formControlStyle={{
              width: "100%",
              margin: 0,
              borderRadius:"10px"
            }}
            handleChange={{}} 
            className={"filtersReport"}  
            />
        </Grid>
        <Grid item md={12}>
          <OutlinedSelect
            val={null}
            options={[]}
            label="Division"
            placeholderTitle={'Select Division'}
            name={"concession_type_id"}
            formControlStyle={{
              width: "100%",
              margin: 0,
              borderRadius:"10px"
            }}
            handleChange={{}} 
            className={"filtersReport"}  
            />
        </Grid>
        
      </Grid>
    );
  }
  
}
export default withStyles(useStyles)(ConcessionReport);