import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible} from "./assets";
export interface ItemInitialField {
  name: string;
  account: string;
  quantity: number;
  rate: number;
  taxIds: string[];
  amount: number;
}

export interface ITDS {
  id: string;
  type: string;
  attributes: {
    id: number;
    section: string;
    tax_rate: number;
    tax_name: string;
    status: boolean;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes?: any;
  handleItemAddMore: () => void;
  itemData: ItemInitialField[];
  handleRemoveItem: (index:number) => void;
  discountTypeState: string;
  tds : Array<ITDS>;
  onChangeTDS :any
  openTDSModal :any
  // open: boolean;
  // handleClose: any;
  // title?: string;
  // buttonTitle?: string;
  // cancelButtonTitle?: string;
  // handleSubmit?: any;
  // submitLoading?: boolean;
  // isError?: boolean | undefined; 
  // errormsg?: string | undefined;
  // hideCancelButton?: boolean;
  // isStep: boolean;
  // stepLabel?: string[] | undefined;
  // activeStep?: number;
  // isHeaderButton: boolean;
  // headerButtonTitle?: string | undefined
  // headerButtonIcon?: any;
  // handleButtonClick?: () => void;
  // isHideButton: boolean;
  // viewForm: boolean;
  // currentForm: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomItemFormController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  
  // Customizable Area End
}
