import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { IVendor } from "../../CommonLayout/VendorSelectionModal/src/VendorSelectionModalController";
import { IExpenseForm } from "./ExpenseFormController";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View,
  Copy
}
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: IExpenseForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  onClose: () => void;
  onSubmit: (form: IExpenseForm) => void;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: IExpenseForm;
  formErrors: { [key: string]: string };
  isSubmitting: boolean;
  deleteModalOpen: boolean;
  taxes: any[];
  sources: any[];
  gstTreatments: any[];
  currencies: any[];
  vendorModalOpen: boolean;
  vendors: IVendor[];
  vendorName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ModalsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  private initialValues: IExpenseForm = {
    reverse_charge: false,
    reverse_charge_account: "",
    reverse_charge_ammount: "",
    reverse_charge_type: "%",
    exchange_amount: "",
    exchange_rate: "",
    expense_recipt: undefined,
    is_tax_inclusive: false,
    expense_type: "goods",
    created_at: new Date().toString(),
    custom_id: "",
    date: "",
    gst_treatment: "",
    comment: "",
    vendor_id: "2",
    invoice: "",
    currency: "",
    duration: "",
    expense_amount: "0",
    source_of_supply: "",
    destination_of_supply: "",
    expense_category: null,
    mode: "amount",
    name: "",
    paid_trough: "",
    sac: "",
    hns_code: "",
    tax: "",
    expence_account: "",
    updated_at: new Date().toString()
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.log("props", props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      vendors: [],
      vendorName: "",
      form: { ...(props.initialValues || this.initialValues) },
      formErrors: {},
      isSubmitting: false,
      deleteModalOpen: false,
      currencies: [],
      gstTreatments: [],
      sources: [],
      taxes: [],
      vendorModalOpen: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (callID) {
        case this.RequestMessage.GetTaxes.messageId:
          if (response !== null) {
            //runEngine.debugLog("taxestaxes", response.data);
            this.setState({
              taxes: response.data
              // isLoading: false
            });
          }

          break;
        case this.RequestMessage.GetSources.messageId:
          if (response !== null) {
            runEngine.debugLog("taxestaxes", response.data);
            this.setState({
              sources: response.data
              // isLoading: false
            });
          }

          break;
        case this.RequestMessage.GetVendors.messageId:
          if (response !== null) {
            const vendors = (response.data || []).map((el: IVendor) => {
              el.attributes.customer_name =
                el.attributes.first_name + " " + el.attributes.last_name;
              el.attributes.phone =
                el.attributes.phone_no_work || el.attributes.phone_no_mob;
              return el;
            });
            this.setState({
              vendors
            });
          }

          break;
          case this.RequestMessage.GetGstTreatments.messageId:
            const gstArray = response?.data?.map((el: any) => {
              return { ...el, id: `${el.id}` }
            });
  
            if (response !== null) {
              this.setState({
                gstTreatments: gstArray || [],
              });
            }
            break;
        case this.RequestMessage.GetCurrencies.messageId:
          if (response !== null) {
            runEngine.debugLog("currencies", response.data);
            this.setState({
              currencies: response.data
              // isLoading: false
            });
          }

          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  fileInputRef: HTMLInputElement;
  public async componentDidMount() {
    super.componentDidMount();
    // let formMode = FormMode.View;
    // if (window.location.pathname === "/CreateExpense")
    //   formMode = FormMode.Create;
    // else if (window.location.pathname === "/ViewExpense")
    //   formMode = FormMode.View;
    // else if (window.location.pathname === "/EditExpense")
    //   formMode = FormMode.Edit;

    this.getTaxesCall();
    this.getSourcesCall();
    this.getVendorsCall();
    this.getGstTreatmentCall();
    this.getCurrenciesCall();
  }

  public RequestMessage = {
    GetExpense: this.buildRequestMessage(Method.GET),
    GetTaxes: this.buildRequestMessage(Method.GET),
    GetSources: this.buildRequestMessage(Method.GET),
    GetVendors: this.buildRequestMessage(Method.GET),
    GetGstTreatments: this.buildRequestMessage(Method.GET),
    GetCurrencies: this.buildRequestMessage(Method.GET),
    CreateExpense: this.buildRequestMessage(Method.POST),
    EditExpense: this.buildRequestMessage(Method.PUT),
    ExportTaxRates: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  };
  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: window.localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TaxesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getTaxesCall() {
    // this.setState({ isLoading: true });

    this.RequestMessage.GetTaxes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TaxEndPoint}/`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetTaxes.id,
      this.RequestMessage.GetTaxes
    );
  }

  private getSourcesCall() {
    // this.setState({ isLoading: true });

    this.RequestMessage.GetSources.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SourceEndPoint}/`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetSources.id,
      this.RequestMessage.GetSources
    );
  }

  private getVendorsCall() {
    // this.setState({ isLoading: true });

    this.RequestMessage.GetVendors.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendorsEndPoint}/`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetVendors.id,
      this.RequestMessage.GetVendors
    );
  }

  private getGstTreatmentCall() {
    // this.setState({ isLoading: true });

    this.RequestMessage.GetGstTreatments.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/business_customers/gst_treatments"
    );

    runEngine.sendMessage(
      this.RequestMessage.GetGstTreatments.id,
      this.RequestMessage.GetGstTreatments
    );
  }

  private getCurrenciesCall() {
    // this.setState({ isLoading: true });

    this.RequestMessage.GetCurrencies.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CurrencyEndPoint}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetCurrencies.id,
      this.RequestMessage.GetCurrencies
    );
  }

  public componentDidUpdate(prevProps: Props, prevState: S) {
    const { isOpen, initialValues, formMode } = this.props;
    const { form, vendors } = this.state;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: IExpenseForm = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View, FormMode.Copy].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };
          if (formMode === FormMode.Copy) form.name = form.name + " - Copy";
        }

        this.setState({
          form,
          isSubmitting: false
        });
      }
    }

    if (
      (form.vendor_id && prevState.form.vendor_id !== form.vendor_id) ||
      vendors !== prevState.vendors
    ) {
      const selectedVendor = vendors.find(el => el.id === form.vendor_id);
      if (selectedVendor)
        this.setState({
          vendorName:
            selectedVendor.attributes.first_name +
            " " +
            selectedVendor.attributes.last_name
        });
    }
  }

  handleFileChange = (files: any[]) => {
    runEngine.debugLog("filesfilesfiles", files);

    this.setState({
      form: { ...this.state.form, expense_recipt: files[0] }
    });
  };

  onCloseVendorModal = () => {
    this.setState({
      vendorModalOpen: false
    });
  };
  onSubmitVendorModal = (id: string) => {
    this.setState({
      vendorModalOpen: false,
      form: { ...this.state.form, vendor_id: id }
    });
  };

  // onCreateExpense = (form: IExpenseForm) => {
  //   this.createExpenseCall(form);
  // };

  // onEditExpense = (form: IExpenseForm) => {
  //   this.createExpenseCall(form);
  // };

  handleReverseChargeChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        reverse_charge: e.target.checked
      }
    });
  };

  handleDateChange = (value: any) => {
    runEngine.debugLog("datedatedated", value);

    this.setState({
      form: { ...this.state.form, date: value }
    });
  };

  handleCurrencyChange = (e: any) => {
    this.setState({
      form: { ...this.state.form, currency: e.target.value }
    });
  };

  handleExpenseAccountChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        expence_account: e.target.value
      }
    });
  };

  handleReverseChargeAccountChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        reverse_charge_account: e.target.value
      }
    });
  };

  handleTaxChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        tax: e.target.value
      }
    });
  };

  handleGstTreatmentChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        gst_treatment: e.target.value
      }
    });
  };

  handleSourceSupplyChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        source_of_supply: e.target.value
      }
    });
  };

  handleDestinationSupplyChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        destination_of_supply: e.target.value
      }
    });
  };

  handlePaidThroughChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        paid_trough: e.target.value
      }
    });
  };

  handleAmountChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        expense_amount: e.target.value
      }
    });
  };

  handleReverseChargeTypeChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        reverse_charge_type: e.target.value
      }
    });
  };

  handleReverseChargeAmountChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        reverse_charge_ammount: e.target.value
      }
    });
  };

  handleExchangeRate = (e: any) => {
    let expense_amount: any =
      parseFloat(e.target.value) *
      parseFloat(this.state.form.exchange_amount || "0.00");
    expense_amount = expense_amount ? expense_amount.toFixed(2) : "0.00";
    this.setState({
      form: {
        ...this.state.form,
        exchange_rate: e.target.value || "0.00",
        expense_amount
      }
    });
  };

  handleExchangeAmount = (e: any) => {
    let expense_amount: any =
      parseFloat(e.target.value) *
      parseFloat(this.state.form.exchange_rate || "0.00");
    expense_amount = expense_amount ? expense_amount.toFixed(2) : "0.00";
    this.setState({
      form: {
        ...this.state.form,
        exchange_amount: e.target.value,
        expense_amount
      }
    });
  };

  handleCommentChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        comment: e.target.value
      }
    });
  };

  handleInvoiceChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        invoice: e.target.value
      }
    });
  };

  handleVendorIdChange = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        vendor_id: e.target.value
      }
    });
  };

  onChangeTaxExclusive = (is_tax_inclusive: boolean) => {
    this.state.form.is_tax_inclusive = is_tax_inclusive;
    this.setState({ form: { ...this.state.form } });
  };

  onChangeSac = (event: any) => {
    this.state.form.sac = event.target.value;
    this.setState({ form: { ...this.state.form } });
  };
  onChangeHsnCode = (event: any) => {
    this.state.form.hns_code = event.target.value;
    this.setState({ form: { ...this.state.form } });
  };

  onChangeExpenseType = (expenseType: string) => {
    this.state.form.expense_type = expenseType;
    this.setState({ form: { ...this.state.form } });
  };

  onChangeExpenseCategory = (event: any) => {
    const form = this.state.form;
    if (form) form.expense_category = event.target.value;
    this.setState({ form: { ...this.state.form } });
  };
  onChangeExpenseName = (event: any) => {
    const form = this.state.form;
    if (form) form.name = event.target.value;
    this.setState({ form: { ...this.state.form } });
  };

  onChangeExpenseMode = (event: any) => {
    const form = this.state.form;
    if (form) form.mode = event.target.value;
    this.setState({ form: { ...this.state.form } });
  };

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false
    });
  }

  prepareVendorName = (vendor: IVendor) =>
    vendor.attributes.first_name + " " + vendor.attributes.last_name;

  public onSubmit() {
    const formErrors: { [key: string]: string } = {};

    const currentVendor = this.state.vendors.find(
      el =>
        this.prepareVendorName(el).toLowerCase() ===
        this.state.vendorName.toLowerCase()
    );

    const isEmpty = (val: any) =>
      val === "" || val === undefined || val === null || val === "null";
    if (isEmpty(this.state.form.gst_treatment))
      formErrors["gst_treatment"] = "Gst Treatment is required.";
    if (isEmpty(this.state.form.date)) formErrors["date"] = "Date is required.";
    if (isEmpty(this.state.form.currency))
      formErrors["currency"] = "Currency is required.";
    else if (this.state.form.currency !== "INR") {
      if (isEmpty(this.state.form.exchange_rate))
        formErrors["exchange_rate"] = "Exchange Rate is required.";
      if (isEmpty(this.state.form.exchange_amount))
        formErrors["exchange_amount"] = "Exchange Amount is required.";
    }

    if (isEmpty(this.state.form.expence_account))
      formErrors["expence_account"] = "Expense Account is required.";
    if (isEmpty(this.state.form.expense_amount))
      formErrors["expense_amount"] = "Expense Amount is required.";
    if (isEmpty(this.state.form.tax)) formErrors["tax"] = "Tax is required.";
    if (isEmpty(this.state.form.paid_trough))
      formErrors["paid_trough"] = "Paid Trough is required.";
    if (isEmpty(this.state.form.source_of_supply))
      formErrors["source_of_supply"] = "Source Of Supply is required.";
    if (isEmpty(this.state.form.destination_of_supply))
      formErrors["destination_of_supply"] =
        "Destination Of Supply is required.";

    // runEngine.debugLog(
    //   "onSubmitd",
    //   currentVendor?.id + " / " + this.state.vendorName
    // );

    if (Object.keys(formErrors).length < 1) {
      this.props.onSubmit({
        ...this.state.form,
        vendor_id: currentVendor ? currentVendor.id : ""
      });
      this.setState({ formErrors: {} });
    } else {
      this.setState({ formErrors });
    }
  }

  public handleFormClose() {
    this.props.onClose();
  }
  // Customizable Area End
}
