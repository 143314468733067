import React, { useState } from "react";
// Customizable Area Start
/*-- side bar --*/
import { withStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
/*-- side bar end --*/
import Collapse from "@material-ui/core/Collapse";
import { sideMenuIcon } from "./assets";
import { Styles } from "@material-ui/styles";
import { sidebarDefault } from "../../Layout/src/color";
import SidebarController, { Props } from "./SidebarController";
import { Box, Grid } from "@material-ui/core";

// Customizable Area End

// Customizable Area Start
const hide = false;
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  /* ---side bar css start--- */

  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: 206,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: 245,
    background: sidebarDefault,
    //"linear-gradient(#ff5f6d 60%, #ffc371)",
    //background: "linear-gradient(#F15B5B 60%, #FFBE00)",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    background: sidebarDefault,
    //background: "linear-gradient(#ff5f6d 60%, #ffc371)",
    //background: "linear-gradient(#F15B5B 60%, #FFBE00)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: "170px"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  logo: {
    paddingTop: "18px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "18px",
    zIndex: 99
  },
  bottomLogo: {
    paddingLeft: "40px",
    paddingRight: "14px",
    paddingTop: "8px",
    position: "absolute",
    bottom: "20px"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  listIcon: {
    color: "white"
  },
  listText: {
    color: "white"
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: "25ch",
    float: "right",
    paddingRight: "46px"
  },
  multiLevelMenu: {
    marginTop: 35,
    paddingLeft: 25,
    paddingBottom: 18,
    height: 50,

    "& + .MuiCollapse-container .MuiListItem-root": {
      marginTop: 0,
      paddingLeft: 25
    }
  }
  /* ---side bar css end--- */
});

function hasChildren(item: any) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

const MenuItem = ({
  item,
  drawerOpen,
  handleClickMenuItem,
  multiLevelMenuStyle,
  handleMenuClick,
  multilevelMenuOpen,
  insideMultiLevel,
  activeSubMenuId,
  activeMenu
}: any) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return (
    <Component
      activeSubMenuId={activeSubMenuId}
      activeMenu={activeMenu}
      insideMultiLevel={insideMultiLevel}
      multilevelMenuOpen={multilevelMenuOpen}
      handleMenuClick={handleMenuClick}
      handleClickMenuItem={handleClickMenuItem}
      drawerOpen={drawerOpen}
      item={item}
      multiLevelMenuStyle={multiLevelMenuStyle}
    />
  );
};

const SingleLevel = ({ drawerOpen, item, handleClickMenuItem, activeSubMenuId, activeMenu }: any) => {
  return (
    <ListItem
      onClick={() => handleClickMenuItem(item)}
      style={{ cursor: "pointer", padding: "0px 40px" }}
    >
      <ListItemIcon style={{ minWidth: "45px", marginLeft: "-15px" }}>
        <img
          src={item.img}
          style={{
            width: "36px",
            height: "28px",
            margin: "14px 0",
            objectFit: "contain"
          }}
        />
      </ListItemIcon>
      {drawerOpen && (
        <ListItemText
          style={{ color: (activeSubMenuId === item.id || activeMenu === item.id) ? "#fff" : 'rgba(255, 255, 255, 0.6)', fontWeight: 600 }}
          primary={item.title}
        />
      )}
    </ListItem>
  );
};

const MultiLevel = ({
  item,
  drawerOpen,
  handleMenuClick,
  handleClickMenuItem,
  multiLevelMenuStyle,
  multilevelMenuOpen,
  insideMultiLevel,
  activeSubMenuId,
  activeMenu
}: any) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  return (
    <React.Fragment>
      <ListItem
        className={multiLevelMenuStyle}
        button
        onClick={() => {
          if (insideMultiLevel) {
            handleClick();
          } else {
            handleMenuClick(item);
          }
        }}
      >
        {(!insideMultiLevel && multilevelMenuOpen) && (
          <ListItemIcon style={{ minWidth: "40px", marginLeft: "-13px", marginRight: '5px' }}>
            <img
              style={{
                width: "10px",
                height: "16px",
                marginTop: "4px",
                opacity: "1"
              }}
              src={sideMenuIcon}
            />
          </ListItemIcon>
        )}
        <ListItemIcon style={{ minWidth: "36px", marginLeft: (!insideMultiLevel && multilevelMenuOpen) ? '-32px' : 0 }}>
          <img
            src={item.img}
            style={{
              width: "36px",
              height: "28px",
              objectFit: "contain"
            }}
          />
        </ListItemIcon>
        {drawerOpen && (
          <ListItemText
            style={{
              color: (activeSubMenuId === item.id || activeMenu === item.id) ? "#fff" : 'rgba(255, 255, 255, 0.6)',
              marginLeft: "6px",
              marginRight: "6px",
              display: "flex"
            }}
            primary={item.title}
          />
        )}
        {!insideMultiLevel ? (
          <ListItemIcon style={{ minWidth: "40px", marginLeft: "15px" }}>
            {!multilevelMenuOpen && (
              <img
                className="rotate90"
                style={{
                  width: "10px",
                  height: "16px",
                  marginTop: "8px",
                  opacity: "0.7"
                }}
                src={sideMenuIcon}
              />
            )}
          </ListItemIcon>
        ) : (
          <ListItemIcon style={{ minWidth: "40px", marginLeft: "15px" }}>
            {open ? (
              <img
                //className="rotate180"
                style={{
                  width: "10px",
                  height: "16px",
                  marginTop: "4px",
                  opacity: "1",
                  transform: 'rotate(90deg)'
                }}
                src={sideMenuIcon}
              />
            ) : (
              <img
                className="rotate90"
                style={{
                  width: "10px",
                  height: "16px",
                  marginTop: "8px",
                  opacity: "0.7"
                }}
                src={sideMenuIcon}
              />
            )}
          </ListItemIcon>
        )}
      </ListItem>
      <Collapse in={insideMultiLevel ? open : multilevelMenuOpen}>
        <List component="div" disablePadding>
          {children.map((child: object, key: number) => (
            <MenuItem
              activeSubMenuId={activeSubMenuId}
              activeMenu={activeMenu}
              insideMultiLevel={true}
              // handleMenuClick={handleMenuClick}
              // multilevelMenuOpen={multilevelMenuOpen}
              handleClickMenuItem={handleClickMenuItem}
              drawerOpen={drawerOpen}
              key={key}
              item={child}
            />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
// Customizable Area End

class Sidebar extends SidebarController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  menuList = [
    {
      id: "11",
      title: (
        <p style={{ display: "flex", flexDirection: "column" }}>
          Master<span>Database</span>
        </p>
      ),
      img: this.state.masterData,
      items: [
        {
          id: "11-1",
          title: "Account",
          img: this.state.account,
          href: "Account"
        },
        {
          id: "2",
          title: "Tax",
          img: this.state.taxIcon,
          items: [
            {
              id: "2-1",
              title: "Tax Rates",
              img: this.state.taxRatesIcon,
              href: "TaxRates"
            },
            {
              id: "2-2",
              title: "GST Settings",
              img: this.state.moduloWhite,
              href: "GSTSettings"
            }
          ]
        },
        {
          id: "2-11",
          title: "Concession",
          img: this.state.concession,
          href: "Concession"
        },
        {
          id: "4-1",
          title: "Fee",
          img: this.state.feeMasters,
          href: "Fee"
        },
        {
          id: "5-1",
          title: "Fines",
          img: this.state.fineIcon,
          href: "Fine"
        },
        {
          id: "6-1",
          title: (
            <p style={{ display: "flex", flexDirection: "column" }}>
              Payment<span>Preference</span>
            </p>
          ),
          img: this.state.paymentPreferenceIcon,
          href: "PaymentPreference"
        }

        // {
        //   id: '1-2',
        //   title: "Users",
        //   img: this.state.modulo,
        //   href: "/Concession"
        // }
      ]
    },
    {
      id: "0",
      title: "Dashboard",
      img: this.state.dashboardIcon,
      href: "DashboardCharts"
    },
    {
      id: "1",
      title: "Roles / Users",
      img: this.state.userRolesIcon,
      items: [
        {
          id: "1-1",
          title: "Roles",
          img: this.state.roleIcon,
          href: "RolesPermissions"
        },
        {
          id: "1-2",
          title: "Users",
          img: this.state.users,
          href: "Users"
        }
      ]
    },
    {
      id: "3",
      title: "Settings",
      img: this.state.settingsIcon,
      items: [
        {
          id: "3-1",
          title: (
            <p style={{ display: "flex", flexDirection: "column" }}>
              Organization<span>Profile</span>
            </p>
          ),

          img: this.state.organizationIcon,
          href: "OrganizationProfile"
        }
      ]
    },
    {
      id: "4",
      title: "Sales",
      img: this.state.salesIcon,
      items: [
        {
          id: "4-1",
          title: (
            <p style={{ display: "flex", flexDirection: "column" }}>
              Customers
            </p>
          ),

          img: this.state.customersIcon,
          href: "Customer"
        }
      ]
    },
    // {
    //   id: "6",
    //   title: "Expenses",
    //   img: this.state.customersIcon,
    //   items: [
    //     {
    //       id: "6-1",
    //       title: "Recurring Expenses",
    //       img: this.state.recurringExpenseIcon,
    //       href: "RecurringExpenses"
    //     }
    //   ]
    // },
    {
      id: "6",
      title: "Purchase",
      img: this.state.PurchaseIcon,
      items: [
        {
          id: "6-1",
          title: "Vendors",
          img: this.state.vendorsIcon,
          href: "Vendors"
        },
        {
          id: "6-2",
          title: "Expense",
          img: this.state.expenseIcon,
          href: "Expense"
        },
        {
          id: "6-3",
          title: (
            <p style={{ display: "flex", flexDirection: "column" }}>
              Recurring<span>Expenses</span>
            </p>
          ),
          img: this.state.recurringExpenseIcon,
          href: "RecurringExpenses"
        }
      ]
    },
    {
      id: "8",
      title: (
        <p style={{ display: "flex", flexDirection: "column" }}>
          Fee<span>Management</span>
        </p>
      ),
      img: this.state.feeManagement,
      items: [
        {
          id: "8-1",
          title: "Fee Assignment",
          img: this.state.feeAssignmentIcon,
          href: "FeeAssignment"
        }
      ]
    },
    {
      id: "9",
      title: "Reports",
      img: this.state.ReportsIcon,
      items: [
        {
          id: "9-1",
          title: "Reports",
          img: this.state.ReportsIcon,
          href: "AdHocReporting"
        }
      ]
    }
    // {
    //   id: "10",
    //   title: "Payment Preference",
    //   img: this.state.expenseIcon,
    //   items: [
    //     {
    //       id: "10-1",
    //       title: "Payment Preference",
    //       img: this.state.paymentPreferenceIcon,
    //       href: "PaymentPreference"
    //     }
    //   ]
    // }
  ];
  // FeeManagementList = [
  //   {
  //     id: "9",
  //     title: (
  //       <p style={{ display: "flex", flexDirection: "column" }}>
  //         Fee<span>Management</span>
  //       </p>
  //     ),
  //     img: this.state.feeManagement,
  //     items: [
  //       {
  //         id: '9-1',
  //         title: ,
  //         img: this.state.feeAssignmentIcon,
  //         href: "FeeAssignment"
  //       },

  //     ]
  //   }
  // ];

  // Fee Management
  // Customizable Area End

  render() {
    // Customizable Area Start
    this.urlInit(this.menuList);
    const renderMenuItem = (key: any, item: any) => (
      <MenuItem
        multilevelMenuOpen={this.state.multilevelMenuOpen}
        handleMenuClick={this.handleMenuClick}
        handleClickMenuItem={this.handleClickMenuItem}
        multiLevelMenuStyle={classes.multiLevelMenu}
        drawerOpen={open}
        key={key}
        item={item}
        activeSubMenuId={this.state.activeSubMenuId}
        activeMenu={this.state.showedMenu}
      />
    )
    
    const { classes, open } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <Drawer
        style={{
          zIndex: 0
          // minHeight:"100px"
        }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <Toolbar className={classes.logo}>
          <img height="70px" width="70px" src={this.state.logo} />
        </Toolbar>
        {/* <Divider /> */}
        <div style={{ height: "70vh", overflowY: "scroll" }}>

          {this.menuList.map((item: any, key: number) => {
            if (!this.state.activeMenu || !this.state.multilevelMenuOpen || (this.state.activeMenu === item.id || item?.items?.some((el: any) => el.id === this.state.activeMenu))) {
              return renderMenuItem(key, item);
            }
          })}
        </div>
        <Toolbar className={classes.bottomLogo}>
          <img
            height="50px"
            width="50px"
            style={{ opacity: ".6" }}
            src={this.state.logo1}
          />
        </Toolbar>
      </Drawer>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

export default withStyles(useStyles)(Sidebar);
