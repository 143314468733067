import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { CChart } from "@coreui/react-chartjs";
import {
  bulkUpdateIcon,
  currentFeeIcon,
  feeOutstandingIcon,
  feeReceivableIcon,
  feeReceivedIcon
} from "./assets";

import FilterController, { Props } from "./FilterController";

import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Layout from "../../CommonLayout/Layout/src/Layout.web";

import "./index.css";
import { FormControl, FormLabel, Popover, Typography } from "@material-ui/core";
import {
  Button,
  ButtonType
} from "../../CommonLayout/HRMSCommons/src/button/default";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import DatePicker from "react-date-picker";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  filterTitle: {
    color: "#FC585D",
    borderBottom: "1px solid #CCC",
    marginBottom: 10
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "25px",
    marginLeft: "10px",
    marginRight: "10px"
  },
  input: {
    maxHeight: "40px"
  }
});
// Customizable Area End

class Filter extends FilterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, anchor, onClose, visible, onSubmit } = this.props;

    return (
      <Popover
        open={visible}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Grid
          container
          direction="column"
          style={{
            width: 410,
            padding: 25
          }}
        >
          <Grid item className={classes.filterTitle}>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              Filters
            </Typography>
          </Grid>
          <Grid container direction="column">
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "20px"
              }}
            >
              <OutlinedSelect
                val={this.state.class}
                options={["IX", "X", "XI"].map((el: string) => ({
                  id: el,
                  name: el
                }))}
                label="Class"
                name={"Class"}
                formControlStyle={{
                  width: "175px",
                  margin: 0
                }}
                styleOverrides={{ maxHeight: "40px" }}
                handleChange={(e: any) =>
                  this.setState({ class: e.target.value })
                }
              />

              <OutlinedSelect
                val={this.state.division}
                options={["A", "B", "C"].map((el: string) => ({
                  id: el,
                  name: el
                }))}
                label="Division"
                name={"Division"}
                formControlStyle={{
                  width: "175px",
                  margin: 0
                }}
                styleOverrides={{ maxHeight: "40px" }}
                handleChange={(e: any) =>
                  this.setState({ division: e.target.value })
                }
              />
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "20px"
              }}
            >
              <OutlinedSelect
                val={this.state.date}
                options={[
                  "Custom",
                  "Last 3 Months",
                  "Last 6 Months",
                  "Last 12 Months"
                ].map((el: string) => ({
                  id: el,
                  name: el
                }))}
                label="Class"
                name={"Class"}
                formControlStyle={{
                  width: "175px",
                  margin: 0
                }}
                styleOverrides={{ maxHeight: "40px" }}
                handleChange={(e: any) =>
                  this.setState({ date: e.target.value })
                }
              />
            </Grid>
            {this.state.date === "Custom" && (
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "20px"
                }}
              >
                <FormControl style={{ width: "175px" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>From</FormLabel>
                  <DatePicker
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    calendarClassName="Calender"
                    onChange={undefined}
                    name={"valid_until"}
                    value={null}
                    calendarIcon={
                      <img
                        style={{ height: "27px" }}
                        src={this.state.calenderIcon}
                      />
                    }
                    className={classes.input}
                  />
                </FormControl>
                <FormControl style={{ width: "175px" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>To</FormLabel>
                  <DatePicker
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    calendarClassName="Calender"
                    onChange={undefined}
                    name={"valid_until"}
                    value={null}
                    calendarIcon={
                      <img
                        style={{ height: "27px" }}
                        src={this.state.calenderIcon}
                      />
                    }
                    className={classes.input}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid item className={classes.actionButtons}>
            <Button
              title="Cancel"
              buttonType={ButtonType.Cancel}
              onClick={onClose}
            />
            <Button
              title="Apply"
              buttonType={ButtonType.Primary}
              onClick={onSubmit}
            />
          </Grid>
        </Grid>
      </Popover>
    );
    // Customizable Area End
  }

  // Customizable Area End
}

export default withStyles(useStyles)(Filter);
