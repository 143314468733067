import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import CustomDeleteModal from "../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web";
import CreateConcession from "./CreateConcession.web";
import BulkUpdateConcession from "./BulUpdateConcession.web";
import EditConcession from "./EditConcession.web";
import ViewConcession from "./ViewConcession.web";
import CopyConcession from "./CopyConcession.web";
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import { printIcon, importExportIcon } from "./assets";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import { bulkUpdateIcon } from "./assets";
// Customizable Area End
import { defaultBtn } from "../../CommonLayout/Layout/src/color";
import ConcessionController, { Props } from "./ConcessionController";
import { FormControl, FormLabel, Hidden, Slider, TextField } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { capitalizeFirst } from "../../../framework/src/Utilities";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import ReactToPrint from "react-to-print";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import moment from "moment";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import CommonSlider from "../../CommonLayout/HRMSCommons/src/CommonSlider";
import DatePicker from "react-date-picker";
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  searchbar: {
    width: "85%",
    marginLeft: "139px"
  },
  gridContainer: {
    marginTop: "95px",
    marginRight: "8px",
    marginBottom: "20px"
  }, menu: {
    marginLeft: "-25px",
    marginTop: "15px",
    width:"100%",
    "& .MuiPaper-root": {
      overflow: "visible",
      // top: "268px !important",
      // left: "1705px !important" 
    }
  },
  // "& .MuiPaper-elevation8":{

  // },
  arrowUp: {
    position: "absolute",
    width: "16px",
    height: "12px",
    top: "-12px",
    zIndex: 1,
   
    '&.left': {
      left: "10px",
    },
    '&.right': {
      right: "10px",
      boxShadow: "0px 29px 8px -3px rgb(0 0 0 / 20%)",
    },
    '&.center': {
      left: "73%",
      transform: "translateX(-50%)",
    },
    '&:before': {
      content: "''",
      position: "absolute",
      top: "0",
      left: "0",
      borderStyle: "solid",
      borderWidth: "0 8px 12px",
      borderColor: "#FFF transparent",
      display: "block",
      width: 0,
      zIndex: 0,
    }
  },
  pageTitle: {
    fontWeight: "bold"
  },
  actionBtn: {
    marginRight: "8px",
    borderRadius: "8px"
  },
  featureBtn: {
    borderRadius: "5px",
    border: `1px solid #ffb2b2`,
    backgroundColor: "#ffffff",
    margin: "10px",
    padding: "18px",
    "&:hover": {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      color: "white",
      opacity: "1"
    },
    "&:active": {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      opacity: "1",
      boxShadow: "none",
      color: "white"
    },
    "&:focus": {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn
    }
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,

    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    // height: "calc(100vh - 381px) !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  },
  tableGrid: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  heading: {
    width: "81%",
    backgroundColor: "#fcf3d4",
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 5
  },
  filterBar: {
    width: "18%",
    height: 60,
    display: "flex",
    alignItems: "center",
    borderRadius: 5
  },
  bulkUpdateBtn: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#fffff",
    border: "1px solid #4b4f5f",
    borderRadius: "5px",
    color: "#455366"
  },

  createBtn: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "14px 44px",
    outline: 0,
    border: 0,
    width: 185,
    lineHeight: 1.5,
    backgroundColor: defaultBtn,
    borderColor: defaultBtn,
    color: "white",
    orderRadius: "10px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      boxShadow: "none",
      color: "white"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      color: "white",
      border: "none",
      outline: 0
    }
  },
  menuItem: {
    // margin:"10px",
    "&:hover": {
      background: defaultBtn,
      color: "white"
    }
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  checkbox: {
    padding: 0
  },
  headingLayout: {
    width: "63%",
    backgroundColor: "#fcf3d4",
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "5px"
  },
  desTextConcat: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "190px"
  }
});

interface fee_concession_type {
  id: number;
  data: {
    attributes: {
      id: any;
      name: any;
    };
  };
}

interface concessionAttribute {
  custom_id: number;
  name: string;
  fee_concession_type: fee_concession_type;
  valid_until: any;
  mode: string;
  amount: any;
}

interface ConcessionData {
  id: number;
  attributes: concessionAttribute;
}

class Concession extends ConcessionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // localStorage.clear();
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const {
      checkBulkUpdateRow,
      disableBukUpload,
      allRowsChecked,
      page,
      importExportanchorEl,
      searchKeyword
    } = this.state;

    return (
      //handleGlobalSearch={this.handleSearchChange}
      <Layout
        searchKeyword={searchKeyword}
        handleGlobalSearch={this.handleSearchChange}
        navigation={this.props.navigation}
      >
        <Grid className={classes.tableGrid} container>
          <PageHeader
            createButtonTitle={"Create"}
            title="Concession"
            onClickCreateButton={this.onClickCreateConcession}
            onFilterFormSubmit={this.onFilterFormSubmit}
            filterForm={this.renderFilterForm()}
            printReference={this.selectorRef}
            importModalTitle="Import Concession by CSV file"
            onClickDownloadCSVTemplate={this.handleDownloadCSVFile}
            onClickExportCSVButton={this.handleExportConcession}
            onUploadCSVForImport={this.importConcessionData}
            importErrorMessage={this.state.concessionImporterrorMessage}
            FilterTitle="Select Concession"
            closeImportModal={this.state.closeImportModal}
          />
          {!disableBukUpload && (
            <Grid style={{ paddingTop: "20px" }}>
              <Button
                className={classes.bulkUpdateBtn}
                onClick={() =>
                  this.setState({ bulkUpdateConcessionModalOpen: true })
                }
              >
                <img
                  style={{ marginRight: "6px" }}
                  height="18px"
                  width="18px"
                  src={bulkUpdateIcon}
                />{" "}
                Bulk Update
              </Button>
            </Grid>
          )}

          {/* {this.state.concessionListerrorMessage ? (
            <p
              style={{
                color: "red",
                fontWeight: 600,
                fontSize: "18px",
                textAlign: "center"
              }}
            >
             {this.state.concessionListerrorMessage}
            </p>
          ) : ( */}
            <>
              <TableContainer
                style={{ overflow: this.state.showLoader ? 'hidden' : '' }}
                ref={(el: any) => (this.selectorRef = el)}
                component={Paper}
                className={classes.tableContainer}
              >
                {this.state.showLoader && (
                  <Loader
                    loading={this.state.showLoader}
                    positionType={"center"}
                  />
                )}
                <Table ref={this.tableRef} className={classes.table} aria-label="a dense table">
                  <TableHead
                    style={{ position: "sticky", top: "0px", zIndex: 9 }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classNames(
                          classes.actionColumn,
                          classes.tableHead
                        )}
                      >
                        <Checkbox
                          className={classes.checkbox}
                          indeterminate={
                            allRowsChecked === "indeterminate" ? true : false
                          }
                          onChange={this.handleCheckAllAccount}
                          checked={!!allRowsChecked}
                          inputProps={{ "aria-label": "select all desserts" }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableHead}>ID</TableCell>
                      <TableCell className={classes.tableHead}>
                        Concession Type
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Concession Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Valid Until
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Concession Amount
                      </TableCell>
                      <TableCell className={classes.tableHead} align="center" />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.concessionList &&
                    this.state.concessionList.length ? (
                      this.state.concessionList.map((row: ConcessionData) => (
                        <TableRow key={row.id}>
                          <TableCell
                            align="center"
                            className={classes.actionColumn}
                          >
                            <Checkbox
                              className={classes.checkbox}
                              checked={
                                checkBulkUpdateRow && checkBulkUpdateRow[row.id]
                                  ? true
                                  : false
                              }
                              onChange={e =>
                                this.handleCheckAccountItem(e, row.id)
                              }
                              inputProps={{
                                "aria-label": "select all desserts"
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Grid
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="subtitle1">
                                {row.attributes.custom_id}
                              </Typography>

                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid
                              style={{ display: "flex", alignItems: "center", }}
                            >
                              <Typography variant="subtitle1">
                                { row.attributes.fee_concession_type.data 
                                    ? capitalizeFirst( row.attributes.fee_concession_type.data
                                      .attributes.name): 
                                    null
                                    }
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid
                              // style={{ display: "flex", alignItems: "center",overflow:"hidden" }}
                            >
                              <Typography variant="subtitle1" style={{display: "block",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    width: "155px",}}>
                                {row.attributes.name ?capitalizeFirst(row.attributes.name):null}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="subtitle1">
                                {row.attributes.valid_until ? moment(row.attributes.valid_until).format(
                                  "DD-MM-YYYY"
                                ) :null}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography style={{ paddingLeft:"15px" }} variant="subtitle1">
                                {row.attributes.mode && row.attributes.mode === "percentage"
                                  ? parseFloat(row.attributes.amount) + ".00 %"
                                  : row.attributes.amount +".00" }
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid
                              style={{ display: "flex", alignItems: "left" }}
                            >
                              <Typography variant="subtitle1">
                                <IconButton
                                  onClick={e => this.handleAnchorClick(e, row)}
                                  className={classes.actionBtn}
                                >
                                  <img
                                    height="18px"
                                    width="4px"
                                    src={this.state.actionIcon}
                                  />
                                </IconButton>
                              </Typography>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : !this.state.showLoader ? (
                      <TableRow>
                        <TableCell colSpan={12} style={{  color: "red",
                        fontWeight: 600,
                         fontSize: "18px",
                            textAlign: "center"}}>
                          {"No Record Found"}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleAnchorClose}
                anchorOrigin={{ horizontal: -20, vertical: "center" }}
                className={classes.menu}
                // style={{margin:"10px"}}
              >
              <div className={classNames(classes.arrowUp, "center")}> </div>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => this.handleModalAction("edit", true)}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => this.handleModalAction("copy", true)}
                >
                  Copy
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => this.handleModalAction("view", true)}
                >
                  View
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => this.handleModalAction("delete", true)}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          <CreateConcession
            concessionErrorMessage={this.state.concessionErrorMessage}
            concessionCreateerrorMessage={
              this.state.concessionCreateerrorMessage
            }
            concessionTypeCreateerrorMessage={this.state.concessionTypeCreateerrorMessage}
            submitLoading={this.state.submitLoading}
            ConcessionTypeList={this.state.ConcessionTypeList}
            open={this.state.createModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
            handleSubmitConcessionType={this.addConcessionType}
            handleEditConcessionTypeSubmit={this.updateConcessionType}
            handleConcessionTypemodalClose={this.handleConcessionTypemodalClose}
            handleConcessionTypeDeletemodalClose={this.handleConcessionTypeDeletemodalClose}
            handleConcessionTypeDeletemodal={this.state.handleConcessionTypeDeletemodal}
            handleConcessionTypeEditmodal={this.state.handleConcessionTypeEditmodal}
            handleConcessionTypeEditmodalClose={this.handleConcessionTypeEditmodalClose}
            handleConcessionTypemodalOpen={this.handleConcessionTypemodalOpen}
            handleConcessionTypeEditmodalOpen={this.handleConcessionTypeEditmodalOpen}
            handleConcessionTypeDeletemodalOpen={this.handleConcessionTypeDeletemodalOpen}
            selectedConcessionTypeItem={this.state.selectedConcessionTypeItem}
            handleConcessionTypemodal={this.state.handleConcessionTypemodal}
            handleDeleteConcessionTypeSubmit={this.deleteConcessionType.bind(this)}
          />
          <EditConcession
            concessionUpdateerrorMessage={
              this.state.concessionUpdateerrorMessage
            }
            concessionTypeCreateerrorMessage={this.state.concessionTypeCreateerrorMessage}
            concessionErrorMessage={this.state.concessionErrorMessage}
            open={this.state.editModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
            ConcessionTypeList={this.state.ConcessionTypeList}
            concessionDetails={this.state.selectedConcessionItem}
            concessionDetailserrorMessage={
              this.state.concessionDetailserrorMessage
            }
            handleSubmitConcessionType={this.addConcessionType}
            handleEditConcessionTypeSubmit={this.updateConcessionType}
            handleConcessionTypemodalClose={this.handleConcessionTypemodalClose}
            handleConcessionTypeDeletemodalClose={this.handleConcessionTypeDeletemodalClose}
            handleConcessionTypeDeletemodal={this.state.handleConcessionTypeDeletemodal}
            handleConcessionTypeEditmodal={this.state.handleConcessionTypeEditmodal}
            handleConcessionTypeEditmodalClose={this.handleConcessionTypeEditmodalClose}
            handleConcessionTypemodalOpen={this.handleConcessionTypemodalOpen}
            handleConcessionTypeEditmodalOpen={this.handleConcessionTypeEditmodalOpen}
            handleConcessionTypeDeletemodalOpen={this.handleConcessionTypeDeletemodalOpen}
            selectedConcessionTypeItem={this.state.selectedConcessionTypeItem}
            handleConcessionTypemodal={this.state.handleConcessionTypemodal}
            handleDeleteConcessionTypeSubmit={this.deleteConcessionType.bind(this)}
          />
          <CopyConcession
            concessionErrorMessage={this.state.concessionErrorMessage}
            concessionCreateerrorMessage={
              this.state.concessionCreateerrorMessage
            }
            concessionTypeCreateerrorMessage={this.state.concessionTypeCreateerrorMessage}
            open={this.state.copyModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
            concessionTypeList={this.state.ConcessionTypeList}
            concessionDetails={this.state.concessionDetails}
            concessionDetailserrorMessage={
              this.state.concessionDetailserrorMessage
            }
            handleSubmitConcessionType={this.addConcessionType}
            handleEditConcessionTypeSubmit={this.updateConcessionType}
            handleConcessionTypemodalClose={this.handleConcessionTypemodalClose}
            handleConcessionTypeDeletemodalClose={this.handleConcessionTypeDeletemodalClose}
            handleConcessionTypeDeletemodal={this.state.handleConcessionTypeDeletemodal}
            handleConcessionTypeEditmodal={this.state.handleConcessionTypeEditmodal}
            handleConcessionTypeEditmodalClose={this.handleConcessionTypeEditmodalClose}
            handleConcessionTypemodalOpen={this.handleConcessionTypemodalOpen}
            handleConcessionTypeEditmodalOpen={this.handleConcessionTypeEditmodalOpen}
            handleConcessionTypeDeletemodalOpen={this.handleConcessionTypeDeletemodalOpen}
            selectedConcessionTypeItem={this.state.selectedConcessionTypeItem}
            handleConcessionTypemodal={this.state.handleConcessionTypemodal}
            handleDeleteConcessionTypeSubmit={this.deleteConcessionType.bind(this)}
          />
          <CustomDeleteModal
            DialogueDeletetitle={"Delete Concession"}
            open={this.state.deleteModalOpen}
            handleClose={this.handleDeleteModalClose}
            handleSubmit={this.handleModalFormDelete}
            msgHandle={this.state.deleteMessage}
          />
          <ViewConcession
            concessionDetails={this.state.selectedConcessionItem}
            concessionDetailserrorMessage={
              this.state.concessionDetailserrorMessage
            }
            open={this.state.viewModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
          />
          <BulkUpdateConcession
            submitLoading={this.state.submitLoading}
            open={this.state.bulkUpdateConcessionModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
            concessionTypeList={this.state.ConcessionTypeList}
            checkBulkUpdateId={this.state.checkBulkUpdateRow}
            concessionbulkUpdateerrorMessage={
              this.state.concessionbulkUpdateerrorMessage
            }
          />

          <Grid container justify="flex-end" alignItems="center">
            {this.state.concessionListMeta &&
              this.state.concessionListMeta.total_pages > 0 && (
                <Pagination
                  page={page}
                  handlePageClick={this.handlePageClick}
                  totalPageCount={
                    this.state.concessionListMeta
                      ? this.state.concessionListMeta.total_pages
                      : 0
                  }
                />
              )}
          </Grid>
        </Grid>
      </Layout>

      //Merge Engine End DefaultContainer
    );
  }

  // Customizable Area Start
  private renderFilterForm() {
    const { classes } = this.props;
    const {
      filterForm: {
        amount_start,
        amount_end,
        fee_concession_type_id,
        mode,
        valid_until
      },
      rupeeIcon,
      percentageIcon
    } = this.state;

    return (
      <Grid
        container
        spacing={2}
        style={{
          marginTop: "10px"
        }}
      >
        <Grid item md={6}>
          <OutlinedSelect
            val={fee_concession_type_id}
            options={this.state.ConcessionTypeList}
            label="Concession Type"
            name={"concession_type_id"}
            formControlStyle={{
              width: "100%",
              margin: 0
            }}
            handleChange={this.onChangeFilterConcessionType} className={undefined}          />
          {/* {this.state.filterRoleError ? <p className="errorMsg2">{this.state.filterRoleError}</p> :null} */}
        </Grid>
        <Grid item md={6}>
          <FormControl style={{ minWidth: "100%" }}>
            <FormLabel style={{ marginBottom: "8px" }}>valid Until</FormLabel>
            <DatePicker
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              calendarClassName="Calender"
              onChange={this.handleDateChange}
              name={"valid_until"}
              value={valid_until}
              calendarIcon={<img style={{height:"27px"}}
              src={this.state.calenderIcon} />}
                />
            {/* <TextField
              placeholder={`Select date`}
              value={valid_until}
              style={
                // concessionValidUntilError
                // ?
                // {
                //     border: "1px solid #d74a3b",
                //     outline: "0px",
                //     width: "100%"
                //   }
                // :
                {
                  border: "none",
                  width: "90%"
                }
              }
              color={"primary"}
              variant="outlined"
              type={"date"}
              InputLabelProps={{ shrink: true }}
              name={"valid_until"}
              onChange={this.onChangeFilterDate}
            /> */}
          </FormControl>

          {/* {this.state.filterStatusError ? <p className="errorMsg2">{this.state.filterStatusError}</p> :null} */}
        </Grid>
        <Grid item md={4} style={{ display: "flex" }}>
          <OutlinedSelect
            labelStyle={{ marginBottom: "0px" }}
            val={mode}
            options={[
              {
                id: "all",
                name: "All"
              },
              {
                id: "amount",
                name: (
                  <span>
                    <img
                      style={{ height: "15px", width: "15px" }}
                      src={rupeeIcon}
                      alt="rupeeIcon" />
                  </span>
                )
              },
              {
                id: "percentage",
                name: (
                  <span>
                    <img
                      style={{ height: "15px", width: "15px" }}
                      src={percentageIcon}
                      alt="percentageIcon" />
                  </span>
                )
              }
            ]}
            label="Mode"
            name={"mode"}
            formControlStyle={{
              width: "54%",
              margin: 0,
              display: "flex",
              borderRadius: "7px",
              flexDirection: "row",
              alignItems: "center",
              minWidth: "75%",
              height: "45px",
              justifyContent: "space-between"
            }}
            styleOverrides={{ height: "30px", marginLeft: "5px" }}
            handleChange={this.onChangeFilterMode}
            placeholderTitle={""} className={undefined}          />
        </Grid>
        <Grid item md={12}>
          <CommonSlider
            onChange={this.handleFilterSliderRange.bind(this)}
            valueLabelDisplay="on"
            start_range={amount_start}
            end_range={amount_end}
            min={0}
            max={this.state.max}
          />
        </Grid>
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(Concession);
