import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import { defaultBtn } from "../../CommonLayout/Layout/src/color";
import AdHocReportingController, {
  Props,
} from "./AdHocReportingController";
import { capitalizeFirst } from "../../../framework/src/Utilities";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import { FormControl, FormLabel } from "@material-ui/core";
import DatePicker from "react-date-picker";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import CommonCardCount from "./CommonCardCount";
import Loader from "../../../components/src/Loader.web";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";

// Customizable Area End

// Customizable Area Start

interface feeRecvData {
  id: number;
  registration_no: string;
  roll_no: string;
  division: string;
  class: string;
}

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  searchbar: {
    width: "85%",
    marginLeft: "139px",
  },
  gridContainer: {
    marginTop: "95px",
    marginRight: "8px",
    marginBottom: "20px"
  }, menu: {
    marginLeft: "-25px",
    marginTop: "15px",
    width:"100%",
    "& .MuiPaper-root": {
      overflow: "visible",
    }
  },
  pageTitle: {
    fontWeight: "bold"
  },
  TotalHeader:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width:"32%"
  },
  actionBtn: {
    marginRight: "8px",
    borderRadius: "8px",
  },
  featureBtn: {
    borderRadius: "5px",
    border: `1px solid #ffb2b2`,
    backgroundColor: "#ffffff",
    margin: "10px",
    padding: "18px",
    '&:hover': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      color: "white",
      opacity: "1",
    },
    '&:active': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      opacity: "1",
      boxShadow: 'none',
      color: "white",
    }, '&:focus': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
    },
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    // height: "calc(100vh - 381px) !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableGrid: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap",

  },
  heading: {
    width: "81%",
    backgroundColor: "#fcf3d4",
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 5
  },
  filterBar: {
    width: "18%",
    height: 60,
    display: "flex",
    alignItems: "center",
    borderRadius: 5,

  },
  bulkUpdateBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#fffff",
    border: "1px solid #4b4f5f",
    borderRadius: "5px",
    color: "#455366",
  },

  createBtn: {

    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '14px 44px',
    outline: 0,
    border: 0,
    width: 185,
    lineHeight: 1.5,
    backgroundColor: defaultBtn,
    borderColor: defaultBtn,
    color: "white",
    orderRadius: "10px",
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      boxShadow: 'none',
      color: "white",
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      color: "white",
      border: "none",
      outline: 0,
    },
  },
  menuItem: {
    "&:hover": {
      background: defaultBtn,
      color: "white"
    },
  },
  arrowUp: {
    position: "absolute",
    width: "16px",
    height: "12px",
    top: "-12px",
    zIndex: 1,
   
    '&.left': {
      left: "10px",
    },
    '&.right': {
      right: "10px",
      boxShadow: "0px 29px 8px -3px rgb(0 0 0 / 20%)",
    },
    '&.center': {
      left: "73%",
      transform: "translateX(-50%)",
    },
    '&:before': {
      content: "''",
      position: "absolute",
      top: "0",
      left: "0",
      borderStyle: "solid",
      borderWidth: "0 8px 12px",
      borderColor: "#FFF transparent",
      display: "block",
      width: 0,
      zIndex: 0,
    }
  },
  headingLayout: {
    width: "63%",
    backgroundColor: "#fcf3d4",
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "5px",
  },
  desTextConcat: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "190px",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
  },
  title: {
    color: "#656c7c",
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "28px",
  },
  listItemTitle: {
    color: "#e86a67",
    fontWeight: 600
  },
  
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 16,
    marginBottom: 10,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
  },

});

// Customizable Area End

class FeeReceivables extends AdHocReportingController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // localStorage.clear();
    // Customizable Area End
  }
 
  
  render() {

    // Customizable Area Start
    // console.log('getdata',this.state.feeReceivableData);
     const { classes } = this.props;
     const { searchKeyword,feeReceivableData,feeReceivablesListMeta,page,showLoader } = this.state;
     const cardCountData = [
      {
        title: 'Fee Receivables',
        icon: this.state.feeReceivable,
        amount:<span style={{color:"black"}}>{'₹ 50,297.35'}</span> ,
      },
      {
        title: 'Fee Outstanding',
        icon: this.state.feeOutstanding,
        amount: <span style={{color:"red"}}>{'₹ 50,297.35'}</span>,
      },
      {
        title: 'Fee Received',
        icon: this.state.feeReceived,
        amount:<span style={{color:"rgb(62 187 63)"}}>{'₹ 50,297.35'}</span>,
      }
     ];
     const feeRecvData = [
      {
        id:1,
        class:'Grade 3',
        division:'A',
        enrolled_students:40,
        receivables:'50,000.00',
        received:'40,000.00',
        outstanding:'₹ 10,000.00',
      },
      {
        id:2,
        class:'Grade 5',
        division:'B',
        enrolled_students:32,
        receivables:'36,000.00',
        received:'20,000.00',
        outstanding:'₹ 16,000.00',
      },
      {
        id:3,
        class:'Grade 7',
        division:'B',
        enrolled_students:50,
        receivables:'66,000.00',
        received:'40,000.00',
        outstanding:'26,000.00',
      }
     ];
    // Customizable Area End

    return (
      // Customizable Area Start
      //handleGlobalSearch={this.handleSearchChange}
      <Layout searchKeyword={searchKeyword} navigation={this.props.navigation} >
        <Grid className={classes.tableGrid} container>
          <PageHeader
            title="Fee Receivables"
            haveCreateButton={false}
            FilterTitle="Select fee receivable"
            filterForm={this.renderFilterForm()}
          />
          <CommonCardCount classes={this.props.classes} cardCountData={cardCountData}/>
            <TableContainer style={{ overflow: '' }} ref={(el: any) => this.feeRecvSelectorRef = el} component={Paper} className={classes.tableContainer}>
            {showLoader && <Loader loading={showLoader} positionType={'center'} />}
                <Table ref={this.feeRecvtableRef} className={classes.table} aria-label="a dense table">
                 
                  <TableHead style={{ position: "sticky", top: "0px", zIndex: 9, }}>
                    <TableRow>
                      <TableCell style={{paddingLeft:"40px"}}>Class</TableCell>
                      <TableCell style={{paddingLeft:"25px"}}>Division</TableCell>
                      <TableCell style={{paddingLeft:"25px"}}>Enrolled Students</TableCell>
                      <TableCell style={{paddingLeft:"25px"}}>Receivables</TableCell>
                      <TableCell style={{paddingLeft:"25px"}}>Received</TableCell>
                      <TableCell style={{paddingLeft:"25px"}}>Outstanding</TableCell>
                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    {(feeReceivableData && feeReceivableData.length) ? feeReceivableData.map((row: feeRecvData) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center",paddingLeft:"31px" }}>
                            <Typography variant="subtitle1" >{row.class}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center",paddingLeft:"20px" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.division)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center",paddingLeft:"65px" }}>
                            <Typography variant="subtitle1" >{row.roll_no}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center",paddingLeft:"20px" }}>
                            <Typography variant="subtitle1" >{'₹ 50,0000'}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center",paddingLeft:"20px" }}>
                            <Typography variant="subtitle1" >{'₹ 15,000'}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center",paddingLeft:"20px" }}>
                            <Typography variant="subtitle1" >{'₹ 50,000'}</Typography>
                          </Grid>
                        </TableCell>
                        
                      </TableRow>
                    )) : null}
                  
                  </TableBody>

                </Table>
              </TableContainer>
              <Grid container justify="flex-end" alignItems="center">
                {(feeReceivablesListMeta && feeReceivablesListMeta.total_pages > 0) &&
                  <Pagination page={page} handlePageClick={this.handlePageClick} totalPageCount={feeReceivablesListMeta ? feeReceivablesListMeta.total_pages : 0} />
                }
              </Grid>
         
        </Grid >
      </Layout>
      // Customizable Area End
    );
  }
  private renderFilterForm() {
    return (
      <Grid
        container
        spacing={2}
        style={{
          padding: "10px 5px"
        }}
      >
        <Grid item md={6}>
          <FormControl style={{ minWidth: "100%" }}>
            <FormLabel style={{ marginBottom: "8px" }}>From</FormLabel>
            <DatePicker
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              calendarClassName="Calender"
              onChange={undefined}
              name={"valid_until"}
              value={null}
              calendarIcon={<img style={{height:"27px"}}
              src={this.state.calenderIcon} />}
                />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl style={{ minWidth: "100%" }}>
            <FormLabel style={{ marginBottom: "8px" }}>To</FormLabel>
            <DatePicker
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              calendarClassName="Calender"
              onChange={undefined}
              name={"valid_until"}
              value={null}
              calendarIcon={<img style={{height:"27px"}}
              src={this.state.calenderIcon} />}
                />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <OutlinedSelect
            val={null}
            options={[]}
            label="Class"
            name={"concession_type_id"}
            placeholderTitle={'Select Class'}
            formControlStyle={{
              width: "100%",
              margin: 0,
              borderRadius:"10px"
            }}
            handleChange={{}} 
            className={"filtersReport"}  
            />
        </Grid>
        
      </Grid>
    );
  }
}
export default withStyles(useStyles)(FeeReceivables);
