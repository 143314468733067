import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { deleteIcon, concessionTypeEditIcon, dropdownIcon } from "./assets";
import { arrow} from "../../AdHocReporting/src/assets";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  open: boolean;
  handleClose: any;
  handleSubmit:(formData: object) => void;
  handleEditConcessionTypeSubmit:(formData: object) => void;
  handleDeleteConcessionTypeSubmit:() => void;
  handleDownloadCSV?: any;
  importerrorMessage: string;
  ConcessionTypeList: any;
  selectedConcessionTypeItem:any;
  handleConcessionTypeEditmodal:any;
  handleConcessionTypeEditmodalClose:any;
  handleConcessionTypeDeletemodalClose:any
  handleConcessionTypeDeletemodal:any
  handleConcessionTypemodalOpen:any
  handleConcessionTypemodal:any
  handleConcessionTypemodalClose:any
  handleConcessionTypeEditmodalOpen:any
  handleConcessionTypeDeletemodalOpen:any
  concessionTypeCreateerrorMessage:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  concessionTypeForm: InitialField;
  concessionType: any;
  concessionDescription: string;
  concessionTypeError: any;
  concessionDescriptionError: any;
  showList: boolean;
  metaMsg:any;
  deleteIcon: any;
  concessionTypeEditIcon: any;
  dropdownIcon: any;
  handalConcessionTypeEditmodal: boolean;
  selectedConcessionTypeItem:any;
  deleteModalOpen:boolean;
  arrowIcon:any;
  //   downloadIcon: any;
  // Customizable Area End
}
interface concessionTypeData {
  id: number;
  attributes:concessionTypeAttribute
}
interface concessionTypeAttribute {
  id:any;
  name: string;
  description:string;
}
interface InitialField {
  fee_concession_type: string;
  description: string;
}
interface SS {
  id: any;
}
const concessionInitialField: InitialField = {
  fee_concession_type: "",
  description: ""
};

export default class CreateConcessionTypeController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      showList: false,
      txtSavedValue: "A",
      selectedConcessionTypeItem:'',
      enableField: false,
      concessionType: "",
      concessionTypeError: "",
      metaMsg:'',
      arrowIcon:arrow,
      deleteModalOpen:false,
      concessionDescriptionError: "",
      concessionDescription: "",
      concessionTypeForm: concessionInitialField,
      deleteIcon: deleteIcon,
      concessionTypeEditIcon: concessionTypeEditIcon,
      dropdownIcon: dropdownIcon,
      handalConcessionTypeEditmodal: false
      //   downloadIcon: downloadIcon,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  handleChange = (e: any) => {
    let value = e.target.value;

    this.setState({
      concessionTypeForm: {
        ...this.state.concessionTypeForm,
        [e.target.name]: value
      }
    });
  };
  handleListShow = () => {
    this.setState({ showList: !this.state.showList });
  };
  async componentDidMount() {
    super.componentDidMount();
  }
 
  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open) {
      this.resetFormState();
    }
  }
 

  resetFormState() {
    this.setState({
      concessionTypeForm: concessionInitialField,
      concessionTypeError:'',
      showList:false
    });
   
  }

  handleValidation = (formState: InitialField) => {
    let isValid = true;
    // const regex = /^[a-zA-Z0-9_]{2,40}$/i;
    if (formState.fee_concession_type === "") {
      this.setState({ concessionTypeError: "Concession type is required" });
      isValid = false;
    } else {
      this.setState({ concessionTypeError: "" });
    }

    return isValid;
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation(this.state.concessionTypeForm)) {
      this.props.handleSubmit({
        concessionData: this.state.concessionTypeForm
      });
    }
  };
  // Customizable Area End
}
