import React from 'react';
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButtonMU from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import CustomDeleteModal from "../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import FineListController, { Props } from "./FineListController";
import FineModalForm from "./FineModalForm.web";
import { capitalize } from "lodash";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "40px",
    width: "40px"
  },
  collapseColumn: {
    maxWidth: "10px",
    width: "10px"
  },
  // arrowColumn: {
  //   maxWidth: "10px",
  //   width: "10px"
  // },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  }
});
// Customizable Area End

class FineList extends FineListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta: { total_pages },
      isLoading,
      fines,
      anchorEl,
      actionMenuItems,
      deleteModalOpen,
      selectAllCheckboxChecked,
      currentPage
    } = this.state;

    return (
      <Layout
        navigation={navigation}
        handleGlobalSearch={this.handleSearchChange}
      >
        <Grid container className={classes.container}>
          <PageHeader
            title="Fine"
            createButtonTitle="Create"
            importModalTitle="Import fine by CSV file"
            printReference={this.printReferance}
            FilterTitle={"Select Fine"}
            filterForm={this.renderFilterForm()}
            onClickPrint={() => this.setState({ showAllRows: true })}
            onAfterPrint={() => this.setState({ showAllRows: false })}
            onClickCreateButton={this.onClickCreate.bind(this)}
            onUploadCSVForImport={this.onUploadCSVForImport.bind(this)}
            onClickExportCSVButton={this.onClickExportCSVButton.bind(this)}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            onClickDownloadCSVTemplate={this.onClickSampleCSVButton}
            // importErrorMessage={this.state.importErrorMessage}
          />

          <TableContainer
            style={{ overflow: isLoading ? "hidden" : "" }}
            component={Paper}
            className={classNames(classes.mt20, classes.tableContainer)}
            ref={(el: any) => (this.printReferance = el)}
          >
            {isLoading && <Loader loading positionType={"center"} />}

            <Table
              ref={this.tableRef}
              stickyHeader
              className={classes.table}
              aria-label="Fines"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classNames(
                      classes.tableHead,
                      classes.collapseColumn
                    )}
                  />
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.actionColumn,
                      classes.tableHead
                    )}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      inputProps={{ "aria-label": "select all tax reates" }}
                      onChange={this.onSelectAllFines.bind(this)}
                      indeterminate={
                        selectAllCheckboxChecked === "indeterminate"
                      }
                      checked={!!selectAllCheckboxChecked}
                      key={`tax-rate-super-checkbox-${selectAllCheckboxChecked}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHead}>ID</TableCell>
                  <TableCell className={classes.tableHead}>Fine Name</TableCell>
                  <TableCell className={classes.tableHead}>
                    Fine Category
                  </TableCell>
                  <TableCell className={classes.tableHead}>Days</TableCell>
                  <TableCell className={classes.tableHead}>
                    Fine Amount
                  </TableCell>
                  <TableCell className={classes.tableHead}>Mode</TableCell>

                  <TableCell className={classes.tableHead} align="center" />
                  <TableCell className={classes.tableHead} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {fines.length ? (
                  fines.map((row, i) => {
                    const {
                      id,
                      selected,
                      attributes: {
                        custom_id,
                        name,
                        day,
                        duration,
                        fine_amount,
                        fine_category,
                        mode,
                        sub_fines
                      }
                    } = row;
                    console.log("row.sub_fines", row);
                    return (
                      <>
                        <TableRow key={`fine-${id}`}>
                          <TableCell
                            // align="center"
                            // className={classes.actionColumn}
                            size="small"
                          >
                            {row.attributes.sub_fines &&
                              row.attributes.sub_fines[0] && (
                                <IconButtonMU
                                  size="small"
                                  aria-label="expand row"
                                  onClick={() =>
                                    this.setState({
                                      showAllRows: false,
                                      openedRow:
                                        this.state.openedRow === i
                                          ? undefined
                                          : i
                                    })
                                  }
                                >
                                  {this.state.openedRow === i ? (
                                    <ArrowDropDown />
                                  ) : (
                                    <ArrowRight />
                                  )}
                                </IconButtonMU>
                              )}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.actionColumn}
                          >
                            <Checkbox
                              className={classes.checkbox}
                              value={selected}
                              checked={selected}
                              inputProps={{ "aria-label": "select fine" }}
                              key={`${id}-checkbox-${selected}`}
                              onChange={event => this.onSelectFine(event, row)}
                            />
                          </TableCell>
                          <TableCell>{capitalize(custom_id)}</TableCell>
                          <TableCell>{capitalize(name)}</TableCell>
                          <TableCell>
                            {capitalize(fine_category).replace("_", " ")}
                          </TableCell>
                          <TableCell>
                            {duration === "month"
                              ? day * 30
                              : duration === "week"
                              ? day * 7
                              : day}
                          </TableCell>
                          <TableCell>{fine_amount}</TableCell>
                          <TableCell>{capitalize(mode)}</TableCell>
                          <TableCell
                            align="center"
                            className={classes.actionColumn}
                          >
                            <IconButton
                              icon={Icon.Action}
                              borderless
                              className={classes.actionColumnButton}
                              onClick={event =>
                                this.onClickActionButton(event, row)
                              }
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                        {(this.state.openedRow === i ||
                          this.state.showAllRows) &&
                          (row.attributes.sub_fines &&
                            row.attributes.sub_fines.length > 0) && (
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={10}
                              >
                                <Collapse
                                  in={
                                    this.state.openedRow === i ||
                                    this.state.showAllRows
                                  }
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box style={{ margin: 1 }}>
                                    <Table
                                      stickyHeader
                                      className={classes.table}
                                      aria-label="Sub fines"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className={classes.tableHead}
                                          >
                                            Fine Name
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableHead}
                                          >
                                            Fine Category
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableHead}
                                          >
                                            Days
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableHead}
                                          >
                                            Fine Amount
                                          </TableCell>

                                          <TableCell
                                            className={classes.tableHead}
                                            style={{ paddingRight: "155px" }}
                                          >
                                            Mode
                                          </TableCell>
                                          {/* <TableCell
                                      className={classes.tableHead}
                                      align="center"
                                    /> */}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {row.attributes.sub_fines &&
                                          row.attributes.sub_fines.map(
                                            subFine => (
                                              <TableRow key={subFine.id}>
                                                <TableCell>
                                                  {capitalize(subFine.name)}
                                                </TableCell>
                                                <TableCell>
                                                  {capitalize(
                                                    fine_category
                                                  ).replace("_", " ")}
                                                </TableCell>
                                                <TableCell>
                                                  {subFine.day}
                                                </TableCell>
                                                <TableCell>
                                                  {subFine.fine_amount}
                                                </TableCell>
                                                <TableCell>
                                                  {capitalize(subFine.mode)}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                      </>
                    );
                  })
                ) : !this.state.isLoading ? (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      style={{
                        color: "red",
                        fontWeight: 600,
                        fontSize: "18px",
                        textAlign: "center"
                      }}
                    >
                      {"No Record Found"}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justify="flex-end" alignItems="center">
            <Pagination
              totalPageCount={total_pages}
              page={currentPage}
              handlePageClick={this.onClickPagination.bind(this)}
            />
          </Grid>
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          arrowSide="right"
          items={actionMenuItems}
          anchorOrigin={{ vertical: -12, horizontal: -48 }}
        />

        <CustomDeleteModal
          DialogueDeletetitle={"Delete Fine"}
          open={deleteModalOpen}
          handleClose={this.onCloseDeleteModal.bind(this)}
          handleSubmit={this.onSubmitDeleteModal.bind(this)}
          msgHandle={this.state.deleteMessage}
        />

        <FineModalForm
          {...this.formProps[this.state.formMode]}
          isOpen={this.state.isFormModalOpened}
          initialValues={this.state.form}
          formErrors={this.state.formErrors}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  private renderFilterForm() {
    const {
      filterForm: { name, fine_category }
    } = this.state;

    return (
      <Grid container direction="column">
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "30px"
          }}
        >
          <OutlinedSelect
            val={name}
            options={this.state.fineNames.map((el: string) => ({
              id: el,
              name: el
            }))}
            label="Fine Name"
            name={"fine_name"}
            placeholderTitle="Select Name"
            formControlStyle={{
              width: "180px",
              margin: 0
            }}
            handleChange={this.onChangeFilterFineName.bind(this)}
            className={undefined}
          />
          <OutlinedSelect
            val={fine_category}
            options={[
              { id: "miscellaneous", name: "Miscellaneous" },
              { id: "late_fine", name: "Late Fine" }
            ]}
            label="Fine Category"
            placeholderTitle="Select Category"
            name={"fine_category"}
            formControlStyle={{
              width: "180px",
              margin: 0
            }}
            handleChange={this.onChangeFilterFineCategory.bind(this)}
            className={undefined}
          />
        </Grid>
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(FineList);
