import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
// Customizable Area Start
import { IVendor } from "../../CommonLayout/VendorSelectionModal/src/VendorSelectionModalController";
import { BillAttributes } from "./CentralisedBillingController";
export const configJSON = require("./config.js");

export interface IPaymentTerms {
    isNew?: boolean;
    id: string;
    type?: string;
    account_id?: number;
    no_of_days: number;
    status?: boolean;
    term_name: string;
}

export interface ITDS {
    id: string;
    type: string;
    attributes: {
      id: number;
      section: string;
      tax_rate: number;
      tax_name: string;
      status: boolean;
    }
  }

export interface ITDSSection {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
    }
  }

export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    createPaymentTerms: (paymentTerms: Array<IPaymentTerms>) => void;
    deletePaymentTerms: (data:any) => void;
    paymentTerms: Array<IPaymentTerms>;
    handleAddNewPaymentField: () => void;
    handleChangePaymentTerms: (e: React.ChangeEvent<HTMLInputElement>,i:number) => void;
    createTDS:(tds: any) => void;
    createSection: (section: any) => void;
    tds: Array<ITDS>;
    tdSection: Array<ITDSSection>;
    vendorModalOpen: boolean;
    onCloseVendorModal: () => void;
    OnOpenVendorModal: () => void;
    vendors:Array<IVendor>;
    onSubmitVendorModal: (id:string) => void;
    handleItemAddMore: () => void;
    billData:BillAttributes;
    handleRemoveItem: (index:number) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    discountTypeState: string;
    handleReverChargeShow: () => void;
    showReverseCharge: boolean;
}


interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    isPaymentTermsModalOpened: boolean;
    isTDSModalOpened:boolean;
}

interface SS {
    id: any;
}

// const accountInitialField: InitialField =
// { account_type_id: "",name: "",code: "",description: "",parent_academic_account_id: "",
//     is_sub_account: false
// };
// Customizable Area End
export default class CreateBillController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            closeModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            isPaymentTermsModalOpened: false,
            isTDSModalOpened:false,
            //accountFormstate: accountInitialField,
            //accNameError: "",
            //accTypeError: "",
            //subAccountStatus: false,
            //parentAccError: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    // componentDidUpdate(prevProps: Props) {
    //     if (prevProps.open !== this.props.open) {
    //        // this.resetFormState();
    //     }
    // }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.handleClose("create", false);
    }

    handleDateChange = (value: any) => {
    }

    public openTDSModal() {
        this.setState({
          isTDSModalOpened: true
        });
      }

    public onCloseTDSModal() {
        this.setState({
          isTDSModalOpened: false
        });
      }
    public onTDSModalSubmit(tds: any) {
        this.props.createTDS(tds);
        this.setState({
          isTDSModalOpened: false
        });
      }
    public onSectionSubmit(section: string) {
        this.props.createSection(section);
      }

    onPaymentTermsModalSubmit=(paymentTerms: Array<IPaymentTerms>)=> {
        this.props.createPaymentTerms(paymentTerms);
        this.setState({
          isPaymentTermsModalOpened: false
        });
    }

    onClickDelete=(index:number)=> {
        this.props.deletePaymentTerms(index);
    }

    onClosePaymentTermsModal = ()=> {
        this.setState({
          isPaymentTermsModalOpened: false
        });
    }

    onChangePaymentTerms =(element: React.ChangeEvent<{ value: string }>)=> {
        this.setState({
        //   form: {
        //     ...this.state.form,
        //     payment_term_id: element.target.value
        //   }
        });
      }

      public openPaymentTermsModal=() => {
        this.setState({
          isPaymentTermsModalOpened: true
        });
      }

      

    // Customizable Area End
}