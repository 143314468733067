// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { HtmlHTMLAttributes } from "react";
import FeeAssignmentForm from "./FeeAssignmentForm";
import { AnyRecord } from "dns";
import { feeAssignmentData, installmentMultiFieldAttributes } from './FeeAssignmentController';
import { object } from "yup";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start

    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    handleSubmit: (formData: object) => void;
    handleChange: () => void;
    academicYearList: [];
    academicClassList: [];
    divisionList: [];
    studentList: [];
    feeList: [];
    taxList: [];
    fineList: [];
    invoiceTemplateList: [];
    emailTemplateList: [];
    getStudentList:(id: number, division_id: number,fee_structure:number,status:string) => void;
    handleAcademicDivisionByClass: (id: string) => void;
    handleStudentsByDivision: (id: number, division_id: number, fee_structure: number, status: string,student_name: string) => void;
    studentListerrorMessage: string;
    feeAssignmentList: (page: number) => void;
    feeAssignmentData: feeAssignmentData;
    feeStructureNameList: [];
    viewForm: boolean;
    // Customizable Area End

}

// Customizable Area Start

interface feeStructureInitialField {
    id: number,
    amount: string;
    tax_ids: string[];
}
interface checkBulkRow {
    [key: number]: boolean;
}
interface feeListItem {
    id: number;
    name: string;
    amount: string;
    tax: string[];
}

interface installmentCycleField {
    name: string,
    due_date: Date|null,
    fine_id: number
    max_date: Date|null,
    min_date: Date|null,
}
interface feeStructureErrorField {
    feeNameError: string;
    feeAmountError: string;
    feeTaxError: string;
}
interface feeInstallmentDetailsErrorField {
    installmentNameError: string,
    installmentNameDueDateError: string,
    installmentFineError: string
}
interface installmentAttributes {
    name: string,
    installment_code: string,
    total_amount: number,
    concession: number|null,
    concession_type: string,
    discounted_amount: number,
    bill_duration_no: number|null,
    bill_duration: string,
    billing_cycle: number|null,
    payment_collection: string,
    invoice_template_id: number,
    email_template_id: number,
    payment_option: string,
    installment_details_attributes: installmentCycleField[],
}
interface feeStructuresAttribute {
    name: string,
    status: string
}

interface studentData {
    id: number,
    email: string,
    fee_structures: feeStructuresAttribute[]
}

interface studentID {
    student_ids: string[]
}

interface InitialField {
    feeAssignmentType: string;
    academic_year_id: number;
    grade_id: number;
    division_id: number;
    grade: string,
    division: string,
    name: string;
    is_class_and_division: boolean;
    is_group: boolean;
    is_class: boolean;
    fee_type: string;
    group_attributes: studentID;
    sub_fee_structures_attributes: feeStructureInitialField[];
    installments: installmentAttributes;
    installmentFormAttributes: installmentAttributes[];
    sum_total_amount: number;
}
interface S {
    step: number;
    filterModalStatus: HTMLElement | null;
    AddStudentStatus: boolean;
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    feeAssignmentFormstate: InitialField;
    feeStructureNameError: string;
    academicYearError: string;
    subAccountStatus: boolean;
    parentAccError: string;
    feeAssignmentTypeError: string;
    feeAssignmentClassError: string;
    feeAssignmentDivisionError: string;
    maxDueDate:Date[];
    minDueDate:Date[];
    feeAssignmentGroupError: string;
    feeStructureError: feeStructureErrorField[];
    feeInstallmentError: feeInstallmentDetailsErrorField[];
    feeInstallmentNameError: string;
    feeInstallmentDurationError: string;
    feeInstallmentBillDurationError: string;
    feeInstallmentBillingCycleError: string;
    installmentPlanDetailsData: installmentMultiFieldAttributes | null;
    installPlanAction: string;
    updatePlanIndex: string;
    deleteModalOpen: boolean;
    deletePlanId: string;
    showForm: boolean;
    currentStep: number;
    currentForm: string;
    studentFilterFeeStrucure: number;
    studentFilterStatus: string;
    filterStudentError: string;
    searchStudentKeyword: string;
    allStudentRowsChecked:any;
    checkStudentRow:checkBulkRow | []
    feeInstallmentConcessionError:string;
    feeInstallmentConcessionTypeError:string;
}

interface SS {
    id: any;
}

const feeStructureAddMoreField: feeStructureInitialField = {
    id: 0, amount: '', tax_ids: [],
};
const studentIdInitial: studentID = {
    student_ids: []
};
const feeStructureErrorInitialField: feeStructureErrorField = { feeNameError: "", feeAmountError: "", feeTaxError: '' };
const installmentCycleInitialField: installmentCycleField = { name: '', due_date: null, fine_id: 0,max_date:null ,min_date:new Date};
const installmentCycleErrorInitialField: feeInstallmentDetailsErrorField = { installmentNameError: '', installmentNameDueDateError: '', installmentFineError: '' };
const installmentsInitialField: installmentAttributes = {
    name: '',
    installment_code: '',
    total_amount: 0,
    concession: null,
    concession_type: '',
    discounted_amount: 0,
    bill_duration_no: null,
    bill_duration: '',
    billing_cycle: null,
    payment_collection: 'manual_due_dates',
    invoice_template_id: 0,
    email_template_id: 0,
    payment_option: 'razorpay',
    installment_details_attributes: [],

}
const feeAssignmentInitialField: InitialField = {
    academic_year_id: 0,
    grade_id: 0,
    division_id: 0,
    name: '',
    grade: "",
    division: "",
    is_class_and_division: false,
    is_group: false,
    is_class: true,
    feeAssignmentType: 'is_class',
    fee_type: 'academic_fee',
    group_attributes: studentIdInitial,
    sub_fee_structures_attributes: [{ ...feeStructureAddMoreField }],
    installments: installmentsInitialField,
    installmentFormAttributes: [],
    sum_total_amount: 0,
};
// Customizable Area End

export default class CreateFeeAssignmentController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            step: 0,
            feeAssignmentTypeError: '',
            filterModalStatus: null,
            AddStudentStatus: false,
            closeModal: false,
            feeAssignmentClassError: "",
            data: [],
            maxDueDate:[],
            minDueDate:[],
            feeAssignmentDivisionError: "",
            feeInstallmentNameError: '',
            feeAssignmentGroupError: "",
            checkStudentRow: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            allStudentRowsChecked: false,
            feeAssignmentFormstate: feeAssignmentInitialField,
            feeStructureNameError: "",
            academicYearError: "",
            subAccountStatus: false,
            parentAccError: "",
            feeStructureError: [{ ...feeStructureErrorInitialField }],
            feeInstallmentError:[{...installmentCycleErrorInitialField}],
            feeInstallmentDurationError:"",
            feeInstallmentBillDurationError:'',
            feeInstallmentBillingCycleError:"",
            feeInstallmentConcessionTypeError:"",
            feeInstallmentConcessionError:'',
            installmentPlanDetailsData:null,
            installPlanAction: 'create',
            updatePlanIndex: '',
            deleteModalOpen: false,
            deletePlanId: '',
            showForm: false,
            currentStep: 0,
            currentForm: '',
            studentFilterFeeStrucure: 0,
            studentFilterStatus: '',
            filterStudentError: '',
            searchStudentKeyword: '',
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.open !== this.props.open) {
            this.resetFormState();
        }
        console.log('finl form', this.state.feeAssignmentFormstate)
    }

    resetFormState() {
        this.setState({
            feeAssignmentFormstate: feeAssignmentInitialField,
            feeStructureNameError: "",
            academicYearError: "",
            subAccountStatus: false,
            parentAccError: "", step: 0,
            showForm: false,
            currentStep: 0,
            currentForm: '',
            checkStudentRow: [],
            allStudentRowsChecked:false,feeAssignmentTypeError: '',
            feeAssignmentClassError: "",
            feeAssignmentDivisionError: "",
            feeInstallmentNameError: '',
            feeAssignmentGroupError: "",
            feeStructureError: [{ ...feeStructureErrorInitialField }],
            feeInstallmentError:[{...installmentCycleErrorInitialField}],
            feeInstallmentDurationError:"",
            feeInstallmentBillDurationError:'',
            feeInstallmentBillingCycleError:"",
            feeInstallmentConcessionTypeError:"",
            feeInstallmentConcessionError:'',
              });
        this.setState({feeAssignmentFormstate:{...feeAssignmentInitialField, sub_fee_structures_attributes:[{ ...feeStructureAddMoreField }]} });
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true, })
        this.props.handleClose("create", false);
        this.resetFormState();
    }
   
    handleAddStudent = () => {
        this.setState({ AddStudentStatus: !this.state.AddStudentStatus })
    }

    handleFilterButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ filterModalStatus: event.currentTarget })
    }
    onCloseFilterPopover = () => {
        this.setState({ filterModalStatus: null });
    }

    filterStudenthandleChange = (e: any) => {

        let { name, value } = e.target;
        if (name === 'status') {
            if (value === "null") {
                value = "";
            }
            this.setState({ 'studentFilterStatus': value });
        }
        if (name === 'fee_structure_name') {
            if (value === "null") {
                value = 0;
            }
            this.setState({ 'studentFilterFeeStrucure': value });
        }
    }

    handleStudentFilterValidation = (status: string, fee_structure: number) => {
        let isValid = true;
        console.log('fileterval', status, fee_structure);
        if (!fee_structure && !status) {
            this.setState({
                filterStudentError: "Atleast one field is required"
            });
            isValid = false;
        } else {
            this.setState({ filterStudentError: '' });
        }
        console.log('filter console', isValid);
        return isValid;
    };

    studentFilterApply = () => {
        const { grade_id, division_id } = this.state.feeAssignmentFormstate;
        const { studentFilterStatus, studentFilterFeeStrucure } = this.state;

        if (this.handleStudentFilterValidation(studentFilterStatus, studentFilterFeeStrucure)) {
            this.props.handleStudentsByDivision(grade_id, division_id, studentFilterFeeStrucure, studentFilterStatus,'');
            this.onCloseFilterPopover();
            this.setState({ 'studentFilterFeeStrucure': 0, 'studentFilterStatus': "" });
        }
        //getStudentList
    }

    handleSearchStudentChange = (e: any) => {
        this.setState({'searchStudentKeyword': e.target.value})
        const { grade_id, division_id } = this.state.feeAssignmentFormstate;
        this.props.handleStudentsByDivision(grade_id, division_id, 0, '',e.target.value);
        
    }

    handleButtonClick = () => {
        const structureFieldData = this.state.feeAssignmentFormstate.sub_fee_structures_attributes;
        structureFieldData.push({ ...feeStructureAddMoreField });
        this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, sub_fee_structures_attributes: structureFieldData } })

        const feeStructureError = this.state.feeStructureError;
        feeStructureError.push({ ...feeStructureErrorInitialField });
        this.setState({ feeStructureError: feeStructureError })
    }
    handleCheckStudentItem = (evt: React.FormEvent<HTMLInputElement>, id: number) => {
        const { checkStudentRow } = this.state;
        const newState = { ...checkStudentRow, [id]: evt.currentTarget.checked };
        // const disableBukUpload = this.bulkUpdateDisable(newState);
        let allRowsChecked = false;
        let checkAnyTrue = false;
        const trueCount = Object.keys(newState).filter((itm: string) => newState[parseInt(itm)]).length;
        if (trueCount === this.props.studentList.length) {
            allRowsChecked = true;
        } else {
            checkAnyTrue = (trueCount > 0) ? true : false;
        }

        this.setState({ checkStudentRow: newState, allStudentRowsChecked: (checkAnyTrue) ? "indeterminate" : allRowsChecked });
        console.log("checkStudentRow", checkStudentRow)
    };

    handleCheckAllStudents = (evt: React.FormEvent<HTMLInputElement>) => {
        if (this.props.studentList) {
            const checkAccount: checkBulkRow = {};
            this.props.studentList.map((item: studentData) => {
                checkAccount[item.id] = evt.currentTarget.checked;
            });
            console.log("==========================", checkAccount)
            this.setState({ allStudentRowsChecked: evt.currentTarget.checked, checkStudentRow: checkAccount });

        }
    };


    calculateConsession = (targetName: string, targetValue: string) => {
        let { concession, concession_type, discounted_amount, total_amount } = this.state.
            feeAssignmentFormstate.installments
        const totalSum = this.state.feeAssignmentFormstate.sum_total_amount;
        if (targetName === 'concession') {
            concession = parseFloat(targetValue);
        } else if (targetName === 'concession_type') {
            concession_type = targetValue;
        }

        if (concession && concession_type) {
            let discounamt = 0;
            if (concession_type === 'amount') {
                discounamt = totalSum - concession;
            } else {
                   let amount=(concession / 100) * totalSum
                discounamt = totalSum-amount;
            }
            return discounamt;
        } else {
            return totalSum;
        }
    }

    handleInstallmentForm = (e: any) => {
        let value = e.target.value;
        // this.handleValidation({...this.state.feeAssignmentFormstate, [e.target.name]: e.target.value});
        if (e.target.name === "bill_duration" || e.target.name === "invoice_template_id" || e.target.name === "email_template_id") {
            value = (e.target.value === "null") ? "" : e.target.value;

            if(e.target.name == "bill_duration"&& this.state.feeAssignmentFormstate.installments.billing_cycle && this.state.feeAssignmentFormstate.installments.bill_duration_no){
               
                // const installmentCycleFieldData: installmentCycleField[] = [];
                // installmentCycleInitialField['due_date'] = null
                // installmentCycleFieldData.push({ ...installmentCycleInitialField });
                const newState: any = [...this.state.feeAssignmentFormstate.installments.installment_details_attributes];
                for (let i = 0; i < this.state.feeAssignmentFormstate.installments.billing_cycle; i++) {
                    newState[i]["due_date"] = null;
                    newState[i]["max_date"] = null;
                    newState[i]["min_date"] = null;
                }
                this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, [e.target.name]: value, installment_details_attributes: newState }} });
            }else{
                this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, [e.target.name]: value, } } });
            }
            
        } else if (e.target.name === "billing_cycle") {
            const installmentCycleFieldData: installmentCycleField[] = [];
            const feeInstallmentError = this.state.feeInstallmentError;
            if (value > 0) {
                for (let i = 0; i < value; i++) {
                    installmentCycleInitialField['name'] = `Installment ${i+1} `
                    installmentCycleFieldData.push({ ...installmentCycleInitialField });
                    feeInstallmentError.push({ ...installmentCycleErrorInitialField });
                }
            }
            this.setState({ feeInstallmentError: feeInstallmentError })
            this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, [e.target.name]: value, installment_details_attributes: installmentCycleFieldData } } });

        } else if (e.target.name === "concession" || e.target.name === "concession_type") {
            if (e.target.name === "concession_type") {
                value = (e.target.value === "null") ? "" : e.target.value;
            }
            else {
                value = e.target.value;
            }
            const consession_amt = this.calculateConsession(e.target.name, value);
            console.log('consession_amt', consession_amt);
            this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, discounted_amount: consession_amt, [e.target.name]: value } } });
        } else {
            if(e.target.name === "bill_duration_no"&& this.state.feeAssignmentFormstate.installments.billing_cycle &&this.state.feeAssignmentFormstate.installments.bill_duration){
                const newState: any = [...this.state.feeAssignmentFormstate.installments.installment_details_attributes];
                for (let i = 0; i < this.state.feeAssignmentFormstate.installments.billing_cycle; i++) {
                    newState[i]["due_date"] = null;
                    newState[i]["max_date"] = null;
                    newState[i]["min_date"] = null;
                    
                }
                this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, [e.target.name]: value, installment_details_attributes: newState }} });
            }else{
                this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, [e.target.name]: value } } });
            }
            
        }

    }

    handleChange = (e: any) => {
        let value = e.target.value;
        // this.handleValidation({...this.state.feeAssignmentFormstate, [e.target.name]: e.target.value});
        if (e.target.name === "grade_id" || e.target.name === "division_id" || e.target.name === "academic_year_id") {
            let gradeName = this.state.feeAssignmentFormstate.grade;
            let divisionName = this.state.feeAssignmentFormstate.division;
            value = (e.target.value === "null") ? "" : e.target.value;
            // if (e.target.name === "academic_year_id") {
            //     this.props.handleAcademicClassByYear();
            // }
            if (e.target.name === "grade_id") {
                const filteredClassData: any = this.props.academicClassList.find((classres: any) => (classres.id === value));
                if (filteredClassData) {
                    gradeName = filteredClassData.name;
                }
                this.props.handleAcademicDivisionByClass(value);
            }
            if (e.target.name === "division_id") {
                const filteredDivData: any = this.props.divisionList.find((divres: any) => (divres.id === value));
                if (filteredDivData) {
                    divisionName = filteredDivData.name;
                }
            }
            if ((e.target.name === "division_id" || e.target.name === "grade_id") && this.state.feeAssignmentFormstate.feeAssignmentType === "is_group") {

                if (e.target.name === "grade_id") {
                    this.props.handleStudentsByDivision(value, 0, 0, '','');
                } else if (e.target.name === "division_id") {
                    this.props.handleStudentsByDivision(this.state.feeAssignmentFormstate.grade_id, value, 0, '','');
                }
            }
            this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, [e.target.name]: value, grade: gradeName, division: divisionName } });
        } else {
            let is_class = true;
            let is_class_and_division = false;
            let is_group = false;

            if (e.target.name === "feeAssignmentType") {

                if (e.target.value === "is_class") {
                    is_class = true;
                    is_class_and_division = false;
                    is_group = false;
                } else if (e.target.value === "is_class_and_division") {
                    is_class = false;
                    is_class_and_division = true;
                    is_group = false;
                } else {
                    is_class = false;
                    is_class_and_division = false;
                    is_group = true;
                }
            }
            this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, [e.target.name]: value, "is_class": is_class, "is_class_and_division": is_class_and_division, "is_group": is_group,grade_id:is_group?0:this.state.feeAssignmentFormstate.grade_id } });
        }

    };

    handleInstallmentAddMore = () => {

        this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: installmentsInitialField } })
        this.setState({ step: 2, currentForm: 'installmentForm' })
    }

    handleInstallmentPlan = (indx: number) => {

        let indexno = indx.toString();
        this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: this.state.feeAssignmentFormstate.installmentFormAttributes[indx] } })
        this.setState({ step: 2, currentForm: 'installmentForm', installPlanAction: 'edit', updatePlanIndex: indexno })
    }


    handleDeleteModalAction = (indx: number) => {
        let indexno = indx.toString();
        this.setState({ deleteModalOpen: true, deletePlanId: indexno });
    }
    handleDeleteModalClose = () => {
        this.setState({ deleteModalOpen: false, deletePlanId: '' });
    }

    handleModalFormDelete = () => {
        const { feeAssignmentFormstate, deletePlanId } = this.state;
        const indexId = parseInt(deletePlanId);
        const installmentArr = feeAssignmentFormstate.installmentFormAttributes;
        installmentArr.splice(indexId, 1);
        this.setState({ deleteModalOpen: false, deletePlanId: '', feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installmentFormAttributes: installmentArr } });
    }

    handleRemoveInstallmentStructure = (indx: number) => {
        const installmentStructureArr = this.state.feeAssignmentFormstate.sub_fee_structures_attributes;
        installmentStructureArr.splice(indx, 1);
        this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, sub_fee_structures_attributes: installmentStructureArr } })

    }

    handleFeeStructureField = (evt: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const prevState: any = this.state.feeAssignmentFormstate.sub_fee_structures_attributes;
        if (evt.target.name === "amount") {
            prevState[index][evt.target.name] = evt.target.value;
        } else if (evt.target.name === "tax_ids") {
            prevState[index]['tax_ids'] = evt.target.value;
        } else {
           let feeStructureValue = (evt.target.value === "null") ? "" : evt.target.value;
           if(feeStructureValue){
            const filteredData: feeListItem[] = this.props.feeList.filter((struct: feeListItem) => (struct.id === parseInt(feeStructureValue)));
            if (filteredData) {
                prevState[index][evt.target.name] = feeStructureValue ;
                prevState[index]['amount'] = filteredData[0]?.amount;
                const taxIds = filteredData[0]?.tax.map((taxId: any) => taxId.id);
                prevState[index]['tax_ids'] = taxIds;
            }
        }else{
                prevState[index][evt.target.name] = feeStructureValue ;
                prevState[index]['amount'] = '';
                prevState[index]['tax_ids'] = [];
        }
        }
        this.setState({feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, sub_fee_structures_attributes: prevState } })
        

    }

    calculateMaxDateByDuration = (duration: string,duration_no: number,currentDate:string) => {
        let maxDate: Date | null;
        const selectedDate = new Date(currentDate);
        if(duration === 'weeks'){
            maxDate = new Date(selectedDate.setDate(selectedDate.getDate() + 7* duration_no));
            return maxDate;
        }
        else if(duration === 'months'){
            maxDate = new Date(selectedDate.setDate(selectedDate.getDate() + 30* duration_no));
            return maxDate;
        }else if(duration === 'year'){
            maxDate = new Date(selectedDate.setDate(selectedDate.getDate() + 365* duration_no));
            return maxDate;
        }else{
            return null;
        }

    }
    handleInstallmentCycleField = (evt: React.ChangeEvent<HTMLInputElement>, indx: number) => {
        // this.handleValidation({...this.state.feeAssignmentFormstate, [evt.target.name]: evt.target.value});
        console.log(evt.target.name)
        let value = evt.target.value;
        if (evt.target.name === "fee_id") {
            value = (evt.target.value === "null") ? "" : evt.target.value;
        }

        const { installments } = this.state.feeAssignmentFormstate;
        const newState: any = [...this.state.feeAssignmentFormstate.installments.installment_details_attributes];
        
        newState[indx][evt.target.name] = evt.target.value;
        this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, installment_details_attributes: newState } } });
        
    }
    equallyCalculateMonth=(billDurationNo:any,value:any,i:any)=>{
        const initialMonth = new Date(value);
        const nextMonth  = new Date(initialMonth.setMonth(initialMonth.getMonth()+i*billDurationNo));
        let nextMonthDueDate = new Date(nextMonth);
        console.log("Welcome to Programiz!",nextMonthDueDate);
        return nextMonthDueDate;
       
    }
    equallyCalculateWeek=(billDurationNo:any,value:any,i:any)=>{
        // console.log("Welcome to Programiz!",billDurationNo,value,i);
        const initialDate = new Date(value);
        const nextWeek  = new Date(initialDate.setDate(initialDate.getDate() + i*7 * billDurationNo));
        let nextWeekDueDate = new Date(nextWeek);
        console.log("Welcome to Programiz!",nextWeekDueDate);
        return nextWeekDueDate;
       
    }
    equallyCalculateYear=(billDurationNo:any,value:any,i:any)=>{
        // console.log("Welcome to Programiz!",billDurationNo,value,i);
        const initialYear = new Date(value);
        const nextYear  = new Date(initialYear.setFullYear(initialYear.getFullYear()+i*billDurationNo));
        let nextDueYear = new Date(nextYear);
        console.log("Welcome to Programiz!",nextDueYear);
        return nextDueYear;
       
    }
 

    handleDateChange = (indx: any)=>(value: any) => {
        // let value1= moment(value).format("y-MM-d")
      console.log("value",value)
      const { installments } = this.state.feeAssignmentFormstate;
      const billDuration=installments.bill_duration
      const billDurationNo=installments.bill_duration_no
      const billingCycle:any= installments.billing_cycle
      const newState: any = [...this.state.feeAssignmentFormstate.installments.installment_details_attributes];
      
      if(installments.payment_collection =="equally_distributes" && billingCycle &&billDurationNo ){
        for (let i = 0; i < billingCycle; i++) {
         if(billDuration == "weeks"){
          const DueWeek= this.equallyCalculateWeek(billDurationNo,value,i)
          newState[i]['due_date'] = DueWeek;
          this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, installment_details_attributes: newState } } });

         }else if(billDuration == "months"){
          const DueMonth= this.equallyCalculateMonth(billDurationNo,value,i)
          newState[i]['due_date'] = DueMonth;
          this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, installment_details_attributes: newState } } });
         }else{
            const DueYear= this.equallyCalculateYear(billDurationNo,value,i)
            newState[i]['due_date'] = DueYear;
            this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, installment_details_attributes: newState } } });
  
         }
        }
      }else{
        if(installments.bill_duration && installments.bill_duration_no && installments.billing_cycle && indx+1 < installments.billing_cycle){
            const maxDateRes = this.calculateMaxDateByDuration(installments.bill_duration,installments.bill_duration_no,value);
            newState[indx+1]['max_date'] = maxDateRes;
            const currentMinDate = new Date(value);
            const nextMinDate = currentMinDate.setDate(currentMinDate.getDate() + 1);
            newState[indx+1]['min_date'] = nextMinDate;
        }
           newState[indx]['due_date'] = value;
           this.setState({feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installments: { ...this.state.feeAssignmentFormstate.installments, installment_details_attributes: newState } } });
      }

      };
    handleValidateFeeAssignment = (formState: InitialField) => {
        
        let isValid = true;
        const regex = /^[^-\s][a-zA-Z0-9_\s-]{2,40}[^\s]$/i;
        
        console.log("feeStructureError===================", this.state.step , this.state.currentForm)
        console.log(formState,"555555555555555555555555555")
        if(this.state.step == 0){
        if (formState.name === "") {
            this.setState({ feeStructureNameError: ('Fee structure name is required') });
            isValid = false;
        } else if (formState.name && regex.test(formState.name) === false) {
            this.setState({
                feeStructureNameError: ('Fee structure name is invalid'
                )
            });
            isValid = false;
        } else {
            this.setState({ feeStructureNameError: '' });
        }
        if (formState.academic_year_id === 0 || !formState.academic_year_id) {
            this.setState({
                academicYearError: (
                    'Academic year is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ academicYearError: '' });
        }
        if (formState.feeAssignmentType === "") {
            this.setState({
                feeAssignmentTypeError: (
                    'Atleast one field is required'
                )
            });
            isValid = false;

        } else {
            this.setState({ feeAssignmentTypeError: '' });
        }
        if ((formState.feeAssignmentType === "is_class" || formState.feeAssignmentType === "is_class_and_division") && formState.grade_id === 0 || !formState.grade_id) {
            this.setState({
                feeAssignmentClassError: (
                    'Class is required'
                )
            });
            isValid = false;

        } else {
            this.setState({ feeAssignmentClassError: '' });
        }
        if (formState.feeAssignmentType === "is_class_and_division" && formState.division_id === 0 ) {
            this.setState({
                feeAssignmentDivisionError: (
                    'Division is required'
                )
            });
            isValid = false;

        } else {
            this.setState({ feeAssignmentDivisionError: '' });
        }
        if (formState.feeAssignmentType === "is_group" && formState.group_attributes.student_ids.length === 0 ) {
            this.setState({
                feeAssignmentGroupError: (
                    'students is required'
                )
            });
            isValid = false;

        } else {
            this.setState({ feeAssignmentGroupError: '' });
        }
    }else if(this.state.step == 1){
        for (let i = 0; i < formState.sub_fee_structures_attributes.length; i++) {

            if (formState.sub_fee_structures_attributes[i].id === 0 || !formState.sub_fee_structures_attributes[i].id) {
                // this.setState({
                //     feeNameError: 
                //         ['Fee name is required']checkStudentRow
                //  });
                const newState: any = this.state.feeStructureError;
                newState[i]["feeNameError"] = "Fee name is required";
                this.setState({ feeStructureError: newState });
                isValid = false;
            } else {
                const newState: feeStructureErrorField[] = this.state.feeStructureError;
                newState[i]["feeNameError"] = "";
                this.setState({ feeStructureError: newState });
            }
            if (formState.sub_fee_structures_attributes[i].amount === "") {
                // this.setState({
                //     feeAmountError: [
                //         'Fee amount is required'
                //     ]
                // });
                const newState: any = this.state.feeStructureError;
                newState[i]["feeAmountError"] = "Fee Amount is required";
                this.setState({ feeStructureError: newState });
                isValid = false;

            } else {
                const newState: any = this.state.feeStructureError;
                newState[i]["feeAmountError"] = "";
                this.setState({ feeStructureError: newState });
            }

            if (formState.sub_fee_structures_attributes[i].tax_ids.length == 0) {
              console.log("formState.sub_fee_structures_attributes[i].tax_ids",formState.sub_fee_structures_attributes[i].tax_ids)
                const newState: any = this.state.feeStructureError;
                newState[i]["feeTaxError"] = "Tax is required";
                this.setState({ feeStructureError: newState });
                isValid = false;

            } else {
                console.log("formState.sub_fee_structures_attributes[i].tax_ids",formState.sub_fee_structures_attributes[i].tax_ids)
                const newState: any = this.state.feeStructureError;
                newState[i]["feeTaxError"] = "";
                this.setState({ feeStructureError: newState });
            }
        }
    }else if(this.state.currentForm =="installmentForm"){
        if (formState.installments.name === "") {
            this.setState({ feeInstallmentNameError: ('Fee installment name is required') });
            isValid = false;
        } else if (formState.name && regex.test(formState.name) === false) {
            this.setState({
                feeStructureNameError: ('Fee installment name is invalid'
                )
            });
            isValid = false;
        } else {
            this.setState({ feeInstallmentNameError: '' });
        }
        
        if (formState.installments.bill_duration_no === 0 || !formState.installments.bill_duration_no) {
            this.setState({ feeInstallmentDurationError: ('Duration number is required') });
            isValid = false;
        } else {
            this.setState({ feeInstallmentDurationError: '' });
        }
        if (formState.installments.bill_duration === "" || !formState.installments.bill_duration ) {
            this.setState({ feeInstallmentBillDurationError: ('Please select bill duration') });
            isValid = false;
        }else {
            this.setState({ feeInstallmentBillDurationError: '' });
        }
        if (formState.installments.billing_cycle === 0 || !formState.installments.billing_cycle) {
            this.setState({ feeInstallmentBillingCycleError: ('Billing cycle is required') });
            isValid = false;
        } else {
            this.setState({ feeInstallmentBillingCycleError: '' });
        }
        let concessionType=formState.installments.concession_type?formState.installments.concession_type:'';
       
        if (formState.installments.concession_type && !formState.installments.concession) {
            this.setState({ feeInstallmentConcessionError: ('Concession '+concessionType.charAt(0).toLowerCase()+ concessionType.substring(1)+' is required') });
            isValid = false;
        }else if (formState.installments.concession_type === "" && formState.installments.concession ) {
            console.log("formState.installments.concession_type",formState.installments.concession_type)
            this.setState({ feeInstallmentConcessionTypeError: ('Concession type is required') });
            isValid = false;
        }else if (formState.installments.concession_type && formState.installments.concession) {
            this.setState({ feeInstallmentConcessionTypeError: ('') });
             if(formState.installments.concession_type ==="percentage"){
                 if(formState.installments.concession < 0 || formState.installments.concession > 100 ){
                    this.setState({ feeInstallmentConcessionError: ('Concession '+concessionType.charAt(0).toLowerCase()+concessionType.substring(1)+' is invalid') });
                    isValid = false;
                 }else{
                    this.setState({ feeInstallmentConcessionError: '' });
                 }
             }else if(formState.installments.concession_type === "amount"){
                if(formState.installments.concession > formState.sum_total_amount || formState.installments.concession < 0 ){
                    // console.log("formState.installments.concession",formState.installments.concession)
                    // console.log("formState.installments.total_amount",formState.installments.total_amount)
                    this.setState({ feeInstallmentConcessionError: ('Concession '+concessionType.charAt(0).toLowerCase()+concessionType.substring(1)+' is invalid') });
                    isValid = false;
                }else{
                    this.setState({ feeInstallmentConcessionError: '' });
                 }
             }else{
                this.setState({ feeInstallmentConcessionError: '' });
             }
            
        }else {
            this.setState({ feeInstallmentConcessionError: '' ,feeInstallmentConcessionTypeError:''});
        }

        for (let i = 0; i < formState.installments.installment_details_attributes.length; i++) {
            if (formState.installments.installment_details_attributes[i].name === '' ) {

                const newState: feeInstallmentDetailsErrorField[] = this.state.feeInstallmentError;
                newState[i]["installmentNameError"] = "Installment name is required";
                this.setState({ feeInstallmentError: newState });
                isValid = false;
            } else {
                const newState: feeInstallmentDetailsErrorField[] = this.state.feeInstallmentError;
                newState[i]["installmentNameError"] = "";
                this.setState({ feeInstallmentError: newState });
            }
            if (formState.installments.installment_details_attributes[i].due_date == null) {

                const newState: any = this.state.feeInstallmentError;
                newState[i]["installmentNameDueDateError"] = "Due Date is required";
                this.setState({ feeInstallmentError: newState });
                isValid = false;

            } else {
                const newState: any = this.state.feeInstallmentError;
                newState[i]["installmentNameDueDateError"] = "";
                this.setState({ feeInstallmentError: newState });
            }

            // if (formState.installments.installment_details_attributes[i].fine_id === 0 || !formState.installments.installment_details_attributes[i].fine_id) {

            //     const newState: any = this.state.feeInstallmentError;
            //     newState[i]["installmentFineError"] = "Fine is required";
            //     this.setState({ feeInstallmentError: newState });
            //     isValid = false;

            // } else {
            //     const newState: any = this.state.feeInstallmentError;
            //     newState[i]["installmentFineError"] = "";
            //     this.setState({ feeInstallmentError: newState });
            // }
        }
    }
        return isValid;
      
    };


    handelCancel = () => {
        this.setState({ AddStudentStatus: !this.state.AddStudentStatus })
        this.setState({ closeModal: true })
        this.props.handleClose("create", false);
        this.resetFormState();
        this.props.feeAssignmentList(0)
    }
    handelStudentCancel = () => {
        this.setState({ AddStudentStatus: !this.state.AddStudentStatus })
        // this.props.studentList
        // this.props.getStudentList(0,0,0,'')
        // this.setState({ closeModal: true })
        // this.resetFormState();
        // this.props.feeAssignmentList(0)
    }

    handleDetailsPlansForm = (data: installmentMultiFieldAttributes, index_id: number) => {
        this.setState({ step: 2, currentForm: 'installmentForm', installmentPlanDetailsData: data });
    }
    handleStudentNext = (e: any) => {
        // const ids = Object.keys(this.state.checkBulkUpdateRow!== null && this.state.checkBulkUpdateRow).filter((item: any) => )
        const ids = Object.keys(this.state.checkStudentRow && this.state.checkStudentRow).filter((item: any) => this.state.checkStudentRow && this.state.checkStudentRow[item])
        this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, group_attributes: { ...this.state.feeAssignmentFormstate.group_attributes, student_ids: ids } } });
        this.setState({ AddStudentStatus: false })
        console.log("studentNext", e.target)
    }

    handleNext = (e: any) => {
        e.preventDefault();
        if(this.props.viewForm === true){
            if(this.state.step ===1){
                this.setState({ step: 2, currentForm : 'installmentPlanForm' });
            }else if(this.state.step ===2 && this.state.currentForm == 'installmentForm'){
                this.setState({ step: 2, currentForm : 'installmentPlanForm' });
            } else{
                this.setState({ step: this.state.step + 1 });
            }
        } else {

            if (this.handleValidateFeeAssignment(this.state.feeAssignmentFormstate)) {
                if(this.state.step < 2){
                    this.setState({ step: this.state.step + 1 });
                }
                console.log(this.state.step,"this.state.stepthis.state.step")
                
                if (this.state.currentForm == 'installmentForm') {

                    const { installments, installmentFormAttributes, sum_total_amount } = this.state.feeAssignmentFormstate;
                    let newInstall = installmentFormAttributes;
                    if (this.state.installPlanAction === "edit") {
                        let installmentsArr = [...installmentFormAttributes];
                        installmentsArr[parseInt(this.state.updatePlanIndex)] = { ...installments };
                        this.setState({ installPlanAction: 'create', currentForm: "installmentPlanForm", feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installmentFormAttributes: installmentsArr } });
                    } else {
                        if (installmentFormAttributes.length > 0) {
                            newInstall.push({ ...installments, total_amount: sum_total_amount });
                        } else {
                            newInstall = [{ ...installments, total_amount: sum_total_amount }];
                        }
                        console.log(newInstall,"newInstallnewInstall")
                        console.log(installments,"installments55555555")
                        this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, installmentFormAttributes: newInstall }, currentForm: "installmentPlanForm" })
                    }

                }
             
                if(this.state.step == 1 ){
                   
                    this.setState({currentStep: this.state.step,currentForm: 'installmentAddForm'})
                }
                if (this.state.step == 2 && this.state.currentForm !== 'installmentForm') {
                    const feeSum: number = this.state.feeAssignmentFormstate.sub_fee_structures_attributes.map(a => parseFloat(a.amount)).reduce(function (a, b) {
                        return a + b;
                    });

                    this.setState({ feeAssignmentFormstate: { ...this.state.feeAssignmentFormstate, sum_total_amount: feeSum }, currentForm: 'installmentForm' });
                }
     
        }
        }
    }


    handleFeeAssignmentSubmit = (e: any) => {
        e.preventDefault();
        // if (this.handleValidation(this.state.feeAssignmentFormstate)) {
        const postData: any = { ...this.state.feeAssignmentFormstate };
        postData.sub_fee_structures_attributes = this.state.feeAssignmentFormstate.sub_fee_structures_attributes ? this.state.feeAssignmentFormstate.sub_fee_structures_attributes.map((sub_fee, index) => {
            return {
                fee_id: sub_fee.id,
                fee_attributes: { ...sub_fee }
            }
        }) : [];
        let installments = [...postData.installmentFormAttributes];
        delete postData.installmentFormAttributes;
        postData.installments_attributes = installments;
        console.log("postDatas: ", postData);
        this.props.handleSubmit({ fee_structure: postData });
        this.resetFormState();
        // }
    };
    // Customizable Area End
}
