export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const userRolesIcon = require("../assets/userRoleIcon.png");
export const userIcon = require("../assets/userIcon.png");
export const roleIcon = require("../assets/roleIcon.png");
export const sideMenuIcon = require("../assets/subMenuIcon.png");
export const logo = require("../assets/logo.png");
export const drawerOpenIcon = require("../assets/drawerOpenIcon.png");
export const drawerCloseIcon = require("../assets/drawerCloseIcon.png");
export const logo1 = require("../assets/logo1.png");
export const masterData = require("../assets/masterData.png");
export const concession = require("../assets/concession.png");
export const moduloWhite = require("../assets/moduloWhite.png");
export const tax = require("../assets/tax.png");
export const users = require("../assets/users.png");
export const account = require("../assets/account.png");
export const feeManagement = require("../assets/feeManagement.png");
export const feeAssignmentIcon = require("../assets/feeAssignmentIcon.png");
export const ReportsIcon = require("../assets/Reports.png");
export const feeMasters = require("../assets/feeMasters.png");
export const sidebarTaxIcon = require("../assets/sidebarTaxIcon.png");
export const sidebarTaxRateIcon = require("../assets/taxRatesIcon.png");
export const sidebarSettingsIcon = require("../assets/settingsIcon.png");
export const sidebarCustomersIcon = require("../assets/customers.png");
export const expenseIcon = require("../assets/expensesIcon.png");
export const sidebarOrganizationIcon = require("../assets/settingsIcon.png");
export const sidebarFineIcon = require("../assets/fineIcon.png");
export const organizationIcon = require("../assets/organizationIcon.png");
export const fineIcon = require("../assets/fineIcon.png");
export const PurchaseIcon = require("../assets/Purchase.png");
export const vendorsIcon = require("../assets/vendorsIcon.png");
export const recurringExpenseIcon = require("../assets/recurringExpenseIcon.png");
export const salesIcon = require("../assets/salesIcon.png");
export const paymentPreferenceIcon = require("../assets/paymentPreference.png");
export const dashboardIcon = require("../assets/dashboard.png");