import React,{ Component } from "react";
// Customizable Area Start
import {  Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// Customizable Area End

// Customizable Area Start


const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 16,
    marginBottom: 10,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
  },
});

type commonProps = { 
  cardCountData:any,
  classes: any

};

// Customizable Area End
export default class CommonCardCount extends Component<commonProps> {
  render() {

    // Customizable Area Start
     const { cardCountData,classes } = this.props;
     console.log(cardCountData.length,"lengthhhhhhhhhhhh")
    // Customizable Area End

    return (
      // Customizable Area Start
      //handleGlobalSearch={this.handleSearchChange}
          
            <Paper className={classes.paper}>
              <Grid container style={{
                backgroundColor: "#ffffff",
                height: "40px",
                position:"relative",
                }}>
                  {cardCountData && cardCountData.map((row: any,i:number) => (
                  <Grid item style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "25px",
                    }}>
                     <span style={{
                        backgroundColor:" #fcf3d4",
                        margin: "0px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: '6px',
                        paddingBottom: "6px",
                        display:"flex",
                        borderRadius:"5px"
                        }}
                        >
                    <img height={'25px'} width={'25px'} src={row.icon}/>
                    </span>
                    <span style={{padding: "0px 50px 0px 18px",color:"#3f5371"}}>
                    {row.title}
                    </span>
                    <span style={cardCountData.length-1 ===i?{fontSize: "18px",color:"#3f5371",paddingRight: "40px"}:{fontSize: "18px",color:"#3f5371",borderRight:"1px solid rgb(0 0 0 / 9%)",paddingRight: "40px"}} >{row.amount}</span>
                    {/* <Grid style={{
                    height: "32px",
                    width: "2px",
                    backgroundColor: "rgb(0 0 0 / 9%)",
                    marginLeft: "25px",
                    marginTop:"8px"
                  }}>
                    </Grid> */}
                  </Grid>
                  ))
                  }
                  
                </Grid>
            </Paper>
          
          
      // Customizable Area End
    );
  }
}
